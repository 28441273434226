import { ThemedWidget } from '../../mixins';
import { ceBase } from './votingWidgets';
export class LiveLikePoll extends ThemedWidget {
    constructor() {
        super(...arguments);
        this.owner = this;
        this.hideVoteInResults = true;
    }
    firstUpdated() {
        super.firstUpdated();
        this.interaction &&
            (this.selectedOption = this.options.find((opt) => opt.id === this.interaction.option_id));
        this.interaction &&
            (this.vote = {
                id: this.interaction.id,
                url: this.interaction.url,
            });
    }
    updated(changedProps) {
        super.updated(changedProps);
        changedProps.has('isStockWidget') &&
            this.showUserVoteInstantly === undefined &&
            (this.showUserVoteInstantly = this.isStockWidget);
    }
    submitVote(option) {
        if (!this.disabled &&
            option.id !== this.selectedOption.id &&
            !this.voteDisable) {
            this.showUserVoteInstantly && this.updateVoteCount(option);
            this.selectedOption = option;
            this.interactionEvent();
            this.voteDisable = true;
            this.vote && this.vote.id
                ? this.updateVote(this.vote.url, { option_id: option.id })
                : this.createVote(option.vote_url);
        }
    }
    // Updates vote_count for a prompt feedback
    updateVoteCount(option) {
        let voteCountValid = true;
        const updatedOptions = this.options.map((item) => {
            const decrement = item.id === this.selectedOption.id ? -1 : 0; // For decrementing vote count of the last selected option
            const increment = item.id === option.id ? 1 : 0;
            const newVoteCount = item.vote_count + increment + decrement;
            newVoteCount < 0 && (voteCountValid = false);
            return Object.assign(Object.assign({}, item), { vote_count: newVoteCount });
        });
        voteCountValid && (this.options = updatedOptions);
    }
}
ceBase('livelike-text-poll', LiveLikePoll);
ceBase('livelike-image-poll', LiveLikePoll);

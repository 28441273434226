var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, property } from 'lit-element';
import { ThemedElement } from '../../mixins/ThemedElement';
import { eventBus, eventDispatcher } from '../../utils';
import { element } from '../../utils/decorators';
import { styles } from './livelike-option.style.js';
// TODO Document submitVote firing. Change submitVote name because not everything is a vote - answer, prediction, etc.
/**
 * Instantiated with `option` or `choice` object from `widgetPayload` `options` or `choices` array.
 * On click, fires 'option-clicked' event.
 * @element livelike-option
 * @event click
 * @fires option-clicked
 * @attr changed
 * @attr disabled
 * @attr correct
 * @attr incorrect
 * @attr selected
 */
let LiveLikeOption = class LiveLikeOption extends ThemedElement {
    constructor() {
        super(...arguments);
        this.defaultStyles = styles;
        this.image = null;
        this.selected = null;
        this.correct = null;
        this.incorrect = null;
        this.disabled = null;
        this.optionSelected = () => {
            if (!this.disabled) {
                // Remove selected prop from all siblings;
                this.parentElement
                    .querySelectorAll('livelike-option')
                    .forEach((el) => el !== this && (el.selected = false));
                this.selected = true;
                // Call vote action
                this.owner.submitVote(this.item);
                eventDispatcher(this, 'option-clicked', { item: this.item });
            }
        };
        this.handleResults = (e) => {
            if (this.item.hasOwnProperty('is_correct') &&
                (!this.owner.hideVoteInResults ||
                    (this.owner.selectedOption && this.owner.selectedOption.id))) {
                this.item.is_correct
                    ? (this.correct = true)
                    : this.selected && (this.incorrect = true);
            }
            else if (this.item.hasOwnProperty('correct_number')) {
                this.item.number === this.item.correct_number
                    ? (this.correct = true)
                    : this.item.number && (this.incorrect = true);
            }
        };
        this.ownerUpdated = (e) => {
            e.name === 'voteDisable' &&
                this.owner.waitForResponse &&
                (this.disabled = this.owner.disabled || e.prop);
        };
    }
    updated(changedProps) {
        changedProps.forEach((prevProp, name) => {
            // Noting for the future. Not firing event if null or undefined might cause issue.
            if (this[name] !== undefined && this[name] !== null && this.item) {
                const optionUpdate = {
                    name,
                    prop: this[name],
                    key: this.item.id,
                    owner: this.owner,
                };
                eventBus.emit('option-updated', optionUpdate);
                eventDispatcher(this, 'option-updated', optionUpdate);
                // This should be moved to Mixin, so integrator can access correct, minimal vote updates
                // on elements without requiring the use of the livelike-option element.
                if (name === 'options' || name === 'choices' || name === 'items') {
                    const newCount = this.items[this.optionIndex]['vote_count' || 'answer_count'];
                    newCount !== this.voteCount && (this.voteCount = newCount);
                    eventDispatcher(this, 'option-vote-updated', this.items[this.optionIndex]);
                }
            }
        });
    }
    connectedCallback() {
        super.connectedCallback();
        this.addEventListener('click', this.optionSelected);
        // console.log('LIVELIKE OPTION this.owner', this.owner);
        const parentEl = this.parentElement;
        !this.owner && (this.owner = parentEl.owner);
        // Workaround to LitElement's update Lifecycle. This sets the item prop directly.
        // Tried to set the prop directly with livelike-select's item.map and customClone().
        // Works but unviable - on connectedCallback, all props are undefined, so all children
        // would need to wait for the item prop and execute everything in firstUpdated.
        // TODO Figure out a fix.
        // NOTE Maybe Everything should just be switched from connectedCallback to firstUpdate();
        // This issue with that is you'd still need to wait for the item prop because
        // it's not guaranteed to run after that prop is set.
        const selfIndex = this.getAttribute('index');
        this.optionIndex = selfIndex;
        this.removeAttribute('index');
        // TODO Remove this.item and this.items
        const items = this.owner.options || this.owner.choices;
        this.item = items[selfIndex];
        this.voteCount = items[selfIndex].vote_count;
        this.disabled = this.owner.disabled;
        this.value = this.item.id;
        this.image = !!this.item.image_url;
        this.owner.options && (this.option = this.owner.options[selfIndex]);
        this.owner.choices && (this.choice = this.owner.choices[selfIndex]);
        this.owner.selectedOption &&
            this.owner.selectedOption.id &&
            this.owner.selectedOption.id === this.value &&
            (this.selected = true);
        (this.owner.phase === 'results' || this.owner.phase === 'finished') &&
            this.handleResults();
        this.owner.addEventListener('results', this.handleResults);
        this.addEventListener('option-provider', (e) => {
            e.detail.provider = this;
            e.stopPropagation();
        });
        // super.connectedCallback();
    }
    disconnectedCallback() {
        this.removeEventListener('click', this.optionSelected);
        super.disconnectedCallback();
    }
    render() {
        return html ` <slot></slot> `;
    }
};
__decorate([
    property({ reflect: true, type: Boolean })
], LiveLikeOption.prototype, "image", void 0);
__decorate([
    property({ reflect: true, type: Boolean })
], LiveLikeOption.prototype, "selected", void 0);
__decorate([
    property({ reflect: true, type: Boolean })
], LiveLikeOption.prototype, "correct", void 0);
__decorate([
    property({ reflect: true, type: Boolean })
], LiveLikeOption.prototype, "incorrect", void 0);
__decorate([
    property({ reflect: true, type: Boolean })
], LiveLikeOption.prototype, "disabled", void 0);
__decorate([
    property({ type: Object })
], LiveLikeOption.prototype, "option", void 0);
__decorate([
    property({ type: Object })
], LiveLikeOption.prototype, "choice", void 0);
__decorate([
    property({ type: Object })
], LiveLikeOption.prototype, "item", void 0);
__decorate([
    property({ type: String })
], LiveLikeOption.prototype, "value", void 0);
LiveLikeOption = __decorate([
    element('livelike-option')
], LiveLikeOption);
export { LiveLikeOption };

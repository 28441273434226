import { ThemedWidget } from '../../mixins';
import { votingStyles } from './votingWidget.styles';
import { ceBase, ResultWidgetMixin } from './votingWidgets';
export class LiveLikeQuiz extends ResultWidgetMixin(ThemedWidget) {
    constructor() {
        super(...arguments);
        this.defaultVoteHide = true;
        this.quizVoteSubmitted = false;
        this.hideVoteInResults = true;
        this.owner = this;
        this.defaultStyles = votingStyles;
        this.submitVote = (option) => {
            if (!this.disabled && option.id !== this.selectedOption.id) {
                this.selectedOption = option;
                this.syntheticIncrement = true;
                this.interactionEvent();
            }
        };
        this.lockInVote = (option) => {
            var _a;
            if (!this.voteDisable && ((_a = this.selectedOption) === null || _a === void 0 ? void 0 : _a.id)) {
                this.updateAnswerCount(this.selectedOption);
                this.voteDisable = true;
                this.createVote(this.selectedOption.answer_url).then(() => {
                    this.quizVoteSubmitted = true;
                    this.disabled = true;
                });
            }
        };
    }
    firstUpdated() {
        super.firstUpdated();
        if (this.interaction) {
            this.selectedOption = this.choices.find((opt) => opt.id === this.interaction.choice_id);
            this.quizVoteSubmitted = true;
            this.disabled = true;
        }
        //set this.vote also to make sure new vote is not sent from same profile
    }
    // Updates answer_count for a prompt feedback
    updateAnswerCount(option) {
        this.choices = this.choices.map((item) => {
            const increment = item.id === option.id ? 1 : 0;
            return Object.assign(Object.assign({}, item), { answer_count: item.answer_count + increment });
        });
    }
}
ceBase('livelike-text-quiz', LiveLikeQuiz);
ceBase('livelike-image-quiz', LiveLikeQuiz);

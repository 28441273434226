import { html } from 'lit-element';
export const messageListStyles = html `<style>
  :host {
    display: block;
    overflow: auto;
  }
  .load-prev-msg-container {
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;
  }
  .load-prev-msg-container button {
    padding: 0.5rem;
    background: #27bf9d;
    border: 0;
    color: #212333;
    cursor: pointer;
  }
  .load-prev-msg-container button:disabled {
    background: #84858b;
    cursor: not-allowed;
  }
</style>`;

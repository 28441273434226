import { track } from './analytics';
import { outsideClick } from './outsideClick';
const animate = {
    SHOW: '1',
    HIDE: '0',
};
export const closeItem = (component, type) => {
    component.outsideClick.remove();
    component.animation = animate.HIDE;
    setTimeout(() => (component[type] = null), 300);
};
const openItem = (component, type, selectedId) => {
    component[type] = selectedId;
    setTimeout(() => {
        component.outsideClick = outsideClick(component, () => closeItem(component, type));
        component.outsideClick.register();
        component.animation = animate.SHOW;
    }, 50);
    const trackObj = {
        'Chat Room ID': component.owner.roomid,
        'Chat Message ID': selectedId,
    };
    type === 'selectedReactions' && track('Chat Reaction Panel Opened', trackObj);
};
export const toggle = (component, selectedId, type) => component[type] === selectedId
    ? closeItem(component, type)
    : openItem(component, type, selectedId);

import { html } from 'lit-element';
export const quoteMessageItemStyles = html `
  <style>
    :host {
      overflow: auto;
    }

    .quote-message-item-container {
      position: relative;
      display: flex;
      justify-content: flex-start;
      width: 100%;
      background: #2a2a2a;
      padding: 0.7rem;
      border-radius: 12px;
    }

    .quote-message-wrapper {
      display: flex;
      opacity: 1;
      transition: all 0.4s;
      border-radius: 0.75rem;
      position: relative;
    }

    .quote-sender-name {
      font-size: 1rem;
      display: inline-block;
      color: #ffff;
      margin: 0 0 0.25rem 0;
      word-break: break-word;
      word-wrap: break-word;
    }

    .quote-sender-message {
      font-size: 1rem;
      display: flex;
      word-break: break-word;
      word-wrap: break-word;
      color: #949494;
      line-height: 1.3;
      padding: 0;
      flex-wrap: wrap;
      overflow: hidden;
    }

    .quote-sender-message .image-message {
      max-width: 6rem;
    }

    .quote-sender-message.deleted,
    .quote-sender-message.sender-blocked {
      font-style: oblique;
      font-size: 0.9rem;
    }
  </style>
`;

import { html } from 'lit-html';
export const styles = () => `
    :host {
      display: flex;
    }

    livelike-chat-composer {
      display: flex;
      position: relative;
      flex-direction: column;
      flex-shrink: 0;
    }
    .livelike-composer-quote-message {
      display: flex;
      width: 95%;
      border-radius: 12px;
      background: rgb(0, 0, 0, 0.4);
      padding: 0.5rem;
    }

    livelike-quote-message-item {
      flex-grow: 1;
    }

    .livelike-compose-quote-message-cancel {
      display: flex;
      padding: 0 0.3rem 0 0.8rem;
      align-items: center;
      color: #808080;
    }

    .livelike-compose-quote-message-cancel svg {
      transform: scale(2);
    }

    .livelike-compose-quote-message-cancel circle {
      fill: transparent;
      cursor: pointer;
    }

    .livelike-compose-quote-message-cancel path {
      fill: #808080;
      cursor: pointer;
    }

    .livelike-chat-input {
      border: 0;
      background: #fff;
      box-sizing: border-box;
      border-radius: 0.5rem;
      color: inherit;
      font-size: 1rem;
      font-family: inherit;
      line-height: 1.5rem;
      height: 40px;
      min-height: 2.5rem;
      overflow: hidden;
      padding: 0.5rem 5rem 0.5rem 0.5rem;
      resize: none;
      width: 100%;
      border: 1px solid transparent;
    }
    .livelike-chat-input:focus {
      outline: none;
      border: 1px solid rgba(255, 255, 255, 0.2);
    }
    .livelike-chat-input::placeholder {
      color: rgba(0, 0, 0, 0.35);
    }
    .send-button {
      background: none;
      border: none;
      padding: 0;
      margin-left: 1rem;
      display: flex;
      align-items: center;
      transition: all 0.3s ease-out;
    }
    .send-icon {
      border: none;
      background: none;
    }
    .send-icon svg {
      fill: #777;
    }
    .send-icon:hover svg,
    .send-icon:focus svg {
      filter: invert(1);
      transition: all 0.3s ease-out;
    }
    .send-icon:hover {
      cursor: pointer;
    }
    .sticker-picker-container {
      position: absolute;
      border: none;
      background: none;
      right: 2rem;
      bottom: 0.5rem;
      transition: all 0.3s ease-out;
    }
`;
export const composerStyles = html `
  <style>
    .livelike-chat-composer-input {
      display: flex;
      position: relative;
      flex-shrink: 0;
    }
  </style>
`;

import { html } from 'lit-element';
export const styles = html `
  <style>
    .sticker-nav-container {
      position: relative;
      background: rgba(40, 40, 40, 1);
      height: auto;
      width: 100%;
      border-radius: 0 0 8px 8px;
      overflow: auto;
      min-width: 15rem;
    }
    .sticker-nav-wrap {
      display: flex;
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
    .sticker-nav-item {
      border: none;
      background: rgba(255, 255, 255, 0);
      transition: all 0.2s;
      padding: 10px;
    }
    .sticker-nav-item.active {
      background: rgba(20, 20, 20, 1);
      border-radius: 0 0 8px 8px;
    }
    .sticker-nav-item:hover {
      cursor: pointer;
    }
    .sticker-nav-item img {
      width: 20px;
      height: 20px;
      vertical-align: middle;
      border: 0;
    }
  </style>
`;

import { convertToCssString as cs } from '../../utils/themeConversions';
export const styles = (theme) => `
    .widget-dismiss {
      z-index: 1;
      border: none;
      background:none;
      height: 18px;
      margin: 0.5rem;
      display: block;
      ${cs(theme.dismiss)}
    }
    .widget-dismiss:hover {
      cursor: pointer;
    }
  `;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { buildUrl, iteratorBase, paginator, request } from '../utils';
import { getBlockProfileIdsResource, setBlockProfileIdsResource, } from '../utils/blockProfileIds';
import { validateArgObj } from '../utils/validateArgs';
import { userProfile, _$$ } from './internal/globals';
import { UserProfileEvent } from '../constants/subscriptionEvents';
import { addUserProfileEventListener, } from './userProfile';
/**
 * @description Block profile using profile Id
 * @async
 * @since 2.13.0
 * @example
 * ```js
 * blockProfile({profileId: "aa7e03fc-01f0-4a98-a2e0-3fed689632d7"}).then(blockInfo => console.log(blockInfo))
 * ```
 */
export function blockProfile(args) {
    return validateArgObj(args, ['profileId'], 'blockProfile').then(() => request.post({
        url: userProfile.block_profile_url,
        data: {
            blocked_profile_id: args.profileId,
        },
    }));
}
/**
 * @description Unblock profile using Block Info id for a given blocked profile, use `getProfileBlockInfo` or `getBlockInfoList` to get corresponding profile block info id
 * @async
 * @since 2.13.0
 * @example
 * ```js
 * unblockProfile({blockId: "c327a72c-94e5-4f5a-bcef-16ee84d35077"}).then(response => console.log(response))
 * ```
 */
export function unblockProfile(args) {
    return validateArgObj(args, ['blockId'], 'unblockProfile').then(() => request.delete({ url: `${userProfile.block_profile_url}${args.blockId}/` }));
}
/**
 * @description Get Block Info list containing list of block info of all the blocked profile
 * @async
 * @since 2.13.0
 * @example
 * ```js
 * getBlockInfoList().then(paginatedBlockInfo => console.log(paginatedBlockInfo))
 * ```
 */
export function getBlockInfoList() {
    return iteratorBase(['results'], paginator(userProfile.block_profile_url, (url) => request.get({ url }), (r) => ({ count: r.count, results: r.results })));
}
/**
 * @description Get Block Info for given profile id if exists
 * @async
 * @since 2.13.0
 * @example
 * ```js
 * getProfileBlockInfo({profileId: "aa7e03fc-01f0-4a98-a2e0-3fed689632d7"}).then(blockInfo => console.log(blockInfo))
 * ```
 */
export function getProfileBlockInfo(args) {
    return __awaiter(this, void 0, void 0, function* () {
        return validateArgObj(args, ['profileId'], 'getProfileBlockInfo')
            .then(() => request.get({
            url: buildUrl(args.profileId, userProfile.blocked_profiles_template_url),
        }))
            .then((response) => response.results && response.results[0]);
    });
}
/**
 * @description Get list of blocked profile ids, could be used to filter chat messages by blocked profile
 * @async
 * @since 2.14.0
 * @example
 * ```js
 * getBlockedProfileIds().then(profileIds => console.log(profileIds))
 * ```
 */
export function getBlockedProfileIds() {
    return __awaiter(this, void 0, void 0, function* () {
        return iteratorBase(['results'], paginator(userProfile.blocked_profile_ids_url, (url) => request.get({ url }), (r) => ({ count: r.count, results: r.results })));
    });
}
function onBlockProfileCb(event) {
    const blockedId = event.message.blocked_profile_id;
    const blockIds = getBlockProfileIdsResource();
    if (!blockIds.find((id) => id === blockedId)) {
        setBlockProfileIdsResource(blockIds.concat([blockedId]));
    }
}
function onUnblockProfileCb(event) {
    const blockedId = event.message.blocked_profile_id;
    const blockIds = getBlockProfileIdsResource();
    const updatedBlockIds = blockIds.filter((id) => id !== blockedId);
    setBlockProfileIdsResource(updatedBlockIds);
}
let hasBlockProfileIdsInitialised = false;
/**
 * setAndUpdateBlockProfileIds to be called once relevant API or feature is used
 * @ignore
 */
export function setAndUpdateBlockProfileIds() {
    if (!_$$.ready || !userProfile) {
        return Promise.reject('SDK not initialised');
    }
    if (hasBlockProfileIdsInitialised) {
        return Promise.resolve();
    }
    return getBlockedProfileIds().then((blockProfileIdsResponse) => {
        setBlockProfileIdsResource(blockProfileIdsResponse.results);
        hasBlockProfileIdsInitialised = true;
        addUserProfileEventListener(UserProfileEvent.BLOCK_PROFILE, onBlockProfileCb);
        addUserProfileEventListener(UserProfileEvent.UNBLOCK_PROFILE, onUnblockProfileCb);
    });
}

import { LitElement } from 'lit-element';
import { eventBus, requestProvider } from '../utils';
// TODO Same logic as WidgetContext - Merge into onto 'Context' Mixin for both wdgets and chat
export const LiveLikeChatElementMixin = (superclass) => class extends superclass {
    constructor() {
        super(...arguments);
        this.updateOwnerProps = (e) => {
            if (this.owner.dataId === e.key) {
                try {
                    this.ownerUpdated(e);
                }
                catch (err) {
                    console.error('Error inside component ownerUpdated implementation', err);
                }
                this.requestUpdate();
                super.requestUpdate();
            }
        };
    }
    static get properties() {
        return {
            owner: { type: Object },
        };
    }
    ownerUpdated(e) { }
    connectedCallback() {
        !this.owner && (this.owner = requestProvider(this, 'chat-provider'));
        eventBus.on('chat-updated', this.updateOwnerProps);
        if (this.defaultStyles) {
            const styles = document.createElement('style');
            styles.innerHTML += this.defaultStyles();
            this.appendChild(styles);
        }
        super.connectedCallback();
    }
};
export class LiveLikeChatElement extends LiveLikeChatElementMixin(LitElement) {
}

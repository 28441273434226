var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, property } from 'lit-element';
import { element } from '../../utils/decorators';
import { defaultHeader } from '../../utils/templates';
import { styles } from './livelike-alert.style';
import { track } from '../../utils/analytics';
import { ThemedWidget } from '../../mixins/WidgetTemplate';
import { kebabToTitle } from '../../utils';
import { WidgetKind } from '../../constants/Widgets';
/**
 * @element livelike-alert
 * @prop {String} [title]
 * @prop {String} [text]
 * @prop {String} [image_url]
 * @prop {String} [link_url]
 * @prop {String} [link_label]
 */
let LiveLikeAlert = class LiveLikeAlert extends ThemedWidget {
    constructor() {
        super(...arguments);
        this.kind = WidgetKind.ALERT;
        this.owner = this;
        this.defaultStyles = styles;
        this.trackLinkOpened = () => {
            this.interactionEvent();
            let trackObj = {
                'Alert Id': this.widgetPayload && this.widgetPayload.id,
                'Program ID': this.widgetPayload && this.widgetPayload.program_id,
                'Widget Type': kebabToTitle(this.widgetPayload.kind),
                'Link URL': this.link_url,
            };
            track('Alert Link Opened', trackObj, this.programid);
        };
    }
    firstUpdated() {
        this.isInteractable = this.link_url && this.link_url.length;
        super.firstUpdated();
    }
    render() {
        const hasCaptionAndMedia = !!this.text && !!this.image_url;
        const hasOnlyMedia = !this.text && !!this.image_url;
        const hasOnlyCaption = !!this.text && !this.image_url;
        return html `
      <template>
        <livelike-widget-root>
          ${defaultHeader}
          <livelike-widget-body>
            ${hasCaptionAndMedia
            ? html `
                  <figure class="widget-captioned-media">
                    ${this.text &&
                html `
                      <figcaption class="widget-caption media-caption">
                        ${this.text}
                      </figcaption>
                    `}
                    ${this.image_url &&
                html `
                      <img
                        class="widget-media"
                        src=${this.image_url}
                        alt=${this.text}
                      />
                    `}
                  </figure>
                `
            : hasOnlyMedia
                ? html `
                  <div class="widget-media">
                    <img src=${this.image_url} />
                  </div>
                `
                : hasOnlyCaption
                    ? html `
                  <div class="widget-caption-container">
                    <span class="widget-caption">${this.text}</span>
                  </div>
                `
                    : null}
            ${this.link_url &&
            html `
              <livelike-footer>
                <a
                  class="widget-link"
                  href=${this.link_url}
                  target="_blank"
                  @click=${this.trackLinkOpened}
                  >${this.link_label}</a
                >
              </livelike-footer>
            `}
          </livelike-widget-body>
        </livelike-widget-root>
      </template>
    `;
    }
};
__decorate([
    property({ type: String })
], LiveLikeAlert.prototype, "title", void 0);
__decorate([
    property({ type: String })
], LiveLikeAlert.prototype, "text", void 0);
__decorate([
    property({ type: String })
], LiveLikeAlert.prototype, "image_url", void 0);
__decorate([
    property({ type: String })
], LiveLikeAlert.prototype, "link_url", void 0);
__decorate([
    property({ type: String })
], LiveLikeAlert.prototype, "link_label", void 0);
LiveLikeAlert = __decorate([
    element('livelike-alert')
], LiveLikeAlert);
export { LiveLikeAlert };

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, property, query } from 'lit-element';
import { ThemedElement } from '../../mixins/ThemedElement';
import { styles } from './livelike-video.style';
import { element } from '../../utils/decorators';
import { eventDispatcher } from '../../utils';
import { VideoPlaybackError } from '../../icons';
/**
 * @description Renders a video element
 * @element livelike-video
 */
let LiveLikeVideo = class LiveLikeVideo extends ThemedElement {
    constructor() {
        super(...arguments);
        this.defaultStyles = styles;
        this.videoAttrs = [
            'autoplay',
            'controls',
            'muted',
            'loop',
            'disablepictureinpicture',
        ];
        this.preload = 'metadata';
        this.controlslist = 'nodownload';
        this.controls = true;
        this.disablepictureinpicture = true;
        this.onVideoPlay = (e) => eventDispatcher(this, e.type);
        this.onVideoPlayError = (e) => {
            this.error = 'Playback Failed';
        };
    }
    createRenderRoot() {
        return this;
    }
    disconnectedCallback() {
        this.scrollObserver.disconnect();
        this.videoEl.removeEventListener('play', this.onVideoPlay);
        super.disconnectedCallback();
    }
    firstUpdated() {
        super.firstUpdated();
        // Some of the video element attributes work if they're specified. For example controls="false" is actually
        // interpreted as controls="true" just because the attribute exists. So we can't set these as reflected properties.
        // Also, the attributes are only used by the HTMLVideoElement on render to initialize the properties.
        // So if they don't exist at render, changing the attribute does nothing and instead the prop must be changed.
        this.videoAttrs.forEach((name) => (this.videoEl[name] = !!this[name]));
        if (!!window.IntersectionObserver) {
            this.scrollObserver = new IntersectionObserver((entries) => entries.forEach((entry) => {
                if (entry.intersectionRatio != 1 && !this.videoEl.paused) {
                    this.videoEl.pause();
                }
            }), { threshold: 0.1 });
            this.scrollObserver.observe(this.videoEl);
        }
        this.videoEl.addEventListener('play', this.onVideoPlay);
    }
    updated(changedProps) {
        this.videoEl &&
            changedProps.forEach((prevProp, name) => {
                if (this.videoAttrs.some((v) => v === name)) {
                    this.videoEl[name] = !!this[name];
                }
            });
    }
    defaultTemplate() {
        return html `
      <video
        id="video"
        poster=${this.poster ? this.poster : ''}
        preload=${this.preload}
        controlslist=${this.controlslist}
      >
        <source
          src=${this.src}
          type="video/mp4"
          @error=${this.onVideoPlayError}
        />
        ${this.tracksrc
            ? html `
              <track
                src=${this.tracksrc}
                label=${this.tracklabel}
                kind=${this.trackkind}
                default
              />
            `
            : null}
      </video>
      ${this.error
            ? html `
            <div class="video-playback-error">
              ${VideoPlaybackError}
              <span class="video-playback-error-text"
                >${this.owner.localize('widget.video.playbackError')}</span
              >
            </div>
          `
            : null}
    `;
    }
};
__decorate([
    query('video')
], LiveLikeVideo.prototype, "videoEl", void 0);
__decorate([
    property({ type: String, reflect: true })
], LiveLikeVideo.prototype, "src", void 0);
__decorate([
    property({ type: String })
], LiveLikeVideo.prototype, "error", void 0);
__decorate([
    property({ type: String })
], LiveLikeVideo.prototype, "poster", void 0);
__decorate([
    property({ type: String })
], LiveLikeVideo.prototype, "preload", void 0);
__decorate([
    property({ type: String })
], LiveLikeVideo.prototype, "controlslist", void 0);
__decorate([
    property({ type: String })
], LiveLikeVideo.prototype, "tracksrc", void 0);
__decorate([
    property({ type: String })
], LiveLikeVideo.prototype, "tracklabel", void 0);
__decorate([
    property({ type: String })
], LiveLikeVideo.prototype, "trackkind", void 0);
__decorate([
    property({ type: Boolean })
], LiveLikeVideo.prototype, "autoplay", void 0);
__decorate([
    property({ type: Boolean })
], LiveLikeVideo.prototype, "controls", void 0);
__decorate([
    property({ type: Boolean })
], LiveLikeVideo.prototype, "muted", void 0);
__decorate([
    property({ type: Boolean })
], LiveLikeVideo.prototype, "loop", void 0);
__decorate([
    property({ type: Boolean })
], LiveLikeVideo.prototype, "disablepictureinpicture", void 0);
LiveLikeVideo = __decorate([
    element('livelike-video')
], LiveLikeVideo);
export { LiveLikeVideo };

import { LitElement } from 'lit-element';
import { _config } from '../scripts/internal/globals';
import { WidgetStyleName } from '../constants/Widgets';
import { eventBus, eventDispatcher, requestProvider } from '../utils';
// import { templateContent } from 'lit-html/directives/template-content';
const TrackedWidgetProps = [
    'phase',
    'disabled',
    'changed',
    'options',
    'choices',
];
export const TemplateRendererMixin = (superclass) => class extends superclass {
    connectedCallback() {
        super.connectedCallback();
        // console.log('TemplateRenderer CC: ', this.localName);
    }
    render() {
        return this.customTemplate
            ? this.customTemplate()
            : this.defaultTemplate();
    }
};
/**
 * @attr disabled
 * @attr changed
 */
export const WidgetContextMixin = (superclass) => class extends superclass {
    constructor() {
        super(...arguments);
        this.updateOwnerProps = (e) => {
            if (this.owner.widgetPayload.id === e.key &&
                this.owner.dataId === e.owner.dataId) {
                // console.log('updateOwnerProps: ', this.localName + ' | ' + e.name + ' | ' + e.prop);
                this.ownerUpdated(e);
                if (e.name === 'disabled' || e.name === 'changed') {
                    this[e.name] = e.prop;
                }
                if (e.name === 'options' || e.name === 'choices') {
                    // TODO sets this.items, this.options, this.choices.
                    // should remove this.items entirely
                    this[e.name] = e.prop;
                    this.items = e.prop;
                    // console.log('WidgetContext this.items update');
                    this.optionListUpdated(e.prop);
                    eventDispatcher(this, 'options-updated', this.items);
                }
            }
        };
    }
    static get properties() {
        return {
            owner: { type: Object },
            items: { type: Array },
            options: { type: Array },
            choices: { type: Array },
            disabled: { type: Boolean, reflect: true },
            changed: { type: Boolean, reflect: true },
        };
    }
    ownerUpdated(e) { }
    optionListUpdated(e) { }
    optionUpdated(e) { }
    connectedCallback() {
        super.connectedCallback();
        // console.log('WIDGET CONTEXT MIXIN CONNECTED: ', this.localName);
        eventBus.on('widget-updated', this.updateOwnerProps);
        !this.owner && (this.owner = requestProvider(this, 'widget-provider'));
        this.items = this.owner.options || this.owner.choices;
        this.owner.options && (this.options = this.owner.options);
        this.owner.choices && (this.choices = this.owner.choices);
    }
    disconnectedCallback() {
        eventBus.off('widget-updated', this.updateOwnerProps);
        super.disconnectedCallback();
    }
};
export class WidgetContext extends WidgetContextMixin(LitElement) {
}
export const ThemeMixin = (superclass) => class extends superclass {
    constructor() {
        super(...arguments);
        this.appendStyles = (styles) => {
            (this.shadowRoot || this).appendChild(styles);
        };
        // Dynamically adds a <style> that contains default and theme styles.
        // Must wait for LitElement updateComplete to finish to ensure shadowRoot is ready.
        // TODO Check for theme is to allow single widget tags to load. This is because they get rendered on the
        // page before the widgetPayload is able to get fetched. The order of operations needs to
        // change slightly so that these Mixins wait for the widgetPayload. This is only an issue for widgets like
        // Cheer Meter, Emoji Slider, etc, because they are getting rendered with html immediately, while
        // voting widgets get loaded from a template, so there is a difference between the order of loading and rendering.
        // All widgets should be loaded, extended, and rendered in the same way to prevent issues like this.
        this.updateTheme = (e) => e.theme &&
            e.theme.widgets &&
            this.defaultStyles &&
            this.updateComplete.then(() => {
                // console.log('THEMEMIXIN updateTheme', this.root);
                const kind = this.owner.kind || this.owner.localName.replace('livelike-', '');
                const styleKind = WidgetStyleName[kind];
                const elementStyles = e.theme.widgets[styleKind];
                const styles = document.createElement('style');
                styles.innerHTML += this.defaultStyles(elementStyles);
                this.appendStyles(styles);
            });
    }
    connectedCallback() {
        super.connectedCallback();
        // console.log('THEME MIXIN CONNECTED: ', this.localName);
        eventBus.on('themeUpdate', this.updateTheme);
        // For widgets rendered as single tags no in livelike-widgets, use _config.theme
        this.updateTheme({ theme: this.owner.theme || _config.theme });
    }
    disconnectedCallback() {
        eventBus.off('themeUpdate', this.updateTheme);
        super.disconnectedCallback();
    }
};
export class ThemedElement extends TemplateRendererMixin(ThemeMixin(WidgetContext)) {
}
const attrList = ['selected', 'correct', 'incorrect', 'image', 'disabled'];
export const OptionContextMixin = (superclass) => class extends superclass {
    constructor() {
        super(...arguments);
        this.updateItem = (e) => {
            if (this.item &&
                this.item.id === e.key &&
                this.owner.widgetPayload.id === e.owner.widgetPayload.id &&
                this.owner.dataId === e.owner.dataId) {
                // Sets this.option, this.choice, or this.item property.
                // TODO this.item should be removed entirely
                (e.name === 'items' || e.name === 'options' || e.name === 'choices') &&
                    (this[e.name.slice(0, -1)] = e.prop.find((i) => i.id === this.item.id));
                this.requestUpdate();
                super.requestUpdate();
                this.optionUpdated(this.item);
                // e.name === 'items' &&
                //   e.name === 'options' &&
                //   console.log('CHILDMIXIN updateItem', this.item);
                // console.log('updateItem localName', this.localName);
                // console.log('updateItem e', e);
                // console.log('updateItem: ', this.localName + ' | ' + e.name + ' | ' + e.prop);
                if (attrList.some((attr) => attr === e.name)) {
                    this[e.name] = e.prop;
                }
            }
        };
    }
    static get properties() {
        return {
            item: { type: Object },
            option: { type: Object },
            choice: { type: Object },
            selected: { type: Boolean, reflect: true },
            correct: { type: Boolean, reflect: true },
            incorrect: { type: Boolean, reflect: true },
            image: { type: Boolean, reflect: true },
            disabled: { type: Boolean, reflect: true },
        };
    }
    connectedCallback() {
        eventBus.on('option-updated', this.updateItem);
        const optionParent = requestProvider(this, 'option-provider');
        // console.log('LiveLikeChild CC optionParent', !!optionParent.item);
        if (optionParent) {
            this.item = optionParent.item;
            optionParent.option && (this.option = optionParent.option);
            optionParent.choice && (this.choice = optionParent.choice);
        }
        super.connectedCallback();
    }
    disconnectedCallback() {
        eventBus.off('option-updated', this.updateItem);
        super.disconnectedCallback();
    }
};
/**
 * @attr selected
 * @attr correct
 * @attr incorrect
 * @attr image
 */
export class LiveLikeOptionChild extends TemplateRendererMixin(ThemeMixin(OptionContextMixin(WidgetContext))) {
}
// TODO Both of these work - check lifecycle logs and ensure the ideal setup based on data flow;
// export class LiveLikeOptionChild extends OptionContextMixin(ThemedElement) {}
export class LiveLikeWidgetElement extends LiveLikeOptionChild {
}

import { convertToCssString as cs } from '../../utils/themeConversions';
export const numberStyles = (theme) => {
    return `
        .livelike-voting-input-container{
            position: relative;
            display: flex;
            justify-content: end;
            width: 100%;
        }
        .livelike-voting-number-input{
            padding: 4px;
            border-radius: 4px;
            font-size: 20px;
            width: 45%;
            text-align: center;
            outline: none;
            max-width: 54px;
            min-width: 22px;
        }
        .user-number-input{
            border: 1px solid rgb(92,92,92);
            color: rgb(27,198,255);
            background: rgb(51,51,51);
            caret-color: white;
            ${cs(theme.optionInputFieldEnabled)}
        }
        .user-number-input::placeholder{
            color: rgb(95,95,95);
            ${cs(theme.optionInputFieldPlaceholder)}
        }
        .user-number-input:focus{
            border: 1px solid white;
        }
        .user-number-input:focus::placeholder{
            color: transparent;
        }
        .user-number-input[disabled]{
            color: rgba(27,198,255,0.5);
            ${cs(theme.optionInputFieldDisabled)}
        }
        .livelike-voting-number-input::-webkit-inner-spin-button, 
        .livelike-voting-number-input::-webkit-outer-spin-button { 
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            margin: 0; 
        }
        .incorrect-number-input{
            border: 1px solid rgb(92,92,92);
            color: rgb(27,198,255);
            background: rgb(51,51,51);
            margin-right: 5px;
        }
        .incorrect-number-input::placeholder{
            color: rgb(95,95,95);
        }
        livelike-option[incorrect] .incorrect-number-input{
            background: rgba(240, 0, 60, 0.2);
            color: rgb(240, 0, 60);
            border: none;
            ${cs(theme.optionInputFieldIncorrect)}
        }
        livelike-option[incorrect] .incorrect-number-input::placeholder{
            color: rgb(240, 0, 60);
        }
        .correct-number-input{
            background: rgba(0, 240, 120, 0.2);
            color: rgb(0, 240, 120);
            border: none;
            ${cs(theme.optionInputFieldCorrect)}
        }
        .correct-number-input::placeholder{
            color: rgb(0, 240, 120);
        }
        .predict-button{
            margin: 0.5rem 0.5rem 0.5rem 1rem;
            padding: 8px 16px;
            border-radius: 5px;
            border: none;
            color: black;
            background: white;
            cursor: pointer;
            ${cs(theme.submitButtonEnabled)}
        }
        .predict-button[disabled]{
            color: rgb(171,171,171);
            background: rgb(84,84,84);
            cursor: pointer;
            ${cs(theme.submitButtonDisabled)}
        }
        livelike-text-number-prediction .voted-text,
        livelike-image-number-prediction .voted-text{
            font-family: Arial;
            ${cs(theme.confirmation)}
        }
    `;
};

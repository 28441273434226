var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, property } from 'lit-element';
import { element } from '../../utils/decorators';
import { LiveLikeOptionChild } from '../../mixins/ThemedElement';
import { styles } from './livelike-vote-count.style';
/**
 * @element livelike-vote-count
 */
let LiveLikeVoteCount = class LiveLikeVoteCount extends LiveLikeOptionChild {
    constructor() {
        super(...arguments);
        this.defaultStyles = styles;
        this.updateCount = (item) => {
            const itemCount = item && (String(item.vote_count) || String(item.answer_count));
            this.count = itemCount || this.owner.count || 0;
        };
        this.ownerUpdated = (e) => {
            if (e.name === 'options' || e.name === 'choices' || e.name === 'count') {
                this.updateCount(this.item);
            }
        };
    }
    connectedCallback() {
        super.connectedCallback();
        this.updateCount(this.item);
    }
    defaultTemplate() {
        return html ` ${this.count} `;
    }
};
__decorate([
    property({ type: String, reflect: true })
], LiveLikeVoteCount.prototype, "count", void 0);
LiveLikeVoteCount = __decorate([
    element('livelike-vote-count')
], LiveLikeVoteCount);
export { LiveLikeVoteCount };

import { html } from 'lit-element';
export const messageMenuStyles = html `
  <style>
    .message-options {
      z-index: 21;
      background: white;
      border-radius: 5px;
    }

    .menu-button {
      display: flex;
      font-size: 0.75rem;
      color: #000;
      padding: 0.25rem 1rem;
      width: 100%;
      border: none;
      background: none;
      transition: background ease 0.3s;
      text-align: left;
    }

    .menu-button:hover {
      background: rgba(0, 0, 0, 0.2);
      transition: background ease 0.3s;
      cursor: pointer;
    }

    .block-user.blocked {
      color: gray;
      cursor: default;
    }

    .menu-item-label {
      margin: 0 0 0 0.5rem;
    }
    .menu-icon {
      line-height: 1.1rem;
      font-size: 0.95rem;
      width: 18px;
    }
    .sub-item-icon {
      position: absolute;
      right: 0.5rem;
    }
  </style>
`;

export const styles = (theme) => {
    return `
    :host {
      display: block;
    }
    livelike-video{
      position: relative;
    }
    livelike-video video {
      width: 100%;
      max-height: 210px;
      border-radius: inherit;
      vertical-align: middle;
    }
    .video-playback-error {
      position: absolute;
      width: 100%;
      text-align: center;
      top: 50%;
      right:50%;
      transform: translate(50%,-50%);
      display: flex;
      align-items: center;
      padding: 0.5rem;
      justify-content: center;
   }
  `;
};

import { convertToCssString as cs } from '../../utils/themeConversions';
export const styles = (theme) => {
    const trackBase = `
    border: none;
    width: 100%;
    height: 1.125rem;
    border-radius: 1.5rem;
    background: ${theme.interactiveTrackRight.background};
    background-image: linear-gradient(
      90deg,
      ${theme.interactiveTrackLeft.background},
      ${theme.interactiveTrackRight.background} var(--x),
      transparent 0
    );
  `;
    const thumbBase = `
    border: none;
    width: var(--thumb-width);
    height: 1em;
    border-radius: 50%;
    margin-top: 0;
  `;
    return `
    .input-form {
      --x: calc(var(--val) / 100 * (100% - var(--thumb-width)));
      position: relative;
      width: 90%;
      margin: 0px auto;
    }

    .input-container {
      display: block;
      position: relative;
    }

    .slider-input {
      -webkit-appearance: none;
      width: 100%;
      display: block;
      height: 5em;
      background: none;
      cursor: pointer;
      position: relative;
      margin: 0;
    }

    .slider-input:focus {
      outline: none;
    }

    .slider-input::-webkit-slider-runnable-track {
      ${trackBase}
    }
    .slider-input::-moz-range-track {
      ${trackBase}
    }
    .slider-input::-ms-track {
      ${trackBase}
    }

    .slider-input::-webkit-slider-thumb {
      ${thumbBase}
    }
    .slider-input::-moz-range-thumb {
      ${thumbBase}
    }
    .slider-input::-ms-thumb {
      ${thumbBase}
    }

    .slider-input:disabled {
      cursor: default;
    }

    .slider-image {
      height: 50px;
    }

    .slider-thumb {
      position: absolute;
      bottom: 0.1em;
      left: var(--x);
      pointer-events: none;
    }

    .result-mark {
      background: rgb(233, 0, 0);
      width: 1rem;
      height: 1rem;
      bottom: 1.1rem;
      position: absolute;
      background: #d23a3a;
      border: 3px solid white;
      border-radius: 1rem;
      bottom: 1.5rem;
      z-index: 1;
      ${cs(theme.marker)}
    }
    livelike-emoji-slider livelike-footer{
      background-color: transparent;
      font-weight: normal;
    }
    livelike-emoji-slider .vote-button{
      margin: 0.5rem 0.5rem 0.5rem 1rem;
      padding: 8px 16px;
      border-radius: 5px;
      border: none;
      color: #fff;
      background: rgba(128, 0, 128, 1);
      cursor: pointer;
      ${cs(theme.submitButtonEnabled)}
    }
    livelike-emoji-slider .vote-button[disabled]{
      background: rgba(128, 0, 128, 0.5);
      color: rgba(255, 255, 255, 0.5);
      cursor: default;
      ${cs(theme.submitButtonDisabled)}
    }
    livelike-emoji-slider .voted-text{
      font-family: Arial;
      ${cs(theme.confirmation)}
    }
    .sponsorship{
      padding: 16px 8px;
      text-align: center;
      font-size: 12px;
      font-family: "HelveticaNeue-Medium";
    }
    .sponsorship img{
      height: 20px;
      vertical-align: middle;
      margin-left: 8px;
    }
`;
};

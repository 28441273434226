var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html } from 'lit-element';
import { formatDateTime } from '../../utils';
import { element } from '../../utils/decorators';
import { ThemedElement } from '../../mixins/ThemedElement';
import { styles } from './livelike-widget-bylines.style';
/**
 * @element livelike-widget-bylines
 */
let LiveLikeWidgetBylines = class LiveLikeWidgetBylines extends ThemedElement {
    constructor() {
        super(...arguments);
        this.defaultStyles = styles;
    }
    firstUpdated() {
        let str = '';
        const timeStr = `
      <time datetime="${this.owner.published_at}">
        ${formatDateTime(this.owner.published_at, this.owner.timeformat, this.owner.lang)}
      </time>
    `;
        if (this.owner.authors && this.owner.created_by.name) {
            str += `<span>${this.owner.created_by.name}</span>`;
            this.owner.timestamps && (str += '&nbsp;&ndash;&nbsp;');
        }
        this.owner.timestamps && (str += timeStr);
        this.insertAdjacentHTML('afterbegin', str);
    }
    defaultTemplate() {
        return html ` <slot></slot> `;
    }
};
LiveLikeWidgetBylines = __decorate([
    element('livelike-widget-bylines')
], LiveLikeWidgetBylines);
export { LiveLikeWidgetBylines };

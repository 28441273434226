var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html } from 'lit-element';
import { debounce, eventBus } from '../../utils';
import { element } from '../../utils/decorators';
import '../livelike-message-item';
import { LiveLikeChatElement } from '../../mixins/ChatElement';
import { userProfile } from '../../scripts/internal/globals';
import { isCustomMessageResponsePayload, } from '../../scripts/types';
import { messageListStyles } from './livelike-message-list.style';
let MessageList = class MessageList extends LiveLikeChatElement {
    constructor() {
        super(...arguments);
        this.appendMessage = (e) => {
            const message = e.detail.message;
            const prevMessage = e.detail.prevMessage;
            if (this.hasMessageData(message)) {
                const messageEl = this.createMessageElem(message);
                const isAtBottom = !prevMessage && this.checkIfAtBottom();
                this.appendChild(messageEl);
                const updateEl = messageEl.updateComplete ? messageEl : this;
                // need more timeout for scrollToBottom during initial load of messages where scrollHeight is 0
                const scrollToBottomTimeout = this.scrollHeight > 0 ? 250 : 750;
                updateEl.updateComplete.then(() => !prevMessage &&
                    (message.sender_id === userProfile.id || isAtBottom) &&
                    /**
                     * There's a race condition when rendering individual message and
                     * rendering message list which causes scroll bar to be not precisely scrolled to bottom.
                     * This might be because appending of messages is done through DOM not via reactive Lit property approach.
                     * By deffering scroll to bottom sets the scrollTop properly based on latest scrollHeight
                     */
                    this.deferredScrollToBottom(scrollToBottomTimeout));
            }
        };
        this.prependMessages = (e) => {
            const messages = e.detail.messages;
            const messageElements = messages
                .filter((message) => this.hasMessageData(message))
                .map((message) => {
                return this.createMessageElem(message);
            });
            this.prependAndAdjustScroll(messageElements);
        };
        this.prependAndAdjustScroll = (messageElements) => {
            const prevScrollHeightMinusTop = this.scrollHeight - this.scrollTop;
            this.prepend(...messageElements);
            window.requestAnimationFrame(() => {
                this.scrollTop = this.scrollHeight - prevScrollHeightMinusTop;
            });
        };
        this.checkIfAtBottom = () => {
            var _a;
            /**
             * Due to ES-3204, for finding if scroll is at the bottom of the list, we are checking
             * if user has scrolled more than 50% of the height of the last message
             * if scrolled more than 50% - scroll is not at the bottom
             * if scrolled less than 50% - scroll is almost at the bottom
             */
            const lastElem = this.children[this.children.length - 1];
            if (!lastElem || !this.scrollHeight) {
                return true;
            }
            const lastElemClientHeight = (_a = lastElem.querySelector('.message-item-container')) === null || _a === void 0 ? void 0 : _a.clientHeight;
            return (Math.abs(this.scrollHeight - (this.scrollTop + this.clientHeight)) <
                0.5 * lastElemClientHeight);
        };
        this.softKeyboardHandler = debounce(() => this.checkIfAtBottom() && this.scrollToBottom());
        this.scrollListener = debounce(() => {
            eventBus.emit(`userScrolled`, {
                key: this.owner.dataId,
                scrolled: !this.checkIfAtBottom(),
            });
        });
        this.deferredScrollToBottomTimeOut = 0;
        this.deferredScrollToBottom = (timeout) => {
            if (this.deferredScrollToBottomTimeOut) {
                window.clearTimeout(this.deferredScrollToBottomTimeOut);
            }
            this.deferredScrollToBottomTimeOut = window.setTimeout(() => this.scrollToBottom(), timeout);
        };
        this.scrollToBottom = () => {
            this.scrollTop = this.scrollHeight + 10000;
        };
        this.scrollToBottomListener = (e) => e.key === this.owner.dataId && this.scrollToBottom();
    }
    hasCustomMessage(message) {
        return (isCustomMessageResponsePayload(message) &&
            !!message.custom_data &&
            this.owner.customMessageRenderer);
    }
    hasMessageData(message) {
        return (!!message.message || !!message.image_url || this.hasCustomMessage(message));
    }
    createMessageElem(message) {
        const messageEl = this.hasCustomMessage(message)
            ? this.owner.customMessageRenderer({ message })
            : document.createElement('livelike-message-item');
        messageEl.message = message;
        messageEl.owner = this.owner;
        return messageEl;
    }
    connectedCallback() {
        // TODO Should not be set here -- too early in lifecycle
        this.addEventListener('scroll', this.scrollListener);
        eventBus.on('scrollDown', this.scrollToBottomListener);
        window.addEventListener('resize', this.softKeyboardHandler);
        super.connectedCallback();
        this.setAttribute('role', 'log');
        this.owner.addEventListener('message-ready', this.appendMessage);
        this.owner.addEventListener('prev-messages-ready', this.prependMessages);
    }
    disconnectedCallback() {
        eventBus.off('scrollDown', this.scrollToBottomListener);
        this.removeEventListener('scroll', this.scrollListener);
        this.owner.removeEventListener('message-ready', this.appendMessage);
        this.owner.addEventListener('prev-messages-ready', this.prependMessages);
        window.removeEventListener('resize', this.softKeyboardHandler);
        super.disconnectedCallback();
    }
    renderLoadPrevMessageButton() {
        if (!this.owner || !this.owner.previousMessages) {
            return null;
        }
        const { loadPrevMessageRenderer, prevMessagesLoading, onLoadPrevMessageClick, hasPrevMessages, } = this.owner;
        if (!hasPrevMessages) {
            return null;
        }
        if (loadPrevMessageRenderer &&
            typeof loadPrevMessageRenderer === 'function') {
            return loadPrevMessageRenderer({
                loading: prevMessagesLoading,
                onClick: onLoadPrevMessageClick,
                hasPrevMessages,
            });
        }
        const buttonLabel = prevMessagesLoading
            ? 'Loading previous messages'
            : 'Load previous messages';
        return html `
      <div class="load-prev-msg-container">
        <button
          @click=${onLoadPrevMessageClick}
          .disabled=${prevMessagesLoading}
        >
          ${buttonLabel}
        </button>
      </div>
    `;
    }
    // TODO Ensure empty/loading/list slots are loading appropriately.
    render() {
        return html `
      ${messageListStyles} ${this.renderLoadPrevMessageButton()}
      <slot></slot>
    `;
    }
};
MessageList = __decorate([
    element('livelike-message-list')
], MessageList);
export { MessageList };

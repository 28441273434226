import { errDocLink } from '../../utils';
function widgetModes() {
    const modeList = {};
    const getMode = (mode) => {
        return typeof mode === 'function'
            ? mode
            : modeList[mode]
                ? modeList[mode]
                : errDocLink('Widget mode: "' + mode + '" not found.');
    };
    const register = (name, mode) => (modeList[name] = mode);
    return {
        modeList,
        getMode,
        register,
    };
}
const { modeList, getMode, register } = widgetModes();
/**
 * @ignore
 */
export { modeList, getMode, register };

import { convertToCssString as cs } from '../../utils/themeConversions';
export const styles = (theme) => {
    let str = `
    :host {
      display: flex;
      position: relative;
      cursor: pointer;
      transition: border ease 0.3s;
      align-items: center;
      border: 2px solid transparent;
      border-radius: 6px;
      padding: 0.5rem;
      ${cs(theme.unselectedOption)}
    }
    :host([selected]) {
      ${cs(theme.selectedOption)}
    }
    :host([disabled]) {
      cursor: default;
    }
  `;
    theme.correctOption &&
        (str += `
      :host([correct]) {
        ${cs(theme.correctOption)}
      }
      :host([incorrect]) {
        ${cs(theme.incorrectOption)}
      }
  `);
    return str;
};

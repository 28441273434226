const Events = {
    LEVEL_SWITCHED: 'hlsLevelSwitched',
    FRAG_CHANGED: 'hlsFragChanged',
};
export class ProgramDateTimeSyncStrategy {
    get currentTimecode() {
        return this.programDateTime;
    }
    get programDateTime() {
        return null;
    }
}
export class HlsJsSyncStrategy extends ProgramDateTimeSyncStrategy {
    constructor(hls, videoEl) {
        super();
        this.hls = hls;
        this.video = videoEl;
        this.level = null;
        this.frag = null;
        this.hls.on(Events.LEVEL_SWITCHED, (e, data) => {
            this.level = this.hls.levels[data.level];
        });
        this.hls.on(Events.FRAG_CHANGED, (e, data) => {
            this.frag = data.frag;
        });
    }
    get baseProgramDateTimeMs() {
        if (this.frag) {
            return this.frag.programDateTime;
        }
        else if (this.level) {
            return this.level.details.fragments[0].programDateTime;
        }
    }
    get fragmentStartMs() {
        if (this.frag) {
            return this.frag.start * 1000;
        }
        else if (this.level) {
            return this.level.details.fragments[0].start * 1000;
        }
    }
    get videoCurrentTimeMs() {
        return this.video.currentTime * 1000;
    }
    get programDateTime() {
        if (this.level) {
            let pdt = this.baseProgramDateTimeMs +
                this.videoCurrentTimeMs -
                this.fragmentStartMs;
            return new Date(pdt);
        }
    }
}

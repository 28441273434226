export var ChatMessageEvent;
(function (ChatMessageEvent) {
    ChatMessageEvent["MESSAGE_CREATED"] = "message-created";
    ChatMessageEvent["IMAGE_CREATED"] = "image-created";
    ChatMessageEvent["CUSTOM_MESSAGE_CREATED"] = "custom-message-created";
})(ChatMessageEvent || (ChatMessageEvent = {}));
export const Events = {
    DELETED: 'messagedeleted',
    RECEIVED: 'messagereceived',
    UPDATED: 'messageupdated',
    SENT: 'messagesent',
    FAILED: 'messagefailed',
    ADDED: 'reactionadded',
    REMOVED: 'reactionremoved',
    MESSAGES: 'messagehistory',
    ROOMENTER: 'roomentered',
    ROOMEXIT: 'roomexited',
};
export const MessageEvents = {
    'chatroom-updated': 'chatroom-updated',
    'message-deleted': Events.DELETED,
    [ChatMessageEvent.MESSAGE_CREATED]: Events.RECEIVED,
    [ChatMessageEvent.IMAGE_CREATED]: Events.RECEIVED,
    [ChatMessageEvent.CUSTOM_MESSAGE_CREATED]: Events.RECEIVED,
    'image-deleted': Events.DELETED,
    'message-updated': Events.UPDATED,
    'message-sent': Events.SENT,
    added: Events.ADDED,
    removed: Events.REMOVED,
};

import { buildUrl, getAppResource, request } from '../utils';
import { validateArgObj } from '../utils/validateArgs';
/**
 * @description Create a video room
 * @async
 * @since 2.24.0
 * @example
 * ```js
 * createVideoRoom({
 *  videoRoomName: "My Video room",
 * }).then(videoRoom => console.log(videoRoom))
 * ```
 */
export function createVideoRoom(args) {
    return getAppResource().then((app) => {
        const data = {
            client_id: app.client_id,
            name: args === null || args === void 0 ? void 0 : args.videoRoomName,
        };
        return request.post({ url: app.video_room_url, data });
    });
}
/**
 * @description get a VideoRoom details
 * @async
 * @since 2.24.0
 * @example
 * ```js
 * getVideoRoom({
 *  videoRoomId: "9e6f1bc4-9f02-4c57-92b7-7521d0f5b027",
 * }).then(videoRoom => console.log(videoRoom))
 * ```
 */
export function getVideoRoom(args) {
    return validateArgObj(args, ['videoRoomId'], 'getVideoRoom')
        .then(() => getAppResource())
        .then((app) => request.get({
        url: buildUrl(args.videoRoomId, app.video_room_detail_url_template),
    }));
}

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, property } from 'lit-element';
import { ThemedElement } from '../../mixins/ThemedElement';
import { element } from '../../utils/decorators';
import { style } from './livelike-title.style';
/**
 * Renders the widget's `title` or `question` property by default. Can override by setting `title` property.
 * @element livelike-title
 * @slot default
 */
let LiveLikeTitle = class LiveLikeTitle extends ThemedElement {
    constructor() {
        super(...arguments);
        this.defaultStyles = style;
        this.getTag = () => {
            switch (this.kind || this.owner.kind) {
                case 'text-quiz':
                case 'image-quiz':
                    return this.owner.localize('widget.quiz.tag');
                case 'text-poll':
                case 'image-poll':
                    return this.owner.localize('widget.poll.tag');
                case 'text-prediction':
                case 'image-prediction':
                    return this.owner.localize('widget.prediction.tag');
                case 'text-prediction-follow-up':
                case 'image-prediction-follow-up':
                    return this.owner.localize('widget.followup.tag');
                case 'text-number-prediction':
                case 'image-number-prediction':
                    return this.owner.localize('widget.numberPrediction.tag');
                case 'text-number-prediction-follow-up':
                case 'image-number-prediction-follow-up':
                    return this.owner.localize('widget.numberPredictionFollowUp.tag');
                default:
                    return '';
            }
        };
    }
    defaultTemplate() {
        const tag = this.getTag().trim();
        return html `
      <slot>
        ${tag.length > 0 ? html `<div class="widget-tag">${tag}</div>` : null}
        <div>
          ${this.title ||
            this.owner.question ||
            this.owner.title ||
            this.owner.comment}
        </div>
      </slot>
    `;
    }
};
__decorate([
    property({ type: String })
], LiveLikeTitle.prototype, "title", void 0);
LiveLikeTitle = __decorate([
    element('livelike-title')
], LiveLikeTitle);
export { LiveLikeTitle };

import { isMsgContentFiltered } from './messageFilter';
const TEXT_CHARAC_DECODER_MAP = {
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&#47;': '/',
};
const TEXT_CHARAC_DECODER_REGEX = /&lt;|&gt;|&amp;|&quot;|&#47;/gi;
const decodeMessageText = (message) => {
    return message
        ? message.replace(TEXT_CHARAC_DECODER_REGEX, (literal) => TEXT_CHARAC_DECODER_MAP[literal])
        : null;
};
export const transformMessage = (messagePayload, roomId) => {
    if (messagePayload.message && messagePayload.message.length) {
        return Object.assign(Object.assign({}, messagePayload), { message: decodeMessageText(messagePayload.message), filtered_message: decodeMessageText(messagePayload.filtered_message), isFiltered: isMsgContentFiltered(roomId, messagePayload) });
    }
    return messagePayload;
};

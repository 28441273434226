import { _$$, userProfile } from './internal/globals';
import { noArgPromiseRej, request, buildUrl } from '../utils';
import { track } from '../utils/analytics';
import { subscriptionCoordinator } from '../utils/subscriptionCoordinator';
const profileFetchBase = (type, data, accessToken) => request[type]({
    url: _$$.profileUrl,
    data,
    accessToken,
});
/**
 * Gets userProfile from accessToken.
 * @async
 * @since 1.18.0
 * @example
 * ```js
 * getUserProfile({ accessToken }).then(profile => console.log(profile))
 * ```
 */
export const getUserProfile = (args) => {
    if (!args || (!args.accessToken && !args.profileId)) {
        return noArgPromiseRej('accessToken or profileId', 'fetchUserProfile');
    }
    else {
        return args.accessToken
            ? profileFetchBase('get', null, args.accessToken).then((profile) => {
                profile.access_token = args.accessToken;
                return profile;
            })
            : args.profileId &&
                request.get({
                    url: buildUrl(args.profileId, _$$.profileDetailUrlTemplate),
                });
    }
};
/**
 * Creates user profile. If no nickname argument is passed, a random nickname will be generated.
 * @async
 * @since 1.18.0
 * @example
 * ```js
 * createUserProfile({ nickname: 'Test Nickname' }).then(profile => console.log(profile))
 * ```
 */
export const createUserProfile = (args) => profileFetchBase('post', args && args.nickname && args, null).then((profile) => {
    // TODO Should this be removed?
    track('Unique Profile Created', {
        'Profile ID': profile.id,
    });
    return profile;
});
/**
 * Updates profile by accessToken with optional changed nickname or custom_data.
 * @async
 * @since 1.18.0
 * @example
 * ```js
 * const options = {nickname: 'New Nickname'}
 * updateUserProfile({accessToken, options}).then(profile => console.log(profile))
 * ```
 */
export const updateUserProfile = (args) => !args || !args.accessToken
    ? noArgPromiseRej('accessToken', 'updateuserprofile')
    : profileFetchBase('patch', args.options, args.accessToken).then((profile) => {
        // Updated profile response does not contain access_token, so add it back in.
        profile.access_token = args.accessToken;
        // Only save to LS and userProfile if it's the current userProfile
        if (profile.id === userProfile.id) {
            _$$.storageStrategy.set(profile);
            userProfile.nickname = profile.nickname;
            Object.assign(userProfile, profile);
        }
        return profile;
    });
/**
 * @description Add listener for the given UserProfileEvent
 * @since 2.13.0
 * @example
 * ```js
 * addUserProfileEventListener(UserProfileEvent.BLOCK_PROFILE, function onBlockProfileListener(blockInfo){
 *  console.log(blockInfo);
 * })
 * ```
 */
export function addUserProfileEventListener(userProfileEvent, listenerFn) {
    subscriptionCoordinator.addListener(userProfile.subscribe_channel, listenerFn, userProfileEvent);
    subscriptionCoordinator.subscribe(userProfile.subscribe_channel);
}
/**
 * @description Remove registered listenerFn for the given UserProfileEvent,
 * when no listener passed, all registered event listener will be removed
 * @since 2.13.0
 * @example
 * ```js
 * removeUserProfileEventListener(UserProfileEvent.BLOCK_PROFILE, onBlockProfileListener);
 * ```
 */
export function removeUserProfileEventListener(userProfileEvent, listenerFn) {
    if (listenerFn) {
        subscriptionCoordinator.removeListener(userProfile.subscribe_channel, listenerFn, userProfileEvent);
    }
    else {
        subscriptionCoordinator.removeAllListenersForEvent(userProfile.subscribe_channel, userProfileEvent);
    }
    subscriptionCoordinator.unsubscribe(userProfile.subscribe_channel);
}

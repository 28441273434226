import { _$$, userProfile } from '../scripts/internal/globals';
import { MessageContentFilter } from '../scripts/types';
export const isMsgContentFiltered = (roomId, message) => {
    const isFiltered = _$$.chatRooms[roomId] &&
        _$$.chatRooms[roomId].content_filter !== 'none' &&
        message.content_filter &&
        message.content_filter.indexOf(MessageContentFilter.PROFANITY) >= 0;
    const sameUser = message.sender_id === userProfile.id;
    const isProducer = userProfile.nickname === 'Producer';
    return !isProducer && !sameUser && isFiltered;
};

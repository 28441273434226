import { html } from 'lit-element';
import { convertToCssString as cs } from '../../utils/themeConversions';
export const styles = html `
  <style>
    /* Option */
    livelike-option::slotted(.container) {
      width: 75%;
      max-width: 75%;
      position: relative;
    }
    /* Progress */
    livelike-progress[image] {
      top: 0;
      height: 1.5rem;
      margin-top: 0.5rem;
    }
    /* Custom */
    .livelike-voting-image-container {
      width: 100%;
      position: relative;
    }
    livelike-footer {
      background-color: #444;
      display: block;
      font-weight: bolder;
      border-radius: 0 0 6px 6px;
    }
    .sponsorship {
      padding: 16px 8px;
      text-align: center;
      font-size: 12px;
      font-family: 'HelveticaNeue-Medium';
    }
    .sponsorship img {
      height: 20px;
      vertical-align: middle;
      margin-left: 8px;
    }
  </style>
`;
export const votingStyles = (theme) => {
    return `
    livelike-text-quiz .vote-button,
    livelike-image-quiz .vote-button{
      margin: 0.5rem 0.5rem 0.5rem 1rem;
      padding: 8px 16px;
      border-radius: 5px;
      border: none;
      color: #fff;
      background: rgba(128, 0, 128, 1);
      cursor: pointer;
      ${cs(theme.submitButtonEnabled)}
    }
    livelike-text-quiz .vote-button[disabled],
    livelike-image-quiz .vote-button[disabled]{
      background: rgba(128, 0, 128, 0.5);
      color: rgba(255, 255, 255, 0.5);
      cursor: default;
      ${cs(theme.submitButtonDisabled)}
    }
    livelike-text-quiz .voted-text,
    livelike-image-quiz .voted-text{
      font-family: Arial;
      ${cs(theme.confirmation)}
    }
  `;
};

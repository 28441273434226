var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html } from 'lit-element';
import { getWidget, getWidgetInteractions } from '../scripts/widgets';
import { getMode } from '../scripts/internal/widgetMode';
import { element, isRegisteredTagName } from '../utils/decorators';
import { getWidgetKindIdForInteraction, interactiveModeBehaviour, } from '../utils';
import { Widget } from '../widgets/base/Widget';
import { ThemeMixin } from './ThemedElement';
const WidgetSetupMixin = (superclass) => class extends superclass {
    constructor() {
        super(...arguments);
        this.setupWidget = () => new Promise((res) => {
            const waitForLiveLike = () => {
                if (window.LiveLike._config &&
                    window.LiveLike._config.ready &&
                    window.LiveLike._config.theme &&
                    window.LiveLike._config.theme.widgets) {
                    const kind = this.kind ||
                        this.getAttribute('kind') ||
                        this.localName.replace('livelike-', '');
                    const id = this.widgetId || this.getAttribute('widgetid');
                    const mode = this.mode || this.getAttribute('mode');
                    const definedMode = typeof mode === 'string' ? getMode(mode) : mode;
                    const addPayloadAndResolve = (payload) => {
                        this.widgetPayload = payload;
                        this.programid = this.widgetPayload.program_id;
                        Object.keys(payload).forEach((key) => (this[key] = payload[key]));
                        this.theme = window.LiveLike._config.theme;
                        if (definedMode) {
                            definedMode({ widget: this });
                        }
                        else {
                            this.widgetPayload.timeout = null;
                            this.hide_dismiss_button = true;
                            interactiveModeBehaviour({
                                target: null,
                                widget: this,
                                timer: null,
                            })
                                .then(() => this.results({ timeout: 5000 }))
                                .then(this.expire)
                                .then(() => this.finished({ timeout: null }));
                        }
                        res();
                    };
                    getWidget({ kind, id }).then((payload) => {
                        let obj = getWidgetKindIdForInteraction(payload);
                        if (obj) {
                            getWidgetInteractions({
                                programId: payload.program_id,
                                widgets: [{ kind: obj.kind, id: obj.id }],
                            }).then((interactions) => {
                                if (interactions && interactions[obj.kind]) {
                                    this.interactions = interactions[obj.kind].filter((e) => e.widget_id === obj.id);
                                    this.interactions &&
                                        (this.interaction = this.interactions[0]);
                                }
                                addPayloadAndResolve(payload);
                            });
                        }
                        else {
                            addPayloadAndResolve(payload);
                        }
                    });
                }
                else
                    setTimeout(waitForLiveLike, 50);
            };
            waitForLiveLike();
        });
    }
};
export const TemplateMixin = (superclass) => class extends superclass {
    constructor() {
        super(...arguments);
        // Needs to overrwrite ThemeMixin appendStyles because for widgets using
        // the ThemedWidget element, the styles need to be appended to `this` instead of `this.shadowRoot`
        this.appendStyles = (styles) => {
            this.appendChild(styles);
        };
    }
    connectedCallback() {
        super.connectedCallback();
        const setupTemplate = () => {
            this.startInteractiveUntilTimer();
            return new Promise((res) => {
                const customTemplate = !!this.customTemplate &&
                    this.customTemplate({ widgetPayload: this.widgetPayload });
                if (customTemplate &&
                    !(customTemplate instanceof HTMLTemplateElement)) {
                    return console.error('customTemplateRenderer must return an HTMLTemplateElement');
                }
                const customDefaultTemplate = document.querySelector(`template[kind=${this.widgetPayload.kind}]`);
                const template = customTemplate || customDefaultTemplate;
                this.updateComplete.then(() => {
                    this.shadowRoot &&
                        this.shadowRoot.appendChild(document.createElement('slot'));
                    const defaultTemplate = this.shadowRoot.querySelector('template');
                    // console.log('BEFORE WIDGET TEMPLATE APPEND');
                    // template &&
                    //   this.appendChild(document.importNode(template.content, true));
                    // TODO Cloning works for every widget except emoji-slider currently
                    if (template || defaultTemplate) {
                        if (template)
                            this.appendChild(document.importNode(template.content, true));
                        else {
                            if (isRegisteredTagName(this.localName)) {
                                this.isStockWidget = true;
                            }
                            this.appendChild(defaultTemplate.content);
                        }
                        res();
                    }
                    else {
                        res();
                    }
                });
            });
        };
        // TODO Singular widgets should not need to have a `widgetPayload` object.
        // It makes sense in the context of the rest of the widgets, but a use case
        // of single widgets is "dumb" widgets that just render the UI along with
        // whatever properties you add. It's a lot easier to just pass singular attributes
        // or properties that are needed. One issue though is passing options/choices arrays.
        // LitElement will correctly coerce the attributes if the `type` property
        // of the @property declaration is correct (ex. {type: Object} will coerce the attribute to an object)
        // but only if it is a valid stringified object. For example
        // options="[{description: 'value'}]" won't work but options='[{"description": "value"}]' will work.
        // How it is now, almost all widgets work solely with single attribute/props, except for the image slider.
        // Since in the Widget base class we are looping over the widgetPayload props and creating properties from
        // each key/value pair, most things work. In the image slider widget, the initial and average mag property need
        // to be part of the widgetpayload. There are other considerations that need to be made because there are quite a few areas
        // that use the widgetPayload or the mapped prop, and this shouldn't be. Because of single widgets, single properties should be used.
        // the widgetPayload prop should be looked at as an initializer of the rest of the properties - if it exists. If not, that's fine
        // because the rest of the props/attrs can be set directly by the integrator.
        return this.widgetPayload && Object.keys(this.widgetPayload).length !== 0
            ? setupTemplate()
            : this.setupWidget().then(setupTemplate);
    }
};
export class TemplateWidget extends TemplateMixin(WidgetSetupMixin(Widget)) {
}
export class ThemedWidget extends TemplateMixin(WidgetSetupMixin(ThemeMixin(Widget))) {
}
export const LiveLikeWidgetMixin = (superclass) => class extends superclass {
    connectedCallback() {
        super.connectedCallback();
        this.setupWidget();
    }
};
let LiveLikeWidget = class LiveLikeWidget extends Widget {
    render() {
        return html ` <slot></slot> `;
    }
};
LiveLikeWidget = __decorate([
    element('livelike-widget')
], LiveLikeWidget);
export { LiveLikeWidget };

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement, property } from 'lit-element';
import { element } from '../../utils/decorators';
import { messageMenuStyles } from './message-menu.style';
import { downTriangle } from '../../icons';
let MessageMenu = class MessageMenu extends LitElement {
    constructor() {
        super(...arguments);
        this.subMenuOpen = null;
        this.renderSubIcon = (item) => item.type === 'multi'
            ? html ` <div class="sub-item-icon">${downTriangle}</div> `
            : null;
        this.renderButton = (item, idx, click) => html `
    <button
      id="menu-button-${idx}"
      aria-label="${item.label}"
      @click=${click}
      class="button menu-button"
    >
      <div class="menu-item-label">${item.label}</div>
      ${this.renderSubIcon(item)}
    </button>
  `;
        this.renderCustomHtml = (item) => {
            const custom = new DOMParser().parseFromString(item.html, 'text/html');
            const arr = [];
            custom.body.childNodes.forEach((el) => arr.push(el));
            return arr.map((el) => html ` ${el} `);
        };
        this.renderItem = (item, idx) => {
            const itemId = this.message.id + String(idx);
            const toggleSub = () => this.subMenuOpen === itemId
                ? (this.subMenuOpen = null)
                : (this.subMenuOpen = itemId);
            const clickAndToggle = () => {
                item.onClick();
                this.closeMenu('selectedMessage');
            };
            const click = item.type === 'single' ? clickAndToggle : toggleSub;
            return html `
      ${item.type === 'custom' && item.html
                ? this.renderCustomHtml(item)
                : this.renderButton(item, idx, click)}
      ${this.subMenuOpen === itemId && item.type === 'multi'
                ? this.renderSubMenu(item.items)
                : null}
    `;
        };
        this.renderSubMenu = (menu) => html ` <div class="sub-menu-container">${this.renderMenu(menu, 10)}</div> `;
        this.renderMenu = (menu, n) => menu.map((item, idx) => item.type === 'separator'
            ? html ` <hr style="margin: 0" /> `
            : this.renderItem(item, n ? idx + n : idx));
    }
    createRenderRoot() {
        return this;
    }
    render() {
        return html `
      ${messageMenuStyles}
      <style>
        message-menu {
          position: absolute;
          width: 100%;
          max-width: 10rem;
          right: 2.5rem;
          display: block;
          top: 50%;
          margin: auto;
          top: 0;
          bottom: 0;
          right: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
        }
        .message-options-anchor {
          z-index: 21;
          transition: opacity 0.3s ease;
          opacity: ${this.animation};
        }
      </style>
      <div class="message-options-anchor " id="chat_moderation">
        <div class="message-options">${this.renderMenu(this.menu)}</div>
      </div>
    `;
    }
};
__decorate([
    property({ type: Array })
], MessageMenu.prototype, "menu", void 0);
__decorate([
    property({ type: Object })
], MessageMenu.prototype, "message", void 0);
__decorate([
    property({ type: Number })
], MessageMenu.prototype, "animation", void 0);
__decorate([
    property({ type: String })
], MessageMenu.prototype, "subMenuOpen", void 0);
__decorate([
    property({ type: Function })
], MessageMenu.prototype, "closeMenu", void 0);
MessageMenu = __decorate([
    element('message-menu')
], MessageMenu);
export { MessageMenu };

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, property } from 'lit-element';
import { ThemedElement } from '../../mixins/ThemedElement';
import { element } from '../../utils/decorators';
import { styles } from './livelike-timer.style';
import { eventBus } from '../../utils';
// TODO Add @slot doc comment below
/**`
 * @element livelike-timer
 */
let LiveLikeTimer = class LiveLikeTimer extends ThemedElement {
    constructor() {
        super(...arguments);
        this.started = false;
        this.defaultStyles = styles;
        this.start = () => {
            if (!this.started) {
                this.started = true;
                const timeStarted = new Date().getTime();
                const frame = () => {
                    const timeout = this.time || this.owner.timeout;
                    const timeDiff = new Date().getTime() - timeStarted;
                    const timePercent = (1 - (timeout - timeDiff) / timeout) * 100;
                    this.style.width = `${timePercent > 100 ? 100 : timePercent}%`;
                    timeDiff < timeout &&
                        this.owner.phase === 'interactive' &&
                        requestAnimationFrame(frame);
                };
                frame();
            }
        };
        this.ownerUpdated = (e) => {
            if (e.name === 'phase') {
                e.prop === 'interactive' ? this.start() : (this.style.display = 'none');
            }
        };
    }
    firstUpdated() {
        this.owner.phase === 'interactive' && this.start();
        this.pageVisible = !document.hidden;
        eventBus.on('pageVisibilityChange', (e) => (this.pageVisible = e.pageVisible));
    }
    render() {
        return html ` <slot></slot> `;
    }
};
__decorate([
    property({ type: Number, reflect: true })
], LiveLikeTimer.prototype, "time", void 0);
__decorate([
    property({ type: Boolean })
], LiveLikeTimer.prototype, "pageVisible", void 0);
LiveLikeTimer = __decorate([
    element('livelike-timer')
], LiveLikeTimer);
export { LiveLikeTimer };

import { MessageEvents } from '../constants/ChatEvents';
import { _$$ } from './internal/globals';
import { typeErr, getChannel, noArgPromiseRej, paginator, getChatRoomResource, isDate, request, uninitializedPromiseRej, filterAndTransformMessages, } from '../utils';
import { subscriptionCoordinator } from '../utils/subscriptionCoordinator';
import { validateArgObj } from '../utils/validateArgs';
import { chatMessagePrep, sendBaseMessage, createBaseMessagePayload, } from './baseMessage';
import { transformMessage } from '../utils/transformMessages';
import { getBlockedProfileIds, setAndUpdateBlockProfileIds, } from './blockProfile';
function createMessageUrl(url, opts = {}, chatRoomId) {
    const queryParamsArr = [
        chatRoomId && `chat_room_id=${chatRoomId}`,
        opts.count && `page_size=${opts.count}`,
        opts.start && `until=${opts.start}`,
        opts.end && `since=${opts.end}`,
    ].filter((param) => !!param);
    const queryParams = queryParamsArr.length
        ? `?${queryParamsArr.join('&')}`
        : '';
    return `${url}${queryParams}`;
}
/**
 * getMessageList
 * Returns array of message objects
 * @async
 * @example
 * ```js
 * getMessageList({roomId: string}).then(list => console.log(list))
 * ```
 */
export const getMessageList = (roomId, opts = {}) => {
    if (!_$$.ready) {
        return uninitializedPromiseRej();
    }
    if (!opts.count) {
        opts.count = 25;
    }
    if (opts.start && isDate(opts.start)) {
        opts.start = new Date(opts.start).toISOString();
    }
    if (opts.end && isDate(opts.end)) {
        opts.end = new Date(opts.end).toISOString();
    }
    return getChatRoomResource(roomId).then((chatRoom) => {
        const paginatedMethod = (startTime) => {
            opts.start = new Date(startTime).toISOString();
            const messageListUrl = createMessageUrl(chatRoom.chatroom_messages_url, opts, chatRoom.id);
            return Promise.all([
                getBlockedProfileIds(),
                request.get({ url: messageListUrl }),
            ]).then(([blockProfileIdsRes, messageListRes]) => {
                var _a, _b;
                const blockedProfileIds = blockProfileIdsRes.results;
                const messages = messageListRes.results;
                const cleanedList = messages.length
                    ? filterAndTransformMessages(roomId, messages, blockedProfileIds, opts.includeFilteredMessages)
                    : [];
                // determine if next messages are present using the count of current messages response
                const hasNext = messages.length >= opts.count;
                return {
                    results: cleanedList,
                    next: (_b = (hasNext && ((_a = messages === null || messages === void 0 ? void 0 : messages[0]) === null || _a === void 0 ? void 0 : _a.created_at))) !== null && _b !== void 0 ? _b : null,
                };
            });
        };
        const timeNow = opts.start || new Date().getTime();
        const iterator = paginator(timeNow, paginatedMethod, (r) => ({
            messages: r.results,
        }));
        return iterator.next().then((firstRes) => ({
            messages: firstRes.value.messages,
            done: firstRes.done,
            next: iterator.next,
        }));
    });
};
/**
 * getMessageCount
 * Returns number of messages in chat room
 * @async
 * @example
 * ```js
 * getMessageCount('c1f6082c-f7b9-4d1e-b64e-9071a3860ffc', {since: "2020-04-16T16:29:39.158Z"}).then(r => console.log(r.count))
 ```
 */
export const getMessageCount = (
/**
 * The roomId for the chat room.
 */
roomId, opts) => {
    if (!_$$.ready) {
        return uninitializedPromiseRej();
    }
    if (!roomId) {
        return noArgPromiseRej('roomId', 'messagecount');
    }
    if (!opts) {
        opts = {};
    }
    if (opts.since) {
        if (isDate(opts.since)) {
            opts.since = new Date(opts.since).toISOString();
        }
        else {
            return Promise.reject(typeErr('messageCount', 'since', 'Date'));
        }
    }
    if (opts.until) {
        if (isDate(opts.until)) {
            opts.until = new Date(opts.until).toISOString();
        }
        else {
            return Promise.reject(typeErr('messageCount', 'until', 'Date'));
        }
    }
    return getChatRoomResource(roomId)
        .then((chatRoom) => {
        const messageCountUrl = createMessageUrl(chatRoom.chatroom_messages_count_url, { end: opts.since, start: opts.until });
        return request.get({ url: messageCountUrl });
    })
        .then(({ count }) => ({
        roomId,
        count,
    }));
};
const messageListenerMap = new Map();
/**
 * Adds a listener to a chatroom that calls passed callback function when event fires.
 * @since 1.18.0
 * @example
 * ```js
 * addMessageListener({roomId: string}, function(e){console.log(e)})
 * ```
 */
export const addMessageListener = (arg, callback) => validateArgObj(arg, ['roomId'], 'addMessageListener')
    .then(() => getChannel(arg.roomId))
    .then((channel) => {
    const listener = ({ message, event }) => {
        callback({
            message: transformMessage(message, arg.roomId),
            roomId: arg.roomId,
            event: MessageEvents[event],
        });
    };
    // maintain a reference of integrator callback with custom listener mapper fn
    messageListenerMap.set(callback, listener);
    subscriptionCoordinator.addListener(channel, listener);
    subscriptionCoordinator.subscribe(channel);
    return setAndUpdateBlockProfileIds();
});
/**
 * Removes listener from chatroom
 * @since 1.18.0
 * @example
 * ```js
 * removeMessageListener({roomId: string}, function(e){console.log(e)})
 * ```
 */
export const removeMessageListener = (arg, callback) => validateArgObj(arg, ['roomId'], 'removeMessageListener')
    .then(() => getChannel(arg.roomId))
    .then((channel) => {
    if (messageListenerMap.has(callback)) {
        const listener = messageListenerMap.get(callback);
        subscriptionCoordinator.removeListener(channel, listener);
        subscriptionCoordinator.unsubscribe(channel);
        messageListenerMap.delete(callback);
    }
});
/**
 * sendMessage
 * Send user message to chat room.
 * @async
 * @example
 * ```js
 * sendMessage({message: 'Test message', roomId: 'c1f6082c-f7b9-4d1e-b64e-9071a3860ffc'})
 * ```
 */
export const sendMessage = (args) => validateArgObj(args, ['roomId'], 'messages').then(() => sendBaseMessage(createBaseMessagePayload(args), args.roomId));
/**
 * sendCustomMessage
 * Send custom message to chat room.
 * @async
 * @example
 * ```js
 * sendCustomMessage({custom_data: 'Test message', roomId: 'c1f6082c-f7b9-4d1e-b64e-9071a3860ffc'})
 * ```
 */
export const sendCustomMessage = (args) => {
    const payload = {
        program_date_time: args.program_date_time,
        custom_data: args.custom_data,
    };
    return getChatRoomResource(args.roomId).then((chatRoomDetails) => request.post({
        url: chatRoomDetails.custom_messages_url,
        data: payload,
    }));
};
export const prepareOutgoingChatMessage = (roomId, preparationCallback) => (chatMessagePrep[roomId] = preparationCallback);

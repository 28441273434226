import { convertToCssString as cs } from '../../utils/themeConversions';
export const styles = (theme) => {
    return `
    .text-ask-prompt-container {
      padding: 1rem;
      ${cs(theme.prompt)}
    }
    .text-ask-prompt {
      word-break: break-word;
      word-wrap: break-word;
      padding: 0;
      width: 100%;
    }
    .text-ask-form {
      padding: 0 16px 16px 16px;
    }
    .text-ask-input-container {
      padding: 15px 3px 17px 15px;
      border-radius: 4px;
      background-color: rgb(40,40,40);
      border-color: rgb(63,63,63);
      position: relative;
    }
    .text-ask-input {
      border: none;
      background-color: rgb(40,40,40);
      color: rgb(255,255,255);
      font-size: 12px;
      line-height: 20px;
      resize: none; 
      width: 100%;
      box-sizing: border-box;
      padding: 0;
      padding-right: 8px;
      font-family: "HelveticaNeue-Regular";
      ${cs(theme.replyEnabled)}
    }
    .text-ask-input::placeholder {
      color: rgb(171,171,171);
      ${cs(theme.replyPlaceholder)}
    }  
    .text-ask-input:focus {
      outline: none;
    }
    .text-ask-input[disabled] {
      color: rgb(171,171,171);
      ${cs(theme.replyDisabled)}
    }
    .text-ask-input::-webkit-scrollbar {
      width: 4px;
    }
    .text-ask-input::-webkit-scrollbar-track {
      margin-right: 3px;
    }
    .text-ask-input::-webkit-scrollbar-thumb {
      -webkit-border-radius: 100px;
      border-radius: 100px;
      background: rgb(84,84,84);
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    }
    .text-ask-input-counter {
      font-size: 10px;
      color: rgb(171,171,171);
      position: absolute;
      right: 5px;
      bottom: 2px;
    }
    .text-ask-button {
      width: fit-content;
      padding: 12px 16px;
      border: none;
      border-radius: 4px;
      font-size: 10px;
      font-family: "HelveticaNeue-Bold";
      cursor: pointer;
      ${cs(theme.submitButtonEnabled)}      
    }
    .text-ask-button[disabled] {
      background-color: rgb(84,84,84);
      color: rgb(171,171,171);
      cursor: default;
      ${cs(theme.submitButtonDisabled)}
    }
    .confirmation-message-container {
      padding: 0;
      padding-left: 11px;
    }
    .hidden {
      opacity: 0;
    }
    .confirmation-message {
      color: rgb(0,240,120);
      font-size: 12px;
      text-align: left;
      word-break: break-word;
      word-wrap: break-word;
      padding: 0;
      ${cs(theme.confirmation)}
    }
    .text-ask-footer {
      padding: 0 16px 16px 16px;
      display: flex;
      align-items: center;
    }
    `;
};

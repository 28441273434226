export var UserQuestStatus;
(function (UserQuestStatus) {
    UserQuestStatus["INCOMPLETE"] = "incomplete";
    UserQuestStatus["COMPLETED"] = "completed";
})(UserQuestStatus || (UserQuestStatus = {}));
export var QuestRewardStatus;
(function (QuestRewardStatus) {
    QuestRewardStatus["CLAIMED"] = "claimed";
    QuestRewardStatus["UNCLAIMED"] = "unclaimed";
})(QuestRewardStatus || (QuestRewardStatus = {}));

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, property } from 'lit-element';
import { LiveLikeChatElement } from '../../mixins/ChatElement';
import { element } from '../../utils/decorators';
import { modalStyles } from './livelike-modal.style';
let LiveLikeModal = class LiveLikeModal extends LiveLikeChatElement {
    render() {
        const getMessage = {
            block: this.owner.localize('chat.blockConfirmation.message', {
                username: this.data ? this.data.nickname : null,
            }),
            report: this.owner.localize('chat.reportConfirmation.message'),
            muted: this.owner.localize('chat.mutedMessage'),
            error: this.owner.localize('chat.errorMessage'),
        };
        return html `
      ${modalStyles}
      <div class="livelike-modal ${this.type !== '' ? 'showing' : null}">
        <div class="modal-body">${getMessage[this.type]}</div>
      </div>
    `;
    }
};
__decorate([
    property({ type: String, reflect: true })
], LiveLikeModal.prototype, "type", void 0);
__decorate([
    property({ type: Object })
], LiveLikeModal.prototype, "data", void 0);
LiveLikeModal = __decorate([
    element('livelike-modal')
], LiveLikeModal);
export { LiveLikeModal };

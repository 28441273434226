var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, property } from 'lit-element';
import { element } from '../utils/decorators';
import { queueBase } from '../utils/queueBase';
import { addMessageListener, getMessageList, removeMessageListener, sendMessage, } from '../scripts/messages';
import { blockProfile } from '../scripts/blockProfile';
import { _$$, userProfile } from '../scripts/internal/globals';
import { eventBus, eventDispatcher, immuteAppend, errDocLink, getLang, selfChildCheck, filterCheck, fetchReactions, fetchStickers, localize, getChatRoomResource, request, getSyncStrategy, updateWithQueue, waitForSyncStrat, getProgramDateTime, programDateTimeSort, } from '../utils';
import '../components/livelike-message-list';
import '../components/livelike-chat-composer';
import '../components/livelike-scroll-down';
import { messageAction, generateUUID } from '../scripts/internal/pubnubHandler';
import { getBlockProfileIdsResource } from '../utils/blockProfileIds';
import { addUserProfileEventListener, removeUserProfileEventListener, quoteMessage, } from '../scripts';
import { UIEvent } from '../constants/UIEvent';
import { UserProfileEvent } from '../constants/subscriptionEvents';
import { addDeletedMessageId } from '../utils/deletedMessageIds';
import { transformMessage } from '../utils/transformMessages';
/**
 * @element livelike-chat
 */
let LiveLikeChat = class LiveLikeChat extends LitElement {
    constructor() {
        super(...arguments);
        this.dataId = generateUUID();
        this.hasPrevMessages = false;
        this.deletedMessages = [];
        /**
         * Show / hide the livelike-chat-composer
         * @since 1.25.0
         */
        this.hidecomposer = false;
        /**
         * If the element has loaded
         */
        this.loaded = false;
        /**
         * Add to to enable timestamps in chat message
         */
        this.timestamps = false;
        /**
         * Add to element to enable chat message menu
         */
        this.messagemenus = false;
        this.blockList = userProfile.blockList || [];
        /**
         * The array of chat messages
         */
        this.messageList = [
            'Loading',
        ];
        /**
         * Object containing time formatting properties.
         */
        this.timeformat = {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        };
        /**
         * enable message quote feature
         * @since 2.18.0
         */
        this.messagequotes = false;
        /**
         * enable UI of loading previous messages
         */
        this.previousMessages = false;
        /**
         * flag to determine whether loading of previous messages is in progress
         */
        this.prevMessagesLoading = false;
        /**
         * Comma separated regex patterns for matching chat message urls
         * @since
         */
        this.messageUrlPatterns = [
            /((https?):\/\/|www\.)?([-A-Z0-9._\+~#=]*)\.[a-z]{2,}\b([@%_\+.~#?&:\/\/=]+[-A-Z0-9\/]+)*/gi,
        ];
        this.showFilteredMessages = false;
        this.translations = _$$.localizedStrings;
        this.onLoadPrevMessageClick = () => {
            if (this.prevMessagesLoading) {
                return;
            }
            this.prevMessagesLoading = true;
            return this.messageIterator().then((res) => {
                var _a, _b;
                this.prevMessagesLoading = false;
                this.hasPrevMessages = !res.done;
                if ((_b = (_a = res.value) === null || _a === void 0 ? void 0 : _a.messages) === null || _b === void 0 ? void 0 : _b.length) {
                    /**
                     * filter messages whose program date time is after the program dateTime of
                     * first message in the message list which would be processed as per message queue.
                     * For rest of the messages, prepend to the existing message list
                     */
                    const firstMsg = this.messageList[0];
                    const prevMessages = [];
                    const nextMessages = [];
                    const firstMsgDateTime = firstMsg && getProgramDateTime(firstMsg);
                    res.value.messages.forEach((msg) => {
                        const msgDateTime = getProgramDateTime(msg);
                        if (firstMsgDateTime &&
                            msgDateTime &&
                            firstMsgDateTime < msgDateTime) {
                            nextMessages.push(msg);
                        }
                        else {
                            prevMessages.push(msg);
                        }
                    });
                    if (prevMessages.length) {
                        prevMessages.sort(programDateTimeSort);
                        this.messageList = [...prevMessages, ...this.messageList];
                        eventDispatcher(this, 'prev-messages-ready', {
                            messages: prevMessages,
                            element: this,
                        });
                    }
                    if (nextMessages.length) {
                        nextMessages.forEach((message) => this.messageReceived({ message, prevMessage: true }));
                        this.updateEl();
                    }
                }
                return res;
            });
        };
        this.addBlockProfileId = (event) => {
            eventDispatcher(this, UIEvent.QUOTE_MESSAGE, {
                status: 'remove',
                senderId: event.message.blocked_profile_id,
            });
        };
        this.updateLocalization = (e) => (this.translations = e.localizedStrings);
        this.sendChatMessage = (e) => {
            const evt = (type) => eventDispatcher(this, type, {
                message: e.detail.message,
                quoteMessage: e.detail.quoteMessage,
                roomId: this.roomid,
            });
            const msgArgs = {
                message: e.detail.message,
                quoteMessage: e.detail.quoteMessage,
                roomId: this.roomid,
                sender_image_url: this.avatarurl,
                timestamp: this.syncStrategy && this.syncStrategy.currentTimecode
                    ? this.syncStrategy.currentTimecode
                    : null,
            };
            const sendMessageApi = this.messagequotes && e.detail.quoteMessage ? quoteMessage : sendMessage;
            e.detail.message &&
                e.detail.message.match(/([^\n]+\n)*[^\n]+/g) &&
                sendMessageApi(msgArgs)
                    .then(() => evt('messagesent'))
                    .catch(() => {
                    this.displayModal('muted');
                    evt('messagefailed');
                });
        };
        this.connectChat = () => {
            if (_$$.ready && userProfile && userProfile.id) {
                removeUserProfileEventListener(UserProfileEvent.BLOCK_PROFILE, this.addBlockProfileId);
                addUserProfileEventListener(UserProfileEvent.BLOCK_PROFILE, this.addBlockProfileId);
                this.loaded = false;
                this.hasPrevMessages = false;
                // Resetting prop with a different val generated from original prop should be changed.
                // this.syncStrategy should purely be property externally set, and something else like
                // this.createdSyncStrategy, etc, should be what is referenced internally when checking sync time.
                if (this.syncStrategy) {
                    //to re-initialize the queue
                    this.queue = queueBase(this.syncStrategy);
                }
                else {
                    this.syncStrategy = getSyncStrategy(_$$.syncStrategy);
                }
                getChatRoomResource(this.roomid)
                    .then((chatRoomDetails) => {
                    this.channel = chatRoomDetails.channels.chat.pubnub;
                    fetchReactions(this.roomid).then((r) => (this.reactionPack = r[0] && r[0].emojis ? r[0].emojis : r));
                    fetchStickers(this.roomid).then((s) => (this.stickerPacks = s));
                    this.loaded = true;
                    eventDispatcher(this, 'roomentered', {
                        room: this,
                        roomId: this.roomid,
                    });
                    return addMessageListener({ roomId: this.roomid }, this.demultiplex);
                })
                    .then(() => {
                    return this.getMessages();
                });
            }
            else {
                setTimeout(this.connectChat, 1000);
            }
        };
        this.demultiplex = (e) => {
            const event = e.event;
            event === 'messagereceived' &&
                this.messageReceived(Object.assign(Object.assign({}, e), { message: transformMessage(e.message, this.roomid) }), this.updateEl);
            (event === 'reactionremoved' || event === 'reactionadded') &&
                this.updateReaction(e.message, event);
            // For backwards compat - remove when deletion API is completely moved
            event === 'messagedeleted' && this.messageDeleted(e);
            eventDispatcher(this, event, { message: e.message, roomId: this.roomid });
        };
        this.getMessages = () => {
            const listEl = this.querySelector('livelike-message-list');
            listEl && listEl.remove();
            return getMessageList(this.roomid, {
                includeFilteredMessages: this.showFilteredMessages,
            }).then((res) => {
                this.messageIterator = !res.done && res.next;
                // Enable loading of previous messages when current msg list is empty and there are prev messages present
                this.hasPrevMessages = this.messageIterator && res.messages.length === 0;
                this.messageList = ['No Messages'];
                this.appendElements(() => {
                    if (res.messages && res.messages.length > 0) {
                        waitForSyncStrat(this, () => {
                            res.messages.forEach((message) => this.messageReceived({ message }));
                            this.updateEl();
                        });
                    }
                });
                eventDispatcher(this, 'messagehistory', { messages: res.messages });
            });
        };
        this.updateReaction = (message, event) => {
            if (this.messageList) {
                const updatedList = [...this.messageList];
                const { messageTimetoken, actionTimetoken, uuid, value } = message;
                const mIdx = updatedList.findIndex((m) => m.timetoken === messageTimetoken);
                if (mIdx !== -1) {
                    let reaction = updatedList[mIdx].reactions[value];
                    const byProfileId = (r) => r.uuid === userProfile.id;
                    const byActionTT = (r) => r.actionTimetoken === actionTimetoken;
                    const foundIdx = (fn) => updatedList[mIdx].reactions[value].findIndex(fn);
                    const getIdx = () => uuid === userProfile.id
                        ? foundIdx(byProfileId)
                        : foundIdx(byActionTT);
                    if (event === 'reactionadded') {
                        !reaction && (updatedList[mIdx].reactions[value] = []);
                        let rIdx = getIdx();
                        if (rIdx === -1 || uuid !== userProfile.id) {
                            updatedList[mIdx].reactions[value].push({ uuid, actionTimetoken });
                        }
                        else {
                            updatedList[mIdx].reactions[value][rIdx].actionTimetoken =
                                actionTimetoken;
                        }
                    }
                    if (event === 'reactionremoved') {
                        let rIdx = getIdx();
                        rIdx !== -1 && updatedList[mIdx].reactions[value].splice(rIdx, 1);
                    }
                    this.messageList = updatedList;
                }
            }
        };
        this.handleMessageReaction = (messageId, reactionId, type) => new Promise((res, rej) => {
            const notFound = (str) => rej(console.error(str));
            const trackObj = {
                'Chat Room ID': this.roomid,
                'Chat Message ID': messageId,
                'Chat Reaction ID': reactionId,
            };
            const reactionAction = (action) => messageAction[type](this.channel, foundMsg.timetoken, action, trackObj, res, notFound);
            const foundMsg = this.messageList.find((m) => m.id === messageId);
            !foundMsg && notFound('Could not find message for reaction action.');
            if (type === 'remove') {
                const err = 'Reaction not deleted. Reaction not found.';
                !foundMsg.reactions[reactionId] && notFound(err);
                let foundReaction = foundMsg.reactions[reactionId].find((r) => r.uuid === userProfile.id);
                foundReaction
                    ? reactionAction(foundReaction.actionTimetoken)
                    : notFound(err);
            }
            else {
                this.reactionPack.find((r) => r.id === reactionId)
                    ? reactionAction({ type: 'rc', value: reactionId })
                    : notFound('Reaction not added. Reaction not found');
            }
        });
        this.addMessageReaction = ({ messageId, reactionId }) => this.handleMessageReaction(messageId, reactionId, 'add');
        this.removeMessageReaction = ({ messageId, reactionId }) => this.handleMessageReaction(messageId, reactionId, 'remove');
        this.messageReceived = (e, cb) => {
            // Checks to see if identical message is already in message list.
            // Caused by strange behavior related to pubnub sending old messages when initially subscribing.
            // This is just a hopefully temporary patch - need to investigate why pubnub is doing this.
            const messageEls = Array.from(this.querySelectorAll('livelike-message-item'));
            const idx = messageEls.findIndex((m) => m.id === e.message.id);
            idx === -1 &&
                filterCheck(this.roomid, e.message, getBlockProfileIdsResource(), this.showFilteredMessages) &&
                this.queue &&
                this.queue.enqueueItem({ payload: e.message, prevMessage: e.prevMessage }, cb);
        };
        this.messageDeleted = (e) => {
            addDeletedMessageId({
                roomId: this.roomid,
                messageId: String(e.message.id),
            });
            eventDispatcher(this, UIEvent.QUOTE_MESSAGE, {
                status: 'remove',
                messageId: e.message.id,
            });
            const idx = this.messageList.findIndex((v) => String(e.message.id) === String(v.id) ||
                e.message.id === v.quote_message_id);
            if (idx !== -1) {
                this.deletedMessages = immuteAppend(this.deletedMessages, e.message);
                eventDispatcher(this, e.event, {
                    message: e.message,
                    roomId: this.roomid,
                });
                // TODO Other way? also rename
                // eventBus.emit('messageDeleted', {
                //   message: e.message,
                //   roomId: this.roomid,
                // });
            }
        };
        this.updateEl = () => {
            updateWithQueue(this, () => true, (args) => {
                let m = args.payload;
                m && !m.reactions && (m.reactions = {});
                const noMessages = this.messageList && typeof this.messageList[0] === 'string';
                // show load prev message button only when atmost one message is rendered as message list item
                if (this.messageIterator && !this.hasPrevMessages && noMessages) {
                    this.hasPrevMessages = true;
                }
                noMessages
                    ? (this.messageList = [m])
                    : (this.messageList = immuteAppend(this.messageList, m));
                eventDispatcher(this, 'message-ready', {
                    message: m,
                    element: this,
                    prevMessage: args.prevMessage,
                });
            });
        };
        /**
         * Registers message menu items. Pass a function that returns an array
         * of menu items. The function has a paramter that is the message object.
         * ADD DOC LINK
         * @since 1.18.0
         * @see https://docs.livelike.com/docs/
         */
        this.registerMessageMenu = (menu) => {
            let doubleNested = false;
            const arr = menu({});
            arr.forEach((m) => m.items && m.items.forEach((v) => v.items && (doubleNested = true)));
            if (doubleNested)
                return errDocLink('Message menu currently supports submenus only one level deep.');
            this.messageMenuFunc = menu;
        };
        this.appendElements = (cb) => {
            this.updateComplete.then(() => {
                const lSlot = this.shadowRoot.querySelector('slot[name=message-list]');
                const listSlotted = lSlot &&
                    lSlot
                        .assignedNodes({ flatten: true })
                        .filter((node) => node.nodeType === 1).length > 0;
                !listSlotted &&
                    this.insertAdjacentHTML('afterbegin', `<livelike-message-list slot="message-list">
          </livelike-message-list>`);
                this.insertAdjacentHTML('beforeend', '<livelike-scroll-down slot="snap-to-live"></livelike-scroll-down>');
                cb && cb();
            });
        };
        this.localize = (key, variables) => localize(key, this.lang, variables);
        this.reportUser = (message) => this.sendReport(message).then((res) => this.displayModal('report', res));
        this.sendReport = (message) => request.post({
            url: _$$.chatRooms[this.roomid].report_message_url,
            data: {
                channel: this.channel,
                user_id: message.sender_id,
                nickname: message.sender_nickname,
                message_id: message.id,
                message: message.image_url ? message.image_url : message.message,
                profile_id: message.sender_id,
                pubnub_timetoken: message.timetoken,
            },
        });
        this.blockUser = (message) => {
            blockProfile({
                profileId: message.sender_id,
            }).then((res) => this.displayModal('block', res.blocked_profile));
        };
        this.onQuoteAction = (message) => {
            eventDispatcher(this, UIEvent.QUOTE_MESSAGE, {
                // creating new message reference obj to avoid deleting linked quote message
                // data (which happens inside quoteMessage API) in the case of quoting a quote message
                message: Object.assign({}, message),
                status: 'add',
            });
        };
        this.displayModal = (messageType, res) => {
            const modal = document.createElement('livelike-modal');
            modal.type = messageType;
            modal.data = res;
            const ml = this.querySelector('livelike-message-list');
            ml.append(modal);
            setTimeout(() => modal.remove(), 4000);
        };
        this.defaultMessageMenuFunc = (message) => {
            let defaultOtherUserMessageMenus = [];
            if (message.sender_id !== userProfile.id) {
                defaultOtherUserMessageMenus = [
                    {
                        label: this.localize('chat.messageMenu.reportMessage'),
                        type: 'single',
                        onClick: () => this.reportUser(message),
                    },
                    {
                        label: this.localize('chat.messageMenu.cancel'),
                        type: 'single',
                        onClick: () => { },
                    },
                    {
                        label: this.localize('chat.messageMenu.blockUser'),
                        type: 'single',
                        onClick: () => this.blockUser(message),
                    },
                ];
            }
            return [
                this.messagequotes && {
                    label: this.localize('chat.messageMenu.quote'),
                    type: 'single',
                    onClick: () => this.onQuoteAction(message),
                },
                ...defaultOtherUserMessageMenus,
            ].filter(Boolean);
        };
    }
    get chatComposerEl() {
        return this.querySelector('livelike-chat-composer');
    }
    connectedCallback() {
        eventBus.on('localizationUpdate', this.updateLocalization);
        this.addEventListener('sendChatMessage', this.sendChatMessage);
        this.addEventListener('chat-provider', (event) => {
            event.detail.provider = this;
            event.stopPropagation();
        });
        super.connectedCallback();
    }
    disconnectedCallback() {
        this.removeEventListener('sendChatMessage', this.sendChatMessage);
        removeMessageListener({ roomId: this.roomid }, this.demultiplex);
        removeUserProfileEventListener(UserProfileEvent.BLOCK_PROFILE, this.addBlockProfileId);
        eventBus.off('localizationUpdate', this.updateLocalization);
    }
    firstUpdated() {
        this.messageMenuFunc = this.defaultMessageMenuFunc;
        selfChildCheck(this);
        getLang(this);
        //@ts-ignore
        this.style +=
            'display:flex; display: -webkit-box; display: -webkit-flex;display: -ms-flexbox;';
    }
    updated(changedProps) {
        changedProps.forEach((prevProp, name) => {
            // console.log('livelike-chat updatePRops', name, ' ---- ', prevProp, ' ---- ', this[name])
            if (name === 'hidecomposer') {
                const composer = this.querySelector('livelike-chat-composer');
                const cSlot = this.shadowRoot.querySelector('slot[name=composer]');
                const composerSlotted = cSlot && cSlot.assignedNodes().length > 0;
                this.hidecomposer
                    ? composer && composer.remove()
                    : !composerSlotted &&
                        this.insertAdjacentHTML('beforeend', '<livelike-chat-composer slot="composer"></livelike-chat-composer>');
            }
            if (name === 'syncStrategy' && this.syncStrategy) {
                this.queue = queueBase(this.syncStrategy);
            }
            const roomId = name === 'roomid' && this.roomid;
            if (roomId) {
                if (prevProp !== undefined && prevProp !== null) {
                    removeMessageListener({ roomId: prevProp }, this.demultiplex);
                    eventDispatcher(this, 'roomexited', { roomId: this.roomid });
                }
                this.connectChat();
            }
            this[name] !== undefined &&
                this[name] !== null &&
                eventBus.emit('chat-updated', {
                    name,
                    key: this.dataId,
                    prop: this[name],
                    owner: this,
                });
        });
    }
    renderEmptyListMessage() {
        var _a;
        if (((_a = this.messageList) === null || _a === void 0 ? void 0 : _a[0]) && this.messageList[0] === 'No Messages') {
            return html ` <slot name="message-list-empty"></slot> `;
        }
        return null;
    }
    renderMessageListLoading() {
        var _a;
        if (((_a = this.messageList) === null || _a === void 0 ? void 0 : _a[0]) && this.messageList[0] === 'Loading') {
            return html ` <slot name="message-list-loading"></slot> `;
        }
        return null;
    }
    render() {
        return html `
      <style>
        :host {
          display: flex;
          flex-direction: column;
          height: 100%;
        }
      </style>
      ${this.loaded &&
            userProfile &&
            userProfile.id &&
            this.dataId &&
            this.roomid
            ? html `
            ${this.renderEmptyListMessage()}
            <slot name="message-list">
              ${this.renderMessageListLoading()}
            </slot>
            <slot name="composer"></slot>
            <slot name="snap-to-live"></slot>
          `
            : html ` <slot name="chat-element-loading"></slot> `}
    `;
    }
};
__decorate([
    property({ type: Array })
], LiveLikeChat.prototype, "deletedMessages", void 0);
__decorate([
    property({ type: String })
], LiveLikeChat.prototype, "channel", void 0);
__decorate([
    property({ type: String, reflect: true })
], LiveLikeChat.prototype, "roomid", void 0);
__decorate([
    property({ type: String, reflect: true })
], LiveLikeChat.prototype, "lang", void 0);
__decorate([
    property({ type: String })
], LiveLikeChat.prototype, "placeholder", void 0);
__decorate([
    property({ type: Boolean })
], LiveLikeChat.prototype, "hidecomposer", void 0);
__decorate([
    property({ type: Boolean })
], LiveLikeChat.prototype, "loaded", void 0);
__decorate([
    property({ type: Boolean })
], LiveLikeChat.prototype, "timestamps", void 0);
__decorate([
    property({ type: Boolean })
], LiveLikeChat.prototype, "messagemenus", void 0);
__decorate([
    property({ type: Function })
], LiveLikeChat.prototype, "messageMenuFunc", void 0);
__decorate([
    property({ type: Array })
], LiveLikeChat.prototype, "blockList", void 0);
__decorate([
    property({ type: Array })
], LiveLikeChat.prototype, "messageList", void 0);
__decorate([
    property({ type: Object })
], LiveLikeChat.prototype, "syncStrategy", void 0);
__decorate([
    property({ type: Object })
], LiveLikeChat.prototype, "queue", void 0);
__decorate([
    property({ type: Array })
], LiveLikeChat.prototype, "stickerPacks", void 0);
__decorate([
    property({ type: Array })
], LiveLikeChat.prototype, "reactionPack", void 0);
__decorate([
    property({ type: Object })
], LiveLikeChat.prototype, "timeformat", void 0);
__decorate([
    property({ type: String })
], LiveLikeChat.prototype, "avatarurl", void 0);
__decorate([
    property({ type: Boolean })
], LiveLikeChat.prototype, "showavatar", void 0);
__decorate([
    property({ type: Boolean })
], LiveLikeChat.prototype, "messagequotes", void 0);
__decorate([
    property({ type: Function })
], LiveLikeChat.prototype, "customMessageRenderer", void 0);
__decorate([
    property({ type: Function })
], LiveLikeChat.prototype, "loadPrevMessageRenderer", void 0);
__decorate([
    property({ type: Boolean })
], LiveLikeChat.prototype, "previousMessages", void 0);
__decorate([
    property({ type: Boolean })
], LiveLikeChat.prototype, "prevMessagesLoading", void 0);
__decorate([
    property({ type: Boolean })
], LiveLikeChat.prototype, "messageurls", void 0);
__decorate([
    property({ type: Array })
], LiveLikeChat.prototype, "messageUrlPatterns", void 0);
__decorate([
    property({ type: Boolean })
], LiveLikeChat.prototype, "showFilteredMessages", void 0);
__decorate([
    property({
        type: Object,
        hasChanged() {
            return true;
        },
    })
], LiveLikeChat.prototype, "translations", void 0);
LiveLikeChat = __decorate([
    element('livelike-chat')
], LiveLikeChat);
export { LiveLikeChat };

import { _$$ } from './internal/globals';
import { eventBus, mergeObj } from '../utils';
/**
 * applyLocalization
 * Dynamically add localization Languages
 * @async
 * @since 2.1.2
 */
export const applyLocalization = (localizedStrings) => {
    _$$.localizedStrings = mergeObj(_$$.localizedStrings, localizedStrings);
    eventBus.emit('localizationUpdate', {
        localizedStrings: _$$.localizedStrings,
    });
    return Promise.resolve(true);
};

// @ts-strict
import { localizedStrings } from '../../constants/Localization';
export var _$$ = {
    eb: null,
    appResource: {},
    progResource: {},
    chatRooms: {},
    ready: false,
    clientId: null,
    accessToken: null,
    syncStrategy: null,
    storageStrategy: null,
    lsKey: null,
    logger: false,
    pn: null,
    chatUrlTemplate: null,
    createChatUrlTemplate: null,
    programUrlTemplate: null,
    chatRoomInvitationDetailUrlTemplate: null,
    chatRoomInvitationsUrl: null,
    pageVisible: true,
    leaderboardUrlTemplate: null,
    endpoint: 'https://cf-blast.livelikecdn.com/api/v1/',
    localizedStrings,
    profileDetailUrlTemplate: null,
    profileUrl: null,
    analyticsProvider: null,
    pinnedMessagesUrl: null,
    blockedProfileIds: [],
    deletedMessageIds: {},
    reactionSpaces: {},
    quests: {},
    userQuests: {},
    pnConfig: null,
};
const LiveLikeTheme = {};
export var _config = {
    version: '2.34.0',
    ready: false,
    theme: LiveLikeTheme,
};
export var userProfile = {
    access_token: '',
    badges: null,
    created_at: '',
    id: '',
    nickname: '',
    points: 0,
    reported_count: 0,
    subscribe_channel: '',
    url: '',
    blockList: null,
    custom_data: null,
    reactions: null,
    chat_room_memberships_url: null,
    badges_url: '',
    badge_progress_url: '',
    reward_item_balances_url: null,
    reward_item_transfer_url: null,
    block_profile_url: '',
    blocked_profiles_template_url: '',
    blocked_profile_ids_url: '',
    leaderboards_url: '',
    leaderboard_views_url: '',
};

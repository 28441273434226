import { html } from 'lit-element';
import { registerElementSafely } from '../../utils/decorators';
import { eventDispatcher } from '../../utils';
import { defaultHeader } from '../../utils/templates';
import { styles } from './votingWidget.styles';
export const ceBase = (tag, superclass) => {
    registerElementSafely(tag, class extends superclass {
        constructor() {
            super(...arguments);
            this.waitForResponse = true;
        }
        // TODO See PercentCalcMixin todo
        render() {
            const textOptionTemplate = html `
          <div style="position:relative;width:100%;display:flex;">
            <livelike-progress></livelike-progress>
            <livelike-description></livelike-description>
            <livelike-percentage></livelike-percentage>
          </div>
        `;
            const imageOptionTemplate = html `
          <div class="livelike-voting-image-container">
            <div class="image-description-wrapper">
              <livelike-description></livelike-description>
              <livelike-progress></livelike-progress>
            </div>
            <livelike-percentage></livelike-percentage>
          </div>
          <livelike-image height="80px" width="80px"></livelike-image>
        `;
            const votedLabel = this.quizVoteSubmitted
                ? html `<span class="voted-text"
              >${this.owner.localize('widget.quiz.votedText')}</span
            >`
                : null;
            const voteButton = tag.indexOf('quiz') !== -1
                ? html `
                <livelike-footer>
                  <button
                    class="vote-button"
                    @click=${this.lockInVote}
                    ?disabled="${!this.selectedOption ||
                    !this.selectedOption.id ||
                    this.quizVoteSubmitted ||
                    this.voteDisable ||
                    this.disabled}"
                  >
                    ${this.owner.localize('widget.quiz.voteButton.label')}
                  </button>
                  ${votedLabel}
                </livelike-footer>
              `
                : null;
            const sponsor = tag.indexOf('poll') !== -1 &&
                this.widgetPayload.sponsors &&
                this.widgetPayload.sponsors.length
                ? html `
                <livelike-widget-footer>
                  <div class="sponsorship">
                    ${this.owner.localize('widget.sponsors.label')}
                    <img
                      src="${this.widgetPayload.sponsors[0].logo_url}"
                      alt="${this.widgetPayload.sponsors[0].name}"
                    />
                  </div>
                </livelike-widget-footer>
              `
                : null;
            return html `
          <template>
            <livelike-widget-root>
              ${styles} ${defaultHeader}
              <livelike-widget-body>
                <livelike-select>
                  <template>
                    <livelike-option>
                      ${tag.indexOf('image') !== -1
                ? imageOptionTemplate
                : textOptionTemplate}
                    </livelike-option>
                  </template>
                </livelike-select>
                ${voteButton} ${sponsor}
              </livelike-widget-body>
            </livelike-widget-root>
          </template>
        `;
        }
    });
};
export const ResultWidgetMixin = (superclass) => class extends superclass {
    constructor() {
        super(...arguments);
        this.resultEvent = () => {
            super.resultEvent();
            let result = 'unattempted';
            if (this.selectedOption &&
                this.selectedOption.id &&
                this.selectedOption.hasOwnProperty('is_correct')) {
                result = this.selectedOption.is_correct ? 'correct' : 'incorrect';
            }
            eventDispatcher(this, 'results', {
                result,
                element: this,
                widget: this.widgetPayload,
                selectedOption: this.selectedOption,
            });
            this.localName.indexOf('quiz') !== -1 &&
                !this.quizVoteSubmitted &&
                !this.vote &&
                this.selectedOption &&
                this.selectedOption.id &&
                this.lockInVote(this.selectedOption.answer_url);
        };
    }
};

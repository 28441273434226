import { convertToCssString as cs } from '../../utils/themeConversions';
export const styles = (theme) => {
    let str = `
    :host {
      display: block;
      position: relative;
      padding: 0.5rem;
      z-index: 10;
      ${cs(theme.unselectedOptionPercentage)}
    }
    :host([selected]) {
      ${cs(theme.selectedOptionPercentage)}
    }
    `;
    theme.correctOption &&
        (str += `
      :host([correct]) {
        ${cs(theme.correctOptionPercentage)}
      }
      :host([incorrect]) {
        ${cs(theme.incorrectOptionPercentage)}
      }
  `);
    return str;
};

import { convertToCssString as cs } from '../../utils/themeConversions';
export const styles = (theme) => {
    let str = `
    :host {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      ${cs(theme.unselectedOptionImage)}
    }
    ::slotted(img) {
      object-fit: contain;
    }
    img {
      max-width: 90px;
      display: block;
      margin: 0 auto;
    }
    :host([selected]) {
      ${cs(theme.selectedOptionImage)}
    }
  `;
    theme.correctOption &&
        (str += `
      :host([correct]) {
        ${cs(theme.correctOptionImage)}
      }
      :host([incorrect]) {
        ${cs(theme.incorrectOptionImage)}
      }
    `);
    return str;
};

import { _config } from './internal/globals';
import { getTheme } from '../utils/themeConversions';
import { eventBus } from '../utils';
/**
 * applyTheme
 * Dynamically change the theme
 * @async
 * @since 1.24.0
 */
export const applyTheme = (t) => new Promise((res) => {
    const theme = getTheme(t);
    _config.theme = theme;
    eventBus.emit('themeUpdate', { theme });
    function checkTheme() {
        if (theme && theme.version) {
            res();
        }
        else {
            setTimeout(checkTheme, 1000);
        }
    }
    checkTheme();
});

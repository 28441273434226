var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, property } from 'lit-element';
import { userProfile } from '../../scripts/internal/globals';
import { messageItemStyles } from './livelike-message-item.style.js';
import { messageReactionStyles } from '../styles/messageReactionStyles';
import { formatDateTime } from '../../utils';
import { element } from '../../utils/decorators';
import { toggle, closeItem } from '../../utils/animationToggle';
import { miniReactions, reactionButton, reactionItems, } from '../reaction-items';
import { moreIcon } from '../../icons';
import '../livelike-modal';
import '../message-menu';
import { isQuoteMessagePayload, } from '../../scripts/types';
import '../livelike-quote-message-item';
import { buildMessage } from '../../utils/buildMessage';
import { trackMessage } from '../../utils/analytics';
import '../livelike-chat-message-avatar';
import { LiveLikeChatElement } from '../../mixins/ChatElement';
let MessageItem = class MessageItem extends LiveLikeChatElement {
    constructor() {
        super(...arguments);
        // Props
        // TODO Should this be changed? this.message.message for the text string seems off.
        this.message = null;
        // State
        this.animation = 0;
        this.selectedMessage = null;
        this.selectedReactions = null;
        // TODO Should this be part of the message objecT?
        this.isDeleted = false;
        this.messageDeleted = (e) => {
            const message = this.owner.localize('chat.messageDeleted');
            if (this.message && e.message && e.message.id === this.message.id) {
                this.isDeleted = true;
                this.message = Object.assign(Object.assign({}, this.message), { image_url: null, message, filtered_message: message });
            }
        };
        this.ownerUpdated = (e) => {
            e.name === 'deletedMessages' &&
                this.messageDeleted({ message: e.prop[e.prop.length - 1] });
        };
        this.renderMessageMenu = (message) => html `
      <message-menu
        .menu=${this.owner.messageMenuFunc(message)}
        .message=${message}
        .animation=${this.animation}
        .closeMenu=${() => closeItem(this, 'selectedMessage')}
      ></message-menu>
    `;
        this.renderMenuButton = (activeClass, message) => this.owner.messagemenus &&
            !this.isDeleted &&
            this.owner.messageMenuFunc(message) &&
            this.owner.messageMenuFunc(message).length
            ? html `
          <div class="menu-button-container">
            <button
              aria-label="${this.owner.localize('chat.messageMenu.ariaLabel')}"
              class="more-icon ${activeClass}"
              @click=${() => toggle(this, message.id, 'selectedMessage')}
            >
              ${moreIcon}
            </button>
          </div>
        `
            : null;
        this.renderTimestamp = (m) => {
            const time = m.created_at;
            const formatted = formatDateTime(time, this.owner.timeformat, this.owner.lang);
            return html `
      <time
        aria-label=${formatted}
        class="message-timestamp"
        datetime="${time}"
      >
        ${formatted}
      </time>
    `;
        };
        /////////////// REACTIONS ///////////////
        this.reactionAction = (reaction) => {
            const foundReaction = this.message.reactions[reaction.id] &&
                this.message.reactions[reaction.id].find((r) => r.uuid === userProfile.id);
            const cb = () => closeItem(this, 'selectedReactions');
            const reactionMessage = {
                value: reaction.id,
                uuid: userProfile.id,
                messageTimetoken: this.message.timetoken,
                actionTimetoken: (foundReaction && foundReaction.actionTimetoken) || null,
            };
            const action = (type, evt) => {
                this.owner[type]({
                    messageId: this.message.id,
                    reactionId: reaction.id,
                }).then(cb);
                this.owner.updateReaction(reactionMessage, evt);
            };
            foundReaction
                ? action('removeMessageReaction', 'reactionremoved')
                : action('addMessageReaction', 'reactionadded');
        };
        this.getReactionCount = (reaction) => this.message.reactions[reaction.id]
            ? this.message.reactions[reaction.id].length
            : 0;
        this.renderMiniReactions = () => {
            const reactionArr = Object.entries(this.message.reactions).reduce((totalReactions, [reactionId, reactions]) => {
                var _a;
                if ((_a = this.owner.reactionPack) === null || _a === void 0 ? void 0 : _a.find((reaction) => reaction.id === reactionId)) {
                    totalReactions = totalReactions.concat(reactions);
                }
                return totalReactions;
            }, []);
            return miniReactions(reactionArr.length, this.getReactionCount, this.owner.reactionPack);
        };
        this.renderReactions = () => {
            const isSelected = (reaction) => this.message.reactions[reaction.id] &&
                this.message.reactions[reaction.id].find((r) => r.uuid === userProfile.id);
            const selected = (reaction) => (isSelected(reaction) ? 'selected' : '');
            return reactionItems(this.owner.reactionPack, this.animation, selected, this.getReactionCount, this.reactionAction, 'chat.reactions.reactionSelectionButton.ariaLabel', this.owner.lang);
        };
        this.trackClickEvent = (ev) => {
            if (ev.target && ev.target.href) {
                const message = this.message;
                message.messageLink = ev.target.href;
                trackMessage(message, this.owner.roomid, 'Chat Message Link Clicked');
            }
        };
        this.renderQuoteMessage = () => {
            if (!this.owner.messagequotes ||
                !isQuoteMessagePayload(this.message) ||
                this.isDeleted) {
                return null;
            }
            return html `<livelike-quote-message-item
      .message=${this.message.quote_message}
      .showavatar=${false}
    ></livelike-quote-message-item>`;
        };
    }
    createRenderRoot() {
        return this;
    }
    connectedCallback() {
        super.connectedCallback();
        trackMessage(this.message, this.owner.roomid, 'Chat Message Displayed');
    }
    render() {
        const blocked = this.owner.blockList.find((id) => id === this.message.sender_id);
        const isSelected = this.selectedMessage === this.message.id;
        const activeClass = isSelected ? 'active' : '';
        const selfClass = this.message.sender_id === userProfile.id ? 'self' : '';
        const avatarClass = this.owner.showavatar ? 'has-avatar' : '';
        const deletedClass = this.isDeleted ? 'deleted' : '';
        const filteredClass = this.message.isFiltered ? 'filtered' : '';
        const avatar = this.owner.showavatar
            ? html `<livelike-chat-message-avatar
          .senderImageUrl=${this.message.sender_image_url}
        ></livelike-chat-message-avatar>`
            : null;
        return !blocked
            ? html `
          ${messageItemStyles} ${messageReactionStyles}
          <div
            class="message-item-container ${activeClass} ${selfClass} ${filteredClass}"
          >
            ${avatar}
            <div class="message-wrapper ${avatarClass}" tabindex="0">
              ${this.owner.reactionPack && this.owner.reactionPack.length > 0
                ? this.renderMiniReactions()
                : null}
              <div aria-live="polite" role="log">
                <div
                  class="sender-name"
                  aria-label=${this.message.sender_nickname}
                >
                  ${this.message.sender_nickname}
                </div>
                ${this.renderQuoteMessage()}
                <div
                  @click=${this.trackClickEvent}
                  aria-label=${this.message.image_url
                ? this.message.image_url
                : this.message.message}
                  id="senderMessage-${this.message.id}"
                  class="sender-message ${deletedClass}"
                >
                  ${buildMessage(this.message, this.owner.stickerPacks, this.owner.messageurls, this.owner.messageUrlPatterns)}
                </div>
                ${this.owner.timestamps
                ? this.renderTimestamp(this.message)
                : null}
              </div>
              ${this.owner.reactionPack &&
                this.owner.reactionPack.length > 0 &&
                !this.isDeleted
                ? reactionButton(this, this.message.id, 'chat.reactions.openButton.ariaLabel')
                : null}
            </div>
            ${this.selectedReactions === this.message.id
                ? this.renderReactions()
                : null}
            ${isSelected ? this.renderMessageMenu(this.message) : null}
            ${this.renderMenuButton(activeClass, this.message)}
          </div>
        `
            : null;
    }
};
__decorate([
    property({ type: Object })
], MessageItem.prototype, "message", void 0);
__decorate([
    property({ type: Number })
], MessageItem.prototype, "animation", void 0);
__decorate([
    property({ type: String })
], MessageItem.prototype, "selectedMessage", void 0);
__decorate([
    property({ type: String })
], MessageItem.prototype, "selectedReactions", void 0);
__decorate([
    property({ type: Boolean })
], MessageItem.prototype, "isDeleted", void 0);
MessageItem = __decorate([
    element('livelike-message-item')
], MessageItem);
export { MessageItem };

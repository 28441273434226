var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, property } from 'lit-element';
import { element } from '../../utils/decorators';
import { defaultHeader } from '../../utils/templates';
import { styles } from './livelike-cheer-meter.style';
import { ThemedWidget } from '../../mixins/WidgetTemplate';
import { throttle } from '../../utils';
import '../../components/livelike-option';
import '../../components/livelike-image';
import { WidgetKind } from '../../constants/Widgets';
let LiveLikeCheerMeter = class LiveLikeCheerMeter extends ThemedWidget {
    constructor() {
        super(...arguments);
        this.kind = WidgetKind.CHEER_METER;
        this.owner = this;
        this.defaultStyles = styles;
        this.cheerVote = {};
        this.count = 0;
        this.submitVote = (option) => {
            if (!this.disabled) {
                this.cheerVote[option.id]
                    ? this.cheerVote[option.id].count++
                    : (this.cheerVote[option.id] = {
                        createUrl: option.vote_url,
                        count: 1,
                    });
                this.selectedOption = option;
                this.interactionTrack();
                this.sendVote();
            }
        };
        this.sendVote = throttle(() => {
            for (const key in this.cheerVote) {
                if (this.cheerVote[key].count) {
                    this.interactionEvent(this.cheerVote[key].count);
                    const vote = { vote_count: this.cheerVote[key].count };
                    this.cheerVote[key].count = 0;
                    const cb = (payload) => payload.url && (this.cheerVote[key].updateUrl = payload.url);
                    this.cheerVote[key].updateUrl
                        ? this.updateVote(this.cheerVote[key].updateUrl, vote).then(cb)
                        : this.createVote(this.cheerVote[key].createUrl, vote).then(cb);
                }
            }
        }, 3000);
        this.updateTotalVoteCount = () => {
            this.count = this.options.reduce((a, c) => a + c.vote_count, 0);
        };
    }
    voteCountChanged(e) {
        this.updateTotalVoteCount();
    }
    firstUpdated() {
        super.firstUpdated();
        //set updateUrl in cheer vote to make sure PATCH happens
        this.updateTotalVoteCount();
    }
    render() {
        return html `
      <template>
        <livelike-widget-root>
          ${defaultHeader}
          <livelike-widget-body>
            <livelike-dueling-progress></livelike-dueling-progress>
            <div class="cheer-image-body">
              <livelike-vote-count></livelike-vote-count>
              ${this.options.map((option, idx) => {
            const isDisabled = this.disabled ? 'disabled' : 'enabled';
            return html `
                  <div class="cheer-item-container">
                    <livelike-option index=${idx} style="display:contents;">
                      <div
                        class="cheer-image-wrap button${idx}  ${this.interacted
                ? ''
                : 'initial'} ${isDisabled}"
                      >
                        <img
                          class="cheer-image ${isDisabled}"
                          src=${option.image_url}
                        />
                      </div>
                    </livelike-option>
                  </div>
                `;
        })}
            </div>
          </livelike-widget-body>
        </livelike-widget-root>
      </template>
    `;
    }
};
__decorate([
    property({ type: Object })
], LiveLikeCheerMeter.prototype, "cheerVote", void 0);
__decorate([
    property({ type: Number })
], LiveLikeCheerMeter.prototype, "count", void 0);
LiveLikeCheerMeter = __decorate([
    element('livelike-cheer-meter')
], LiveLikeCheerMeter);
export { LiveLikeCheerMeter };

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, property } from 'lit-element';
import { element } from '../../utils/decorators';
import { eventBus } from '../../utils';
import { downIcon } from '../../icons';
import { styles } from './livelike-scroll-down.style';
import { LiveLikeChatElement } from '../../mixins/ChatElement';
let ScrollDown = class ScrollDown extends LiveLikeChatElement {
    constructor() {
        super(...arguments);
        this.defaultStyles = styles;
        this.userScrolled = false;
        this.ownerUpdated = (e) => {
            (e.name === 'translations' || e.name === 'lang') &&
                this.updateLocalizedStrings();
        };
        this.setScrolled = (e) => {
            if (e.key === this.owner.dataId) {
                const button = this.querySelector('.down-icon');
                const iconClass = e.scrolled ? 'down-icon show' : 'down-icon';
                button && button.setAttribute('class', iconClass);
            }
        };
        this.scrollDown = () => eventBus.emit('scrollDown', { key: this.owner.dataId });
        this.updateLocalizedStrings = () => {
            this.querySelector('button.down-icon').setAttribute('aria-label', this.owner.localize('chat.scrollDownButton.ariaLabel'));
        };
    }
    connectedCallback() {
        super.connectedCallback();
        eventBus.on('userScrolled', this.setScrolled);
        this.addEventListener('click', this.scrollDown);
        this.insertAdjacentHTML('beforeend', `<button tabindex="0" aria-label="${this.owner.localize('chat.scrollDownButton.ariaLabel')}" class="down-icon">${downIcon}</button>`);
    }
    render() {
        return html ` <slot></slot> `;
    }
};
__decorate([
    property({ type: Boolean })
], ScrollDown.prototype, "userScrolled", void 0);
ScrollDown = __decorate([
    element('livelike-scroll-down')
], ScrollDown);
export { ScrollDown };

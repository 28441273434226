export const styles = () => `
  <style>
    @keyframes slideDown {
      from {
        opacity: 0;
        transform: translate3d(0, -3rem, 0);
      }
      to {
        opacity: 1;
        transform: translate3d(0, 0rem, 0);
      }
    }

    @keyframes slideUp {
      from {
        opacity: 0;
        transform: translate3d(0, 0rem, 0);
      }
      to {
        opacity: 1;
        transform: translate3d(0, -2rem, 0);
      }
    }

    .down-icon {
      z-index: 15;
      position: absolute;
      border: none;
      right: 3.75rem;
      bottom: 5.5rem;
      padding: 0;
      border-radius: 50%;
      height: auto;
      width: auto;
      display: none;
      flex-direction: column;
      animation: slideDown 0.5s ease forwards;
      transition: all 0.3s ease;
    }
    .down-icon.show {
      display: flex;
      animation: slideUp 0.5s ease forwards;
    }
    .down-icon:hover {
      cursor: pointer;
      transition: all 0.3s ease;
    }
    .down-icon svg > rect {
      fill: #b0b0b0;
    }
    .down-icon svg > path {
      fill: #fff;
    }
  </style>
`;

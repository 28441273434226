var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, property, LitElement } from 'lit-element';
import { element } from '../../utils/decorators';
import { styles } from './livelike-load-more-button.style';
/**
 * Can either be added as a child of <livelike-widgets> or added with a `for` attribute that matches a <livelike-widgets> `id` attribute.
 * On click loads the next page of widgets and attaches them to <livelike-widgets>
 * @element livelike-load-more-button
 * @slot default
 */
let LiveLikeLoadMoreButton = class LiveLikeLoadMoreButton extends LitElement {
    constructor() {
        super(...arguments);
        this.owner = document.querySelector(this.getAttribute('for')) || this.parentElement;
        this.loaded = true;
        this.loadPage = () => {
            if (this.loaded) {
                this.loaded = false;
                this.buttonEl.innerHTML = `<div class="loading-spinner"></div>`;
                this.owner.loadNextPage().then((done) => {
                    this.loaded = true;
                    done && this.buttonEl.remove();
                    this.buttonEl && (this.buttonEl.innerHTML = 'Load More');
                });
            }
        };
    }
    connectedCallback() {
        super.connectedCallback();
        const style = document.createElement('style');
        style.innerHTML = styles;
        this.appendChild(style);
        const button = document.createElement('button');
        button.addEventListener('click', this.loadPage);
        button.setAttribute('class', 'livelike-load-more-button');
        button.setAttribute('aria-label', 'Load more widgets');
        button.innerHTML = 'Load More';
        this.buttonEl = button;
        this.appendChild(button);
    }
    render() {
        return html ` <slot></slot> `;
    }
};
__decorate([
    property({ type: Boolean })
], LiveLikeLoadMoreButton.prototype, "loaded", void 0);
LiveLikeLoadMoreButton = __decorate([
    element('livelike-load-more-button')
], LiveLikeLoadMoreButton);
export { LiveLikeLoadMoreButton };

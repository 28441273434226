import { WidgetKind } from '../../constants/Widgets';
import { ThemedWidget } from '../../mixins';
import { getWidgetInteractions } from '../../scripts';
import { request } from '../../utils';
import { LiveLikePoll } from './poll';
import { ceBase, ResultWidgetMixin } from './votingWidgets';
// TODO pending backend changes for savihng/updating pred votes.
export const predictionVotes = {};
export class PredictionFollowUp extends ThemedWidget {
    constructor() {
        super(...arguments);
        this.claimRewards = (interaction, predId) => {
            interaction.claim_token &&
                request
                    .post({
                    url: this.widgetPayload.claim_url,
                    data: { claim_token: interaction.claim_token },
                })
                    .then((payload) => {
                    predictionVotes[predId] &&
                        (predictionVotes[predId].claim_token = null);
                    this.rankChanged(this.widgetPayload, payload.rewards);
                })
                    .catch((err) => console.warn(err.message));
        };
        this.interactive = () => Promise.resolve();
    }
}
export class LiveLikePrediction extends LiveLikePoll {
    constructor() {
        super(...arguments);
        this.defaultVoteHide = true;
        this.hideVoteInResults = true;
    }
    firstUpdated() {
        super.firstUpdated();
        this.addEventListener('prediction', (e) => {
            predictionVotes[e.detail.widget.id] = {
                option_id: e.detail.prediction.option_id,
                claim_token: e.detail.prediction.claim_token,
            };
        });
    }
}
ceBase('livelike-text-prediction', LiveLikePrediction);
ceBase('livelike-image-prediction', LiveLikePrediction);
export class LiveLikeFollowUp extends ResultWidgetMixin(PredictionFollowUp) {
    constructor() {
        super(...arguments);
        this.owner = this;
    }
    firstUpdated() {
        super.firstUpdated();
        this.updateComplete.then(() => (this.disabled = true));
        const predId = this.widgetPayload.text_prediction_id ||
            this.widgetPayload.image_prediction_id;
        const predKind = this.widgetPayload.text_prediction_id
            ? WidgetKind.TEXT_PREDICTION
            : WidgetKind.IMAGE_PREDICTION;
        const savedPred = predictionVotes[predId] || this.interaction;
        if (savedPred) {
            this.selectedOption = { id: savedPred.option_id };
            this.claimRewards(savedPred, predId);
        }
        else {
            getWidgetInteractions({
                programId: this.programid,
                widgets: [{ kind: predKind, id: predId }],
            }).then((res) => {
                if (res && res[predKind] && res[predKind][0]) {
                    this.selectedOption = { id: res[predKind][0].option_id };
                    this.claimRewards(res[predKind][0], predId);
                }
            });
        }
    }
}
ceBase('livelike-image-prediction-follow-up', LiveLikeFollowUp);
ceBase('livelike-text-prediction-follow-up', LiveLikeFollowUp);

import { getChatRoomControlChannel, getChannel } from '../utils';
import { subscriptionCoordinator } from '../utils/subscriptionCoordinator';
import { userProfile } from './internal/globals';
import { ChatRoomEvent } from '../constants/subscriptionEvents';
function getSubscribeChannel(chatRoomEvent, roomId) {
    switch (chatRoomEvent) {
        case ChatRoomEvent.ADD_NEW_MEMBER:
        case ChatRoomEvent.INVITE_NEW_MEMBER:
            return Promise.resolve(userProfile.subscribe_channel);
        case ChatRoomEvent.PIN_MESSAGE:
        case ChatRoomEvent.UNPIN_MESSAGE:
            return getChatRoomControlChannel(roomId);
        case ChatRoomEvent.CHATROOM_UPDATED:
            return getChannel(roomId);
    }
}
/**
 * @description Add listener for the given ChatRoomEvent, pass options based on the events
 * @since 2.12.0
 * @example
 * ```js
 * addChatRoomEventListener(ChatRoomEvent.INVITE_NEW_MEMBER, function onReceieveChatRoomInvitationListener(invitationEvent){
 *  console.log(invitation);
 * })
 * // for adding Invite new member event listener
 * addChatRoomEventListener(ChatRoomEvent.INVITE_NEW_MEMBER, function onReceieveChatRoomInvitationListener(invitationEvent){
 *  console.log(invitation);
 * })
 * // for adding pin message event listener
 * addChatRoomEventListener(
 *    ChatRoomEvent.PIN_MESSAGE,
 *    function onReceivedPinMessageListener (pinMessageInfoEvent) { console.log(pinMessageInfoEvent)},
 *    { roomId: "695ea6f4-fe7b-47cc-817c-2d73fdba264a" }
 * );
 * ```
 */
export function addChatRoomEventListener(chatRoomEvent, listenerFn, options) {
    return getSubscribeChannel(chatRoomEvent, options === null || options === void 0 ? void 0 : options.roomId).then((channel) => {
        subscriptionCoordinator.addListener(channel, listenerFn, chatRoomEvent);
        subscriptionCoordinator.subscribe(channel);
    });
}
/**
 * @description Remove registered listenerFn for the given ChatRoomEvent, pass options based on the events
 * when no listener passed, all registered event listener will be removed
 * @since 2.12.0
 * @example
 * ```js
 * // for removing Invite new member event listener
 * removeChatRoomEventListener(ChatRoomEvent.INVITE_NEW_MEMBER, onReceieveChatRoomInvitationListener);
 * // for removing pin message event listener
 * removeChatRoomEventListener(
 *    ChatRoomEvent.PIN_MESSAGE,
 *    onReceivedPinMessageListener,
 *    { roomId: "695ea6f4-fe7b-47cc-817c-2d73fdba264a" }
 * );
 * ```
 */
export function removeChatRoomEventListener(chatRoomEvent, listenerFn, options) {
    return getSubscribeChannel(chatRoomEvent, options === null || options === void 0 ? void 0 : options.roomId).then((channel) => {
        if (listenerFn) {
            subscriptionCoordinator.removeListener(channel, listenerFn, chatRoomEvent);
        }
        else {
            subscriptionCoordinator.removeAllListenersForEvent(channel, chatRoomEvent);
        }
        subscriptionCoordinator.unsubscribe(channel);
    });
}

import { html } from 'lit-element';
import { toggle } from '../utils/animationToggle';
import { localize } from '../utils';
export const reactionButton = (component, id, localizationKey) => html `
  <div class="reaction-button-container">
    <button
      class="reaction-button"
      aria-label="${localizationKey
    ? component.owner.localize(localizationKey)
    : 'Open Reactions'}"
      @click=${() => toggle(component, id, 'selectedReactions')}
    ></button>
  </div>
`;
function abbrevNum(num) {
    const unitSymbols = ['', 'k', 'M'];
    const tier = (Math.log10(num) / 3) | 0;
    if (tier == 0)
        return num;
    const scaled = num / Math.pow(10, tier * 3);
    // Check if number is whole or above 100k so '.0' doesn't get added.
    const wholeOrThreeDigit = scaled % 1 === 0 || scaled >= 10 ? 0 : 1;
    return scaled.toFixed(wholeOrThreeDigit) + unitSymbols[tier];
}
export const miniReactions = (totalReactions, getReactionCount, reactionArr) => {
    return html `
    <div class="mini-reactions">
      ${reactionArr.map((reaction) => getReactionCount(reaction) > 0
        ? html `
              <img
                class="mini-reaction-image"
                src=${reaction.file}
                alt="Reaction ${reaction.name}"
              />
            `
        : null)}
      ${totalReactions > 0
        ? html `
            <span class="mini-reaction-count">
              ${abbrevNum(totalReactions)}
            </span>
          `
        : null}
    </div>
  `;
};
export const reactionItems = (reactionArr, animation, selected, getReactionCount, onClickCallback, localizationKey, lang) => html `
  <div class="reaction-item-container" style="opacity: ${animation}">
    <div class="reaction-item-wrap">
      ${reactionArr.map((reaction) => {
    const count = getReactionCount(reaction);
    return html `
          <button
            aria-label="${localizationKey
        ? localize(localizationKey, lang || 'en-US', {
            reactionName: reaction.name,
        })
        : 'Reaction ' + reaction.name}"
            class="reaction-item ${selected(reaction)}"
            @click=${() => onClickCallback(reaction)}
          >
            <img class="reaction-image" src=${reaction.file} alt="" />
            ${count > 0
        ? html ` <span class="reaction-count">${abbrevNum(count)}</span> `
        : null}
          </button>
        `;
})}
    </div>
  </div>
`;

export const localizedStrings = {
    en: {
        'chat.inputPlaceholder': 'Say something...',
        'chat.messageMenu.quote': 'Quote message',
        'chat.messageMenu.blockUser': 'Block this user',
        'chat.messageMenu.reportMessage': 'Report message',
        'chat.messageMenu.cancel': 'Cancel',
        'chat.messageMenu.ariaLabel': 'Message options',
        'chat.blockConfirmation.message': 'You will no longer see messages from this user.',
        'chat.reportConfirmation.message': 'The message has been reported to the moderators. Thank you.',
        'chat.mutedMessage': 'Unable to send message.',
        'chat.errorMessage': 'There was an error. Please try again.',
        'chat.messageDeleted': 'Message deleted',
        'chat.messageSenderBlocked': 'Message sender blocked',
        'chat.sendButton.ariaLabel': 'Send Message',
        'chat.scrollDownButton.ariaLabel': 'Scroll Down',
        'chat.stickerPicker.title': 'STICKERS',
        'chat.stickerPicker.openButton.ariaLabel': 'Open Stickers',
        'chat.stickerPicker.closeButton.ariaLabel': 'Close Stickers',
        'chat.stickerPicker.stickerPackTab.ariaLabel': 'Sticker pack <packName>',
        'chat.stickerPicker.stickerSelectionButton.ariaLabel': '<stickerName> Sticker',
        'chat.giphyPicker.title': 'GIPHY',
        'chat.giphyPicker.placeholder': 'Search GIFs via GIPHY',
        'chat.giphyPicker.openButton.ariaLabel': 'Open GIPHY',
        'chat.giphyPicker.closeButton.ariaLabel': 'Close GIPHY',
        'chat.giphyPicker.stickerSelectionButton.ariaLabel': '<stickerName> GIF',
        'chat.reactions.openButton.ariaLabel': 'Message reactions',
        'chat.reactions.reactionSelectionButton.ariaLabel': 'Reaction <reactionName>',
        'widget.quiz.voteButton.label': 'Answer',
        'widget.quiz.votedText': 'Answered!',
        'widget.slider.voteButton.label': 'Vote',
        'widget.slider.votedText': 'Voted!',
        'widget.textAsk.placeholder': 'Type something...',
        'widget.textAsk.sendButton.label': 'SEND',
        'widget.video.playbackError': 'Can’t play this video',
        'widget.sponsors.label': 'Sponsored by',
        'widget.quiz.tag': '',
        'widget.poll.tag': '',
        'widget.prediction.tag': '',
        'widget.followup.tag': '',
        'widget.numberPrediction.tag': '',
        'widget.numberPredictionFollowUp.tag': '',
        'widget.numberPrediction.voteButton.label': 'Predict',
        'widget.numberPrediction.votedText': 'Predicted!',
    },
};

const gradBase = { direction: 0, format: 'uniformGradient' };
const predHeader = {
    background: Object.assign(Object.assign({}, gradBase), { colors: ['003264', '0096c8'] }),
};
const nonVotingHeader = {
    background: { format: 'fill', color: '111111' },
};
const correctBase = {
    correctOptionBar: {
        background: Object.assign(Object.assign({}, gradBase), { colors: ['81ca00', '429321'] }),
    },
    incorrectOptionBar: {
        background: Object.assign(Object.assign({}, gradBase), { colors: ['dc0028', 'd0021b'] }),
    },
    correctOption: { borderWidth: 2, borderColor: '81ca00' },
    incorrectOption: { borderWidth: 2, borderColor: 'dc0028' },
};
export const defaultTheme = {
    widgets: {
        alert: {
            body: {},
            header: {
                background: Object.assign(Object.assign({}, gradBase), { colors: ['9f041b', 'f5515f'] }),
            },
        },
        imageSlider: {
            header: Object.assign({}, nonVotingHeader),
            body: {},
            interactiveTrackRight: {
                background: {
                    format: 'fill',
                    color: '888',
                },
            },
            interactiveTrackLeft: {
                background: {
                    format: 'fill',
                    color: 'ff6a13',
                },
            },
            // TODO Add to image slider
            resultsTrackRight: {},
            resultsTrackLeft: {},
        },
        prediction: {
            header: Object.assign({}, predHeader),
            selectedOption: { borderColor: '0096c8' },
            selectedOptionBar: {
                borderColor: '0096c8',
                background: Object.assign(Object.assign({}, gradBase), { colors: ['003264', '0096c8'] }),
            },
        },
        predictionFollowUp: Object.assign(Object.assign({}, correctBase), { header: Object.assign({}, predHeader), selectedOption: { borderColor: '0096c8' }, selectedOptionBar: {
                borderColor: 'c96dd7',
                background: Object.assign(Object.assign({}, gradBase), { colors: ['003264', '0096c8'] }),
            } }),
        poll: {
            header: {
                background: Object.assign(Object.assign({}, gradBase), { colors: ['3123ae', 'c96dd7'] }),
            },
            selectedOption: { borderColor: 'c86dd7' },
            selectedOptionBar: {
                borderColor: 'c96dd7',
                background: Object.assign(Object.assign({}, gradBase), { colors: ['3123ae', 'c86dd7'] }),
            },
        },
        quiz: Object.assign(Object.assign({}, correctBase), { header: {
                background: Object.assign(Object.assign({}, gradBase), { colors: ['f76b1c', 'ffb400'] }),
            }, selectedOption: { borderColor: 'ffb400' }, selectedOptionBar: {
                borderColor: 'c96dd7',
                background: Object.assign(Object.assign({}, gradBase), { colors: ['f76b1c', 'ffb400'] }),
            } }),
        socialEmbed: {
            header: Object.assign({}, nonVotingHeader),
            body: {},
        },
        richText: {
            header: Object.assign({}, nonVotingHeader),
            body: {},
        },
        cheerMeter: {
            header: Object.assign({}, nonVotingHeader),
            body: {},
        },
        videoAlert: {
            body: {},
            header: {
                background: Object.assign(Object.assign({}, gradBase), { colors: ['9f041b', 'f5515f'] }),
            },
        },
        textAsk: {
            header: {
                background: Object.assign(Object.assign({}, gradBase), { colors: ['9f041b', 'f5515f'] }),
            },
            body: {},
        },
        numberPrediction: {
            header: Object.assign({}, predHeader),
        },
        numberPredictionFollowUp: {
            header: Object.assign({}, predHeader),
        },
    },
    version: 1,
};

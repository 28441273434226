import { validateArgObj } from '../utils/validateArgs';
import { createBaseMessagePayload, sendBaseMessage } from './baseMessage';
import { isQuoteMessagePayload, } from './types';
/**
 * Quote user message for a given chat room Id.
 * @async
 * @since 2.18.0
 * @example
 * ```js
 * quoteMessage({
 *   message: 'Test reply',
 *   roomId: 'c1f6082c-f7b9-4d1e-b64e-9071a3860ffc',
 *   quoteMessage: {
 *      // message object of type IChatMessageResponsePayload
 *   }
 * })
 * ```
 */
export const quoteMessage = (args) => validateArgObj(args, ['roomId', 'quoteMessage'], 'quoteMessage').then(() => {
    // in case of quoting of quoted message delete the quote_message field from quoteMessage
    // to avoid recursive nesting and to avoid msg size limit error
    if (isQuoteMessagePayload(args.quoteMessage)) {
        delete args.quoteMessage.quote_message;
    }
    delete args.quoteMessage.reactions;
    const payload = Object.assign(Object.assign({}, createBaseMessagePayload(args)), { quote_message: args.quoteMessage, quote_message_id: args.quoteMessage.id });
    return sendBaseMessage(payload, args.roomId);
});

import { _$$, userProfile } from '../scripts/internal/globals';
const createPromise = (type) => {
    const req = (opts) => {
        if (!opts.url && !opts.path) {
            console.warn('Need either a url or a path');
            return Promise.reject('Need either a url or a path');
        }
        let data, endpoint, token = opts.accessToken;
        const storedProfile = _$$.storageStrategy.get();
        const storedToken = storedProfile && storedProfile.access_token;
        // Allows accessToken to be set as null on purpose
        token === undefined &&
            (token = storedToken || _$$.accessToken || userProfile.access_token);
        opts.url
            ? (endpoint = opts.url)
            : opts.path && (endpoint = _$$.endpoint + opts.path);
        const headers = new Headers();
        token && headers.append('Authorization', 'Bearer ' + token);
        if (type === 'POST' || type === 'PATCH') {
            headers.append('Content-Type', 'application/json');
            opts.data && (data = JSON.stringify(opts.data));
        }
        const request = new Request(endpoint, {
            method: type,
            body: data,
            headers: headers,
        });
        return fetch(request).then((response) => {
            const statusMap = {
                400: 'Incorrect data.',
                401: 'Unauthorized. Access token missing from request.',
                403: 'Access Denied. Access token incorrect.',
                404: 'Resource not found.',
                405: 'Method not allowed.',
                409: 'Resource already exists',
                415: 'Unsupported media type.',
            };
            let errorStatus = statusMap[response.status];
            response.status >= 500 && (errorStatus = 'Server error. Try again.');
            if (response.status >= 300) {
                response.text().then((text) => console.error(text));
                return Promise.reject({
                    status: response.status,
                    message: errorStatus,
                });
            }
            else {
                const contentType = response.headers.get('content-type');
                if (contentType && contentType.indexOf('application/json') !== -1) {
                    return response.json();
                }
                else {
                    // return the actual response to be handled by integrator
                    return response;
                }
            }
        });
    };
    return req;
};
export const request = {
    get: createPromise('GET'),
    put: createPromise('PUT'),
    post: createPromise('POST'),
    patch: createPromise('PATCH'),
    delete: createPromise('DELETE'),
};

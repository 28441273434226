var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, property } from 'lit-element';
import { userProfile } from '../../scripts/internal/globals';
import { quoteMessageItemStyles } from './livelike-quote-message-item.style.js';
import { element } from '../../utils/decorators';
import '../livelike-chat-message-avatar';
import { LiveLikeChatElement } from '../../mixins/ChatElement';
import { isDeletedMessageId } from '../../utils/deletedMessageIds';
import { buildMessage } from '../../utils/buildMessage';
import { getBlockProfileIdsResource } from '../../utils/blockProfileIds';
let QuoteMessageItem = class QuoteMessageItem extends LiveLikeChatElement {
    constructor() {
        super(...arguments);
        // Props
        this.message = null;
        // State
        this.animation = 0;
        // Props
        this.showavatar = true;
        this.isSenderBlocked = false;
        this.isDeleted = false;
        this.ownerUpdated = (e) => {
            if (e.name === 'blockList') {
                this.messageBlocked(e.prop);
            }
            else if (e.name === 'deletedMessages') {
                this.messageDeleted({ message: e.prop[e.prop.length - 1] });
            }
        };
        this.messageDeleted = (e) => {
            if (this.message && e.message && e.message.id === this.message.id) {
                this.isDeleted = true;
            }
        };
        this.messageBlocked = (blockList) => {
            if (!this.message) {
                return;
            }
            this.isSenderBlocked = blockList.indexOf(this.message.sender_id) > -1;
        };
        this.renderMessage = () => {
            if (this.isDeleted || this.isSenderBlocked) {
                const text = this.isDeleted
                    ? this.owner.localize('chat.messageDeleted')
                    : this.owner.localize('chat.messageSenderBlocked');
                return html `<span>${text}</span>`;
            }
            return buildMessage(this.message, this.owner.stickerPacks, this.owner.messageurls, this.owner.messageUrlPatterns);
        };
    }
    createRenderRoot() {
        return this;
    }
    connectedCallback() {
        super.connectedCallback();
        if (!this.message) {
            return;
        }
        if (isDeletedMessageId(this.owner.roomid, this.message.id)) {
            this.messageDeleted({ message: this.message });
        }
        const blockIds = this.owner.blockList.concat(getBlockProfileIdsResource());
        this.messageBlocked(blockIds);
    }
    render() {
        if (!this.message) {
            return null;
        }
        const selfClass = this.message.sender_id === userProfile.id ? 'self' : '';
        const avatarClass = this.showavatar ? 'has-avatar' : '';
        const deletedClass = this.isDeleted ? 'deleted' : '';
        const senderBlockedClass = this.isSenderBlocked ? 'sender-blocked' : '';
        const avatar = this.showavatar
            ? html `<livelike-chat-message-avatar
          .senderImageUrl=${this.message.sender_image_url}
        ></livelike-chat-message-avatar>`
            : null;
        const messageLabel = this.message.image_url
            ? this.message.image_url
            : this.message.message;
        return html `
      ${quoteMessageItemStyles}
      <div class="quote-message-item-container ${selfClass}">
        ${avatar}
        <div class="quote-message-wrapper ${avatarClass}" tabindex="0">
          <div aria-live="polite" role="log">
            <div
              class="quote-sender-name"
              aria-label=${this.message.sender_nickname}
            >
              ${this.message.sender_nickname}
            </div>
            <div
              @click=${this.trackClickEvent}
              aria-label=${messageLabel}
              id="senderMessage-${this.message.id}"
              class="quote-sender-message ${deletedClass} ${senderBlockedClass}"
            >
              ${this.renderMessage()}
            </div>
          </div>
        </div>
      </div>
    `;
    }
};
__decorate([
    property({ type: Object })
], QuoteMessageItem.prototype, "message", void 0);
__decorate([
    property({ type: Number })
], QuoteMessageItem.prototype, "animation", void 0);
__decorate([
    property({ type: Boolean })
], QuoteMessageItem.prototype, "showavatar", void 0);
__decorate([
    property({ type: Boolean })
], QuoteMessageItem.prototype, "isSenderBlocked", void 0);
__decorate([
    property({ type: Boolean })
], QuoteMessageItem.prototype, "isDeleted", void 0);
QuoteMessageItem = __decorate([
    element('livelike-quote-message-item')
], QuoteMessageItem);
export { QuoteMessageItem };

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, property } from 'lit-element';
import { LiveLikeOptionChild } from '../../mixins/ThemedElement';
import { styles } from './livelike-image.style';
import { element } from '../../utils/decorators';
/**
 * @description Renders an `option`/`choice` image_url. Can be overridden by setting `src` property.
 * @element livelike-image
 * @slot Default slot
 */
let LiveLikeImage = class LiveLikeImage extends LiveLikeOptionChild {
    constructor() {
        super(...arguments);
        this.defaultStyles = styles;
    }
    connectedCallback() {
        super.connectedCallback();
        let imgSrc = this.src || (this.item && this.item.image_url) || this.owner.image_url;
        if (imgSrc) {
            const img = document.createElement('img');
            img.setAttribute('height', this.height);
            img.setAttribute('width', this.width);
            img.setAttribute('src', imgSrc);
            img.setAttribute('alt', this.alt || (this.item && this.item.description));
            this.appendChild(img);
        }
    }
    defaultTemplate() {
        return html ` <slot> </slot> `;
    }
};
__decorate([
    property({ type: String, reflect: true })
], LiveLikeImage.prototype, "src", void 0);
__decorate([
    property({ type: String, reflect: true })
], LiveLikeImage.prototype, "alt", void 0);
__decorate([
    property({ type: String, reflect: true })
], LiveLikeImage.prototype, "height", void 0);
__decorate([
    property({ type: String, reflect: true })
], LiveLikeImage.prototype, "width", void 0);
LiveLikeImage = __decorate([
    element('livelike-image')
], LiveLikeImage);
export { LiveLikeImage };

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, property, query } from 'lit-element';
import { element } from '../../utils/decorators';
import { defaultHeader } from '../../utils/templates';
import { styles } from './livelike-video-alert.style';
import { track } from '../../utils/analytics';
import { ThemedWidget } from '../../mixins/WidgetTemplate';
import { kebabToTitle } from '../../utils';
import { userProfile } from '../../scripts/internal/globals';
import '../../components/livelike-video';
import { WidgetKind } from '../../constants/Widgets';
/**
 * @element livelike-video-alert
 * @prop {String} [title]
 * @prop {String} [text]
 * @prop {String} [video_url]
 * @prop {String} [link_url]
 * @prop {String} [link_label]
 */
let LiveLikeVideoAlert = class LiveLikeVideoAlert extends ThemedWidget {
    constructor() {
        super(...arguments);
        this.kind = WidgetKind.VIDEO_ALERT;
        this.owner = this;
        this.defaultStyles = styles;
        this.trackLinkOpened = () => {
            this.interactionEvent();
            const trackObj = {
                'Alert Id': this.widgetPayload && this.widgetPayload.id,
                Nickname: userProfile && userProfile.nickname,
                'Program ID': this.widgetPayload && this.widgetPayload.program_id,
                'Widget Type': kebabToTitle(this.widgetPayload.kind),
                'Link URL': this.link_url,
            };
            track('Alert Link Opened', trackObj, this.programid);
        };
        // TODO This is fired every time the video is started - can happen multiple times. Is that desired?
        this.onVideoPlayStarted = () => {
            this.interactionEvent();
            const trackObj = {
                'Alert Id': this.widgetPayload && this.widgetPayload.id,
                'Program ID': this.widgetPayload && this.widgetPayload.program_id,
                'Widget Type': kebabToTitle(this.widgetPayload.kind),
                'Video URL': this.video_url,
            };
            track('Video Alert Play Started', trackObj, this.programid);
        };
        this.getBorderClass = () => {
            const header = this.title || this.text;
            if (header && this.link_url)
                return '';
            if (this.link_url)
                return 'no-header';
            if (header)
                return 'no-footer';
            return 'only-media';
        };
    }
    firstUpdated() {
        super.firstUpdated();
        this.isInteractable =
            (this.video_url && this.video_url.length) ||
                (this.link_url && this.link_url.length);
    }
    render() {
        const borderClass = this.getBorderClass();
        const text = this.text &&
            html `
        <div class="video-alert-caption-container">
          <span class="video-alert-caption">${this.text}</span>
        </div>
      `;
        const footer = this.link_url &&
            html `
        <livelike-widget-footer>
          <a
            class="video-alert-link"
            href=${this.link_url}
            target="_blank"
            @click=${this.trackLinkOpened}
            >${this.link_label}</a
          >
        </livelike-widget-footer>
      `;
        return html `
      <template>
        <livelike-widget-root>
          ${this.title ? defaultHeader : null}
          <livelike-widget-body class=${this.title ? '' : 'no-header'}>
            ${text}
            <livelike-video
              class=${borderClass}
              .src=${this.src || this.video_url}
              @play=${this.onVideoPlayStarted}
            ></livelike-video>
            ${footer}
          </livelike-widget-body>
        </livelike-widget-root>
      </template>
    `;
    }
};
__decorate([
    property({ type: String })
], LiveLikeVideoAlert.prototype, "title", void 0);
__decorate([
    property({ type: String })
], LiveLikeVideoAlert.prototype, "text", void 0);
__decorate([
    property({ type: String })
], LiveLikeVideoAlert.prototype, "video_url", void 0);
__decorate([
    property({ type: String })
], LiveLikeVideoAlert.prototype, "link_url", void 0);
__decorate([
    property({ type: String })
], LiveLikeVideoAlert.prototype, "link_label", void 0);
__decorate([
    query('video')
], LiveLikeVideoAlert.prototype, "videoEl", void 0);
LiveLikeVideoAlert = __decorate([
    element('livelike-video-alert')
], LiveLikeVideoAlert);
export { LiveLikeVideoAlert };

import { _$$, _config, userProfile } from '../scripts/internal/globals';
import { addListener, removeListener, safeSubscribe, } from '../scripts/internal/pubnubHandler';
import { MessageContentFilter, } from '../scripts/types';
import { request } from './requests';
import { MessageEvents } from '../constants/ChatEvents';
import { WidgetKind, interactiveWidgets, widgetEndPhase, } from '../constants/Widgets';
import { addDeletedMessageId, getDeletedMessageIds } from './deletedMessageIds';
import { transformMessage } from './transformMessages';
import { isMsgContentFiltered } from './messageFilter';
/**
 * Converts ISO8601 format duration into an object (PYYYYMMDDThhmmss)
 * Example of duration - P3Y6M4DT12H30M5S
 * @param {string} duration Time duration in ISO8601 format
 * @returns {object} The date object converted into a key value pair of {o: <Number>, y: <Number>, m: <Number>, etc.}
 */
export const getMilliseconds = (duration) => {
    const regex = /(-)?P(?:([.,\d]+)Y)?(?:([.,\d]+)M)?(?:([.,\d]+)W)?(?:([.,\d]+)D)?(?:T(?:([.,\d]+)H)?(?:([.,\d]+)M)?(?:([.,\d]+)S)?)?/;
    const m = duration.match(regex);
    const s = m[8] && m[8] * 1000;
    const mm = m[7] && m[7] * 60000;
    const h = m[6] && m[6] * 3600000;
    const d = m[5] && m[5] * 86400000;
    return s + mm + h + d;
};
// Converts kebab-case to Title Case
export const kebabToTitle = (str) => str
    .split('-')
    .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
    .join(' ');
export function mergeObj(current, update) {
    Object.keys(update).forEach((key) => {
        current.hasOwnProperty(key) &&
            typeof current[key] === 'object' &&
            !(current[key] instanceof Array)
            ? mergeObj(current[key], update[key])
            : (current[key] = update[key]);
    });
    return current;
}
export const immuteAppend = (arr, v) => {
    const newArr = arr.slice();
    newArr.splice(arr.length, 0, v);
    return newArr;
};
const defaultModeBehavior = (target, widget) => {
    const isFollowup = widget.kind === WidgetKind.TEXT_PREDICITON_FOLLOW_UP ||
        widget.kind === WidgetKind.IMAGE_PREDICITON_FOLLOW_UP ||
        widget.kind === WidgetKind.TEXT_NUMBER_PREDICTION_FOLLOW_UP ||
        widget.kind === WidgetKind.IMAGE_NUMBER_PREDICTION_FOLLOW_UP;
    return target
        .attach(widget)
        .then(() => (isFollowup ? Promise.resolve() : widget.interactive()))
        .then(() => widget.results({
        timeout: isFollowup && widget.timeout ? getMilliseconds(widget.timeout) : 5000,
    }));
};
export const popupMode = ({ target, widget }) => defaultModeBehavior(target, widget)
    .then(widget.expire)
    .then(() => target.detach(widget));
export const timelineMode = ({ target, widget }) => {
    widget.hide_dismiss_button = true;
    widget.initialLoad
        ? target
            .attach(widget, 'append')
            .then(widget.results)
            .then(widget.expire)
            .then(widget.finished)
        : defaultModeBehavior(target, widget)
            .then(widget.expire)
            .then(widget.finished);
};
export const isPredictionEndPhase = (widgetPhase) => {
    return widgetEndPhase.includes(widgetPhase);
};
export const hasFollowUpPublished = (widgetPayload) => widgetPayload.follow_ups.some((follow_up) => follow_up.status === 'published');
export const interactiveModeBehaviour = (args) => {
    const { target, widget, timer } = args;
    const { widgetPayload } = widget;
    let showResults = false;
    switch (widget.kind) {
        case WidgetKind.TEXT_PREDICITON_FOLLOW_UP:
        case WidgetKind.IMAGE_PREDICITON_FOLLOW_UP: {
            const predictionId = widgetPayload.text_prediction_id || widgetPayload.image_prediction_id;
            if (target) {
                let prediction = target.querySelector(`[widgetid="${predictionId}"]`);
                //stop all interactive predictions for follow-ups
                if (prediction &&
                    (!prediction.disabled || !isPredictionEndPhase(prediction.phase))) {
                    prediction.results();
                }
            }
            showResults = true;
            break;
        }
        case WidgetKind.TEXT_PREDICTION:
        case WidgetKind.IMAGE_PREDICTION: {
            showResults = target && hasFollowUpPublished(widgetPayload);
            break;
        }
        case WidgetKind.TEXT_QUIZ:
        case WidgetKind.IMAGE_QUIZ: {
            if (!widget.interaction) {
                widget.addEventListener('answer', (e) => {
                    widget.results();
                });
            }
            showResults = !!widget.interaction;
            break;
        }
        case WidgetKind.EMOJI_SLIDER: {
            if (!widget.interaction) {
                widget.addEventListener('slider', (e) => {
                    setTimeout(widget.results, 2000);
                });
            }
            showResults = !!widget.interaction;
            break;
        }
        case WidgetKind.TEXT_ASK: {
            if (!widget.interaction) {
                widget.addEventListener('reply', (e) => {
                    widget.results();
                });
            }
            showResults = !!widget.interaction;
            break;
        }
        case WidgetKind.TEXT_NUMBER_PREDICTION_FOLLOW_UP:
        case WidgetKind.IMAGE_NUMBER_PREDICTION_FOLLOW_UP: {
            const predictionId = widgetPayload.text_number_prediction_id ||
                widgetPayload.image_number_prediction_id;
            if (target) {
                let prediction = target.querySelector(`[widgetid="${predictionId}"]`);
                //stop all interactive predictions for follow-ups
                if (prediction &&
                    (!prediction.disabled || !isPredictionEndPhase(prediction.phase))) {
                    prediction.results();
                }
            }
            showResults = true;
            break;
        }
        case WidgetKind.TEXT_NUMBER_PREDICTION:
        case WidgetKind.IMAGE_NUMBER_PREDICTION: {
            showResults =
                !!widget.interaction || (target && hasFollowUpPublished(widgetPayload));
            break;
        }
        default:
            showResults = false;
    }
    return showResults
        ? Promise.resolve()
        : widget.interactive &&
            widget.interactive({ timeout: timer ? timer : null });
};
export const interactiveTimelineMode = (args) => {
    const { target, widget, timer } = args;
    const { widgetPayload } = widget;
    widget.hide_dismiss_button = true;
    const isFollowup = widget.kind === WidgetKind.TEXT_PREDICITON_FOLLOW_UP ||
        widget.kind === WidgetKind.IMAGE_PREDICITON_FOLLOW_UP ||
        widget.kind === WidgetKind.TEXT_NUMBER_PREDICTION_FOLLOW_UP ||
        widget.kind === WidgetKind.IMAGE_NUMBER_PREDICTION_FOLLOW_UP;
    return target
        .attach(widget, widget.initialLoad ? 'append' : 'prepend')
        .then(() => {
        return interactiveModeBehaviour(args);
    })
        .then(() => widget.results({
        timeout: isFollowup && widget.timeout ? getMilliseconds(widget.timeout) : 5000,
    }))
        .then(widget.expire)
        .then(() => widget.finished({ timeout: null }));
};
export const debounce = (callback, time) => {
    let interval;
    return (...args) => {
        const delay = time || 250;
        return clearTimeout(interval, 
        // @ts-ignore
        (interval = setTimeout(callback, delay, ...args)));
    };
};
export const throttle = (fn, wait) => {
    let previouslyRun, queuedToRun;
    return function invokeFn(...args) {
        const now = Date.now();
        queuedToRun = clearTimeout(queuedToRun);
        if (!previouslyRun || now - previouslyRun >= wait) {
            fn.apply(null, args);
            previouslyRun = now;
        }
        else {
            queuedToRun = setTimeout(invokeFn.bind(null, ...args), wait - (now - previouslyRun));
        }
    };
};
export const pageVisHandler = () => {
    _$$.pageVisible = !document.hidden;
    document.addEventListener('visibilitychange', () => {
        _$$.pageVisible = !document.hidden;
        eventBus.emit('pageVisibilityChange', { pageVisible: !document.hidden });
    }, false);
};
export const formatDateTime = (val, format, lang) => new Date(val).toLocaleDateString(lang || 'en-US', format);
export const getLang = (component) => {
    if (!component.lang) {
        const walkParent = (node) => {
            const foundLang = node.querySelector('[lang]');
            foundLang
                ? (component.lang = foundLang.getAttribute('lang'))
                : node.parentElement && walkParent(node.parentElement);
        };
        walkParent(component.parentElement);
    }
};
export const votePercentage = (owner, item, defaultCount = 0) => {
    const optionArr = owner.options || owner.choices;
    // console.log('optionArr[0]', optionArr[0].vote_count)
    // console.log('optionArr[1]', optionArr[1].vote_count)
    const voteType = item.vote_count ? 'vote_count' : 'answer_count';
    const totalVotes = optionArr.reduce((a, b) => a + b[voteType], 0);
    return totalVotes > 0
        ? Math.round((item[voteType] / totalVotes) * 100)
        : defaultCount;
};
export const filterCheck = (roomId, message, blockProfileIds = [], includeFilteredMessages = false) => {
    const isSender = message.sender_id === userProfile.id;
    const isFromShadowMutedSender = message.content_filter &&
        message.content_filter.indexOf(MessageContentFilter.SHADOW_MUTED) >= 0 &&
        !isSender;
    const isContentFiltered = isMsgContentFiltered(roomId, message) && !includeFilteredMessages;
    const isBlockedUser = blockProfileIds.includes(message.sender_id);
    return !isFromShadowMutedSender && !isContentFiltered && !isBlockedUser;
};
export const filterAndTransformMessages = (roomId, messages, blockProfileIds, includeFilteredMessages) => {
    if (messages && Array.isArray(messages)) {
        const cleanedList = [];
        // Splits created and deleted messages
        messages.forEach((msg) => {
            var _a;
            if (filterCheck(roomId, msg, blockProfileIds, includeFilteredMessages)) {
                if (MessageEvents[msg.message_event] === 'messagereceived') {
                    msg.timetoken = msg.pubnub_timetoken;
                    msg.reactions = (_a = msg.reactions) !== null && _a !== void 0 ? _a : {};
                    msg = transformMessage(msg, roomId);
                    cleanedList.push(msg);
                }
                else {
                    addDeletedMessageId({ roomId, messageId: msg.id });
                }
            }
        });
        // If message id from deleted list matches a message, remove it.
        getDeletedMessageIds(roomId).forEach((deletedId) => {
            const idx = cleanedList.findIndex((v) => v.id === deletedId);
            idx !== -1 && cleanedList.splice(idx, 1);
        });
        return cleanedList;
    }
    else
        return [];
};
// TODO when message count API is introduced,
// remove filterChatMessages and use above filterMessage util function
export const filterChatMessages = (roomId, messages, blockProfileIds) => {
    if (messages && Array.isArray(messages)) {
        const cleanedList = [];
        // Splits created and deleted messages
        messages.forEach((m) => {
            // Shared by pubnub.history and pubnub.fetchMessages which return arrays
            // with two different object structures. 'obj' is the difference.
            const obj = m.entry || m.message;
            if (filterCheck(roomId, obj.payload, blockProfileIds)) {
                obj.payload.timetoken = m.timetoken;
                obj.payload.reactions = m.data && m.data.rc ? m.data.rc : {};
                if (MessageEvents[obj.event] === 'messagereceived') {
                    cleanedList.push(obj.payload);
                }
                else {
                    addDeletedMessageId({ roomId, messageId: obj.payload.id });
                }
            }
        });
        // If message id from deleted list matches a message, remove it.
        getDeletedMessageIds(roomId).forEach((deletedId) => {
            const idx = cleanedList.findIndex((v) => v.id === deletedId);
            idx !== -1 && cleanedList.splice(idx, 1);
        });
        return cleanedList;
    }
    else
        return [];
};
// ============== FETCH URL ============== //
export const buildUrl = (path, template) => template && encodeURI(template.replace(/{(.*?)}/g, path));
export const getData = (url, type) => !url
    ? Promise.reject(`${type} url missing.`)
    : fetch(url).then((response) => {
        const notFound = response.status === 404;
        const accessDenied = response.status === 403;
        const roomNotFound = type === 'Room' && response.status > 202;
        if (notFound || roomNotFound || accessDenied) {
            return Promise.reject(`${type} resource not found. Check ${type} Id.\nRead more: https://www.npmjs.com/package/@livelike/engagementsdk`);
        }
        else {
            const contentType = response.headers.get('content-type');
            if (contentType && contentType.indexOf('application/json') !== -1) {
                return response.json();
            }
            else {
                return;
            }
        }
    });
export const fetchData = {
    program: (programId) => getData(buildUrl(programId, _$$.programUrlTemplate), 'Program'),
    app: (clientId) => getData(encodeURI(`${_$$.endpoint}applications/${clientId}/`), 'Client'),
    chatRoom: (roomId) => getData(buildUrl(roomId, _$$.chatUrlTemplate), 'Room'),
    leaderboard: (leaderboardId) => getData(buildUrl(leaderboardId, _$$.leaderboardUrlTemplate), 'Leaderboard'),
    leaderboardEntryDetail: (entry_detail_url_template, profileId) => getData(buildUrl(profileId, entry_detail_url_template), 'Leaderboard Entry'),
};
const getResource = (obj, id, type) => obj[id]
    ? Promise.resolve(obj[id])
    : fetchData[type](id)
        .then((r) => {
        obj[id] = r;
        return r;
    })
        .catch((err) => Promise.reject(err));
export const getAppResource = () => getResource(_$$.appResource, _$$.clientId, 'app');
export const getChatRoomResource = (roomId) => getResource(_$$.chatRooms, roomId, 'chatRoom');
export const getProgramResource = (programId) => getResource(_$$.progResource, programId, 'program');
export const getChannel = (roomId) => new Promise((res, rej) => _$$.chatRooms && _$$.chatRooms[roomId] && _$$.chatRooms[roomId].channels
    ? res(_$$.chatRooms[roomId].channels.chat.pubnub)
    : getChatRoomResource(roomId)
        .then((r) => res(r.channels.chat.pubnub))
        .catch((err) => rej(err)));
export const getChatRoomControlChannel = (roomId) => new Promise((res, rej) => _$$.chatRooms && _$$.chatRooms[roomId] && _$$.chatRooms[roomId].channels
    ? res(_$$.chatRooms[roomId].channels.control.pubnub)
    : getChatRoomResource(roomId)
        .then((r) => res(r.channels.control.pubnub))
        .catch((err) => rej(err)));
/**
 * Takes in initial url, and then resets the url to the `next` url internally.
 * Takes paginatedMethod to call
 * Takes resolvedObject - the data being resolved.
 *
 * Should add `previous`
 * Should change how it's set up so external methods using `paginator` don't need to
 * always do the same setup creating iterator, resolving initial next() function.
 *
 * Resolves `next` which can then be use immediately or saved anywhere to be used later
 * Next is async, and repeated `next()` calls will call the subsequent page.
 * EXAMPLES:
  let nextPage;
  LiveLike.getPostedWidgets({programId: 'e7df6164-bbc9-47d0-b7e8-ad4c86fa2e26'}).then(r=> {
    console.log('initial response',r)
    nextPage = r.next;
  })
  nextPage().then(nextPageResponse => console.log('nextPageResponse',nextPageResponse))

  OR

  async function test(){
    const initial = await LiveLike.getLeaderboardEntries({leaderboardId: '288e2f55-0055-4069-b197-439e8efa3502'})
    console.log('initial leaderboard', initial)
    const nextPage = await initial.next();
    console.log('nextPage', nextPage)
  }

  */
export function paginator(url, paginatedMethod, resolvedObject) {
    let urls = {
        next: url,
        prev: null,
    };
    const page = (type) => {
        return urls[type]
            ? paginatedMethod(urls[type]).then((response) => {
                urls = { next: response.next, prev: response.previous };
                return Promise.resolve({
                    value: resolvedObject(response),
                    done: response.next ? false : true,
                });
            })
            : Promise.resolve({ done: true });
    };
    return {
        [Symbol.asyncIterator]() {
            return {
                next: () => page('next'),
                previous: () => page('prev'),
            };
        },
        next: () => page('next'),
        previous: () => page('prev'),
    };
}
export const iteratorBase = (props, iterator) => iterator.next().then((res) => {
    let obj = {
        count: res.value.count,
        iterator,
        next: iterator.next,
        previous: iterator.previous,
    };
    props.forEach((key) => {
        obj[key] = res.value[key];
    });
    return obj;
});
// ============== EVENTS ============== //
/**
 * Listen to an event
 * eventBus.on('eventName', e => console.log(e))
 * Listen to all events
 * eventBus.on('*', (type, e) => console.log(type, e))
 * Fire event
 * emitter.emit('eventName', { a: 'test' })
 * @param {*} all
 */
function eventEmitter(all) {
    all = all || Object.create(null);
    const on = (type, handler) => (all[type] || (all[type] = [])).push(handler);
    return {
        on,
        peak: () => all,
        uniqueOn: (type, handler) => (!all[type] || all[type].length === 0) && on(type, handler),
        off: (type, handler) => {
            all[type] && all[type].splice(all[type].indexOf(handler) >>> 0, 1);
            // all[type] && all[type].length < 1 && delete all[type]
        },
        emit: (type, evt) => {
            (all[type] || []).slice().map((handler) => handler(evt));
            (all['*'] || []).slice().map((handler) => handler(type, evt));
        },
    };
}
export const eventBus = eventEmitter();
_$$.eb = eventBus.peak;
export const eventDispatcher = (el, evt, obj) => {
    const customEvent = new CustomEvent(evt, {
        detail: obj,
        bubbles: true,
        composed: true,
        cancelable: true,
    });
    el.dispatchEvent(customEvent);
    return customEvent;
};
export function requestProvider(el, key) {
    let event = eventDispatcher(el, key, {});
    return event.detail.provider;
}
// https://stackblitz.com/edit/lit-element-hello-world-loqjvc?file=my-element.ts
// ============== ERRORS ============== //
export const docLink = (path) => `\nSee https://docs.livelike.com/docs/${path ? path : 'getting-started-with-the-web-sdk'} for more details.`;
export const refLink = (path) => {
    const nums = _config.version.split('.');
    nums[2] = '0';
    const version = nums.join('.');
    let link = `https://websdk.livelikecdn.com/docs/${version}/`;
    if (path) {
        link = `${link}#${path.toLowerCase()}`;
    }
    return `See ${link} for more details.`;
};
export const errRefLink = (s, path = '') => {
    console.error(s, refLink(path));
    return s + '\n' + refLink(path);
};
export const typeErr = (funcName, v, type) => {
    const errorString = `${funcName}: ${v} must be of type <${type}>`;
    console.error(errorString);
    return errorString;
};
export const noArg = (arg, path) => errRefLink('Missing ' + arg + ' argument', path);
export const noArgPromiseRej = (arg, path) => Promise.reject(noArg(arg, path));
export const errDocLink = (s, path) => console.error(s, docLink(path));
export const depWarn = (dep, rec, path) => {
    console.warn(dep +
        ' has been deprecated, and will be removed in the next major version. \n' +
        'Please use ' +
        rec +
        '\n' +
        refLink(path));
};
export const selfChildCheck = (el) => el.parentElement &&
    el.parentElement.localName === el.localName &&
    console.error(el.localName + ' cannot be a child of itself.');
// ============== LOCALSTORAGE ============== //
export const saveLocalStorage = (data) => localStorage.setItem(_$$.lsKey, JSON.stringify(data));
export const loadLocalStorage = () => JSON.parse(localStorage.getItem(_$$.lsKey));
export const appendToStorage = (key, value) => {
    let item = _$$.storageStrategy.get() || {};
    item[key] = value;
    _$$.storageStrategy.set(item);
};
export const defaultStorageStrategy = {
    get: loadLocalStorage,
    set: saveLocalStorage,
};
export { request,
// polyfill
 };
// FETCH REACTIONs/STICKERS
export const fetchAllPages = (url) => {
    return new Promise((resolve) => {
        let items = [];
        let retryFetch = true;
        setTimeout(() => (retryFetch = false), 10000);
        const fetchItems = (url) => request
            .get({ url })
            .then((response) => {
            if (response && response.results) {
                items = [...items, ...response.results];
                response.next ? fetchItems(response.next) : resolve(items);
            }
            else
                resolve([]);
        })
            .catch(() => retryFetch ? setTimeout(() => fetchItems(url), 2000) : resolve([]));
        fetchItems(url);
    });
};
const stickerReactionBase = (roomId, type, url) => _$$.chatRooms[roomId][url] &&
    fetchAllPages(_$$.chatRooms[roomId][url]).then((res) => {
        _$$.chatRooms[roomId][type] = res;
        return res;
    });
export const fetchReactions = (roomId) => stickerReactionBase(roomId, 'reactionPack', 'reaction_packs_url');
export const fetchStickers = (roomId) => stickerReactionBase(roomId, 'stickerPacks', 'sticker_packs_url');
export const localize = (keyStr, langLocale, variables) => {
    let lang = langLocale && langLocale.slice(0, 2);
    if (!langLocale || !_$$.localizedStrings[lang]) {
        lang = 'en';
    }
    let value = _$$.localizedStrings[lang][keyStr] || _$$.localizedStrings['en'][keyStr];
    if (value && variables) {
        Object.keys(variables).forEach((key) => (value = value.replace('<' + key + '>', variables[key])));
    }
    return value;
};
export function pseudoVectorClock() {
    let calculating = false;
    let cache = {};
    return (nextTimetoken, currentSequence, programId) => new Promise((res) => {
        const calc = () => {
            if (!calculating) {
                calculating = true;
                !cache[programId] && (cache[programId] = {});
                if (!cache[programId].sequence ||
                    currentSequence === cache[programId].sequence) {
                    cache[programId].sequence = currentSequence + 1;
                    const lastTimetoken = localStorage.getItem('ltt-' + programId);
                    const allowNext = !lastTimetoken || nextTimetoken > lastTimetoken;
                    allowNext &&
                        localStorage.setItem('ltt-' + programId, nextTimetoken);
                    // If the message is allowed, set sequenceTime to nextTimetoken
                    // to allow the subsequent *waiting* function call to check equivalency.
                    // If not, set sequenceTime to either the lastTimetoken if it exists, or
                    // to null to ensure that the subsequent waiting function returns false.
                    cache[programId].allowNext = allowNext;
                    calculating = false;
                    res(cache[programId]);
                }
                else {
                    // This part will only run if there was an instance of this return func already running.
                    calculating = false;
                    // When two widgets get the same pubnub messages at the same time,
                    // The if block above will set the sequence time. If it's the same
                    // as this secondary function calls nextTimetoken, let it through
                    // because it is a valid "duplicate" - the same pubnub message at
                    // the same time, but intercepted from a different element.
                    res(cache[programId]);
                }
            }
            else
                setTimeout(calc, 50);
        };
        calc();
    });
}
export const messageSequence = pseudoVectorClock();
const _listenerBase = () => {
    const listeners = [];
    return {
        add: (channel, id, callback, listener) => {
            listeners.push({ id, listener, callback });
            addListener(listener);
            safeSubscribe(channel);
        },
        remove: (id, callback) => {
            const foundIdx = listeners.findIndex((l) => l.id === id && String(l.callback) === String(callback));
            if (foundIdx !== -1) {
                removeListener(listeners[foundIdx].listener);
                listeners.splice(foundIdx, 1);
            }
            else {
                console.warn('Listener not removed. Check arguments.');
            }
        },
    };
};
export const listenerBase = _listenerBase();
export const downloadFile = (url) => {
    return fetch(url).then((response) => response.blob());
};
export const uploadImageToImgurl = (url, blob) => {
    var formData = new FormData();
    formData.append('type', 'file');
    formData.append('image', blob, 'image.png');
    return fetch(url, {
        method: 'POST',
        body: formData,
    }).then((response) => response.json());
};
export const getSyncStrategy = (strat) => strat && typeof strat === 'object'
    ? strat
    : {
        get currentTimecode() {
            return null;
        },
    };
export const updateWithQueue = (el, conditional, callback) => {
    if (!el.isUpdating) {
        el.isUpdating = true;
        const update = () => {
            if (el.queue && el.queue.hasNextItem()) {
                if (el.queue.isNextItemReady() && conditional()) {
                    callback(el.queue.dequeueItem());
                    update();
                }
                else
                    setTimeout(() => {
                        el.isUpdating = false;
                        update();
                    }, 1000);
            }
            else
                el.isUpdating = false;
        };
        update();
    }
};
export const waitForSyncStrat = (el, callback) => {
    let count = 0;
    const wait = () => {
        count === 30 ||
            (el.syncStrategy && el.syncStrategy.currentTimecode !== undefined)
            ? callback()
            : setTimeout(() => {
                count++;
                wait();
            }, 100);
    };
    wait();
};
export const isDate = (val) => ({
    number: true,
    string: !isNaN(Date.parse(convertDate(val))),
    object: val instanceof Date && !isNaN(val.getTime()),
}[typeof val]);
export const convertDate = (val) => typeof val === 'string' &&
    /^(\d{4})-(\d{2})-(\d{2})[Tt](\d{2}):(\d{2}):(\d{2}(?:[,.]\d*)?)(([+-](\d{2}):(\d{2})|Z)?)$/.test(val)
    ? val.replace(',', '.')
    : val;
export const getProgramDateTime = (message) => {
    return isDate(message.program_date_time)
        ? new Date(convertDate(message.program_date_time))
        : null;
};
export const programDateTimeSort = (a, b) => {
    const atc = getProgramDateTime(a);
    const btc = getProgramDateTime(b);
    if (atc === btc) {
        return 0;
    }
    return atc > btc ? 1 : -1;
};
export const addInteractionsToWidgets = ({ widgets, interactions }) => widgets.map((widget) => {
    let widgetInteractionDetails = getWidgetKindIdForInteraction(widget);
    if (widgetInteractionDetails) {
        const { kind, id } = widgetInteractionDetails;
        return {
            widgetPayload: widget,
            interactions: interactions && interactions[kind]
                ? interactions[kind].filter((e) => e.widget_id === id)
                : null,
        };
    }
    else {
        return { widgetPayload: widget, interactions: null };
    }
});
export const getWidgetKindIdForInteraction = (widgetPayload) => {
    if (interactiveWidgets.some((v) => widgetPayload.kind === v)) {
        return {
            kind: widgetPayload.kind.replace('-follow-up', ''),
            id: widgetPayload.kind === WidgetKind.TEXT_PREDICITON_FOLLOW_UP ||
                widgetPayload.kind === WidgetKind.IMAGE_PREDICITON_FOLLOW_UP ||
                widgetPayload.kind === WidgetKind.TEXT_NUMBER_PREDICTION_FOLLOW_UP ||
                widgetPayload.kind === WidgetKind.IMAGE_NUMBER_PREDICTION_FOLLOW_UP
                ? widgetPayload.text_prediction_id ||
                    widgetPayload.image_prediction_id ||
                    widgetPayload.text_number_prediction_id ||
                    widgetPayload.image_number_prediction_id
                : widgetPayload.id,
        };
    }
    else {
        return null;
    }
};
export const getChatRoomInvitationUrlTemplate = () => _$$.chatRoomInvitationsUrl;
export const getChatRoomInvitationDetailUrlTemplate = (invitationId) => buildUrl(invitationId, _$$.chatRoomInvitationDetailUrlTemplate);
export const getPinMessageUrl = () => _$$.pinnedMessagesUrl;
export const uninitializedPromiseRej = () => Promise.reject(errRefLink('SDK not initialized', 'init'));

// @ts-strict
import { userProfile } from './internal/globals';
import { request, paginator, iteratorBase, noArgPromiseRej, getAppResource, } from '../utils';
import { subscriptionCoordinator } from '../utils/subscriptionCoordinator';
import { validateArgObj } from '../utils/validateArgs';
const KEY_VALUE_DELIMITER = ':';
/**
 * Gets Application Reward Items.
 * @async
 * @since 2.9.0
 * @example
 * ```js
 * getApplicationRewardItems().then(padinatedResponse => console.log(padinatedResponse))
 * ```
 */
export const getApplicationRewardItems = (args) => getAppResource().then((app) => {
    let reward_items_url = app.reward_items_url;
    if (args && args.attributes && args.attributes.length) {
        args.attributes.forEach((attr) => {
            reward_items_url += `&attributes=${attr.key}${KEY_VALUE_DELIMITER}${attr.value}`;
        });
    }
    return iteratorBase(['results'], paginator(reward_items_url, (url) => request.get({ url }), (r) => ({
        count: r.count,
        results: r.results,
    })));
});
/**
 * Gets userProfile Reward Item Balance.
 * @async
 * @since 2.9.0
 * @example
 * ```js
 * getRewardItemBalances({ rewardItemIds: ["xxx", "xxx"] }).then(res => console.log(res))
 * ```
 */
export const getRewardItemBalances = (args) => args && args.rewardItemIds && args.rewardItemIds.length > 0
    ? request.get({
        url: userProfile.reward_item_balances_url +
            '?reward_item_id=' +
            args.rewardItemIds.join('&reward_item_id='),
    })
    : noArgPromiseRej('rewardItemIds', 'getRewardItemBalances');
/**
 * Transfer Reward Item Balance to another profile.
 * @async
 * @since 2.9.0
 * @example
 * ```js
 * transferRewardItemAmount({ rewardItemId: "xxxx", amount: 10, recipientProfileId: "xxxx" }).then(res => console.log(res))
 * ```
 */
export const transferRewardItemAmount = (args) => validateArgObj(args, ['rewardItemId', 'amount', 'recipientProfileId'], 'transferRewardItemAmount').then(() => request.post({
    url: userProfile.reward_item_transfer_url,
    data: {
        reward_item_id: args.rewardItemId,
        reward_item_amount: args.amount,
        recipient_profile_id: args.recipientProfileId,
    },
}));
/**
 * Gets user's Reward Item Transfers.
 * @async
 * @since 2.9.0
 * @param {IRewardItemTransferArgs} args - arguments to filter user's Reward Item Transfers
 * @example
 * ```js
 * getRewardItemTransfers({transferType: "sent"}).then(res => console.log(res))
 * ```
 */
export const getRewardItemTransfers = (args) => {
    let transferUrl = userProfile.reward_item_transfer_url;
    if (args && args.transferType) {
        transferUrl += '?transfer_type=' + args.transferType;
    }
    return iteratorBase(['results'], paginator(transferUrl, (url) => request.get({ url }), (r) => ({
        count: r.count,
        results: r.results,
    })));
};
/**
 * @description Add listener for the RewardEvent
 * @since 2.12.0
 * @example
 * ```js
 * addRewardEventListener(RewardEvent.REWARD_ITEM_TRANSFER_RECEIVED, function onReceiveNewRewardItemTransfer(rewardItemTransfer){
 *  console.log(rewardItemTransfer);
 * })
 * ```
 */
export function addRewardEventListener(rewardEvent, listenerFn) {
    subscriptionCoordinator.addListener(userProfile.subscribe_channel, listenerFn, rewardEvent);
    subscriptionCoordinator.subscribe(userProfile.subscribe_channel);
}
/**
 * @description Remove registered listenerFn for the RewardEvent. If no listenerFn is passed, it removes all the listeners attached to that event
 * @since 2.12.0
 * @example
 * ```js
 * removeRewardEventListener(RewardEvent.REWARD_ITEM_TRANSFER_RECEIVED);
 * ```
 */
export function removeRewardEventListener(rewardEvent, listenerFn) {
    listenerFn
        ? subscriptionCoordinator.removeListener(userProfile.subscribe_channel, listenerFn, rewardEvent)
        : subscriptionCoordinator.removeAllListenersForEvent(userProfile.subscribe_channel, rewardEvent);
    subscriptionCoordinator.unsubscribe(userProfile.subscribe_channel);
}
/**
 * @description Fetch paginated List of Reward Transactions filtered by widget_id and kind
 * @since 2.13.0
 * @example
 * ```js
 * getRewardTransactions({
 *   widgetIds: ["xx", "yy"],
 *   widgetKinds: ["text-poll", "text-prediction"]
 * }).then(res => console.log(res));
 * ```
 */
export const getRewardTransactions = (args) => getAppResource().then((app) => {
    var _a, _b, _c, _d, _e;
    let transactionsUrl = app.reward_transactions_url + '?';
    let searchParams = [];
    (_a = args === null || args === void 0 ? void 0 : args.widgetIds) === null || _a === void 0 ? void 0 : _a.forEach((widget_id) => {
        searchParams.push('widget_id=' + widget_id);
    });
    (_b = args === null || args === void 0 ? void 0 : args.widgetKinds) === null || _b === void 0 ? void 0 : _b.forEach((kind) => {
        searchParams.push('widget_kind=' + kind);
    });
    (_c = args === null || args === void 0 ? void 0 : args.profileIds) === null || _c === void 0 ? void 0 : _c.forEach((p) => {
        searchParams.push('profile_id=' + p);
    });
    (_d = args === null || args === void 0 ? void 0 : args.rewardItemIds) === null || _d === void 0 ? void 0 : _d.forEach((id) => {
        searchParams.push('reward_item_id=' + id);
    });
    (_e = args === null || args === void 0 ? void 0 : args.rewardActionKeys) === null || _e === void 0 ? void 0 : _e.forEach((key) => {
        searchParams.push('reward_action_key=' + key);
    });
    if (args && args.createdSince) {
        searchParams.push('created_since=' + new Date(args.createdSince).toISOString());
    }
    if (args && args.createdUntil) {
        searchParams.push('created_until=' + new Date(args.createdUntil).toISOString());
    }
    transactionsUrl += searchParams.join('&');
    return iteratorBase(['results'], paginator(transactionsUrl, (url) => request.get({ url }), (r) => ({
        count: r.count,
        results: r.results,
    })));
});

// @ts-strict
import { request, paginator, iteratorBase, noArgPromiseRej, getAppResource, } from '../utils';
import { getUserProfile } from './userProfile';
const defaultPaginator = (data) => paginator(data.badges_url, (url) => request.get({ url }), (r) => ({
    count: r.count,
    results: r.results,
}));
export const getPaginatedProfileBadges = (args) => getUserProfile(args).then(defaultPaginator);
/**
 * Gets userProfile Badges.
 * @async
 * @since 2.7.0
 * @example
 * ```js
 * getProfileBadges({ profileId }).then(padinatedResponse => console.log(padinatedResponse))
 * ```
 */
export const getProfileBadges = (args) => !args || !args.profileId
    ? noArgPromiseRej('profileId', 'getProfileBadges')
    : getPaginatedProfileBadges(args).then((iterator) => iteratorBase(['results'], iterator));
/**
 * Gets Application Badges.
 * @async
 * @since 2.7.0
 * @example
 * ```js
 * getApplicationBadges().then(padinatedResponse => console.log(padinatedResponse))
 * ```
 */
export const getApplicationBadges = () => getAppResource().then((app) => iteratorBase(['results'], defaultPaginator(app)));
/**
 * Gets Progress of Badges.
 * @async
 * @since 2.7.0
 * @example
 * ```js
 * getBadgeProgress().then(res => console.log(res))
 * ```
 */
export const getBadgeProgress = (args) => {
    if (args && args.profileId && args.badgeIds && args.badgeIds.length > 0) {
        return getUserProfile(args).then((r) => request.get({
            url: r.badge_progress_url +
                '?badge_id=' +
                args.badgeIds.join('&badge_id='),
        }));
    }
    else {
        return !args || !args.profileId
            ? noArgPromiseRej('profileId', 'getBadgeProgress')
            : noArgPromiseRej('badgeIds', 'getBadgeProgress');
    }
};

import PubNub from 'pubnub';
import { userProfile, _$$ } from './globals';
import { track } from '../../utils/analytics';
let pubnub;
/**
 * @private
 */
export const pubnubInitialise = (pnConfig) => {
    _$$.logger &&
        console.table(Object.assign(Object.assign({}, pnConfig), { profileToken: userProfile.access_token }));
    pubnub = new PubNub(Object.assign(Object.assign({}, pnConfig), { uuid: userProfile.id, restore: true, ssl: true }));
    _$$.logger &&
        console.log(`PubNub is initialised with uuid: ${userProfile.id} and subscribeKey: ${pnConfig.subscribeKey}`);
    return pubnub;
};
function params(o) {
    return o;
}
export const generateUUID = () => PubNub.generateUUID();
export const subscribe = (channel) => pubnub === null || pubnub === void 0 ? void 0 : pubnub.subscribe({ channels: [channel] });
export const safeSubscribe = (channel) => (pubnub === null || pubnub === void 0 ? void 0 : pubnub.getSubscribedChannels) &&
    !(pubnub === null || pubnub === void 0 ? void 0 : pubnub.getSubscribedChannels().some((c) => c === channel)) &&
    subscribe(channel);
export const unsubscribe = (channel) => pubnub === null || pubnub === void 0 ? void 0 : pubnub.unsubscribe({ channels: [channel] });
export const addListener = (listener) => pubnub === null || pubnub === void 0 ? void 0 : pubnub.addListener(listener);
export const removeListener = (listener) => pubnub === null || pubnub === void 0 ? void 0 : pubnub.removeListener(listener);
export const connect = (args) => {
    pubnub === null || pubnub === void 0 ? void 0 : pubnub.addListener(args.listener);
    pubnub === null || pubnub === void 0 ? void 0 : pubnub.subscribe({ channels: [args.channel] });
};
export const disconnect = (args) => {
    pubnub === null || pubnub === void 0 ? void 0 : pubnub.removeListener(args.listener);
    pubnub === null || pubnub === void 0 ? void 0 : pubnub.unsubscribe({ channels: [args.channel] });
};
export const publishMessage = (args) => new Promise((res, rej) => pubnub === null || pubnub === void 0 ? void 0 : pubnub.publish({
    message: args.message,
    channel: args.channel,
    ttl: 0,
}, (status, response) => (status.error ? rej(status) : res(response))));
export const getMessages = (channel, opts = params({ count: 25, end: undefined, start: undefined })) => new Promise((res, rej) => {
    pubnub === null || pubnub === void 0 ? void 0 : pubnub.fetchMessages({
        channels: [channel],
        count: opts.count,
        stringifiedTimeToken: true,
        start: opts.start,
        end: opts.end,
        includeMessageActions: true,
    }, (status, response) => (status.error ? rej(status) : res(response)));
});
export const getHistory = (channel, end) => pubnub === null || pubnub === void 0 ? void 0 : pubnub.history({
    channel,
    stringifiedTimeToken: true,
    end,
    count: 100,
});
export const messageAction = {
    add: (channel, messageTimetoken, action, trackObj, cb, err) => pubnub === null || pubnub === void 0 ? void 0 : pubnub.addMessageAction({
        channel,
        messageTimetoken,
        action,
    }, function (status, response) {
        if (status.error) {
            err('Error creating reaction');
        }
        else {
            track('Chat Reaction Added', trackObj);
            cb(response);
        }
    }),
    remove: (channel, messageTimetoken, actionTimetoken, trackObj, cb, err) => pubnub === null || pubnub === void 0 ? void 0 : pubnub.removeMessageAction({
        channel,
        messageTimetoken,
        actionTimetoken,
    }, function (status, response) {
        if (status.error) {
            err('Error removing reaction');
        }
        else {
            track('Chat Reaction Removed', trackObj);
            cb(response);
        }
    }),
};

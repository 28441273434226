import { convertToCssString as cs } from '../../utils/themeConversions';
export const styles = (theme) => {
    let str = `
    :host {
      display: block;
      border-radius: 0 0 6px 6px;
      background: rgba(0,0,0,6);
      ${cs(theme.body)}
    }
  `;
    return str;
};

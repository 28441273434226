var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, property } from 'lit-element';
import { element } from '../../utils/decorators';
import { defaultHeader } from '../../utils/templates';
import { styles } from './livelike-text-ask.style';
import { ThemedWidget } from '../../mixins/WidgetTemplate';
import { request } from '../../utils';
import { WidgetKind } from '../../constants/Widgets';
/**
 * @element livelike-text-ask
 * @prop {String} [title]
 * @prop {String} [prompt]
 * @prop {String} [confirmation_message]
 * @prop {String} [replyDisable]
 * @prop {String} [showConfirmation]
 */
let LiveLikeTextAsk = class LiveLikeTextAsk extends ThemedWidget {
    constructor() {
        super(...arguments);
        this.kind = WidgetKind.TEXT_ASK;
        this.owner = this;
        this.defaultStyles = styles;
        this.text = '';
        this.maxlength = 240;
        this.replyDisable = true;
        this.firstUpdated = () => {
            super.firstUpdated();
            if (this.interaction) {
                this.text = this.interaction.text;
                this.disabled = true;
                this.showConfirmation = true;
            }
        };
        this.submitReply = () => {
            this.replyDisable = true;
            this.interactionEvent();
            request
                .post({
                url: this.widgetPayload.reply_url,
                data: { text: this.text },
            })
                .then((payload) => {
                this.disabled = true;
                this.replyDisable = false;
                this.reply = this.text;
                this.showConfirmation = true;
            })
                .catch((err) => {
                this.replyDisable = false;
            });
        };
        this.inputHandler = (e) => {
            this.text = e.target.value.trimLeft();
            this.inputEl.value = this.text;
            this.counter.textContent = this.maxlength - this.text.length;
            this.replyDisable = this.text.length > 0 ? false : true;
        };
    }
    get counter() {
        return this.querySelector('.text-ask-input-counter');
    }
    get inputEl() {
        return this.querySelector('.text-ask-input');
    }
    render() {
        const charactersLeft = this.disabled
            ? null
            : html `<span class="text-ask-input-counter">${this.maxlength}</span>`;
        return html `
      <template>
        <livelike-widget-root>
          ${defaultHeader}
          <livelike-widget-body>
            <div class="text-ask-prompt-container">
              <span class="text-ask-prompt">${this.prompt}</span>
            </div>
            <form class="text-ask-form">
              <div class="text-ask-input-container">
                <textarea
                  class="text-ask-input"
                  type="text"
                  name="reply"
                  rows="2"
                  .value=${this.text}
                  maxlength="${this.maxlength}"
                  ?disabled="${this.disabled}"
                  placeholder=${this.owner.localize('widget.textAsk.placeholder')}
                  @input=${this.inputHandler}
                ></textarea>
                ${charactersLeft}
              </div>
            </form>
            <div class="text-ask-footer">
              <button
                class="text-ask-button"
                @click=${this.submitReply}
                ?disabled="${this.disabled || this.replyDisable}"
              >
                <span class="text-ask-button-label"
                  >${this.owner.localize('widget.textAsk.sendButton.label')}</span
                >
              </button>
              <div
                class="confirmation-message-container ${!this.showConfirmation
            ? 'hidden'
            : ''}"
              >
                <span class="confirmation-message"
                  >${this.confirmation_message}</span
                >
              </div>
            </div>
          </livelike-widget-body>
        </livelike-widget-root>
      </template>
    `;
    }
};
__decorate([
    property({ type: String })
], LiveLikeTextAsk.prototype, "title", void 0);
__decorate([
    property({ type: String })
], LiveLikeTextAsk.prototype, "prompt", void 0);
__decorate([
    property({ type: String })
], LiveLikeTextAsk.prototype, "confirmation_message", void 0);
__decorate([
    property({ type: Boolean })
], LiveLikeTextAsk.prototype, "replyDisable", void 0);
__decorate([
    property({ type: Boolean })
], LiveLikeTextAsk.prototype, "showConfirmation", void 0);
LiveLikeTextAsk = __decorate([
    element('livelike-text-ask')
], LiveLikeTextAsk);
export { LiveLikeTextAsk };

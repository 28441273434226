import { request, getPinMessageUrl, paginator, iteratorBase } from '../utils';
import { validateArgObj } from '../utils/validateArgs';
const pinMessageOrderBy = {
    asc: 'pinned_at',
    desc: '-pinned_at',
};
/**
 * @description Pin a chat message
 * @async
 * @since 2.14.0
 * @example
 * ```js
 * pinMessage({
 *  roomId: "9e6f1bc4-9f02-4c57-92b7-7521d0f5b027",
 *  messageId: "aa7e03fc-01f0-4a98-a2e0-3fed689632d7",
 *  messagePayload: { // messagePayload of type IMessagePayload
 *     message: "test Message"
 *  }
 * }).then(pinMessageInfo => console.log(pinMessageInfo))
 * ```
 */
export function pinMessage(args) {
    return validateArgObj(args, ['roomId', 'messageId', 'messagePayload'], 'pinMessage').then(() => {
        const url = getPinMessageUrl();
        return request.post({
            url: `${url}?chat_room_id=${args.roomId}`,
            data: {
                chat_room_id: args.roomId,
                message_id: args.messageId,
                message_payload: args.messagePayload,
            },
        });
    });
}
/**
 * @description Unpin a chat message
 * @async
 * @since 2.14.0
 * @example
 * ```js
 * unpinMessage({
 *  pinMessageInfoId: "3f81ccb0-ec95-4e71-a0b7-462889699f75"
 * }).then(res => console.log(res))
 * ```
 */
export function unpinMessage(args) {
    return validateArgObj(args, ['pinMessageInfoId'], 'unpinMessage').then(() => request.delete({
        url: `${getPinMessageUrl()}${args.pinMessageInfoId}/`,
    }));
}
/**
 * @description Get list of pin message info
 * @async
 * @since 2.14.0
 * @example
 * ```js
 * getPinMessageInfoList({
 *  roomId: "37e1720a-fc7b-4962-b216-6be9ed69dc96",
 *  orderBy: "desc"
 * }).then(pinMessageInfoList => console.log(pinMessageInfoList))
 * ```
 */
export function getPinMessageInfoList(args) {
    return validateArgObj(args, ['roomId'], 'getPinMessageInfoList').then(() => {
        const orderBy = args.orderBy
            ? `&ordering=${pinMessageOrderBy[args.orderBy]}`
            : '';
        const pinMessageInfoListUrl = `${getPinMessageUrl()}?chat_room_id=${args.roomId}${orderBy}`;
        return iteratorBase(['results'], paginator(pinMessageInfoListUrl, (url) => request.get({ url }), (r) => ({ count: r.count, results: r.results })));
    });
}

import { request, paginator, iteratorBase, getAppResource, buildUrl, } from '../utils';
import { validateArgObj } from '../utils/validateArgs';
/**
 * @description Get list of reaction packs in an application
 * @async
 * @since 2.29.0
 * @example
 * ```js
 * getReactionPacks().then(({results}) => console.log(results))
 * ```
 */
export function getReactionPacks() {
    return getAppResource().then((app) => {
        return iteratorBase(['results'], paginator(app.reaction_packs_url, (url) => request.get({ url }), (r) => ({ count: r.count, results: r.results })));
    });
}
/**
 * @description Get reaction pack detail with collection of reactions using a reaction pack Id
 * @async
 * @since 2.29.0
 * @example
 * ```js
 * getReactionPackDetail({
 *  reactionPackId: "aa7e03fc-01f0-4a98-a2e0-3fed689632d7",
 * }).then(reactionPack => console.log(reactionPack))
 * ```
 */
export function getReactionPackDetail(arg) {
    return validateArgObj(arg, ['reactionPackId'], 'getReactionPackDetail')
        .then(() => getAppResource())
        .then((app) => {
        return request.get({
            url: buildUrl(arg.reactionPackId, app.reaction_pack_detail_url_template),
        });
    });
}

import { convertToCssString as cs } from '../../utils/themeConversions';
export const styles = (theme) => `
    :host {
      width: 0%;
      display: block;
      background: white;
      height: 2px;
      position: absolute;
      bottom: 0;
      transition-timing-function: linear;
      ${cs(theme.timer)}
    }
  `;

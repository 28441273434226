import { _$$ } from '../scripts/internal/globals';
import { safeSubscribe, addListener as pnAddListener, unsubscribe as pnUnsubscribe, pubnubInitialise, } from '../scripts/internal/pubnubHandler';
import { eventDispatcher } from './index';
import { isBlockedProfileMessage } from './blockProfileIds';
const getListenerMapKey = (channel, event) => {
    let mapKey = 'channel-' + channel;
    if (event) {
        mapKey += '-event-' + event;
    }
    return mapKey;
};
export function subscriptionCoordinatorBase() {
    let listeners = {};
    // Only fires callbacks for channel/callbacks groups that match the
    // current pubnub message's subscription channel.
    const demultiplex = (channel, message, event) => {
        const eventListeners = listeners[getListenerMapKey(channel, event)];
        const channelListeners = listeners[getListenerMapKey(channel)];
        if (event) {
            eventListeners === null || eventListeners === void 0 ? void 0 : eventListeners.forEach((cb) => cb({ message, event }));
        }
        channelListeners === null || channelListeners === void 0 ? void 0 : channelListeners.forEach((cb) => cb({ message, event }));
    };
    const mainListener = {
        message: (msg) => {
            msg.message.event === 'program-status-updated' &&
                eventDispatcher(document, msg.message.event, msg.message.payload);
            msg.timetoken && (msg.message.payload.timetoken = msg.timetoken);
            // stop broadcasting of message events from blocked profile
            if (isBlockedProfileMessage(msg.message.payload, msg.message.event)) {
                return;
            }
            demultiplex(msg.channel, msg.message.payload, msg.message.event);
        },
        messageAction: (msg) => {
            demultiplex(msg.channel, msg.data, msg.event);
        },
    };
    const initialisePubnub = (pnConfig) => {
        if (!(pnConfig === null || pnConfig === void 0 ? void 0 : pnConfig.subscribeKey) || !(pnConfig === null || pnConfig === void 0 ? void 0 : pnConfig.publishKey)) {
            _$$.logger &&
                console.log('PubNub is not configured for the current application.');
            return;
        }
        const pn = pubnubInitialise(pnConfig);
        !_$$.pn && pnAddListener(mainListener);
        _$$.pn = pn;
    };
    return {
        listeners,
        initialisePubnub,
        subscribe: (channel) => {
            !_$$.pn && _$$.pnConfig && initialisePubnub(_$$.pnConfig);
            safeSubscribe(channel);
        },
        addListener: (channel, callback, event) => {
            const listenerKey = getListenerMapKey(channel, event);
            const listenersHasKey = listeners.hasOwnProperty(listenerKey);
            if (listenersHasKey) {
                const currentListeners = listeners[listenerKey];
                callback && currentListeners.push(callback);
                listeners[listenerKey] = currentListeners;
            }
            else {
                listeners[listenerKey] = [callback];
            }
        },
        unsubscribe: (channel) => {
            let channelMapKey = getListenerMapKey(channel);
            const hasListeners = Object.keys(listeners).some((k) => k.startsWith(channelMapKey));
            !hasListeners && pnUnsubscribe(channel);
        },
        removeListener: (channel, callback, event) => {
            const listenerKey = getListenerMapKey(channel, event);
            const listenersHasKey = listeners.hasOwnProperty(listenerKey);
            if (listenersHasKey) {
                const currentListeners = listeners[listenerKey];
                const newListeners = currentListeners.filter((cb) => cb !== callback);
                if (newListeners && newListeners.length) {
                    listeners[listenerKey] = newListeners;
                }
                else {
                    delete listeners[listenerKey];
                }
            }
            return listeners;
        },
        removeAllListenersForEvent: (channel, event) => {
            delete listeners[getListenerMapKey(channel, event)];
            return listeners;
        },
    };
}
export const subscriptionCoordinator = subscriptionCoordinatorBase();
window.SCPEAK = () => subscriptionCoordinator.listeners;

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html } from 'lit-element';
import { PercentCalcMixin } from '../../mixins/PercentCalcMixin';
import { LiveLikeOptionChild } from '../../mixins/ThemedElement';
import { element } from '../../utils/decorators';
import { styles } from './livelike-percentage.style';
// TODO Explain calculation - relation to livelike-option parent, option/choice object vote_count prop, etc.
/**
 * Child of <livelike-option>
 * Calculates and renders vote count percentage
 * @element livelike-percentage
 * @property value
 * @attr value
 */
let LiveLikePercentage = class LiveLikePercentage extends PercentCalcMixin(LiveLikeOptionChild) {
    constructor() {
        super(...arguments);
        this.defaultStyles = styles;
    }
    defaultTemplate() {
        return html ` ${this.value}% `;
    }
};
LiveLikePercentage = __decorate([
    element('livelike-percentage')
], LiveLikePercentage);
export { LiveLikePercentage };

import { request, getChatRoomInvitationUrlTemplate, getChatRoomInvitationDetailUrlTemplate, iteratorBase, paginator, } from '../utils';
import { validateArgObj } from '../utils/validateArgs';
import { userProfile } from './internal/globals';
/**
 * @description Send chat room invite to profile (user)
 * @params args of type ISendChatRoomInviteArgs
 * @async
 * @since 2.12.0
 * @returns Promise<ChatRoomInvitation>
 * @example
 * ```js
 * sendChatRoomInviteToProfile({
 *  roomId: "9e6f1bc4-9f02-4c57-92b7-7521d0f5b027",
 *  profileId: "aa7e03fc-01f0-4a98-a2e0-3fed689632d7"
 * }).then(chatRoomInvitation => console.log(chatRoomInvitation))
 * ```
 */
export function sendChatRoomInviteToProfile(args) {
    return validateArgObj(args, ['roomId', 'profileId'], 'sendChatRoomInviteToProfile').then(() => request.post({
        url: getChatRoomInvitationUrlTemplate(),
        data: {
            chat_room_id: args.roomId,
            invited_profile_id: args.profileId,
        },
    }));
}
/**
 * @description Updates chat room invitation status
 * @params args of type IUpdateChatRoomInvitationStatusArgs
 * @returns Promise<IChatRoomInvitation>
 * @async
 * @since 2.12.0
 * @example
 * ```js
 * updateChatRoomInviteStatus({
 *  invitationId: "28cc0ceb-8934-48cd-abc5-4d3a3a681c1b",
 *  invitationStatus: ChatRoomInvitationStatus.ACCEPTED
 * }).then(chatRoomInvitation => console.log(chatRoomInvitation))
 * ```
 */
export function updateChatRoomInviteStatus(args) {
    return validateArgObj(args, ['invitationId', 'invitationStatus'], 'updateChatRoomInviteStatus').then(() => request.patch({
        url: getChatRoomInvitationDetailUrlTemplate(args.invitationId),
        data: {
            status: args.invitationStatus,
        },
    }));
}
/**
 * @description Get list of chat room invitations sent by the current user profile
 * @params args of type IGetInvitationsArgs
 * @returns Promise<IPaginatedResponse<IChatRoomInvitation>>
 * @async
 * @since 2.12.0
 * @example
 * ```js
 * getSentChatRoomInvitations({
 *  invitationStatus: ChatRoomInvitationStatus.PENDING
 * }).then(paginatedInvitations => console.log(paginatedInvitations))
 * ```
 */
export function getSentChatRoomInvitations(args) {
    return validateArgObj(args, ['invitationStatus'], 'getSentChatRoomInvitations').then(() => {
        const invitationUrl = `${getChatRoomInvitationUrlTemplate()}&invited_by_id=${userProfile.id}&status=${args.invitationStatus}`;
        return iteratorBase(['results'], paginator(invitationUrl, (url) => request.get({ url }), (r) => ({ count: r.count, results: r.results })));
    });
}
/**
 * @description Get list of chat room invitations received by the current user profile
 * @params args of type IGetInvitationsArgs
 * @returns Promise<IPaginatedResponse<IChatRoomInvitation>>
 * @async
 * @since 2.12.0
 * @example
 * ```js
 * getReceivedChatRoomInvitations({
 *  invitationStatus: ChatRoomInvitationStatus.PENDING
 * }).then(paginatedInvitations => console.log(paginatedInvitations))
 * ```
 */
export function getReceivedChatRoomInvitations(args) {
    return validateArgObj(args, ['invitationStatus'], 'getReceivedChatRoomInvitations').then(() => {
        const invitationUrl = `${getChatRoomInvitationUrlTemplate()}&invited_profile_id=${userProfile.id}&status=${args.invitationStatus}`;
        return iteratorBase(['results'], paginator(invitationUrl, (url) => request.get({ url }), (r) => ({ count: r.count, results: r.results })));
    });
}

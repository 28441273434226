import { convertToCssString as cs } from '../../utils/themeConversions';
export const styles = (theme) => {
    let str = `
    :host {
      display: block;
      flex-grow: 1;
      z-index: 10;
      padding: 0.5rem;
      word-break: break-word;
      position: relative;
      ${cs(theme.unselectedOptionDescription)}
    }
    :host([image]) {
      padding: 0.75rem 0.5rem 0.25rem 0.5rem;
    }
    :host([selected]) {
      ${cs(theme.selectedOptionDescription)}
    }
  `;
    theme.correctOption &&
        (str += `
      :host([correct]) {
        ${cs(theme.correctOptionDescription)}
      }
      :host([incorrect]) {
        ${cs(theme.incorrectOptionDescription)}
      }`);
    return str;
};

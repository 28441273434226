// import { convertToCssString as cs } from '../../utils/themeConversions';
import { html } from 'lit-element';
export const styles = html `
  <style>
    :host {
    }
    @keyframes slideIn {
      from {
        opacity: 0;
        transform: translate3d(0, -1rem, 0);
      }
      to {
        opacity: 1;
        transform: translate3d(0, -0.5rem, 0);
      }
    }

    .sticker-icon {
      border: none;
      background: none;
      padding: 1px 6px;
    }

    .sticker-icon:hover {
      cursor: pointer;
    }
    .sticker-icon svg {
      fill: #888;
    }

    .sticker-container {
      position: absolute;
      bottom: 4rem;
      opacity: 0;
      transition: all ease 0.3s;
      z-index: 21;
      right: 0;
      max-width: 20rem;
    }
    .sticker-container.show {
      opacity: 1;
      transition: all ease 0.3s;
    }
    .sticker-container.hide {
      opacity: 0;
      transition: all ease 0.3s;
    }
    .sticker-container-header {
      position: relative;
      border-radius: 0.5rem 0.5rem 0 0;
      background: rgba(20, 20, 20, 1);
      padding: 0.625rem 1rem;
      color: #fff;
      font-size: 1rem;
      font-weight: bold;
    }
    .sticker-close-icon {
      border: none;
      background: none;
      position: relative;
      float: right;
      top: -1px;
      right: 0px;
      background-size: contain;
      width: 1.25rem;
      height: 1.25rem;
      cursor: pointer;
    }
    .sticker-body {
      position: relative;
      background: rgba(20, 20, 20, 1);
      padding: 0.625rem 0.3rem;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      overflow-y: auto;
      min-height: 8rem;
      max-height: 8rem;
    }
    .sticker-wrap {
      border: none;
      color: #fff;
      padding: 0.325rem;
      width: 3.75rem;
      background: none;
      border-radius: 0.75rem;
      transition: all ease 0.3s;
      margin: 0.425rem;
    }
    .sticker-wrap:hover,
    .sticker-wrap:focus {
      outline: none;
      background: #818592;
      cursor: pointer;
    }
    .sticker-image {
      width: 100%;
      vertical-align: middle;
    }
  </style>
`;

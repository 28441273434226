// @ts-strict
import { request, paginator, fetchData, iteratorBase, buildUrl, getAppResource, } from '../utils';
import { validateArgObj } from '../utils/validateArgs';
import { userProfile } from './internal/globals';
/**
 * Gets leaderboard detail from leaderboardId.
 * @async
 * @since 1.26.0
 * @example
 * ```js
 * LiveLike.getLeaderboard({leaderboardId: string}).then(leaderboard => console.log(leaderboard))
 * ```
 */
export const getLeaderboard = (args) => validateArgObj(args, ['leaderboardId'], 'getLeaderboard').then(() => fetchData.leaderboard(args.leaderboardId));
/**
 * Gets leaderboard list for a given program.
 * @async
 * @since 1.26.0
 * @example
 * ```js
 * LiveLike.getLeaderboards({programId: string}).then(leaderboards => console.log(leaderboards))
 * ```
 */
export const getLeaderboards = (args) => validateArgObj(args, ['programId'], 'getLeaderboards').then(() => fetchData['program'](args.programId).then((r) => r.leaderboards));
/**
 * Gets leaderboard entries list from leaderboardId.
 * @async
 * @returns Promise<ILeaderboardEntries>
 * @since 1.26.0
 * @example
 * ```js
 * LiveLike.getLeaderboardEntries({programId: string}).then(response => console.log(response))
 * ```
 */
export const getPaginatedLeaderboardEntries = (args) => validateArgObj(args, ['leaderboardId'], 'getPaginatedLeaderboardEntries').then(() => fetchData
    .leaderboard(args.leaderboardId)
    .then((res) => {
    let entries_url = res.entries_url;
    if (args.profileIds && args.profileIds.length) {
        entries_url += '?';
        args.profileIds.forEach((profileId) => {
            if (profileId) {
                entries_url += `profile_id=${profileId}&`;
            }
        });
    }
    return paginator(entries_url, (url) => request.get({ url }), (r) => ({
        count: r.count,
        entries: r.results,
    }));
}));
export const getLeaderboardEntries = (args) => getPaginatedLeaderboardEntries(args).then((iterator) => iteratorBase(['entries'], iterator));
/**
 * Gets leaderboard entry for a given profile in a given leaderboard.
 * @async
 * @since 1.26.0
 * @example
 * ```js
 * LiveLike.getLeaderboardProfileRank({leaderboardId: string, profileId: string}).then(leaderboardEntry => console.log(leaderboardEntry))
 * ```
 */
export const getLeaderboardProfileRank = (args) => validateArgObj(args, ['leaderboardId', 'profileId'], 'getLeaderboardProfileRank').then(() => fetchData
    .leaderboard(args.leaderboardId)
    .then((r) => fetchData.leaderboardEntryDetail(r.entry_detail_url_template, args.profileId)));
/**
 * Get list of leaderboard in which a given profile is part of.
 * If no profileId is passed, get current profile leaderboards
 * @async
 * @since 2.33.0
 * @example
 * ```js
 * LiveLike.getProfileLeaderboards({profileId: "<profile-id>"})
 * .then(leaderboards => console.log(leaderboards))
 * ```
 */
export const getProfileLeaderboards = (args) => getAppResource().then((app) => {
    var _a;
    return iteratorBase(['results'], paginator(buildUrl((_a = args === null || args === void 0 ? void 0 : args.profileId) !== null && _a !== void 0 ? _a : userProfile.id, app.profile_leaderboards_url_template), (url) => request.get({ url }), (r) => ({ count: r.count, results: r.results })));
});
/**
 * Get list of leaderboard views in which a given profile is part of.
 * If no profileId is passed, get current profile leaderboard views
 * @async
 * @since 2.33.0
 * @example
 * ```js
 * LiveLike.getProfileLeaderboardViews({profileId: "<profile-id>"})
 * .then(leaderboardviews => console.log(leaderboardviews))
 * ```
 */
export const getProfileLeaderboardViews = (args) => getAppResource().then((app) => {
    var _a;
    return iteratorBase(['results'], paginator(buildUrl((_a = args === null || args === void 0 ? void 0 : args.profileId) !== null && _a !== void 0 ? _a : userProfile.id, app.profile_leaderboard_views_url_template), (url) => request.get({ url }), (r) => ({ count: r.count, results: r.results })));
});

export function outsideClick(component, callback) {
    function initOutsideClick(e) {
        // If element has shadowRoot, need to check click there.
        const clickedShadowRoot = component.shadowRoot || document;
        // Actual element that was clicked on
        const clickedEl = clickedShadowRoot.elementFromPoint(e.x, e.y);
        // Tracks elements in shadow DOM and light DOM / slotted.
        const light = component.contains(clickedEl);
        const shadow = component.shadowRoot && component.shadowRoot.contains(clickedEl);
        // If both are false, click was outside of element that registered outsideClick.
        !light && !shadow && callback();
    }
    return {
        register: () => window.addEventListener('click', initOutsideClick, false),
        remove: () => window.removeEventListener('click', initOutsideClick, false),
    };
}
// /**
//  * outsideClick decorator
//  * @example
// ```
// @outsideClick()
// callback() {
//   // will run when click outside of element (host component) is done.
// }
// ```
//  */
// export function outsideClick(): any {
//   return (proto: LitElement, methodName: string): any => {
//     const { connectedCallback, disconnectedCallback } = proto;
//     proto.connectedCallback = function() {
//       const method = this[methodName];
//       registerOutsideClick(proto, this, method);
//       return connectedCallback && connectedCallback.call(proto);
//     };
//     proto.disconnectedCallback = function() {
//       const method = this[methodName];
//       removeOutsideClick(proto, this, method);
//       return disconnectedCallback && disconnectedCallback.call(this);
//     };
//   };
// }

import { html } from 'lit-element';
export const rightChevron = html ` <svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
>
  <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
</svg>`;
export const leftChevron = html ` <svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
>
  <path
    d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"
  />
</svg>`;
export const downTriangle = html ` <svg
  viewBox="0 0 512 512"
  height="12"
  width="12"
>
  <path
    d="M98.9,184.7l1.8,2.1l136,156.5c4.6,5.3,11.5,8.6,19.2,8.6c7.7,0,14.6-3.4,19.2-8.6L411,187.1l2.3-2.6  c1.7-2.5,2.7-5.5,2.7-8.7c0-8.7-7.4-15.8-16.6-15.8v0H112.6v0c-9.2,0-16.6,7.1-16.6,15.8C96,179.1,97.1,182.2,98.9,184.7z"
  />
</svg>`;
export const emojiIconInlineSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16" fill="rgba(150, 150, 150, 0.5)"><path fill-rule="nonzero" d="M2.083 9a6.002 6.002 0 0 0 11.834 0H2.083zM8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zM5.5 7a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm5 0a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"></path></svg>`;
export const emojiIcon = html ` <svg width="18" height="18" viewBox="0 0 16 16">
  <path
    fill-rule="nonzero"
    d="M2.083 9a6.002 6.002 0 0 0 11.834 0H2.083zM8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zM5.5 7a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm5 0a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
  />
</svg>`;
export const downIcon = `
  <svg width="25px" height="25px" viewBox="0 0 30 30">
    <rect id="snap-to-live-button" fill="#FFFFFF" x="0" y="0" width="30" height="30" rx="15"></rect>
    <path d="M15.0326453,21 C14.7426596,21 14.4527097,20.8852999 14.2316216,20.6563819 L7.2744003,13.4491933 C6.83183015,12.9907267 6.83183015,12.2787537 7.97485699,11.4850649 C9.11788383,10.6913761 9.53369152,11.0267838 9.97629747,11.4850649 L15.0326453,16.7405562 L20.0890468,11.4850649 C20.5316169,11.0267838 21.1431577,10.6735312 22.0549346,11.4850649 C22.9667115,12.2965986 23.2335678,12.9909492 22.7907828,13.4494158 L15.8336689,20.6566045 C15.6124734,20.8855596 15.3225235,21 15.0326453,21 Z" id="ic_arrowDown" fill="#23282D"></path>
  </svg>`;
export const moreIcon = html ` <svg width="4" height="20" viewBox="0 0 3 15">
  <path
    fill-rule="nonzero"
    d="M1.5 12a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm0-6a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm0-6a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3z"
  />
</svg>`;
export const sendIcon = `
  <svg width="16" height="16" viewBox="0 0 16 16">
    <path fill-rule="nonzero" d="M.579.29l14.705 7.352a.4.4 0 0 1 0 .716L.58 15.71A.4.4 0 0 1 0 15.353V10.47a1 1 0 0 1 .863-.99L8.71 8.396a.4.4 0 0 0 0-.792L.863 6.519A1 1 0 0 1 0 5.53V.647A.4.4 0 0 1 .579.29z"/>
  </svg>`;
export const closeIconSvg = `
<svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1">
  <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <circle id="circle" fill="#000000" opacity="0.200000003" cx="9" cy="9" r="9"></circle>
    <path d="M10.1443526,8.99976063 L12.7631923,11.617505 C13.0789359,11.9337699 13.0789359,12.4465365 12.7631923,12.7628014 C12.4474488,13.0790662 11.9355273,13.0790662 11.6197838,12.7628014 L9.00023937,10.1443526 L6.38249497,12.7631923 C6.06623012,13.0789359 5.55346349,13.0789359 5.23719864,12.7631923 C4.92093379,12.4474488 4.92093379,11.9355273 5.23719864,11.6197838 L7.85564741,9.00023937 L5.23680767,6.38249497 C4.92106411,6.06623012 4.92106411,5.55346349 5.23680767,5.23719864 C5.55255122,4.92093379 6.06447267,4.92093379 6.38021623,5.23719864 L8.99976063,7.85564741 L11.617505,5.23680767 C11.9337699,4.92106411 12.4465365,4.92106411 12.7628014,5.23680767 C13.0790662,5.55255122 13.0790662,6.06447267 12.7628014,6.38021623 L10.1443526,8.99976063 Z" id="x" fill="#FFFFFF" fill-rule="nonzero"></path>
  </g>
</svg>`;
export const closeIcon = html `<svg
  width="18px"
  height="18px"
  viewBox="0 0 18 18"
  version="1.1"
>
  <g
    id="Symbols"
    stroke="none"
    stroke-width="1"
    fill="none"
    fill-rule="evenodd"
  >
    <circle
      id="circle"
      fill="#000000"
      opacity="0.200000003"
      cx="9"
      cy="9"
      r="9"
    ></circle>
    <path
      d="M10.1443526,8.99976063 L12.7631923,11.617505 C13.0789359,11.9337699 13.0789359,12.4465365 12.7631923,12.7628014 C12.4474488,13.0790662 11.9355273,13.0790662 11.6197838,12.7628014 L9.00023937,10.1443526 L6.38249497,12.7631923 C6.06623012,13.0789359 5.55346349,13.0789359 5.23719864,12.7631923 C4.92093379,12.4474488 4.92093379,11.9355273 5.23719864,11.6197838 L7.85564741,9.00023937 L5.23680767,6.38249497 C4.92106411,6.06623012 4.92106411,5.55346349 5.23680767,5.23719864 C5.55255122,4.92093379 6.06447267,4.92093379 6.38021623,5.23719864 L8.99976063,7.85564741 L11.617505,5.23680767 C11.9337699,4.92106411 12.4465365,4.92106411 12.7628014,5.23680767 C13.0790662,5.55255122 13.0790662,6.06447267 12.7628014,6.38021623 L10.1443526,8.99976063 Z"
      id="x"
      fill="#FFFFFF"
      fill-rule="nonzero"
    ></path>
  </g>
</svg>`;
export const defaultAvatar = html ` <svg
  width="64px"
  height="64px"
  viewBox="0 0 64 64"
>
  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g>
      <g>
        <rect
          id="Rectangle"
          fill="#505A64"
          x="0"
          y="0"
          width="64"
          height="64"
        />
        <path
          d="M31.9590262,14 C40.9427465,14 44.8185604,18.1297028 43.8568799,26.9807577 C42.8951994,35.8318125 39.1802051,40 31.9730147,40 C26.0823195,40 21.3936793,37.5331739 20.2595234,28.1644789 C19.1253676,18.7957839 21.4756955,14 31.9590262,14 Z"
          id="Path-2"
          fill="#8C96A0"
        />
        <path
          d="M8,64 C13.6440504,52.375041 21.5113023,46.3802666 31.6017556,46.0156768 C41.692209,45.651087 49.8249571,51.6458614 56,64 L8,64 Z"
          id="Path-3"
          fill="#8C96A0"
        />
      </g>
    </g>
  </g>
</svg>`;
export const giphyIcon = html `
  <svg height="18" width="18" viewBox="0 0 64 64">
    <g id="Giphy">
      <rect fill="#00ff92" x="8.61" y="9.09" width="6.31" height="46.75" />
      <rect fill="#fff24d" x="8.61" y="3.06" width="26.37" height="6.46" />
      <rect fill="#00c9ff" x="8.73" y="55.23" width="45.68" height="6.46" />
      <rect fill="#971eff" x="48.1" y="22.62" width="6.31" height="32.62" />
      <polygon
        fill="#ff6562"
        points="47.81 16.2 47.81 9.8 41.28 9.8 41.28 3.05 34.74 3.05 34.74 16.2 34.74 22.62 41.28 22.62 54.41 22.62 54.41 16.2 47.81 16.2"
      />
      <polygon
        fill="#5d1498"
        points="48.1 22.62 54.41 22.62 48.1 29.09 48.1 22.62"
      />
      <polygon
        fill="#968f2d"
        points="34.74 9.44 34.74 3.14 28.27 9.44 34.74 9.44"
      />
    </g>
  </svg>
`;
export const VideoPlaybackError = html `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    fill="none"
    viewBox="0 0 36 36"
  >
    <path
      fill="#fff"
      d="M24.675 20.215l3.825-2.43L12 7.285v.255l12.675 12.675zM4.215 4l-2.13 2.115L12 16.03v12.255l7.485-4.77 10.17 10.17 2.115-2.115L4.215 4zM15 22.825V19.03l2.325 2.325L15 22.825z"
    />
  </svg>
`;

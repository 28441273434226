import { mergeObj } from '../utils';
import { defaultTheme } from './defaultTheme';
function camelToKebab(string) {
    return string.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
}
function convertHexUnitTo255(hexStr) {
    return parseInt(hexStr.repeat(2 / hexStr.length), 16);
}
const isHex = (s) => /^([A-Fa-f0-9]{3,4}){1,2}$/.test(s);
// 8 digit hex isn't supported by all browsers, convert to rgba.
// Works for 3, 4, 6, and 8 digit hex.
function hexToRGBA(hex) {
    // const isHex = !isNaN(Number('0x' + hex));
    if (isHex(hex)) {
        // Needed to break the hex down if it's a multiple of 2 or 3.
        const chunkSize = Math.floor(hex.length / 3);
        // Gets array, 'ff7700cc' will be ['ff', '77', '00', 'cc'];
        const hexArr = hex.match(new RegExp(`.{${chunkSize}}`, 'g'));
        // Convert hex chunk to rgb 255 val.
        const arr = hexArr.map(convertHexUnitTo255);
        // Converts alpha 255 into number between 0 and 1 with three significant figures.
        const alpha = arr[3] || arr[3] === 0 ? (arr[3] / 255).toFixed(2) : 1;
        return `rgba(${arr[0]}, ${arr[1]}, ${arr[2]}, ${alpha})`;
    }
    else {
        return hex;
    }
}
function convertBackground(theme, key) {
    const prop = theme[key];
    if (prop.format === 'fill') {
        theme[key] = isHex(prop.color) ? hexToRGBA(prop.color) : prop.color;
    }
    else if (prop.format === 'uniformGradient') {
        theme[key] = `linear-gradient(90deg, ${hexToRGBA(prop.colors[0])}, ${hexToRGBA(prop.colors[1])})`;
    }
}
function stringifyProps(theme) {
    Object.keys(theme).forEach((key) => {
        const propIsArray = theme[key] instanceof Array;
        if (key === 'fontFamily') {
            // Should just be the join() func, but now it's converting to string and
            // throwing error. Likely looping issue related to (ps issue) below.
            theme[key] = propIsArray ? theme[key].join(' ') : theme[key];
        }
        else if (typeof theme[key] === 'object' && !propIsArray) {
            key === 'background'
                ? convertBackground(theme, key)
                : stringifyProps(theme[key]);
        }
        else if (propIsArray) {
            const pxArr = theme[key].map((n) => n + 'px');
            theme[key] = pxArr.join(' ');
        }
        else if (key.toLowerCase().indexOf('color') !== -1) {
            theme[key] =
                theme[key].slice(0, 3) === 'rgb' ? theme[key] : hexToRGBA(theme[key]);
        }
        else if (key === 'fontSize' || key === 'borderWidth') {
            // Will onlt have 'px' if set here, so don't re-add it if it already has 'px' suffix.
            if (!String(theme[key]).match(/px/gi)) {
                theme[key] = `${theme[key]}px`;
            }
        }
    });
    return theme;
}
export const getTheme = (customTheme) => stringifyProps(mergeObj(defaultTheme, customTheme));
export function convertToCssString(stringifiedTheme) {
    // Prevents error when there is no matching theme object for used prop;
    if (stringifiedTheme) {
        let str = '';
        Object.keys(stringifiedTheme).forEach((key) => {
            let kebabKey = camelToKebab(key);
            key === 'fontColor' && (kebabKey = 'color');
            str += `${kebabKey}: ${stringifiedTheme[key]};\n`;
        });
        return str;
    }
    return '';
}

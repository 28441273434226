// @ts-strict
import { fetchData, getAppResource, getChatRoomResource, getProgramResource, iteratorBase, noArgPromiseRej, paginator, request, } from '../utils';
import { validateArgObj } from '../utils/validateArgs';
/**
 * Gets sponsors list for a given program.
 * @async
 * @since 2.5.1
 * @deprecated use getProgramSponsors API instead of getSponsors
 * @example
 * ```js
 * LiveLike.getSponsors({programId: string}).then(sponsors => console.log(sponsors))
 * ```
 */
export const getSponsors = (args) => {
    console.warn('getSponsors is depreacted since 2.15.0, use "getProgramSponsors" API instead.');
    return !args || !args.programId
        ? noArgPromiseRej('programId', 'sponsor')
        : fetchData['program'](args.programId).then((r) => r.sponsors);
};
/**
 * Gets sponsors list for a given application.
 * @async
 * @since 2.16.0
 * @example
 * ```js
 * LiveLike.getApplicationSponsors().then(paginatedSponsors => console.log(paginatedSponsors))
 * ```
 */
export const getApplicationSponsors = () => getAppResource().then((app) => {
    const sponsorsUrl = app.sponsors_url;
    return iteratorBase(['results'], paginator(sponsorsUrl, (url) => request.get({ url }), (r) => ({
        count: r.count,
        results: r.results,
    })));
});
/**
 * Gets sponsors list for a given program.
 * @async
 * @since 2.16.0
 * @example
 * ```js
 * LiveLike.getProgramSponsors({programId: string}).then(paginatedSponsors => console.log(paginatedSponsors))
 * ```
 */
export const getProgramSponsors = (args) => validateArgObj(args, ['programId'], 'sponsor')
    .then(() => getProgramResource(args.programId))
    .then((program) => {
    const sponsorsUrl = program.sponsors_url;
    return iteratorBase(['results'], paginator(sponsorsUrl, (url) => request.get({ url }), (r) => ({
        count: r.count,
        results: r.results,
    })));
});
/**
 * Gets sponsors list for a given chatroom.
 * @async
 * @since 2.16.0
 * @example
 * ```js
 * LiveLike.getChatRoomSponsors({roomId: string}).then(paginatedSponsors => console.log(paginatedSponsors))
 * ```
 */
export const getChatRoomSponsors = (args) => validateArgObj(args, ['roomId'], 'sponsor')
    .then(() => getChatRoomResource(args.roomId))
    .then((chatRoom) => {
    const sponsorsUrl = chatRoom.sponsors_url;
    return iteratorBase(['results'], paginator(sponsorsUrl, (url) => request.get({ url }), (r) => ({
        count: r.count,
        results: r.results,
    })));
});

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, property } from 'lit-element';
import { element } from '../../utils/decorators';
import { ThemedElement } from '../../mixins/ThemedElement';
import { styles } from './livelike-dueling-progress.style';
import { votePercentage } from '../../utils';
let LiveLikeDuelingProgress = class LiveLikeDuelingProgress extends ThemedElement {
    constructor() {
        super(...arguments);
        this.defaultStyles = styles;
        this.ownerUpdated = (e) => {
            if (e.name === 'options' || e.name === 'choices') {
                this.items = e.prop;
            }
        };
    }
    connectedCallback() {
        super.connectedCallback();
        this.items = this.items || this.owner.options || this.owner.choices;
    }
    defaultTemplate() {
        const defaultWidth = Math.round((1 / this.items.length) * 100);
        const renderBar = (item, idx) => {
            const w = `width:${votePercentage(this.owner, item, defaultWidth)}%`;
            return html `
        <div class="bar-container bar${idx}" style="${w}">
          ${item.description ? html ` <div>${item.description}</div> ` : null}
        </div>
      `;
        };
        return html ` ${this.items.map(renderBar)} `;
    }
};
__decorate([
    property({ type: Array })
], LiveLikeDuelingProgress.prototype, "items", void 0);
LiveLikeDuelingProgress = __decorate([
    element('livelike-dueling-progress')
], LiveLikeDuelingProgress);
export { LiveLikeDuelingProgress };
// TODO this should be used in place of the div above
// The issue is that the <livelike-description> element
// has some default :host{} styles and it would be awkward to overwrite them.
// Those are the defaults for the voting widgets, but should not be
// the defaults for the element itself. Those defaults should be put in there
// by the voting widgets themselves, and the <livelike-description> element
// should instead have some simpler defaults;
// <livelike-description
// .description=${item.description}
// ></livelike-description>

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, property } from 'lit-element';
import { Phase, WidgetKind, widgetKindVote } from '../../constants/Widgets';
import { request, eventBus, eventDispatcher, getMilliseconds, kebabToTitle, localize, } from '../../utils';
import { track } from '../../utils/analytics';
import { subscriptionCoordinator as sc } from '../../utils/subscriptionCoordinator';
export class Widget extends LitElement {
    constructor() {
        super(...arguments);
        this.firstInteractionTime = null;
        this.changed = false;
        this.phase = Phase.READY;
        this.selectedOption = {};
        this.syntheticIncrement = false;
        this.vote = null;
        this.isInteractable = true;
        this.interaction = null;
        this.interactions = null;
        this.hide_dismiss_button = false;
        this.initialLoad = false;
        this.interacted = false;
        this._disabled = false;
        this.localize = (key, variables) => localize(key, this.lang, variables);
        this.startInteractiveUntilTimer = () => {
            var _a;
            const interactiveUntil = (_a = this.widgetPayload) === null || _a === void 0 ? void 0 : _a.interactive_until;
            if (!interactiveUntil)
                return;
            const MAX_SET_TIMEOUT_DELAY = 2147483647;
            const interactiveUntilTime = new Date(interactiveUntil);
            const timeout = interactiveUntilTime.getTime() - Date.now();
            if (timeout > 0) {
                // setTimeout stores the delay as 32-bit signed integer. So, when interactive_until is set
                // for more than 24.8 days later, the code executes immediately disabling the widget.
                // The below if condition is added to handle the same.
                // If timeout is greater than MAX_SET_TIMEOUT_DELAY, a setTimeout with MAX_SET_TIMEOUT_DELAY
                // as the delay will run which will recursively call startInteractiveUntilTimer again and
                // again until the timeout is less than MAX_SET_TIMEOUT_DELAY and eventually sets this.disabled as true
                if (timeout > MAX_SET_TIMEOUT_DELAY) {
                    this.interactiveUntilTimerTimeout = setTimeout(() => {
                        this.startInteractiveUntilTimer();
                    }, MAX_SET_TIMEOUT_DELAY);
                }
                else {
                    this.interactiveUntilTimerTimeout = setTimeout(() => {
                        this.disabled = true;
                        this.interactiveUntilTimerTimeout = null;
                    }, timeout);
                }
            }
            else
                this.disabled = true;
        };
        this.onResults = (e) => {
            // console.log('onResults e', e);
            // Synthetic increment is to create a 'fake' vote for quizzes since the real
            // vote method doesn't get called until the quiz expires.
            if (e.message.id === this.widgetPayload.id) {
                if (!this.syntheticIncrement) {
                    let changedVote = null;
                    const resArr = e.message.options || e.message.choices;
                    const voteType = e.message.options ? 'vote_count' : 'answer_count';
                    const type = e.message.options ? 'options' : 'choices';
                    // Compares vote_count / answer_count between current options/choices arr and result
                    // event response arr. Prevents unnecessary rerender of children since LitElement will
                    // update when any array is set due to JS obj ref equality. NOTE: ONLY checks [voteType].
                    // Change to real obj compare if needed in future.
                    if (resArr) {
                        // @ts-ignore
                        const updatedArr = this[type].map((item, idx) => {
                            if (item[voteType] !== resArr[idx][voteType]) {
                                changedVote = resArr[idx];
                                item[voteType] = resArr[idx][voteType];
                            }
                            return item;
                        });
                        if (!!changedVote) {
                            this[type] = updatedArr;
                            this.changed = true;
                            // There needs to be a way for the integrator to hook into to
                            // vote updates without needing to use provided sub-elements.
                            voteType === 'vote_count'
                                ? this.voteCountChanged(changedVote)
                                : this.answerCountChanged(changedVote);
                            const eventName = voteType === 'vote_count'
                                ? 'votecountchanged'
                                : 'answercountchanged';
                            eventDispatcher(this, eventName, changedVote);
                            eventDispatcher(this, e.event, resArr);
                        }
                    }
                }
                else {
                    this.syntheticIncrement = false;
                }
            }
        };
        this.removeListener = (evt) => {
            evt &&
                eventDispatcher(this, evt, { element: this, widget: this.widgetPayload });
            if (this.channel) {
                sc.removeListener(this.channel, this.onResults);
                sc.unsubscribe(this.channel);
            }
            return Promise.resolve();
        };
        this.expire = () => this.removeListener('expire');
        this.dismiss = () => {
            const trackObj = {
                'Widget Type': kebabToTitle(this.widgetPayload.kind),
                'Widget ID': this.widgetPayload.id,
            };
            track('Widget Dismissed', trackObj, this.programid);
            return this.removeListener('dismiss');
        };
        // TODO Fix detaching logic - currently works for both single widgets and livelike-widget el
        // but the logic should be improved.
        this.detach = () => {
            const parent = this.parentElement;
            parent && parent.detach ? parent.detach(this) : this.remove();
        };
        this.widgetDismissClick = () => this.dismiss().then(this.detach);
        this.interactionTrack = () => {
            !this.firstInteractionTime && (this.firstInteractionTime = new Date());
            this.interacted = true;
        };
        this.interactionEvent = (voteCount) => {
            this.widgetPayload.kind !== WidgetKind.CHEER_METER &&
                this.interactionTrack();
            const trackObj = {
                'Widget Type': kebabToTitle(this.widgetPayload.kind),
                'Widget ID': this.widgetPayload.id,
            };
            track('Widget Interacted', trackObj, this.programid);
        };
        /**
         * Widget ready state. widget.phase = 'ready'.
         * @since 1.18.0
         */
        this.ready = (args) => new Promise((res) => setTimeout(() => res(), (args && args.timeout) || 0));
        /**
         * Optional timeout argument. If no timeout arg passed, widget.widgetPayload.timeout is used.
         * Initiates timer countdown, allows interaction/voting. Resolves when timer duration elapses.
         * widget.phase = 'interactive'
         * @since 1.18.0
         */
        this.interactive = (args) => new Promise((resolve) => {
            this.phase = Phase.INTERACTIVE;
            this.disabled = false;
            const trackObj = {
                'Widget Type': kebabToTitle(this.widgetPayload.kind),
                'Widget ID': this.widgetPayload.id,
            };
            this.isInteractable &&
                track('Widget Became Interactive', trackObj, this.programid);
            // Allows for setting timeout to `null` to be able to not show timer element
            // Useful when setting widget to interactive state indefinitely.
            let delay = args && args.timeout !== undefined
                ? args.timeout
                : this.widgetPayload.timeout &&
                    getMilliseconds(this.widgetPayload.timeout);
            this.timeout = delay;
            delay !== null && setTimeout(resolve, delay);
        });
        /**
         * Timer is removed, widget is disabled, widget vote/answers are shown.
         * Resolves when timer duration elapses.
         * If program rewards_type is either 'points' or 'badges' and user has interacted
         * with widget, user points received are displayed.
         * If program rewards_type is 'badges' and user has interacted with widget and user has
         * received a new badge, the new badge is displayed.
         * widget.phase = 'results'
         * widget.disabled = true
         * @since 1.18.0
         */
        this.results = (args) => new Promise((resolve) => {
            this.phase = Phase.RESULTS;
            const delay = (args && args.timeout) || 8000;
            this.changed = true;
            this.disabled = true;
            this.resultEvent();
            setTimeout(resolve, delay);
        });
        /**
         * @since 1.18.0
         */
        this.finished = (args) => new Promise((res) => {
            this.phase = Phase.FINISHED;
            this.changed = true;
            this.disabled = true;
            setTimeout(res, (args && args.timeout) || 0);
        });
        //////// Votes ////////
        /**
         * Fires rankchange event when user profile's rank updates
         * @fires rankchange
         */
        this.rankChanged = (widget, rewards) => {
            rewards &&
                rewards.length &&
                eventDispatcher(this, 'rankchange', { widget, rewards, element: this });
        };
        this.voteBase = (url, type, data) => request[type]({ url, data })
            .then((payload) => {
            this.voteDisable = false;
            this.vote = payload;
            this.rankChanged(this.widgetPayload, payload.rewards);
            return Promise.resolve(payload);
        })
            .catch((err) => {
            this.voteDisable = false;
            return Promise.reject(err);
        });
        this.updateVote = (url, data) => this.voteBase(url, 'patch', data);
        this.createVote = (url, data) => this.voteBase(url, 'post', data);
    }
    get disabled() {
        return this._disabled;
    }
    set disabled(val) {
        const oldVal = this._disabled;
        const interactiveUntil = this.widgetPayload.interactive_until;
        const isWidgetDisabled = interactiveUntil &&
            new Date() >= new Date(this.widgetPayload.interactive_until);
        if (isWidgetDisabled) {
            if (!oldVal) {
                this._disabled = true;
                this.requestUpdate('disabled', oldVal);
            }
            return;
        }
        this._disabled = val;
        this.requestUpdate('disabled', oldVal);
    }
    shouldUpdate() {
        // Ensures that widgetPayload property is loaded before doing anything
        return !!this.widgetPayload;
    }
    firstUpdated() {
        // Ensures that widgetPayload property is loaded before doing anything
        this.widgetPayload.pubnub_enabled &&
            (this.channel = this.widgetPayload.subscribe_channel);
        if (this.channel) {
            sc.subscribe(this.channel);
            sc.addListener(this.channel, this.onResults);
        }
        this.widgetPayload.impression_url &&
            request
                .post({ url: this.widgetPayload.impression_url })
                .catch((e) => console.warn('Widget impression request failed'));
    }
    // TODO Must be in connectedCallback or else the EmojiSlider widget doesn't get it in time.
    connectedCallback() {
        // console.log('Widget.ts CC');
        this.addEventListener('dismiss-clicked', this.widgetDismissClick);
        this.addEventListener('widget-provider', (event) => {
            event.detail.provider = this;
            event.stopPropagation();
        });
        super.connectedCallback();
    }
    disconnectedCallback() {
        if (this.interactiveUntilTimerTimeout) {
            clearTimeout(this.interactiveUntilTimerTimeout);
            this.interactiveUntilTimerTimeout = null;
        }
        this.removeListener();
        this.removeEventListener('dismiss-clicked', this.widgetDismissClick);
        super.disconnectedCallback();
    }
    updated(changedProps) {
        changedProps.forEach((prevProp, name) => {
            // const currentVal = this[name];
            // console.log('name|prevProp|curr ---',name,' ||| ',prevProp,' ||| ',currentVal);
            const propEvt = (prop) => this[prop] !== undefined &&
                eventDispatcher(this, prop, {
                    widget: this.widgetPayload,
                    element: this,
                    [prop]: this[prop],
                    item: this.selectedOption,
                });
            name === 'interacted' && propEvt(name);
            if (name === 'phase') {
                propEvt(name);
                if (this.phase === Phase.RESULTS || this.phase === Phase.FINISHED) {
                    this.changed = true;
                    this.disabled = true;
                }
            }
            if (name === 'vote') {
                if (this.vote && (this.vote.id || this.vote.votes)) {
                    this.changed = true;
                    const evt = widgetKindVote[this.kind];
                    eventDispatcher(this, evt, {
                        [evt]: this.vote,
                        element: this,
                        widget: this.widgetPayload,
                    });
                }
            }
            if (name === 'reply') {
                if (this.reply && this.reply.length > 0) {
                    this.changed = true;
                    const evt = widgetKindVote[this.kind];
                    eventDispatcher(this, evt, {
                        [evt]: this.reply,
                        element: this,
                        widget: this.widgetPayload,
                    });
                }
            }
            this[name] !== undefined &&
                this[name] !== null &&
                eventBus.emit('widget-updated', {
                    name,
                    key: this.widgetPayload.id,
                    prop: this[name],
                    owner: this,
                });
        });
    }
    answerCountChanged(e) { }
    voteCountChanged(e) { }
    resultEvent() { }
    submitVote(option) { }
    lockInVote() { }
}
__decorate([
    property({ type: Boolean })
], Widget.prototype, "isStockWidget", void 0);
__decorate([
    property({ type: Boolean, reflect: true })
], Widget.prototype, "changed", void 0);
__decorate([
    property({ type: String, reflect: true })
], Widget.prototype, "lang", void 0);
__decorate([
    property({ type: Boolean })
], Widget.prototype, "reactions", void 0);
__decorate([
    property({ type: String })
], Widget.prototype, "phase", void 0);
__decorate([
    property({ type: Object })
], Widget.prototype, "selectedOption", void 0);
__decorate([
    property({ type: String })
], Widget.prototype, "timeout", void 0);
__decorate([
    property({ type: Object })
], Widget.prototype, "widgetPayload", void 0);
__decorate([
    property({ type: Object })
], Widget.prototype, "mode", void 0);
__decorate([
    property({ type: String })
], Widget.prototype, "kind", void 0);
__decorate([
    property({ type: Boolean })
], Widget.prototype, "syntheticIncrement", void 0);
__decorate([
    property({ type: Object })
], Widget.prototype, "vote", void 0);
__decorate([
    property({ type: Boolean })
], Widget.prototype, "voteDisable", void 0);
__decorate([
    property({ type: Array })
], Widget.prototype, "options", void 0);
__decorate([
    property({ type: Array })
], Widget.prototype, "choices", void 0);
__decorate([
    property({ type: String })
], Widget.prototype, "question", void 0);
__decorate([
    property({ type: Boolean })
], Widget.prototype, "isInteractable", void 0);
__decorate([
    property({ type: Object })
], Widget.prototype, "interaction", void 0);
__decorate([
    property({ type: Array })
], Widget.prototype, "interactions", void 0);
__decorate([
    property({ type: Boolean })
], Widget.prototype, "authors", void 0);
__decorate([
    property({ type: Boolean })
], Widget.prototype, "timestamps", void 0);
__decorate([
    property({ type: Boolean })
], Widget.prototype, "timeformat", void 0);
__decorate([
    property({ type: Boolean })
], Widget.prototype, "hide_dismiss_button", void 0);
__decorate([
    property({ type: String })
], Widget.prototype, "reply", void 0);
__decorate([
    property({
        type: Boolean,
        hasChanged() {
            return true;
        },
    })
], Widget.prototype, "interacted", void 0);
__decorate([
    property({ type: Boolean, reflect: true })
], Widget.prototype, "disabled", null);
/**
 * DEFAULT:
 *
 * 1. Widget comes in through pubnub
 * 2. Widget gets added to queue
 * 3. Widget in queue ? run recursive update
 * 4. If sync time matches, queue.pop() widget.
 * 5. LIFECYCLES begin:
 *    a. beforeWidgetAttached - widget element is created and BWA event is fired.
 *    b. widgetAttached - widget is attached to DOM and WA event fired
 *    c. WIDGET STATES/PHASES
 *       i. Ready - should timer start here?
 *       ii. Interactive - widget is available to be voted on, if non-voting widget, timer just counts down like normal
 *       iii. Results - Show vote results, correct/incorrect or just total vote %
 *            1. Points - If points / rewards are enabled, show the points received.
 *            2. Badge - If badges / rewards are enabled, show badge received
 *       iv. Finish - widget is expired naturally by timer running out, or dismissed by user.
 *    d. beforeWidgetDetched - BWD event fired
 *    e. widgetDetached - WD event fired, widget is removed from DOM
 *
 *
 *
 */
// import '../../components/livelike-description';
// import '../../components/livelike-dismiss-button';
// import '../../components/livelike-dueling-progress';
// import '../../components/livelike-image';
// import '../../components/livelike-option';
// import '../../components/livelike-percentage';
// import '../../components/livelike-progress';
// import '../../components/livelike-select';
// import '../../components/livelike-timer';
// import '../../components/livelike-title';
// import '../../components/livelike-vote-count';
// import '../../components/livelike-widget-body';
// import '../../components/livelike-widget-bylines';
// import '../../components/livelike-widget-footer';
// import '../../components/livelike-widget-header';
// import '../../components/livelike-widget-reactions';
// import '../../components/livelike-widget-root';
// import '../../components/points-display';
// import '../../components/livelike-badge-display';

import { convertToCssString as cs } from '../../utils/themeConversions';
export const style = (theme) => `
    :host {
      padding: 0.5rem 1rem;
      flex-grow: 1;
      overflow-wrap: break-word;
      width: 85%;
      margin: 0;
      ${cs(theme.title)}
    }

    .widget-tag{
      font-size: 12px;
      color: rgba(255,255,255,0.5);
      font-family: "HelveticaNeue-Bold";
      ${cs(theme.tag)}
    }
  `;

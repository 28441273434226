var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, property } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { element } from '../../utils/decorators';
import { defaultHeader } from '../../utils/templates';
import { ThemedWidget } from '../../mixins/WidgetTemplate';
import { WidgetKind } from '../../constants/Widgets';
let LiveLikeRichPost = class LiveLikeRichPost extends ThemedWidget {
    constructor() {
        super(...arguments);
        this.owner = this;
        this.kind = WidgetKind.RICH_POST;
        this.isInteractable = false;
    }
    render() {
        return html `
      <template>
        <livelike-widget-root>
          ${defaultHeader}
          <livelike-widget-body style="display: flex;">
            <section>${unsafeHTML(this.content)}</section>
          </livelike-widget-body>
        </livelike-widget-root>
      </template>
    `;
    }
};
__decorate([
    property({ type: String })
], LiveLikeRichPost.prototype, "content", void 0);
LiveLikeRichPost = __decorate([
    element('livelike-rich-post')
], LiveLikeRichPost);
export { LiveLikeRichPost };

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, property } from 'lit-element';
import { sendMessage } from '../../scripts/messages';
import { debounce } from '../../utils';
import { element } from '../../utils/decorators';
import { styles } from '../livelike-sticker-picker/livelike-sticker-picker.style';
import { giphyIcon } from '../../icons';
import { LiveLikeChatElement } from '../../mixins/ChatElement';
import '../livelike-stickers';
let GiphyPicker = class GiphyPicker extends LiveLikeChatElement {
    constructor() {
        super(...arguments);
        // State
        this.message = '';
        this.giphyList = [];
        this.offset = 0;
        this.isDoneFetching = true;
        this.loading = false;
        this.scrollListenerAdded = false;
        this.fetchNextPageIfBottom = debounce(() => {
            // console.log('fetchNextPAge');
            const el = this.querySelector('.sticker-body');
            const scrolled = el && el.scrollHeight - el.scrollTop === el.clientHeight;
            if (scrolled && !this.isDoneFetching && !this.loading) {
                this.fetchGifs();
            }
        });
        this.fetchGifs = () => {
            this.loading = true;
            fetch(`https://api.giphy.com/v1/gifs/search?q=${this.message}&api_key=jlvX8uZILp5PhutmuW6fdATBP3E5uayi&offset=${this.offset}&rating=g`)
                .then((r) => r.json())
                .then((res) => {
                this.loading = false;
                this.giphyList = [...this.giphyList, ...res.data];
                this.offset = res.pagination
                    ? res.pagination.count + res.pagination.offset
                    : 0;
                this.isDoneFetching = res.pagination
                    ? this.offset === res.pagination.total_count
                    : true;
            });
        };
        this.onStickerClick = (e) => sendMessage({
            image_url: e.detail.sticker.images.downsized.url,
            image_width: e.detail.sticker.images.downsized.width,
            image_height: e.detail.sticker.images.downsized.height,
            roomId: this.owner.roomid,
            sender_image_url: this.owner.avatarurl,
            timestamp: this.owner.syncStrategy && this.owner.syncStrategy.currentTimecode
                ? this.owner.syncStrategy.currentTimecode
                : null,
        });
        this.debouncedInputEvent = debounce(() => {
            this.offset = 0;
            this.giphyList = [];
            this.fetchGifs();
        });
        this.handleMessage = (e) => {
            e.stopPropagation();
            this.message = e.target.value;
            this.debouncedInputEvent();
        };
        this.handleOpen = (e) => {
            // console.log('e.target', e.target);
            const el = e.target.querySelector('.sticker-body');
            // console.log('el', el);
            el && el.addEventListener('scroll', this.fetchNextPageIfBottom);
        };
        this.handleClose = (e) => {
            this.message = '';
            this.giphyList = [];
            this.offset = 0;
            const el = e.target.querySelector('.sticker-body');
            el && el.removeEventListener('scroll', this.fetchNextPageIfBottom);
        };
        this.stickerNavFooter = () => html `
    <nav slot="sticker-drawer-footer" class="sticker-nav-container">
      <div class="sticker-nav-wrap">
        <input
          type="text"
          maxlength="25"
          style="height: 25px"
          class="livelike-chat-input livelike-giphy-input"
          placeholder=${this.owner.localize('chat.giphyPicker.placeholder')}
          @input=${this.handleMessage}
          @keydown=${this.handleKeyDown}
          .value=${this.message}
        />
      </div>
    </nav>
  `;
    }
    createRenderRoot() {
        return this;
    }
    handleKeyDown(e) {
        e.stopPropagation();
    }
    render() {
        return html `
      ${styles}
      <livelike-stickers
        .localizationKey=${'chat.giphyPicker'}
        .stickers=${this.giphyList}
        .buttonIcon=${giphyIcon}
        @sticker-selected=${this.onStickerClick}
        @drawer-opened=${this.handleOpen}
        @drawer-closed=${this.handleClose}
        .navFooterTemplate=${this.stickerNavFooter}
      >
      </livelike-stickers>
    `;
    }
};
__decorate([
    property({ type: String })
], GiphyPicker.prototype, "message", void 0);
__decorate([
    property({ type: Array })
], GiphyPicker.prototype, "giphyList", void 0);
__decorate([
    property({ type: Number })
], GiphyPicker.prototype, "offset", void 0);
__decorate([
    property({ type: Boolean })
], GiphyPicker.prototype, "isDoneFetching", void 0);
__decorate([
    property({ type: Boolean })
], GiphyPicker.prototype, "loading", void 0);
__decorate([
    property({ type: Boolean })
], GiphyPicker.prototype, "scrollListenerAdded", void 0);
GiphyPicker = __decorate([
    element('livelike-giphy-picker')
], GiphyPicker);
export { GiphyPicker };

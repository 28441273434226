import { _$$ } from '../scripts/internal/globals';
export const isDeletedMessageId = (roomId, messageId) => {
    const chatRoomDeletedMessageIds = _$$.deletedMessageIds[roomId];
    return (!!chatRoomDeletedMessageIds && chatRoomDeletedMessageIds.has(messageId));
};
export const getDeletedMessageIds = (roomId) => _$$.deletedMessageIds[roomId] || new Set();
export const addDeletedMessageId = ({ roomId, messageId }) => {
    const chatRoomDeletedMessageIds = _$$.deletedMessageIds[roomId] || new Set();
    chatRoomDeletedMessageIds.add(messageId);
    _$$.deletedMessageIds[roomId] = chatRoomDeletedMessageIds;
};

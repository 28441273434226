var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html } from 'lit-element';
import { ThemedElement } from '../../mixins/ThemedElement';
import { styles } from './livelike-dismiss-button.style';
import { element } from '../../utils/decorators';
import { closeIcon } from '../../icons';
import { eventDispatcher } from '../../utils';
/**
 * Renders a button that fires `dissmiss-clicked` event when clicked.
 * @element livelike-dismiss-button
 * @fires 'dismiss-clicked'
 */
let LiveLikeDismissButton = class LiveLikeDismissButton extends ThemedElement {
    constructor() {
        super(...arguments);
        this.defaultStyles = styles;
        this.dismissClicked = () => eventDispatcher(this, 'dismiss-clicked');
    }
    defaultTemplate() {
        return html `
      ${!this.owner.hide_dismiss_button
            ? html ` <button
            class="widget-dismiss"
            aria-label="Dismiss"
            @click=${this.dismissClicked}
          >
            ${closeIcon}
          </button>`
            : null}
    `;
    }
};
LiveLikeDismissButton = __decorate([
    element('livelike-dismiss-button')
], LiveLikeDismissButton);
export { LiveLikeDismissButton };

import { request, buildUrl, noArgPromiseRej, getChatRoomResource, } from '../utils';
import { _$$, userProfile } from '../scripts/internal/globals';
const chatResolution = (r) => ({
    id: r.id,
    title: r.title,
    created_at: r.created_at,
    visibility: r.visibility,
    content_filter: r.content_filter,
    token_gates: r.token_gates,
});
const resolveSuccess = () => Promise.resolve(true);
const getBase = (args) => !args.roomId
    ? noArgPromiseRej('roomId', 'chatroom')
    : getChatRoomResource(args.roomId);
const membershipBase = (args, type, data) => getBase(args).then((r) => request[type]({ url: r.memberships_url, data }));
/**
 * Creates a new chat room
 * @async
 * @since 1.20
 */
export const createChatRoom = (args) => args &&
    args.visibility &&
    args.visibility !== 'members' &&
    args.visibility !== 'everyone'
    ? Promise.reject('Invalid value for visibility')
    : request
        .post({ url: _$$.createChatUrlTemplate, data: args })
        .then(chatResolution);
/**
 * Updates chat room by id
 * @async
 * @since 1.20
 */
export const updateChatRoom = (roomId, args) => getBase({ roomId }).then((r) => request.patch({ url: r.url, data: args }).then(chatResolution));
/**
 * Gets chat room by roomId
 * @async
 * @since 1.20
 */
export const getChatRoom = (args) => getBase(args).then(chatResolution);
/**
 * Join chat room by roomId, current user becomes 'member' and is available in chatroom membership list through `LiveLike.getChatRoomMemberships()`.
 * @async
 * @since 1.20
 */
export const joinChatRoom = (args) => membershipBase(args, 'post');
/**
 * Leave chat room by roomId, current user 'member' status removed from chatroom membership list through `LiveLike.getChatRoomMemberships()`.
 * @async
 * @since 1.20
 */
export const leaveChatRoom = (args) => membershipBase(args, 'delete').then(resolveSuccess);
/**
 * Get list of users that are a 'member' of given chatroom.
 * @async
 * @since 1.20
 */
export const getChatRoomMemberships = (args) => membershipBase(args, 'get');
/**
 * Get list of chat rooms that the current user is a 'member' of.
 * @async
 * @since 1.20
 */
export const getProfileChatRoomMemberships = () => request.get({ url: userProfile.chat_room_memberships_url });
/**
 * Gets the muted status of the current user for a given chat room
 * @async
 * @since 2.2.0
 */
export const getChatUserMutedStatus = (args) => getBase(args).then((r) => fetch(buildUrl(userProfile.id, r.muted_status_url_template)).then((r) => r.json()));
/**
 * @description Add new member to the chat room (requires roomId and profileId)
 * @param chatRoomAddMember args of type IChatRoomAddMemberArgs
 * @returns Promise<IChatRoomMembership>
 * @example
 * ```js
 * addNewMemberToChatRoom({
 *  roomId: "9e6f1bc4-9f02-4c57-92b7-7521d0f5b027",
 *  profileId: "aa7e03fc-01f0-4a98-a2e0-3fed689632d7"
 * }).then(membership => console.log(membership))
 * ```
 */
export function addNewMemberToChatRoom({ roomId, profileId, }) {
    return membershipBase({ roomId }, 'post', { profile_id: profileId });
}

import { html } from 'lit-element';
const DELIMITER = '__$';
export const buildMessage = (m, stickerPacks, messageurls, urlPatterns) => {
    const stickers = stickerPacks
        ? stickerPacks.reduce((a, c) => a.concat(c.stickers), [])
        : [];
    if (m.image_url) {
        return html `
      <div class="image-message-container">
        <img
          class="image-message"
          width=${m.image_width}
          height=${m.image_height}
          src=${m.image_url}
          alt="Chat image"
        />
      </div>
    `;
    }
    else if (m.message) {
        let message = m.isFiltered ? m.filtered_message : m.message;
        const messages = {};
        if (messageurls && urlPatterns && urlPatterns.length > 0) {
            urlPatterns.forEach((pattern) => {
                message = message.replace(pattern, (w) => {
                    const link = w.indexOf('http') !== 0 ? '//' + w : w;
                    messages[w] = html `<a
            class="livelike-message-link"
            title="${link}"
            target="_blank"
            href="${link}"
            >${w}</a
          >`;
                    return `${DELIMITER}${w}${DELIMITER}`;
                });
            });
        }
        const STICKER_REG = /:[^\/\/]([^:]*):/g;
        const firstShortcode = message.indexOf(':') === 0;
        // necessary check or else ':sticker::sticker:' would count as length 1
        const shortcodeNum = (message.match(STICKER_REG) || []).length;
        const height = shortcodeNum > 1 || message.split(' ').length > 1 ? '22px' : '32px';
        // console.log('getStickerMEssage stickers', stickers)
        message = message.replace(STICKER_REG, (w) => {
            const foundSticker = stickers && stickers.find((s) => s.shortcode === w.slice(1, -1));
            // console.log('foundSticker', foundSticker)
            if (foundSticker) {
                messages[w] = html `
          <span
            style="margin: 0 4px 0 ${firstShortcode
                    ? '0'
                    : '4px'}; height: ${height}"
          >
            <img
              class="word-item"
              height="100%"
              src=${foundSticker.file}
              alt=${foundSticker.shortcode}
            />
          </span>
        `;
                return `${DELIMITER}${w}${DELIMITER}`;
            }
            return w;
        });
        const messageToRender = message
            .split(DELIMITER)
            .map((w) => messages[w] || w);
        return html `${messageToRender}`;
    }
};

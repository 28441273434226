import { html } from 'lit-element';
export const socialEmbedStyles = html `
  <style>
    /* Parent wrapper to slider. */
    .slider-wrapper {
      overflow: hidden;
      margin: auto;
    }

    /* Apply 'border-box' to 'box-sizing' so border and padding is included in the width and height. */
    .slider-wrapper * {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
    }

    /* Use 'transform' to move the slider's items, so setting the 'transform-style' to 'preserve-3d'
       will make sure each nested element is rendered properly in the 3D space. */
    .slider {
      -webkit-transform-style: preserve-3d;
      -moz-transform-style: preserve-3d;
      transform-style: preserve-3d;
    }

    /* By default we're hiding items (except the initial one) until the JS initiates.
    Elements are absolutely positioned with a width of 100% (for mobile first),
    letting the content's height dictate the height of the slider. */
    .slide {
      opacity: 0;
      position: absolute;
      top: 0;
      width: 100%;
      display: none;
      margin: auto;
      z-index: 100;
      transition: transform 0.5s, opacity 0.5s, z-index 0.5s;
    }
    /* Display the initial item and bring it to the front using 'z-index'.
    These styles also apply to the 'active' item. */
    .slide.initial,
    .slide.active {
      opacity: 1;
      position: relative;
      z-index: 900;
      display: flex;
    }
    /* Set 'z-index' to sit behind our '.active' item. */
    .slide.prev,
    .slide.next {
      z-index: 800;
      display: flex;
    }
    /* Translate previous item to the left */
    .slide.prev {
      transform: translateX(-100%);
    }
    /* Translate next item to the right */
    .slide.next {
      transform: translateX(100%);
    }

    .slide-button {
      background: none;
      position: absolute;
      top: 50%;
      width: 3rem;
      height: 3rem;
      transform: translateY(-50%);
      border-radius: 50%;
      cursor: pointer;
      z-index: 20;
      border: none;
    }
    .slide-button > svg {
      fill: #fff;
      filter: drop-shadow(3px 3px 2px rgba(20, 20, 20, 0.7));
    }
    .left-button {
      left: 0;
    }
    .right-button {
      right: 0;
    }
    .dot-nav-container {
      z-index: 20;
      margin-top: 1rem;
    }
    .dot-item {
      border: none;
      height: 15px;
      width: 15px;
      border-radius: 1rem;
    }
  </style>
`;

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, property } from 'lit-element';
import { PercentCalcMixin } from '../../mixins/PercentCalcMixin';
import { LiveLikeOptionChild } from '../../mixins/ThemedElement';
import { styles } from './livelike-progress.style';
import { element } from '../../utils/decorators';
// TODO Add slot doc
// Explain relationship between livelike-option and vote count
/**
 * Renders progress bar with width of vote percentage by default
 * @element livelike-progress
 */
let LiveLikeProgress = class LiveLikeProgress extends PercentCalcMixin(LiveLikeOptionChild) {
    constructor() {
        super(...arguments);
        this.defaultStyles = styles;
        this.max = 100;
        this.min = 0;
    }
    updated(changedProps) {
        super.updated(changedProps);
        changedProps.has('value') && (this.style.width = this.value + '%');
    }
    defaultTemplate() {
        return html `<slot></slot>`;
    }
};
__decorate([
    property({ type: Number, reflect: true })
], LiveLikeProgress.prototype, "max", void 0);
__decorate([
    property({ type: Number, reflect: true })
], LiveLikeProgress.prototype, "min", void 0);
LiveLikeProgress = __decorate([
    element('livelike-progress')
], LiveLikeProgress);
export { LiveLikeProgress };

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, property } from 'lit-element';
import { element } from '../../utils/decorators';
import { LiveLikeChatElement } from '../../mixins/ChatElement';
import { styles } from './livelike-stickers.style';
import { toggle, closeItem } from '../../utils/animationToggle';
import { eventDispatcher } from '../../utils';
import { closeIcon } from '../../icons';
let LiveLikeStickerPicker = class LiveLikeStickerPicker extends LiveLikeChatElement {
    constructor() {
        super(...arguments);
        // State
        this.drawerOpen = false;
        this.animation = 0;
        this.keyedLocalize = (key, data) => this.owner.localize(this.localizationKey + key, data);
        this.closeDrawer = () => {
            eventDispatcher(this, 'drawer-closed');
            closeItem(this, 'drawerOpen');
        };
        this.stickerBody = () => this.stickers &&
            this.stickers.map((sticker) => {
                return html `
        <button
          tabindex="0"
          type="button"
          class="sticker-wrap"
          aria-label="${this.keyedLocalize('.stickerSelectionButton.ariaLabel', { stickerName: sticker.shortcode || sticker.title })}"
          @click=${() => {
                    eventDispatcher(this, 'sticker-selected', { sticker });
                    this.closeDrawer();
                }}
        >
          <span class="sticker-wrap-image">
            <img
              class="sticker-image"
              src=${sticker.file || sticker.images.preview_gif.url}
              alt="${sticker.shortcode || sticker.title}"
            />
          </span>
        </button>
      `;
            });
        this.renderStickerDrawer = () => {
            const title = this.title || this.keyedLocalize('.title');
            return html `
      <div
        class="sticker-container"
        style="opacity: ${this.animation}"
        aria-label="${title}"
      >
        <header class="sticker-container-header">
          <span class="title">${title}</span>
          <button
            tabindex="0"
            type="button"
            class="sticker-close-icon"
            aria-label="${this.keyedLocalize('.closeButton.ariaLabel')}"
            @click=${this.closeDrawer}
          >
            ${closeIcon}
          </button>
        </header>

        <div class="sticker-body">${this.stickerBody()}</div>

        ${this.navFooterTemplate ? this.navFooterTemplate() : null}
      </div>
    `;
        };
    }
    createRenderRoot() {
        return this;
    }
    render() {
        return html `
      ${styles}
      <button
        tabindex="0"
        type="button"
        class="sticker-icon"
        aria-label="${this.keyedLocalize('.openButton.ariaLabel')}"
        @click=${() => {
            toggle(this, this.owner.dataId, 'drawerOpen');
            setTimeout(() => {
                eventDispatcher(this, 'drawer-opened');
            }, 50);
        }}
      >
        ${this.buttonIcon}
      </button>
      ${this.drawerOpen ? this.renderStickerDrawer() : null}
    `;
    }
};
__decorate([
    property({ type: Array })
], LiveLikeStickerPicker.prototype, "stickers", void 0);
__decorate([
    property({ type: String })
], LiveLikeStickerPicker.prototype, "buttonIcon", void 0);
__decorate([
    property({ type: Function })
], LiveLikeStickerPicker.prototype, "onStickerClick", void 0);
__decorate([
    property({ type: String, reflect: true })
], LiveLikeStickerPicker.prototype, "title", void 0);
__decorate([
    property({ type: Boolean })
], LiveLikeStickerPicker.prototype, "drawerOpen", void 0);
__decorate([
    property({ type: String })
], LiveLikeStickerPicker.prototype, "animation", void 0);
__decorate([
    property({ type: String })
], LiveLikeStickerPicker.prototype, "localizationKey", void 0);
LiveLikeStickerPicker = __decorate([
    element('livelike-stickers')
], LiveLikeStickerPicker);
export { LiveLikeStickerPicker };

import { _$$ } from '../scripts/internal/globals';
export const setAnalyticsProvider = (provider) => {
    typeof provider.track === 'function'
        ? (_$$.analyticsProvider = provider)
        : console.error('Error in analyticsProvider: track is not a function');
};
export const getAnalyticsProvider = () => _$$.analyticsProvider;
export const track = (label, dataObj, programId = null) => {
    programId && (dataObj['Program ID'] = programId);
    if (programId && _$$.progResource[programId]) {
        dataObj = Object.assign(Object.assign({}, dataObj), _$$.progResource[programId].analytics_properties);
    }
    _$$.logger === true && console.log('Analytics track event: ', label);
    const analyticsProvider = getAnalyticsProvider();
    analyticsProvider && analyticsProvider.track(label, dataObj);
};
export const trackMessage = (payload, roomId, event) => {
    const { message, id, image_url, messageLink } = payload;
    const stickers = message && message.match(/:([^:]*):/g);
    const trackObj = {
        'Chat Room ID': roomId,
        'Chat Message ID': id,
        'Has External Image': !!image_url,
    };
    if (stickers && stickers.length > 0) {
        trackObj['Sticker Shortcodes'] = stickers;
    }
    if (messageLink) {
        trackObj['Chat Message Link'] = messageLink;
    }
    track(event, trackObj);
};

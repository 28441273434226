var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ThemedWidget } from '../../mixins/WidgetTemplate';
import { property } from 'lit-element';
let scriptArr = [];
export class SocialEmbedBase extends ThemedWidget {
    constructor() {
        super();
        this.owner = this;
        this.moving = false;
        this.slide = 0;
        this.slides = null;
        this.totalSlides = 0;
        this.scriptsLoaded = false;
        this.finalLoad = false;
        this.forceScriptLoad = () => {
            // @ts-ignore
            window.instgrm && window.instgrm.Embeds.process();
            // @ts-ignore
            window.FB && window.FB.XFBML.parse();
            // @ts-ignore
            window.twttr && window.twttr.widgets.load();
        };
        this.initSlider = () => {
            return new Promise((res) => {
                const dot = this.querySelector('#dot0');
                dot && (dot.checked = true);
                this.slides = this.querySelectorAll('.slide');
                const num = this.slides.length;
                this.totalSlides = num;
                this.slide = 0;
                // Initializes by setting active class
                this.slides && this.slides[0] && (this.slides[0].className += ' active');
                num > 1 && (this.slides[1].className += ' next');
                num > 2 && (this.slides[num - 1].className += ' prev');
                res(undefined);
            });
        };
        this.insertHTML = () => {
            return new Promise((res) => {
                this.items.forEach((item, idx) => {
                    const el = this.querySelector(`#embed${idx}`);
                    const holder = document.createElement('div');
                    holder.innerHTML = item.oembed && item.oembed.html;
                    const isScript = (el) => {
                        if (el.nodeName === 'SCRIPT') {
                            const cleanedUrl = el.src.replace('file://', 'https://');
                            if (!scriptArr.includes(cleanedUrl)) {
                                scriptArr.push(cleanedUrl);
                                const script = document.createElement('script');
                                script.src = cleanedUrl;
                                document.body.insertAdjacentElement('beforeend', script);
                            }
                        }
                    };
                    const allDescendants = (node) => {
                        for (let i = 0; i < node.childNodes.length; i++) {
                            const child = node.childNodes[i];
                            isScript(child);
                            node.hasChildNodes() && allDescendants(child);
                        }
                    };
                    allDescendants(holder);
                    el && (el.innerHTML = item.oembed && item.oembed.html);
                    setTimeout(() => {
                        this.forceScriptLoad();
                        if (item.oembed && item.oembed.provider_name === 'Twitter') {
                            function removeFooter(el) {
                                const tweet = el.querySelector('twitter-widget');
                                if (tweet &&
                                    tweet.shadowRoot &&
                                    tweet.shadowRoot.querySelector('.TweetInfo')) {
                                    tweet.shadowRoot.querySelector('.TweetInfo').style.display =
                                        'none';
                                    tweet.shadowRoot.querySelector('.CallToAction').style.display =
                                        'none';
                                }
                                else {
                                    setTimeout(() => removeFooter(el), 100);
                                }
                            }
                            removeFooter(this);
                        }
                        setTimeout(() => {
                            this.scriptsLoaded = true;
                        }, 1500);
                    }, 500);
                });
                res(undefined);
            });
        };
        this.moveCarouselTo = (slide, totalSlides) => {
            this.checkDot();
            const slideClass = 'slide';
            // Preemptively set variables for the current, next, and previous slide.
            let newPrevious = slide - 1, newNext = slide + 1, oldPrevious = slide - 2, oldNext = slide + 2;
            // Checks if the new potential slide is out of bounds and sets slide numbers
            if (newPrevious <= 0) {
                oldPrevious = totalSlides - 1;
            }
            else if (newNext >= totalSlides - 1) {
                oldNext = 0;
            }
            // Check if current slide is at the beginning or end and sets slide numbers
            if (slide === 0) {
                newPrevious = totalSlides - 1;
                oldPrevious = totalSlides - 2;
                oldNext = slide + 1;
            }
            else if (slide === totalSlides - 1) {
                newPrevious = slide - 1;
                newNext = 0;
                oldNext = 1;
            }
            // Based on the current slide, reset to default classes.
            this.items.forEach((v, i) => {
                this.slides[i].className = slideClass;
            });
            // Add the new classes
            this.slides[newPrevious].className = slideClass + ' prev';
            this.slides[slide].className = slideClass + ' active';
            this.slides[newNext].className = slideClass + ' next';
        };
        this.moveNext = () => {
            this.slide === this.totalSlides - 1 ? (this.slide = 0) : this.slide++;
            this.moveCarouselTo(this.slide, this.totalSlides);
        };
        this.movePrev = () => {
            this.slide === 0 ? (this.slide = this.totalSlides - 1) : this.slide--;
            this.moveCarouselTo(this.slide, this.totalSlides);
        };
        this.moveToSlide = (n) => {
            this.slide = n;
            this.moveCarouselTo(this.slide, this.totalSlides);
        };
        this.checkDot = () => {
            this.items.forEach((v, i) => {
                const dot = this.querySelector(`#dot${i}`);
                dot.checked = this.slide === i;
            });
        };
        this.disableInteraction = () => {
            this.moving = true;
            setTimeout(() => {
                this.moving = false;
            }, 500);
        };
        this.moving = false;
        this.slide = 0;
        this.slides = null;
        this.totalSlides = 0;
        this.scriptsLoaded = false;
        this.finalLoad = false;
    }
    updated(changedProps) {
        changedProps.forEach((prevProp, name) => {
            name === 'scriptsLoaded' &&
                this.scriptsLoaded === true &&
                this.forceScriptLoad();
            name === 'finalLoad' && this.finalLoad === true && this.forceScriptLoad();
        });
    }
    connectedCallback() {
        super.connectedCallback().then(() => {
            this.updateComplete.then(() => {
                this.insertHTML()
                    .then(() => this.initSlider())
                    .then(() => setTimeout(() => {
                    this.finalLoad = true;
                    // TODO Deal with default behavior
                    // this.initWidget();
                }, 3000));
            });
        });
    }
}
__decorate([
    property({ type: Array })
], SocialEmbedBase.prototype, "items", void 0);
__decorate([
    property({ type: String })
], SocialEmbedBase.prototype, "comment", void 0);
__decorate([
    property({ type: Boolean })
], SocialEmbedBase.prototype, "moving", void 0);
__decorate([
    property({ type: Number })
], SocialEmbedBase.prototype, "slide", void 0);
__decorate([
    property({ type: Array })
], SocialEmbedBase.prototype, "slides", void 0);
__decorate([
    property({ type: Number })
], SocialEmbedBase.prototype, "totalSlides", void 0);
__decorate([
    property({ type: Boolean })
], SocialEmbedBase.prototype, "scriptsLoaded", void 0);
__decorate([
    property({ type: Boolean })
], SocialEmbedBase.prototype, "finalLoad", void 0);

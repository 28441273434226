var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { property, html } from 'lit-element';
import { ThemedElement } from '../../mixins/ThemedElement';
import { element } from '../../utils/decorators';
import { styles } from './livelike-select.style';
/**
 * Takes a `<template>` element as a child, and renders the `<template>`'s children as a list of elements,
 * one element per object in `wigetPayload`'s `options/choices` property.
 * @element livelike-select
 * @slot default
 */
let LiveLikeSelect = class LiveLikeSelect extends ThemedElement {
    constructor() {
        super(...arguments);
        this.defaultStyles = styles;
    }
    // Check data flow - why connectedcallback doesn't have the `options array`.
    firstUpdated() {
        // When cloning a node, properties are not copied over.
        function customClone(n) {
            let temp = n.cloneNode(true);
            Object.keys(n).forEach((prop) => (temp[prop] = n[prop]));
            return temp;
        }
        this.items = this.items || this.owner.options || this.owner.choices;
        // Gets template element's children, adds props, clones and returns.
        const template = this.querySelector('template');
        this.items.map((item, idx) => {
            // const optionChild: any = template.content.firstElementChild;
            const optionChild = template.content.firstElementChild;
            // optionChild.items = this.items;
            // optionChild.owner = this.owner;
            // optionChild.item = item;
            optionChild.setAttribute('index', idx);
            const clonedOption = customClone(optionChild);
            this.appendChild(clonedOption);
        });
        template.remove();
        super.firstUpdated();
    }
    defaultTemplate() {
        return html ` <slot></slot> `;
    }
};
__decorate([
    property({ type: Array })
], LiveLikeSelect.prototype, "items", void 0);
LiveLikeSelect = __decorate([
    element('livelike-select')
], LiveLikeSelect);
export { LiveLikeSelect };

import { votePercentage } from '../utils';
const hideInitialWidget = [
    'text-quiz',
    'image-quiz',
    'text-prediction',
    'image-prediction',
    'text-poll',
    'image-poll',
];
const isVisibleInResultPhase = (el) => !el.owner.hideVoteInResults ||
    (!!el.owner.selectedOption && !!el.owner.selectedOption.id);
// Putting these functions outside of the class prevents them from being documented
// by doc gen. Maybe there's another way, or we'd want to keep these in the docs.
const updateVal = (el) => (el.value = votePercentage(el.owner, el.item));
// TODO Figure out a way around this default setting.
// In defaults preds and quizzes, the precent/progress is hidden until
// results/finish phase.
const updateVisibility = (el) => {
    let phase = el.owner.phase;
    el.owner.defaultVoteHide
        ? hideInitialWidget.some((v) => v === el.owner.widgetPayload.kind) &&
            (el.style.visibility =
                (phase === 'results' || phase === 'finished') &&
                    isVisibleInResultPhase(el)
                    ? 'visible'
                    : 'hidden')
        : (el.style.visibility =
            phase === 'ready' ||
                ((phase === 'interactive' || el.owner.hideVoteInResults) &&
                    !(el.owner.selectedOption && el.owner.selectedOption.id))
                ? 'hidden'
                : 'visible');
};
export const PercentCalcMixin = (superclass) => class extends superclass {
    constructor() {
        super(...arguments);
        this.ownerUpdated = (e) => (e.name === 'phase' || e.name === 'selectedOption') &&
            updateVisibility(this);
        this.optionUpdated = (e) => {
            updateVal(this);
        };
    }
    static get properties() {
        return {
            /**
             * The value of the vote percentage
             */
            value: { type: Number, reflect: true },
        };
    }
    firstUpdated() {
        updateVal(this);
        updateVisibility(this);
    }
};

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html } from 'lit-element';
import { element } from '../../utils/decorators';
import { SocialEmbedBase } from './SocialEmbedBase';
import { socialEmbedStyles } from './styles/socialEmbedStyles';
import { rightChevron, leftChevron } from '../../icons';
import { defaultHeader } from '../../utils/templates';
import { WidgetKind } from '../../constants/Widgets';
let LiveLikeSocialEmbed = class LiveLikeSocialEmbed extends SocialEmbedBase {
    constructor() {
        super(...arguments);
        this.kind = WidgetKind.SOCIAL_EMBED;
        this.isInteractable = false;
    }
    render() {
        const multi = this.items.length > 1;
        const leftRightButtons = html `
      <div class="button-wrapper">
        <button class="left-button slide-button" @click=${this.movePrev}>
          ${leftChevron}
        </button>
        <button class="right-button slide-button" @click=${this.moveNext}>
          ${rightChevron}
        </button>
      </div>
    `;
        const pageDots = html `
      <form class="dot-nav-container">
        ${this.items.map((v, i) => html `
              <input
                class="dot-input"
                id="dot${i}"
                name="dot"
                type="radio"
                @click=${() => this.moveToSlide(i)}
              />
            `)}
      </form>
    `;
        return html `
      <template>
        ${socialEmbedStyles}
        <livelike-widget-root
          style="opacity: ${this.scriptsLoaded && this.finalLoad ? 1 : 0}"
        >
          ${defaultHeader}
          <livelike-widget-body>
            <div class="slider-wrapper">
              ${multi ? leftRightButtons : null}
              <div class="slider">
                ${this.items.length > 0 &&
            this.items.map((item, idx) => html `
                      <div
                        id="embed${idx}"
                        class="slide ${idx === 0 ? 'initial' : ''}"
                      ></div>
                    `)}
              </div>
              ${multi ? pageDots : null}
            </div>
          </livelike-widget-body>
        </livelike-widget-root>
      </template>
    `;
    }
};
LiveLikeSocialEmbed = __decorate([
    element('livelike-social-embed')
], LiveLikeSocialEmbed);
export { LiveLikeSocialEmbed };

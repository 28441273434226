import { _$$ } from '../scripts/internal/globals';
import { isDate, convertDate, programDateTimeSort } from '../utils';
export function queueBase(syncStrategy) {
    let queueArray = [];
    return {
        queueArray,
        hasNextItem: () => queueArray.length > 0,
        dequeueItem: () => queueArray.shift(),
        enqueueItem: (args, cb) => {
            const { payload } = args;
            const idx = queueArray.findIndex((m) => m.payload.id === payload.id);
            if (idx === -1) {
                queueArray.push(args);
                syncStrategy &&
                    syncStrategy.currentTimecode &&
                    queueArray.sort((a, b) => programDateTimeSort(a.payload, b.payload));
                cb && cb();
            }
        },
        isNextItemReady: () => {
            if (!syncStrategy || !syncStrategy.currentTimecode)
                return true;
            if (syncStrategy.ready)
                return syncStrategy.ready(queueArray[0].payload);
            const pdt = queueArray[0].payload.program_date_time &&
                isDate(queueArray[0].payload.program_date_time) &&
                new Date(convertDate(queueArray[0].payload.program_date_time));
            const syncTime = isDate(syncStrategy.currentTimecode) &&
                new Date(convertDate(syncStrategy.currentTimecode));
            if (_$$.logger) {
                // console.log('syncTime: ', syncTime.toUTCString());
                console.log('syncTime >= pdt', syncTime >= pdt);
            }
            if (pdt && syncTime)
                return syncTime >= pdt;
            return true;
        },
    };
}

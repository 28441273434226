import { getAppResource, iteratorBase, paginator, request } from '../utils';
/**
 * @description Get list of reward action
 * @async
 * @since 2.31.0
 * @example
 * ```js
 * getRewardActions().then(({results}) => console.log(results))
 * ```
 */
export function getRewardActions() {
    return getAppResource().then((app) => {
        return iteratorBase(['results'], paginator(`${app.reward_actions_url}?client_id=${app.client_id}`, (url) => request.get({ url }), (r) => ({ count: r.count, results: r.results })));
    });
}
/**
 * @description Get list of invoked reward action
 * @async
 * @since 2.31.0
 * @example
 * ```js
 * getInvokedRewardActions({
 *   programId: "xxx-yyy-zzz"
 * }).then(({results}) => console.log(results))
 * ```
 */
export function getInvokedRewardActions(args) {
    return getAppResource().then((app) => {
        var _a;
        let queryParams = [];
        if (args === null || args === void 0 ? void 0 : args.programId) {
            queryParams.push(`program_id=${args.programId}`);
        }
        if ((_a = args === null || args === void 0 ? void 0 : args.rewardActionKeys) === null || _a === void 0 ? void 0 : _a.length) {
            args.rewardActionKeys.forEach((rewardActionKey) => queryParams.push(`reward_action_key=${rewardActionKey}`));
        }
        const urlQueryParams = queryParams.length
            ? '?' + queryParams.join('&')
            : '';
        const invokedRewardActionUrl = `${app.invoked_actions_url}${urlQueryParams}`;
        return iteratorBase(['results'], paginator(invokedRewardActionUrl, (url) => request.get({ url }), (r) => ({ count: r.count, results: r.results })));
    });
}

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, property } from 'lit-element';
import { element } from '../../utils/decorators';
import { styles } from './livelike-emoji-slider.style';
import { defaultHeader } from '../../utils/templates';
import { getMilliseconds, eventDispatcher, kebabToTitle } from '../../utils';
import { track } from '../../utils/analytics';
import { WidgetKind } from '../../constants/Widgets';
import { ThemedWidget } from '../../mixins';
/**
 * @noinheritdoc
 */
let LiveLikeEmojiSlider = class LiveLikeEmojiSlider extends ThemedWidget {
    constructor() {
        super(...arguments);
        this.owner = this;
        this.kind = WidgetKind.EMOJI_SLIDER;
        this.defaultStyles = styles;
        this.val = null;
        this.sliderVoteSubmitted = false;
        this.setThumbWidth = () => {
            this.imageEl && this.imageEl.offsetWidth > 0
                ? this.inputContainer.style.setProperty('--thumb-width', this.imageEl.offsetWidth + 'px')
                : setTimeout(this.setThumbWidth, 10);
        };
        /**
         * @ignore
         */
        this.changeVal = () => {
            this.interactionEvent();
            this.updateVal();
        };
        // When the slider is moved, this.val is set to the slider val. Options array is looped through,
        //  and if the val is >= to the `max` number, the corresponding image is shown on the slider thumb.
        this.updateVal = () => {
            if (this.inputEl) {
                let newVal = +this.inputEl.value;
                if (this.val !== newVal) {
                    this.inputContainer.style.setProperty('--val', (this.val = newVal));
                    for (let item of this.options) {
                        if (this.val >= item.max && this.imageEl.src !== item.image_url) {
                            this.imageEl.src = item.image_url;
                            this.setThumbWidth();
                        }
                    }
                }
            }
        };
        this.resultEvent = () => {
            super.resultEvent();
            this.updateComplete.then(() => {
                //For embedded sliders
                if (this.interacted && !this.sliderVoteSubmitted) {
                    this.lockInVote(this.val / 100);
                }
            });
        };
        this.onResults = (e) => {
            if (e.message.average_magnitude) {
                this.average_magnitude = e.message.average_magnitude;
                eventDispatcher(this, e.event, e.message.average_magnitude);
            }
        };
        this.interactive = (args) => new Promise((resolve) => this.updateComplete.then(() => {
            this.phase = 'interactive';
            this.disabled = false;
            const trackObj = {
                'Widget Type': kebabToTitle(this.widgetPayload.kind),
                'Widget ID': this.widgetPayload.id,
            };
            track('Widget Became Interactive', trackObj, this.programid);
            let delay = args && args.timeout !== undefined
                ? args.timeout
                : this.widgetPayload.timeout &&
                    getMilliseconds(this.widgetPayload.timeout);
            this.timeout = delay;
            delay !== null &&
                setTimeout(() => {
                    if (this.interacted && !this.sliderVoteSubmitted) {
                        this.lockInVote(this.val / 100).then(() => setTimeout(resolve, 2000));
                    }
                    else {
                        if (!this.interacted && !this.sliderVoteSubmitted) {
                            this.val = null;
                        }
                        setTimeout(resolve, 2000);
                    }
                }, this.timeout);
        }));
    }
    get inputContainer() {
        return this.querySelector('.input-form');
    }
    get inputEl() {
        return this.querySelector('.slider-input');
    }
    get imageEl() {
        return this.querySelector('.slider-image');
    }
    connectedCallback() {
        super.connectedCallback().then(() => {
            if (this.interaction && this.interaction.magnitude) {
                this.sliderVoteSubmitted = true;
            }
            this.updateComplete.then(() => {
                if (this.inputEl) {
                    this.inputEl.addEventListener('change', this.changeVal);
                    this.inputEl.addEventListener('input', this.updateVal);
                }
                // This "mag" is the magnitude value of each item.
                // It is the number of items, broken up in to percentages
                // The max is the value "between" each item.
                // For example, take a 4 item slider:
                // mag:      0      33.33    66.66     100
                //       |  img1  |  img2  |  img3  |  img4  |
                // max:  0        25       50       75
                this.options = this.options.map((item, idx) => (Object.assign(Object.assign({}, item), { mag: idx === 0 ? 0 : (idx / (this.options.length - 1)) * 100, max: (idx / this.options.length) * 100 })));
                this.updateVal();
            });
        });
    }
    updated(changedProps) {
        super.updated(changedProps);
        changedProps.has('isStockWidget') &&
            this.showUserVoteInstantly === undefined &&
            (this.showUserVoteInstantly = this.isStockWidget);
    }
    lockInVote(mag) {
        return new Promise((resolve, reject) => {
            if (!this.voteDisable && mag >= 0 && mag <= 1) {
                this.showUserVoteInstantly && this.updateAverageMagnitude(mag);
                this.voteDisable = true;
                this.createVote(this.widgetPayload.vote_url, {
                    magnitude: mag.toString(),
                }).then(() => {
                    this.sliderVoteSubmitted = true;
                    this.disabled = true;
                    resolve(true);
                });
            }
            else {
                reject(new Error('Magnitude not a number between 0 and 1.'));
            }
        });
    }
    updateAverageMagnitude(mag) {
        const totalMagnitude = this.engagement_count * parseFloat(this.average_magnitude);
        const newAverage = (totalMagnitude + mag) / (this.engagement_count + 1);
        this.average_magnitude = newAverage;
    }
    render() {
        const initialMag = Math.round((this.interaction
            ? this.interaction.magnitude
            : this.widgetPayload.initial_magnitude) * 100);
        const resultMark = this.phase !== 'ready' &&
            this.phase !== 'interactive' &&
            String(this.val) !== 'null'
            ? html `
            <div
              class="result-mark"
              style="left: calc(${Math.round(this.average_magnitude * 100)}% - 0.5rem)"
            ></div>
          `
            : null;
        const votedLabel = this.sliderVoteSubmitted
            ? html `<span class="voted-text"
          >${this.owner.localize('widget.slider.votedText')}</span
        >`
            : null;
        const sponsor = this.widgetPayload.sponsors && this.widgetPayload.sponsors.length
            ? html `
            <div class="sponsorship">
              ${this.owner.localize('widget.sponsors.label')}
              <img
                src="${this.widgetPayload.sponsors[0].logo_url}"
                alt="${this.widgetPayload.sponsors[0].name}"
              />
            </div>
          `
            : null;
        return html `
      <template>
        <livelike-widget-root>
          ${defaultHeader}
          <livelike-widget-body>
            <form style="--val: ${initialMag};" class="input-form">
              <div class="input-container">
                <input
                  type="range"
                  class="slider-input"
                  value="${initialMag}"
                  ?disabled="${this.disabled}"
                />
                ${resultMark}
              </div>
              <output class="slider-thumb">
                <img class="slider-image" />
              </output>
            </form>
            <livelike-footer>
              <button
                class="vote-button"
                @click=${() => this.lockInVote(this.val / 100)}
                ?disabled="${this.sliderVoteSubmitted ||
            this.voteDisable ||
            this.disabled}"
              >
                ${this.owner.localize('widget.slider.voteButton.label')}
              </button>
              ${votedLabel} ${sponsor}
            </livelike-footer>
          </livelike-widget-body>
        </livelike-widget-root>
      </template>
    `;
    }
};
__decorate([
    property({ type: Number })
], LiveLikeEmojiSlider.prototype, "val", void 0);
__decorate([
    property({ type: String })
], LiveLikeEmojiSlider.prototype, "average_magnitude", void 0);
__decorate([
    property({ type: Boolean })
], LiveLikeEmojiSlider.prototype, "sliderVoteSubmitted", void 0);
LiveLikeEmojiSlider = __decorate([
    element('livelike-emoji-slider')
], LiveLikeEmojiSlider);
export { LiveLikeEmojiSlider };

import { convertToCssString as cs } from '../../utils/themeConversions';
export const styles = (theme) => {
    return `
    .only-media{
      border-radius: 6px;
    }
    .no-header{
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
    }
    .no-footer{
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
    }
    .video-alert-caption-container {
      padding: 0.6rem 0.8rem;
    }
    .video-alert-caption {
      word-break: break-word;
      word-wrap: break-word;
      padding: 0;
      width: 100%;
    }
    .video-alert-link {
      word-wrap: break-word;
      background-color: #444;
      display: block;
      font-weight: bolder;
      padding: 0.5rem 1rem;
      position: relative;
      text-decoration: none;
      border-radius: 0 0 6px 6px;
      ${cs(theme.footer)}
    }
    .video-alert-link:after {
      content: '';
      background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2280px%22%20height%3D%22100px%22%20viewBox%3D%220%200%2080%20100%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3C!--%20Generator%3A%20Sketch%2050%20(54983)%20-%20http%3A%2F%2Fwww.bohemiancoding.com%2Fsketch%20--%3E%0A%20%20%20%20%3Ctitle%3Esort-down%3C%2Ftitle%3E%0A%20%20%20%20%3Cdesc%3ECreated%20with%20Sketch.%3C%2Fdesc%3E%0A%20%20%20%20%3Cdefs%3E%3C%2Fdefs%3E%0A%20%20%20%20%3Cg%20id%3D%22Page-1%22%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%20%20%20%20%20%20%20%20%3Cg%20id%3D%22sort-down%22%20transform%3D%22translate(40.000000%2C%2050.000000)%20rotate(-90.000000)%20translate(-40.000000%2C%20-50.000000)%20translate(-10.000000%2C%2010.000000)%22%20fill%3D%22%23FFFFFF%22%20fill-rule%3D%22nonzero%22%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M98.1437396%2C2.63850125%20C96.9076009%2C0.879013967%2095.4422941%2C0%2093.7491877%2C0%20L6.25047031%2C0%20C4.55667973%2C0%203.09239915%2C0.879013967%201.85523426%2C2.63850125%20C0.61806938%2C4.39993433%200%2C6.48242398%200%2C8.88986179%20C0%2C11.2968131%200.61806938%2C13.3793028%201.85523426%2C15.1392765%20L45.604764%2C77.359553%20C46.843297%2C79.1190402%2048.3075776%2C80%2050%2C80%20C51.6924224%2C80%2053.1580712%2C79.1190402%2054.3942099%2C77.359553%20L98.1437396%2C15.1387901%20C99.3795363%2C13.3793028%20100%2C11.2968131%20100%2C8.88937534%20C100%2C6.48242398%2099.3795363%2C4.39993433%2098.1437396%2C2.63850125%20Z%22%20id%3D%22Shape%22%3E%3C%2Fpath%3E%0A%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E');
      background-position: center right 0.5rem;
      background-size: 0.5rem;
      background-repeat: no-repeat;
      position: absolute;
      width: calc(100% - 2rem);
      height: calc(100% - 1rem);
      left: 0;
      top: 0;
      z-index: 0;
      padding: 0.5rem 1rem;
    }

    *[dir="rtl"] .video-alert-link {
      background-position: center left 0.5rem;
    }
    *[dir="rtl"] .video-alert-link:after {
      transform: scaleX(-1);
    }

    `;
};

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, property } from 'lit-element';
import { LiveLikeOptionChild } from '../../mixins/ThemedElement';
import { styles } from './livelike-description.style';
import { element } from '../../utils/decorators';
/**
 * Renders `option`/`choice` description property. Can be overriden by setting `description` property.
 * @element livelike-description
 * @slot Default slot
 */
let LiveLikeDescription = class LiveLikeDescription extends LiveLikeOptionChild {
    constructor() {
        super(...arguments);
        this.defaultStyles = styles;
    }
    defaultTemplate() {
        return html `
      <slot>
        ${this.description ||
            (this.item && this.item.description) ||
            this.owner.text}
      </slot>
    `;
    }
};
__decorate([
    property({ type: String })
], LiveLikeDescription.prototype, "description", void 0);
LiveLikeDescription = __decorate([
    element('livelike-description')
], LiveLikeDescription);
export { LiveLikeDescription };

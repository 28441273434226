// @ts-strict
import { getAppResource, iteratorBase, paginator, request, buildUrl, } from '../utils';
import { QuestRewardStatus, } from '../types';
import { userProfile, _$$ } from './internal/globals';
import { validateArgObj } from '../utils/validateArgs';
/**
 * Gets Quests available in an application.
 * @async
 * @since 2.27.0
 * @example
 * ```js
 * getQuests({ questIds }).then(padinatedResponse => console.log(padinatedResponse))
 * ```
 */
export const getQuests = (args) => getAppResource()
    .then((app) => {
    let quest_url = app.quests_url;
    if (args && args.questIds && args.questIds.length > 0) {
        args.questIds.forEach((id) => {
            if (id && id.length > 0) {
                quest_url += `&id=${id}`;
            }
        });
    }
    return iteratorBase(['results'], paginator(quest_url, (url) => request.get({ url }), (r) => ({
        count: r.count,
        results: r.results,
    })));
})
    .then((paginatedQuests) => {
    // cache the quests
    paginatedQuests.results.forEach((quest) => {
        _$$.quests[quest.id] = quest;
    });
    return paginatedQuests;
});
/**
 * Start a quest for current user.
 * @async
 * @since 2.27.0
 * @example
 * ```js
 * startUserQuest({ questId }).then(userQuest => console.log(userQuest))
 * ```
 */
export const startUserQuest = (args) => validateArgObj(args, ['questId'], 'startUserQuest').then(() => getAppResource().then((app) => {
    let user_quest_url = app.user_quests_url;
    return request.post({
        url: user_quest_url,
        data: {
            quest_id: args.questId,
            profile_id: userProfile.id,
        },
    });
}));
/**
 * Gets Quests of a user(complete/incomplete).
 * @async
 * @since 2.27.0
 * @example
 * ```js
 * getUserQuests({ profileId }).then(padinatedResponse => console.log(padinatedResponse))
 * ```
 */
export const getUserQuests = (args) => validateArgObj(args, ['profileId'], 'getUserQuests')
    .then(() => getAppResource())
    .then((app) => {
    let user_quest_url = buildUrl(args.profileId, app.user_quests_url_template);
    if (args.status) {
        user_quest_url += `&status=${args.status}`;
    }
    if (args.rewardStatus) {
        user_quest_url += `&rewards_status=${args.rewardStatus}`;
    }
    if (args.userQuestIds && args.userQuestIds.length > 0) {
        args.userQuestIds.forEach((id) => {
            if (id && id.length > 0) {
                user_quest_url += `&id=${id}`;
            }
        });
    }
    return iteratorBase(['results'], paginator(user_quest_url, (url) => request.get({ url }), (r) => ({
        count: r.count,
        results: r.results,
    })));
})
    .then((paginatedUserQuests) => {
    // cache the user quests
    paginatedUserQuests.results.forEach((userQuest) => {
        _$$.userQuests[userQuest.id] = userQuest;
    });
    return paginatedUserQuests;
});
/**
 * Complete a quest task for current user.
 * @async
 * @since 2.27.0
 * @example
 * ```js
 * updateUserQuestTasks({ userQuestId, userQuestTaskIds, status: "completed" }).then(userQuest => console.log(userQuest))
 * ```
 */
export const updateUserQuestTasks = (args) => validateArgObj(args, ['userQuestId', 'userQuestTaskIds', 'status'], 'updateUserQuestTasks').then(() => getAppResource().then((app) => {
    let user_quest_url = buildUrl(args.userQuestId, app.user_quest_detail_url_template);
    let data = [];
    args.userQuestTaskIds.forEach((id) => {
        if (id && id.length > 0) {
            data.push({
                id,
                status: args.status,
            });
        }
    });
    return request.patch({
        url: user_quest_url,
        data: {
            user_quest_tasks: data,
        },
    });
}));
/**
 * Increment user quest quest progress for current user.
 * @async
 * @since 2.28.0
 * @example
 * ```js
 * incrementUserQuestTaskProgress({ userQuestTaskId, customIncrement }).then(userQuestTask => console.log(userQuestTask))
 * ```
 */
export const incrementUserQuestTaskProgress = (args) => validateArgObj(args, ['userQuestTaskId'], 'incrementUserQuestTaskProgress').then(() => getAppResource().then((app) => request
    .post({
    url: app.user_quest_task_progress_url,
    data: {
        user_quest_task_id: args.userQuestTaskId,
        custom_increment: args.customIncrement,
    },
})
    .then((res) => {
    return res.user_quest;
})));
/**
 * Update user quest quest progress (to an absolute value) for current user.
 * @async
 * @since 2.28.0
 * @example
 * ```js
 * setUserQuestTaskProgress({ userQuestTaskId, customProgress }).then(userQuestTask => console.log(userQuestTask))
 * ```
 */
export const setUserQuestTaskProgress = (args) => validateArgObj(args, ['userQuestTaskId', 'customProgress'], 'setUserQuestTaskProgress').then(() => getAppResource().then((app) => request
    .post({
    url: app.user_quest_task_progress_url,
    data: {
        user_quest_task_id: args.userQuestTaskId,
        custom_progress: args.customProgress,
    },
})
    .then((res) => {
    return res.user_quest;
})));
/**
 * Get Quest rewards for a given quest using questId.
 * @async
 * @since 2.30.0
 * @example
 * ```js
 * getQuestRewards({ questId: "1936bf69-caea-481e-a376-46929d32da4f" })
 *  .then(padinatedResponse => console.log(padinatedResponse))
 * ```
 */
export const getQuestRewards = (args) => validateArgObj(args, ['questId'], 'getQuestRewards')
    .then(() => getQuestResource(args.questId))
    .then((quest) => {
    const questRewardUrl = `${quest.quest_rewards_url}?quest_id=${args.questId}`;
    return iteratorBase(['results'], paginator(questRewardUrl, (url) => request.get({ url }), (r) => ({
        count: r.count,
        results: r.results,
    })));
});
/**
 * Get User quest rewards for a given user quest using userQuestId.
 * @async
 * @since 2.30.0
 * @example
 * ```js
 * getUserQuestRewards({ userQuestId: "3936bf69-caea-481e-a376-46929d32da4f" })
 *  .then(padinatedResponse => console.log(padinatedResponse))
 * ```
 */
export const getUserQuestRewards = (args) => validateArgObj(args, ['userQuestId'], 'getUserQuestRewards')
    .then(() => getUserQuestResource(args.userQuestId))
    .then((userQuest) => {
    let userQuestRewardUrl = `${userQuest.user_quest_rewards_url}?user_quest_id=${args.userQuestId}`;
    if (args.rewardStatus) {
        userQuestRewardUrl += `&reward_status=${args.rewardStatus}`;
    }
    return iteratorBase(['results'], paginator(userQuestRewardUrl, (url) => request.get({ url }), (r) => ({
        count: r.count,
        results: r.results,
    })));
});
/**
 * Claim a User quest rewards using userQuestId.
 * @async
 * @since 2.30.0
 * @example
 * ```js
 * claimUserQuestRewards({ userQuestId: "3936bf69-caea-481e-a376-46929d32da4f" })
 *  .then(userQuestTask => console.log(userQuestTask))
 * ```
 */
export const claimUserQuestRewards = (args) => validateArgObj(args, ['userQuestId'], 'claimUserQuestRewards')
    .then(() => getAppResource())
    .then((app) => request.patch({
    url: buildUrl(args.userQuestId, app.user_quest_detail_url_template),
    data: {
        rewards_status: QuestRewardStatus.CLAIMED,
    },
}));
/**
 * @description Internal API which returns cached Quest detail,
 * if not found, fetches + updates the Quest cache and returns the Quest detail
 * @ignore
 */
function getQuestResource(questId, cache = true) {
    const cachedQuest = _$$.quests[questId];
    if (cachedQuest && cache) {
        return Promise.resolve(cachedQuest);
    }
    return getQuests({ questIds: [questId] }).then((paginatedQuestsRes) => {
        var _a;
        const questDetail = (_a = paginatedQuestsRes === null || paginatedQuestsRes === void 0 ? void 0 : paginatedQuestsRes.results) === null || _a === void 0 ? void 0 : _a[0];
        if (!questDetail) {
            return Promise.reject(`Quest Detail not found for questId=${questId}`);
        }
        return questDetail;
    });
}
/**
 * @description Internal API which returns cached UserQuest detail,
 * if not found, fetches + updates the UserQuest cache and returns the UserQuest detail
 * @ignore
 */
function getUserQuestResource(userQuestId, cache = true) {
    const cachedUserQuest = _$$.userQuests[userQuestId];
    if (cachedUserQuest && cache) {
        return Promise.resolve(cachedUserQuest);
    }
    return getUserQuests({
        userQuestIds: [userQuestId],
        profileId: userProfile.id,
    }).then((paginatedUserQuestsRes) => {
        var _a;
        const userQuestDetail = (_a = paginatedUserQuestsRes === null || paginatedUserQuestsRes === void 0 ? void 0 : paginatedUserQuestsRes.results) === null || _a === void 0 ? void 0 : _a[0];
        if (!userQuestDetail) {
            return Promise.reject(`UserQuest Detail not found for userQuestId=${userQuestId}`);
        }
        return userQuestDetail;
    });
}

import { generateUUID } from './internal/pubnubHandler';
import { userProfile } from './internal/globals';
import { trackMessage } from '../utils/analytics';
import { getChatRoomResource, downloadFile, uploadImageToImgurl, request, } from '../utils';
import { ChatMessageEvent } from '../constants/ChatEvents';
export const chatMessagePrep = {};
/**
 * @ignore
 */
export const createBaseMessagePayload = (args) => {
    return {
        chat_room_id: args.roomId,
        client_message_id: generateUUID(),
        sender_id: userProfile.id,
        sender_nickname: userProfile.nickname,
        sender_image_url: args.sender_image_url || null,
        sender_profile_url: userProfile.url,
        badge_image_url: null,
        program_date_time: args.timestamp || null,
        message: args.message || null,
        image_width: ~~args.image_width || null,
        image_height: ~~args.image_height || null,
        image_url: args.image_url || null,
        message_metadata: args.message_metadata || null,
        message_event: args.image_url
            ? ChatMessageEvent.IMAGE_CREATED
            : ChatMessageEvent.MESSAGE_CREATED,
    };
};
function prepareAndSendMessage(payload, chatRoom) {
    const { id: roomId, chatroom_messages_url } = chatRoom;
    chatMessagePrep[roomId] && (payload = chatMessagePrep[roomId](payload));
    trackMessage(payload, roomId, 'Chat Message Sent');
    return request
        .post({ url: chatroom_messages_url, data: payload })
        .then((res) => res)
        .catch((err) => {
        console.error('Error: ' + err + ' Message failed to send.');
        return Promise.reject(err);
    });
}
function sendImageMessage(payload, chatRoom) {
    return downloadFile(payload.image_url) // download file from resource
        .then((blob) => uploadImageToImgurl(chatRoom.upload_url, blob))
        .then(function (data) {
        payload.image_url = data.image_url;
        return prepareAndSendMessage(payload, chatRoom);
    })
        .catch(function (error) {
        console.error('Error in sending image ' + error.message ? error.message : error);
        return Promise.reject(error);
    });
}
/**
 * @ignore
 */
export function sendBaseMessage(payload, roomId) {
    return getChatRoomResource(roomId).then((chatRoom) => payload.image_url
        ? sendImageMessage(payload, chatRoom)
        : prepareAndSendMessage(payload, chatRoom));
}

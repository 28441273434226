export var Phase;
(function (Phase) {
    Phase["READY"] = "ready";
    Phase["INTERACTIVE"] = "interactive";
    Phase["FINISHED"] = "finished";
    Phase["RESULTS"] = "results";
    Phase["POINTS"] = "points";
    Phase["BADGE"] = "badge";
})(Phase || (Phase = {}));
export const widgetEndPhase = [Phase.RESULTS, Phase.FINISHED];
export const widgetKindVote = {
    'emoji-slider': 'slider',
    'cheer-meter': 'cheer',
    'text-poll': 'vote',
    'image-poll': 'vote',
    'text-quiz': 'answer',
    'image-quiz': 'answer',
    'text-prediction': 'prediction',
    'image-prediction': 'prediction',
    'text-ask': 'reply',
    'text-number-prediction': 'number-prediction',
    'image-number-prediction': 'number-prediction',
};
export const WIDGET_RESULTS = {
    EMOJI_SLIDER: 'emoji-slider-results',
    CHEER_METER: 'cheer-meter-results',
    TEXT_POLL: 'text-poll-results',
    IMAGE_POLL: 'image-poll-results',
    TEXT_QUIZ: 'text-quiz-results',
    IMAGE_QUIZ: 'image-quiz-results',
};
export const WidgetStyleName = {
    alert: 'alert',
    'text-poll': 'poll',
    'image-poll': 'poll',
    'text-quiz': 'quiz',
    'image-quiz': 'quiz',
    'cheer-meter': 'cheerMeter',
    'social-embed': 'socialEmbed',
    'rich-post': 'richText',
    'text-prediction': 'prediction',
    'image-prediction': 'prediction',
    'text-prediction-follow-up': 'predictionFollowUp',
    'image-prediction-follow-up': 'predictionFollowUp',
    'emoji-slider': 'imageSlider',
    'text-ask': 'textAsk',
    'video-alert': 'videoAlert',
    'text-number-prediction': 'numberPrediction',
    'image-number-prediction': 'numberPrediction',
    'text-number-prediction-follow-up': 'numberPredictionFollowUp',
    'image-number-prediction-follow-up': 'numberPredictionFollowUp',
};
export var WidgetKind;
(function (WidgetKind) {
    WidgetKind["ALERT"] = "alert";
    WidgetKind["EMOJI_SLIDER"] = "emoji-slider";
    WidgetKind["SOCIAL_EMBED"] = "social-embed";
    WidgetKind["RICH_POST"] = "rich-post";
    WidgetKind["CHEER_METER"] = "cheer-meter";
    WidgetKind["TEXT_POLL"] = "text-poll";
    WidgetKind["IMAGE_POLL"] = "image-poll";
    WidgetKind["TEXT_QUIZ"] = "text-quiz";
    WidgetKind["IMAGE_QUIZ"] = "image-quiz";
    WidgetKind["TEXT_PREDICTION"] = "text-prediction";
    WidgetKind["TEXT_PREDICITON_FOLLOW_UP"] = "text-prediction-follow-up";
    WidgetKind["IMAGE_PREDICTION"] = "image-prediction";
    WidgetKind["IMAGE_PREDICITON_FOLLOW_UP"] = "image-prediction-follow-up";
    WidgetKind["TEXT_ASK"] = "text-ask";
    WidgetKind["VIDEO_ALERT"] = "video-alert";
    WidgetKind["TEXT_NUMBER_PREDICTION"] = "text-number-prediction";
    WidgetKind["TEXT_NUMBER_PREDICTION_FOLLOW_UP"] = "text-number-prediction-follow-up";
    WidgetKind["IMAGE_NUMBER_PREDICTION"] = "image-number-prediction";
    WidgetKind["IMAGE_NUMBER_PREDICTION_FOLLOW_UP"] = "image-number-prediction-follow-up";
})(WidgetKind || (WidgetKind = {}));
export var WidgetCreated;
(function (WidgetCreated) {
    WidgetCreated["ALERT"] = "alert-created";
    WidgetCreated["EMOJI_SLIDER"] = "emoji-slider-created";
    WidgetCreated["SOCIAL_EMBED"] = "social-embed-created";
    WidgetCreated["RICH_POST"] = "rich-post-created";
    WidgetCreated["CHEER_METER"] = "cheer-meter-created";
    WidgetCreated["TEXT_POLL"] = "text-poll-created";
    WidgetCreated["IMAGE_POLL"] = "image-poll-created";
    WidgetCreated["TEXT_QUIZ"] = "text-quiz-created";
    WidgetCreated["IMAGE_QUIZ"] = "image-quiz-created";
    WidgetCreated["TEXT_PREDICTION"] = "text-prediction-created";
    WidgetCreated["TEXT_PREDICITON_FOLLOWUP"] = "text-prediction-follow-up-updated";
    WidgetCreated["IMAGE_PREDICTION"] = "image-prediction-created";
    WidgetCreated["IMAGE_PREDICITON_FOLLOWUP"] = "image-prediction-follow-up-updated";
    WidgetCreated["TEXT_ASK"] = "text-ask-created";
    WidgetCreated["VIDEO_ALERT"] = "video-alert-created";
    WidgetCreated["TEXT_NUMBER_PREDICTION"] = "text-number-prediction-created";
    WidgetCreated["TEXT_NUMBER_PREDICTION_FOLLOWUP"] = "text-number-prediction-follow-up-updated";
    WidgetCreated["IMAGE_NUMBER_PREDICTION"] = "image-number-prediction-created";
    WidgetCreated["IMAGE_NUMBER_PREDICTION_FOLLOWUP"] = "image-number-prediction-follow-up-updated";
})(WidgetCreated || (WidgetCreated = {}));
export const WidgetCreatedEvents = Object.values(WidgetCreated);
export const WidgetResults = [
    WIDGET_RESULTS.EMOJI_SLIDER,
    WIDGET_RESULTS.CHEER_METER,
    WIDGET_RESULTS.TEXT_POLL,
    WIDGET_RESULTS.IMAGE_POLL,
    WIDGET_RESULTS.TEXT_QUIZ,
    WIDGET_RESULTS.IMAGE_QUIZ,
];
export const interactiveWidgets = [
    WidgetKind.CHEER_METER,
    WidgetKind.EMOJI_SLIDER,
    WidgetKind.TEXT_POLL,
    WidgetKind.IMAGE_POLL,
    WidgetKind.TEXT_QUIZ,
    WidgetKind.IMAGE_QUIZ,
    WidgetKind.TEXT_PREDICTION,
    WidgetKind.TEXT_PREDICITON_FOLLOW_UP,
    WidgetKind.IMAGE_PREDICTION,
    WidgetKind.IMAGE_PREDICITON_FOLLOW_UP,
    WidgetKind.TEXT_ASK,
    WidgetKind.TEXT_NUMBER_PREDICTION,
    WidgetKind.TEXT_NUMBER_PREDICTION_FOLLOW_UP,
    WidgetKind.IMAGE_NUMBER_PREDICTION,
    WidgetKind.IMAGE_NUMBER_PREDICTION_FOLLOW_UP,
];

import { request, paginator, iteratorBase, getAppResource, buildUrl, } from '../utils';
import { validateArgObj } from '../utils/validateArgs';
import { getReactionSpaceResource } from './reactionSpace';
/**
 * @description Add a user reaction
 * @async
 * @since 2.29.0
 * @example
 * ```js
 * addUserReaction({
 *  targetId: "target-1",
 *  reactionSpaceId: "aa7e03fc-01f0-4a98-a2e0-3fed689632d7",
 *  reactionId: "0fddc166-b8c3-4ce9-990e-848bde12188b"
 * }).then(reaction => console.log(reaction))
 * ```
 */
export function addUserReaction(arg) {
    return validateArgObj(arg, ['targetId', 'reactionSpaceId', 'reactionId'], 'addUserReaction')
        .then(() => getReactionSpaceResource(arg.reactionSpaceId))
        .then(({ user_reactions_url }) => {
        const { reactionSpaceId, targetId, reactionId } = arg;
        return request.post({
            url: user_reactions_url,
            data: {
                reaction_space_id: reactionSpaceId,
                target_id: targetId,
                reaction_id: reactionId,
            },
        });
    });
}
/**
 * @description Remove a user reaction
 * @async
 * @since 2.29.0
 * @example
 * ```js
 * removeUserReaction({
 *  reactionSpaceId: "aa7e03fc-01f0-4a98-a2e0-3fed689632d7",
 *  userReactionId: "0fddc166-b8c3-4ce9-990e-848bde12188b"
 * })
 * ```
 */
export function removeUserReaction(arg) {
    return validateArgObj(arg, ['userReactionId', 'reactionSpaceId'], 'removeUserReaction')
        .then(() => getReactionSpaceResource(arg.reactionSpaceId))
        .then(({ user_reaction_detail_url_template }) => {
        return request.delete({
            url: buildUrl(arg.userReactionId, user_reaction_detail_url_template),
        });
    });
}
function getAppAndReactionSpaceResource(reactionSpaceId) {
    return Promise.all([
        getAppResource(),
        getReactionSpaceResource(reactionSpaceId),
    ]);
}
/**
 * @description Get list of user reaction based on argument object filters
 * @async
 * @since 2.29.0
 * @example
 * ```js
 * getUserReactions({
 *  reactionSpaceId: "aa7e03fc-01f0-4a98-a2e0-3fed689632d7",
 *  targetId: "0fddc166-b8c3-4ce9-990e-848bde12188b"
 * }).then(paginatedReactions => console.log(paginatedReactions))
 * ```
 */
export function getUserReactions(arg) {
    return validateArgObj(arg, ['reactionSpaceId'], 'getUserReactions', () => {
        if (!(arg === null || arg === void 0 ? void 0 : arg.targetId) && !(arg === null || arg === void 0 ? void 0 : arg.reactionId)) {
            return 'Argument object should contain either "targetId" or "reactionId" property.';
        }
    })
        .then(() => getAppAndReactionSpaceResource(arg.reactionSpaceId))
        .then(([{ client_id }, { user_reactions_url }]) => {
        const { targetId, reactionId, reactionSpaceId } = arg;
        const queryParams = [
            `client_id=${client_id}`,
            reactionSpaceId && `reaction_space_id=${reactionSpaceId}`,
            targetId && `target_id=${targetId}`,
            reactionId && `reaction_id=${reactionId}`,
        ]
            .filter(Boolean)
            .join('&');
        const reactionsUrl = `${user_reactions_url}?${queryParams}`;
        return iteratorBase(['results'], paginator(reactionsUrl, (url) => request.get({ url }), (r) => ({ count: r.count, results: r.results })));
    });
}
/**
 * @description get count of user reactions for a given collection of target in a reaction space
 * @async
 * @since 2.29.0
 * @example
 * ```js
 * getUserReactionsCount({
 *  reactionSpaceId: "aa7e03fc-01f0-4a98-a2e0-3fed689632d7",
 *  targetIds: ["0fddc166-b8c3-4ce9-990e-848bde12188b"],
 * }).then(reaction => console.log(reaction))
 * ```
 */
export function getUserReactionsCount(arg) {
    return validateArgObj(arg, ['reactionSpaceId', 'targetIds'], 'getUserReactionsCount', () => {
        if (!Array.isArray(arg === null || arg === void 0 ? void 0 : arg.targetIds)) {
            return 'Argument object "targetIds" property value should be an array.';
        }
    })
        .then(() => getReactionSpaceResource(arg.reactionSpaceId))
        .then(({ user_reactions_count_url }) => {
        const queryParams = [
            `reaction_space_id=${arg.reactionSpaceId}`,
            ...arg.targetIds.map((targetId) => `target_id=${targetId}`),
        ].join('&');
        const reactionsUrl = `${user_reactions_count_url}?${queryParams}`;
        return iteratorBase(['results'], paginator(reactionsUrl, (url) => request.get({ url }), (r) => ({ count: r.count, results: r.results })));
    });
}

// @ts-strict
export var ChatRoomEvent;
(function (ChatRoomEvent) {
    ChatRoomEvent["ADD_NEW_MEMBER"] = "chat-room-add";
    ChatRoomEvent["INVITE_NEW_MEMBER"] = "chat-room-invite";
    ChatRoomEvent["PIN_MESSAGE"] = "message-pinned";
    ChatRoomEvent["UNPIN_MESSAGE"] = "message-unpinned";
    ChatRoomEvent["CHATROOM_UPDATED"] = "chatroom-updated";
})(ChatRoomEvent || (ChatRoomEvent = {}));
export var RewardEvent;
(function (RewardEvent) {
    RewardEvent["REWARD_ITEM_TRANSFER_RECEIVED"] = "reward-item-transfer-received";
})(RewardEvent || (RewardEvent = {}));
export var UserProfileEvent;
(function (UserProfileEvent) {
    UserProfileEvent["BLOCK_PROFILE"] = "block-profile";
    UserProfileEvent["UNBLOCK_PROFILE"] = "unblock-profile";
})(UserProfileEvent || (UserProfileEvent = {}));
export var ReactionSpaceEvent;
(function (ReactionSpaceEvent) {
    ReactionSpaceEvent["ADD_REACTION"] = "user-reaction-added";
    ReactionSpaceEvent["REMOVE_REACTION"] = "user-reaction-removed";
    ReactionSpaceEvent["UPDATE_REACTION_SPACE"] = "reaction-space-updated";
})(ReactionSpaceEvent || (ReactionSpaceEvent = {}));
export default {
    ChatRoomEvent,
    RewardEvent,
    UserProfileEvent,
    ReactionSpaceEvent,
};

import { getAppResource, docLink, pageVisHandler, defaultStorageStrategy, } from '../utils';
import { loadWCPolyfill } from '../utils/loadAsyncScripts';
import { _$$, _config, userProfile } from './internal/globals';
import { subscriptionCoordinator } from '../utils/subscriptionCoordinator';
import { applyTheme } from './applyTheme';
import { applyLocalization } from './applyLocalization';
import { createUserProfile, getUserProfile } from './userProfile';
import { setAnalyticsProvider } from '../utils/analytics';
import { getSdkEventListeners } from './sdkEventListener';
import { SdkEvent } from '../../shared-types';
const invokeSdkInitialisedEventListeners = (_userProfile) => {
    const listeners = getSdkEventListeners(SdkEvent.INITIALISED);
    listeners.forEach((_listener) => {
        try {
            _listener(_userProfile);
        }
        catch (e) {
            console.error('Error while calling sdk initialised listener', e);
        }
    });
};
const initProfile = (accessToken, nickname) => {
    if (accessToken) {
        return getUserProfile({ accessToken });
    }
    else {
        let storedProfile = _$$.storageStrategy.get();
        return storedProfile
            ? getUserProfile({ accessToken: storedProfile.access_token })
            : createUserProfile({ nickname });
    }
};
export const init = (args) => new Promise((resolve) => {
    let alreadyInit = _$$.ready && _$$.clientId && userProfile;
    alreadyInit &&
        console.error('LiveLike EngagementSDK has already been initialized!');
    if (!alreadyInit) {
        loadWCPolyfill();
        pageVisHandler();
    }
    !args.accessToken &&
        console.warn(`LiveLike EngagementSDK has been initialized without an accessToken.\n ${docLink()}`);
    args.endpoint && (_$$.endpoint = args.endpoint);
    _$$.ready = false;
    _$$.logger = args.logger || null;
    _$$.clientId = args.clientId;
    _$$.accessToken = args.accessToken || null;
    _$$.lsKey = 'livelike-user-auth-' + args.clientId;
    args.syncStrategy && (_$$.syncStrategy = args.syncStrategy);
    _$$.storageStrategy = args.storageStrategy || defaultStorageStrategy;
    args.localizedStrings && applyLocalization(args.localizedStrings);
    args.analyticsProvider && setAnalyticsProvider(args.analyticsProvider);
    applyTheme(args.theme || {}).then(() => getAppResource().then((app) => {
        _$$.programUrlTemplate = app.program_detail_url_template;
        _$$.chatUrlTemplate = app.chat_room_detail_url_template;
        _$$.leaderboardUrlTemplate = app.leaderboard_detail_url_template;
        _$$.createChatUrlTemplate = app.create_chat_room_url;
        _$$.profileDetailUrlTemplate = app.profile_detail_url_template;
        _$$.chatRoomInvitationDetailUrlTemplate =
            app.chat_room_invitation_detail_url_template;
        _$$.chatRoomInvitationsUrl = app.chat_rooms_invitations_url;
        _$$.profileUrl = app.profile_url;
        _$$.pinnedMessagesUrl = app.pinned_messages_url;
        initProfile(args.accessToken, args.nickName).then((profile) => {
            _$$.storageStrategy.set(profile);
            Object.assign(userProfile, profile);
            const currentPnConfig = {
                subscribeKey: app.pubnub_subscribe_key,
                origin: app.pubnub_origin,
                authKey: profile.access_token,
                heartbeatInterval: app.pubnub_heartbeat_interval,
                presenceTimeout: app.pubnub_presence_timeout,
                publishKey: args.publishKey || app.pubnub_publish_key,
            };
            const complete = () => {
                _$$.ready = true;
                _config.ready = true;
                return Promise.resolve(true);
            };
            const initialise = (pnConfig) => {
                subscriptionCoordinator.initialisePubnub(pnConfig);
                return complete();
            };
            const configChanged = _$$.pnConfig &&
                Object.keys(currentPnConfig).findIndex((key) => _$$.pnConfig[key] !== currentPnConfig[key]) !== -1;
            const shouldReInitialise = _$$.pn && configChanged;
            if (!_$$.pnConfig || configChanged) {
                _$$.pnConfig = currentPnConfig;
            }
            shouldReInitialise
                ? initialise(currentPnConfig)
                : complete()
                    .then(() => invokeSdkInitialisedEventListeners(profile))
                    .then(() => resolve(profile));
        });
    }));
});

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, property } from 'lit-element';
import { element } from '../../utils/decorators';
import { eventDispatcher } from '../../utils';
import { closeIconSvg, sendIcon } from '../../icons';
import { styles, composerStyles } from './livelike-chat-composer.style.js';
import '../livelike-sticker-picker';
import '../livelike-giphy-picker';
import { LiveLikeChatElement } from '../../mixins/ChatElement';
import { UIEvent } from '../../constants/UIEvent';
import '../livelike-quote-message-item';
let LiveLikeChatComposer = class LiveLikeChatComposer extends LiveLikeChatElement {
    constructor() {
        super(...arguments);
        this.defaultStyles = styles;
        // State
        this.message = '';
        // State
        this.quoteMessage = undefined;
        this.ownerUpdated = (e) => {
            e.name === 'placeholder' && (this.placeholder = e.prop);
            (e.name === 'lang' || e.name === 'translations') &&
                this.updateLocalizedPlaceholder();
        };
        this.updateLocalizedPlaceholder = () => {
            if (!this.placeholder) {
                const placeholder = this.owner.localize('chat.inputPlaceholder');
                this.inputEl.placeholder = placeholder;
                this.inputEl.setAttribute('aria-label', placeholder);
            }
            this.sendBtn.setAttribute('aria-label', this.owner.localize('chat.sendButton.ariaLabel'));
        };
        this.sendClickHandler = () => {
            // Send message event
            if (!!this.message) {
                eventDispatcher(this, 'sendChatMessage', {
                    message: this.message,
                    quoteMessage: this.quoteMessage,
                });
                this.message = '';
                this.removeQuoteMessage();
                this.inputEl.value = '';
                this.resize('40px');
            }
        };
        this.addSticker = (e) => {
            if (e.detail.sticker &&
                e.detail.sticker.shortcode &&
                this.message.length + e.detail.sticker.shortcode.length <= 200) {
                this.message = this.message + `:${e.detail.sticker.shortcode}:`;
                this.inputEl.value = this.message;
                this.inputEl.focus();
            }
        };
        this.handleMessage = (e) => {
            if (e.target.value !== undefined) {
                this.message = e.target.value;
            }
            this.resize(e.target.value === '' ? '40px' : null);
        };
        this.handleKeyDown = (e) => {
            if (e.which === 13 && !e.shiftKey) {
                e.preventDefault();
                this.sendClickHandler();
            }
        };
        this.resize = (initialHeight) => {
            this.inputEl.style.height =
                initialHeight || `${this.inputEl.scrollHeight}px`;
        };
        this.handleQuoteEvent = (e) => {
            const eventStatus = e.detail.status;
            if (eventStatus === 'add' && this.owner.messagequotes) {
                this.quoteMessage = e.detail.message;
                this.toggleQuoteMessageEl('show');
                this.inputEl.focus();
            }
            else if (eventStatus === 'remove' &&
                this.quoteMessage &&
                (e.detail.senderId === this.quoteMessage.sender_id ||
                    e.detail.messageId === this.quoteMessage.id)) {
                this.removeQuoteMessage();
            }
        };
        this.removeQuoteMessage = () => {
            this.quoteMessage = undefined;
            this.toggleQuoteMessageEl('hide');
        };
        this.toggleQuoteMessageEl = (state) => {
            if (state === 'show') {
                this.querySelector('.livelike-composer-quote-message').style.display =
                    'flex';
                const composerQuoteMessageEl = this.querySelector('livelike-quote-message-item');
                composerQuoteMessageEl.message = this.quoteMessage;
                composerQuoteMessageEl.showavatar = this.owner.showavatar;
            }
            else {
                this.querySelector('.livelike-composer-quote-message').style.display =
                    'none';
                this.querySelector('livelike-quote-message-item').message = undefined;
            }
        };
    }
    get inputEl() {
        return this.querySelector('[slot=body]');
    }
    get sendBtn() {
        return this.querySelector('[slot=send]');
    }
    updated(changedProps) {
        changedProps.forEach((prevProp, name) => {
            if (name === 'placeholder') {
                this.inputEl.placeholder = this.placeholder;
                this.inputEl.setAttribute('aria-label', this.placeholder);
            }
        });
    }
    connectedCallback() {
        super.connectedCallback();
        this.addEventListener('sticker-selected', this.addSticker);
        this.owner.addEventListener(UIEvent.QUOTE_MESSAGE, this.handleQuoteEvent);
        const localizedPlaceholder = this.placeholder ||
            this.owner.placeholder ||
            this.owner.localize('chat.inputPlaceholder');
        this.insertAdjacentHTML('beforeend', `<div slot="quote-message" class="livelike-composer-quote-message" style="display: none;">
        <livelike-quote-message-item></livelike-quote-message-item>
        <div class="livelike-compose-quote-message-cancel">${closeIconSvg}</div>
      </div>`);
        this.insertAdjacentHTML('beforeend', `<textarea slot="body" class="livelike-chat-input" maxlength="200" placeholder="${localizedPlaceholder}" aria-label="${localizedPlaceholder}"></textarea>`);
        this.insertAdjacentHTML('beforeend', `<div slot="body" class="sticker-picker-container">
        <livelike-sticker-picker></livelike-sticker-picker>
        <livelike-giphy-picker></livelike-giphy-picker>
      </div>`);
        this.insertAdjacentHTML('beforeend', `<button slot="send" aria-label="${this.owner.localize('chat.sendButton.ariaLabel')}" class="send-icon">
      ${sendIcon}
    </button>`);
        this.querySelector('.livelike-compose-quote-message-cancel').onclick =
            this.removeQuoteMessage;
    }
    disconnectedCallback() {
        this.removeEventListener('sticker-selected', this.addSticker);
        this.owner.removeEventListener(UIEvent.QUOTE_MESSAGE, this.handleQuoteEvent);
        super.disconnectedCallback();
    }
    render() {
        return html `
      ${composerStyles}
      <slot name="quote-message"></slot>
      <div class="livelike-chat-composer-input">
        <slot
          name="body"
          @input=${this.handleMessage}
          @keydown=${this.handleKeyDown}
        ></slot>
        <slot @click=${this.sendClickHandler} name="send"></slot>
      </div>
      <slot></slot>
    `;
    }
};
__decorate([
    property({ type: String, reflect: true })
], LiveLikeChatComposer.prototype, "placeholder", void 0);
__decorate([
    property({ type: String })
], LiveLikeChatComposer.prototype, "message", void 0);
__decorate([
    property({ type: Object })
], LiveLikeChatComposer.prototype, "quoteMessage", void 0);
LiveLikeChatComposer = __decorate([
    element('livelike-chat-composer')
], LiveLikeChatComposer);
export { LiveLikeChatComposer };

import { convertToCssString as cs } from '../../utils/themeConversions';
export const styles = (theme) => {
    let str = `
    :host {
      display:flex;
    }
    .bar-container {
      font-size: 14px;
      padding: .25rem 0;
      transition: all 0.4s ease;
      text-align: center;
    }
    .bar0{
      background: linear-gradient(to left, #3023ae, #53a0fd);
      ${cs(theme.sideABar)}
    }
    .bar1{
      background: linear-gradient(to right, #9f041b, #f5515f);
      ${cs(theme.sideBBar)}
    }
  `;
    return str;
};

import { convertToCssString as cs } from '../../utils/themeConversions';
export const styles = (theme) => {
    let str = `
    :host {
      height: 2rem;
      position: absolute;
      border-radius: 3px;
      transition: ease width 0.4s;
      background: rgba(255, 255, 255, 0.12);
      ${cs(theme.unselectedOptionBar)}
    }
    :host([selected]) {
      ${cs(theme.selectedOptionBar)}
    }
    :host([hidden]) {
      display: none;
    }
  `;
    theme.correctOption &&
        (str += `
      :host([correct]) {
        ${cs(theme.correctOptionBar)}
      }
      :host([incorrect]) {
        ${cs(theme.incorrectOptionBar)}
      }
  `);
    return str;
};

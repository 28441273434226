export const styles = `
    livelike-load-more-button button{
      display: flex;
      justify-content: center;
      width:100%;
      height: auto;
      border: none;
      border-radius: 0.25rem;
      background: #086fac;
      font-size: 1rem;
      color: #fff;
      padding: 0.75rem;
      margin:1rem 0 0 0;
    }

    livelike-load-more-button button:hover {
      cursor: pointer;
    }

    livelike-load-more-button .loading-spinner {
      position: relative;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      border: 4px solid rgba(255, 255, 255, 0.1);
      border-top: 4px solid #fff;
      animation: rotating 1.2s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);
    }

    @keyframes rotating {
      100% {
        transform: rotate(360deg);
      }
    }
`;

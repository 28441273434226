export var VideoRoomEvent;
(function (VideoRoomEvent) {
    VideoRoomEvent["PARTICIPANT_JOIN"] = "PARTICIPANT_JOIN";
    VideoRoomEvent["PARTICIPANT_LEFT"] = "PARTICIPANT_LEFT";
    VideoRoomEvent["ERROR"] = "ERROR";
    VideoRoomEvent["RECONNECTING"] = "RECONNECTING";
})(VideoRoomEvent || (VideoRoomEvent = {}));
export var VideoRoomConnectionState;
(function (VideoRoomConnectionState) {
    VideoRoomConnectionState["CONNECTED"] = "CONNECTED";
    VideoRoomConnectionState["DISCONNECTED"] = "DISCONNECTED";
    VideoRoomConnectionState["RECONNECTING"] = "RECONNECTING";
})(VideoRoomConnectionState || (VideoRoomConnectionState = {}));
export var VideoRoomError;
(function (VideoRoomError) {
    VideoRoomError["USER_ALREADY_EXIST"] = "USER_ALREADY_EXIST";
    VideoRoomError["CONNECTION_LOST"] = "CONNECTION_LOST";
    VideoRoomError["INVALID_VIDEO_ROOM_ID"] = "INVALID_VIDEO_ROOM_ID";
    VideoRoomError["EXPIRED_VIDEO_ROOM_ID"] = "EXPIRED_VIDEO_ROOM_ID";
    VideoRoomError["MAX_PARTICIPANT_LIMIT_REACHED"] = "MAX_PARTICIPANT_LIMIT_REACHED";
    VideoRoomError["VIDEO_PERMISSION_DENIED"] = "VIDEO_PERMISSION_DENIED";
    VideoRoomError["AUDIO_PERMISSION_DENIED"] = "AUDIO_PERMISSION_DENIED";
    VideoRoomError["PLUGIN_ERROR"] = "PLUGIN_ERROR";
    VideoRoomError["UNABLE_TO_CREATE_VIDEO_ROOM"] = "UNABLE_TO_CREATE_VIDEO_ROOM";
    VideoRoomError["UNABLE_TO_JOIN_VIDEO_ROOM"] = "UNABLE_TO_JOIN_VIDEO_ROOM";
})(VideoRoomError || (VideoRoomError = {}));
export var VideoRoomBannerType;
(function (VideoRoomBannerType) {
    VideoRoomBannerType["INFO"] = "INFO";
    VideoRoomBannerType["ERROR"] = "ERROR";
    VideoRoomBannerType["WARNING"] = "WARNING";
})(VideoRoomBannerType || (VideoRoomBannerType = {}));

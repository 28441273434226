import { html } from 'lit-element';
export const messageItemStyles = html `
  <style>
    :host {
      overflow: auto;
    }

    .message-item-container {
      position: relative;
      margin: 0 0 0.5rem 0;
      display: flex;
      justify-content: flex-start;
    }

    .message-item-container > .menu-button-container > .more-icon {
      opacity: 0;
      transition: opacity ease 0.3s;
    }

    .message-item-container:hover > .menu-button-container > .more-icon {
      opacity: 1;
      transition: opacity ease 0.3s;
    }

    .message-item-container .quote-message-item-container {
      margin: 0 0 0.3rem 0;
    }

    .menu-button-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .more-icon:focus {
      opacity: 0;
      transition: opacity ease 0.3s;
    }

    .message-item-container > .menu-button-container > .more-icon.active {
      opacity: 1;
    }

    .more-icon.active {
      opacity: 1;
    }

    .message-wrapper {
      display: inline-grid;
      opacity: 1;
      transition: all 0.4s;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 0.75rem;
      padding: 0.5rem 1rem;
      max-width: 80%;
      position: relative;
    }
    .message-wrapper:focus {
      outline: none;
    }

    .avatar {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin: 0 0.5rem 0 0;
    }

    .avatar img,
    .avatar svg {
      width: 3rem;
      height: 3rem;
      margin: 0;
      border-radius: 50%;
    }

    .sender-name {
      font-size: 1rem;
      display: inline-block;
      color: inherit;
      margin: 0 0 0.25rem 0;
      word-break: break-word;
      word-wrap: break-word;
    }

    .sender-message {
      font-size: 1rem;
      display: flex;
      word-break: break-word;
      word-wrap: break-word;
      color: inherit;
      line-height: 1.3;
      padding: 0rem 0.25rem 0.25rem 0;
      flex-wrap: wrap;
    }

    .sender-message.deleted {
      font-style: oblique;
      color: inherit;
    }

    .word-item {
      align-self: flex-end;
    }

    .image-message-container {
      max-width: 480px;
    }

    .image-message {
      max-width: 100%;
      height: auto;
      border-radius: 0.5rem;
    }

    .message-timestamp {
      font-size: 0.8rem;
    }

    .more-icon {
      background: none;
      border: none;
      margin: 1rem 0;
      display: flex;
      align-items: center;
      padding: 0 1rem;
    }
    .more-icon:hover {
      cursor: pointer;
    }
    .more-icon svg {
      fill: #777;
    }
    .more-icon:hover svg {
      fill: #555;
    }
    .no-message-container {
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
    }
    .no-messages {
      margin: 0;
      color: #fff;
      position: absolute;
      top: 50%;
    }
  </style>
`;

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html } from 'lit-element';
import { element } from '../../utils/decorators';
import { ThemedElement } from '../../mixins/ThemedElement';
import { styles } from './livelike-widget-footer.style';
// TODO Add slot doc comment
/**
 * @element livelike-widget-footer
 */
let LiveLikeWidgetFooter = class LiveLikeWidgetFooter extends ThemedElement {
    constructor() {
        super(...arguments);
        this.defaultStyles = styles;
    }
    defaultTemplate() {
        return html ` <slot></slot> `;
    }
};
LiveLikeWidgetFooter = __decorate([
    element('livelike-widget-footer')
], LiveLikeWidgetFooter);
export { LiveLikeWidgetFooter };

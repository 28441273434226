// @ts-strict
import { getAppResource, iteratorBase, paginator, request, buildUrl, } from '../utils';
import { validateArgObj } from '../utils/validateArgs';
import { RedemptionKeyStatus, } from '../types';
/**
 * @description Redeem Key by passing the code
 * @async
 * @since 2.17.0
 * @example
 * ```js
 * redeemKeyByCode({
 *  code: "xxxxxxxxx"
 * }).then(redemptionKey => console.log(redemptionKey))
 * ```
 */
export const redeemKeyByCode = (args) => validateArgObj(args, ['code'], 'redeemKeyByCode').then(() => getAppResource().then((app) => request.patch({
    url: buildUrl(args.code, app.redemption_key_detail_by_code_url_template),
    data: {
        status: RedemptionKeyStatus.REDEEMED,
    },
})));
/**
 * @description Redeem Key by passing the Id of Redemption Key Object
 * @async
 * @since 2.17.0
 * @example
 * ```js
 * redeemKeyById({
 *  redemptionKeyId: "xxxxxxxxx"
 * }).then(redemptionKey => console.log(redemptionKey))
 * ```
 */
export const redeemKeyById = (args) => validateArgObj(args, ['redemptionKeyId'], 'redeemKeyById').then(() => getAppResource().then((app) => request.patch({
    url: buildUrl(args.redemptionKeyId, app.redemption_key_detail_url_template),
    data: {
        status: RedemptionKeyStatus.REDEEMED,
    },
})));
/**
 * @description Get list of Redemption Keys
 * @async
 * @since 2.17.0
 * @example
 * ```js
 * getRedemptionKeys()
 * .then(paginatedRedemptionKeys => console.log(paginatedRedemptionKeys))
 * ```
 */
export const getRedemptionKeys = (args) => getAppResource().then((app) => {
    let redemptionKeysUrl = app.redemption_keys_url;
    if (args && args.status) {
        redemptionKeysUrl += '?&status=' + args.status;
    }
    return iteratorBase(['results'], paginator(redemptionKeysUrl, (url) => request.get({ url }), (r) => ({
        count: r.count,
        results: r.results,
    })));
});

import { request, paginator, iteratorBase, getAppResource, buildUrl, } from '../utils';
import { validateArgObj, validateArgs } from '../utils/validateArgs';
import { _$$ } from './internal/globals';
import { subscriptionCoordinator } from '../utils/subscriptionCoordinator';
/**
 * @description Create a reaction space
 * @async
 * @since 2.29.0
 * @example
 * ```js
 * createReactionSpace({
 *  targetGroupId: "target-group-1",
 *  reactionPackIds: ["aa7e03fc-01f0-4a98-a2e0-3fed689632d7", "0fddc166-b8c3-4ce9-990e-848bde12188b"]
 * }).then(reactionSpace => console.log(reactionSpace))
 * ```
 */
export function createReactionSpace(arg) {
    return validateArgObj(arg, ['targetGroupId', 'reactionPackIds'], 'createReactionSpace')
        .then(() => getAppResource())
        .then((app) => {
        const reactionSpaceUrl = app.reaction_spaces_url;
        const { name, reactionPackIds, targetGroupId } = arg;
        return request.post({
            url: reactionSpaceUrl,
            data: {
                name,
                target_group_id: targetGroupId,
                reaction_pack_ids: reactionPackIds,
            },
        });
    });
}
/**
 * @description Update a reaction space with reaction pack ids or reaction space name
 * @async
 * @since 2.29.0
 * @example
 * ```js
 * updateReactionSpace({
 *  reactionSpaceId: "aa7e03fc-01f0-4a98-a2e0-3fed689632d7",
 *  reactionPackIds: ["aa7e03fc-01f0-4a98-a2e0-3fed689632d7", "0fddc166-b8c3-4ce9-990e-848bde12188b"]
 * }).then(reactionSpace => console.log(reactionSpace))
 * ```
 */
export function updateReactionSpace(arg) {
    return validateArgObj(arg, ['reactionSpaceId'], 'updateReactionSpace', () => {
        if (!(arg === null || arg === void 0 ? void 0 : arg.reactionPackIds) && !(arg === null || arg === void 0 ? void 0 : arg.name)) {
            return `Argument object is missing "reactionPackIds" or "name".`;
        }
    })
        .then(() => getAppResource())
        .then((app) => {
        const { name, reactionPackIds } = arg;
        return request.patch({
            url: buildUrl(arg.reactionSpaceId, app.reaction_space_detail_url_template),
            data: {
                name,
                reaction_pack_ids: reactionPackIds,
            },
        });
    });
}
/**
 * @description Delete a reaction space
 * @async
 * @since 2.29.0
 * @example
 * ```js
 * deleteReactionSpace({
 *  reactionSpaceId: "aa7e03fc-01f0-4a98-a2e0-3fed689632d7",
 * })
 * ```
 */
export function deleteReactionSpace(arg) {
    return validateArgObj(arg, ['reactionSpaceId'], 'deleteReactionSpace')
        .then(() => getAppResource())
        .then((app) => {
        return request.delete({
            url: buildUrl(arg.reactionSpaceId, app.reaction_space_detail_url_template),
        });
    });
}
/**
 * @description Get list of reaction spaces in an application
 * @async
 * @since 2.29.0
 * @example
 * ```js
 * getReactionSpaces().then(({results}) => console.log(results))
 * ```
 */
export function getReactionSpaces() {
    return getAppResource().then((app) => {
        return iteratorBase(['results'], paginator(`${app.reaction_spaces_url}?client_id=${app.client_id}`, (url) => request.get({ url }), (r) => ({ count: r.count, results: r.results })));
    });
}
/**
 * @description Get reaction space detail from reactionSpaceId or targetGroupId
 * @async
 * @since 2.29.0
 * @example
 * ```js
 * getReactionSpaceDetail({
 *  reactionSpaceId: "aa7e03fc-01f0-4a98-a2e0-3fed689632d7",
 * }).then(reactionSpace => console.log(reactionSpace))
 *
 * OR
 *
 * getReactionSpaceDetail({
 *  targetGroupId: "target-group-1",
 * }).then(reactionSpace => console.log(reactionSpace))
 *
 * ```
 */
export function getReactionSpaceDetail(arg) {
    return validateArgObj(arg, [], 'getReactionSpaceDetail', () => {
        if (!(arg === null || arg === void 0 ? void 0 : arg.reactionSpaceId) && !(arg === null || arg === void 0 ? void 0 : arg.targetGroupId)) {
            return `Argument object is missing "reactionSpaceId" or "targetGroupId" property.`;
        }
    })
        .then(() => getAppResource())
        .then((app) => {
        if (arg.reactionSpaceId) {
            return request.get({
                url: buildUrl(arg.reactionSpaceId, app.reaction_space_detail_url_template),
            });
        }
        return request
            .get({
            url: `${app.reaction_spaces_url}?client_id=${app.client_id}&target_group_id=${arg.targetGroupId}`,
        })
            .then(({ results }) => {
            if (!(results === null || results === void 0 ? void 0 : results[0])) {
                return Promise.reject({ message: 'Resource not found.' });
            }
            return results[0];
        });
    })
        .then((reactionSpaceDetail) => {
        _$$.reactionSpaces[reactionSpaceDetail.id] = reactionSpaceDetail;
        return reactionSpaceDetail;
    });
}
/**
 * @description Add listener for a given event type occuring in a reactionSpaceId
 * @async
 * @since 2.29.0
 * @example
 * ```js
 *  function onUserReaction(eventDetail){
 *   console.log(eventDetail);
 *  }
 * addReactionSpaceEventListener({
 *    event: ReactionSpaceEvent.ADD_REACTION,
 *    reactionSpaceId: "aa7e03fc-01f0-4a98-a2e0-3fed689632d7"
 *  },
 * onUserReaction
 * )
 * ```
 */
export function addReactionSpaceEventListener(eventArg, listenerFn) {
    return validateArgs([eventArg, listenerFn], [['event', 'reactionSpaceId'], 'function'], 'addReactionSpaceEventListener')
        .then(() => getReactionSpaceResource(eventArg.reactionSpaceId))
        .then(({ reaction_space_channel }) => {
        subscriptionCoordinator.addListener(reaction_space_channel, listenerFn, eventArg.event);
        subscriptionCoordinator.subscribe(reaction_space_channel);
    });
}
/**
 * @description Remove listener for a given event type occuring in a reactionSpaceId
 * if no listener function passed, remove all the listeners function for a given event type
 * @async
 * @since 2.29.0
 * @example
 * ```js
 * function onUserReaction(eventDetail){
 *   console.log(eventDetail);
 * }
 * removeReactionSpaceEventListener({
 *    event: ReactionSpaceEvent.ADD_REACTION,
 *    reactionSpaceId: "aa7e03fc-01f0-4a98-a2e0-3fed689632d7"
 *  },
 * onUserReaction
 * )
 * ```
 */
export function removeReactionSpaceEventListener(eventArg, listenerFn) {
    return validateArgObj(eventArg, ['event', 'reactionSpaceId'], 'removeReactionSpaceEventListener')
        .then(() => getReactionSpaceResource(eventArg.reactionSpaceId))
        .then(({ reaction_space_channel }) => {
        const { event } = eventArg;
        if (listenerFn) {
            subscriptionCoordinator.removeListener(reaction_space_channel, listenerFn, event);
        }
        else {
            subscriptionCoordinator.removeAllListenersForEvent(reaction_space_channel, event);
        }
        subscriptionCoordinator.unsubscribe(reaction_space_channel);
    });
}
/**
 * @description Internal API which returns cached reaction space detail,
 * if not found, updates reaction space cache and returns the reaction space detail
 * @ignore
 */
export function getReactionSpaceResource(reactionSpaceId, cache = true) {
    const cachedReactionSpace = _$$.reactionSpaces[reactionSpaceId];
    if (cachedReactionSpace && cache) {
        return Promise.resolve(cachedReactionSpace);
    }
    return getReactionSpaceDetail({ reactionSpaceId }).then((reactionSpaceDetail) => {
        // cache reaction space resource
        _$$.reactionSpaces[reactionSpaceId] = reactionSpaceDetail;
        return reactionSpaceDetail;
    });
}

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, property } from 'lit-element';
import { element } from '../utils/decorators';
import '../components/livelike-load-more-button';
import { generateUUID } from '../scripts/internal/pubnubHandler';
import { getWidget, registerWidgetMode, getPaginatedWidgets, getWidgetInteractions, getPaginatedUnclaimedRewards, } from '../scripts/widgets';
import { getMode } from '../scripts/internal/widgetMode';
import { _$$, _config } from '../scripts/internal/globals';
import { queueBase } from '../utils/queueBase';
import { getLang, selfChildCheck, eventDispatcher, popupMode, timelineMode, interactiveTimelineMode, kebabToTitle, typeErr, getProgramResource, messageSequence, getSyncStrategy, updateWithQueue, waitForSyncStrat, addInteractionsToWidgets, iteratorBase, } from '../utils';
import { WidgetCreatedEvents, WidgetKind } from '../constants/Widgets';
import { track } from '../utils/analytics';
import { subscriptionCoordinator as sc } from '../utils/subscriptionCoordinator';
/**
 * @element livelike-widgets
 */
let LiveLikeWidgets = class LiveLikeWidgets extends LitElement {
    constructor() {
        super(...arguments);
        this.dataId = generateUUID();
        this.updating = false;
        this.hasWidgetAttached = false;
        this.sequence = 1;
        /**
         *  The mode of the element. Either "pop-up" or "timeline". Default is "pop-up"
         */
        this.mode = 'pop-up';
        /**
         * If the element has loaded
         */
        this.loaded = false;
        /**
         * Add to element to enabled listing the widget author tag in timeline mode
         */
        this.authors = false;
        /**
         * Add to element to enabled listing the widget timestamps tag in timeline mode
         */
        this.timestamps = false;
        /**
         * If true, widget reactions are shown once widget is not longer in 'ready' or 'interactive' phase
         */
        this.reactions = false;
        /**
         * Object containing time formatting properties.
         */
        this.timeformat = {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        };
        /**
         * Boolean to toggle displaying the widget's dismiss button
         */
        this.hide_dismiss_button = false;
        // TODO Temporary - need to find out reason why pubnub is sometimes republishing old messages.
        // Maybe related to `restore` prop, but doesn't happen all the time. Related issue in LiveLikeChat
        this.widgetListener = (e) => {
            if (WidgetCreatedEvents.some((v) => e.event === v)) {
                messageSequence(e.message.timetoken, this.sequence, this.programid).then((cache) => {
                    // console.log(this.dataId + 'message sequence', show)
                    this.sequence = cache.sequence;
                    if (cache.allowNext) {
                        let widgetPayload = this.onWidgetReceived(e.message);
                        widgetPayload &&
                            this.enqueueWidget({ mode: this.mode, widgetPayload });
                    }
                });
            }
        };
        this.connectWidget = () => {
            if (_$$.ready) {
                this.loaded = false;
                if (this.syncStrategy) {
                    //to re-initialize the queue
                    this.queue = queueBase(this.syncStrategy);
                }
                else {
                    this.syncStrategy = getSyncStrategy(_$$.syncStrategy);
                }
                return getProgramResource(this.programid).then((prog) => {
                    this.channel = prog.pubnub_enabled ? prog.subscribe_channel : null;
                    this.channel && sc.subscribe(this.channel);
                    //This whole segment needs to be moved to mode setup so that it can be handled for timeline as well as timeline v2
                    this.mode.includes('timeline')
                        ? this.loadTimeline(this.programid)
                        : (this.loaded = true);
                });
            }
            else {
                setTimeout(this.connectWidget, 1000);
            }
        };
        this.onInitialWidgetsLoaded = ({ widgets }) => {
            return widgets;
        };
        this.afterWidgetsLoaded = ({ widgets, interactionUrl }) => getWidgetInteractions({
            interactionUrl: interactionUrl,
        })
            .then((widgetInteractions) => {
            addInteractionsToWidgets({
                widgets,
                interactions: widgetInteractions,
            }).map(({ widgetPayload, interactions }) => {
                this.enqueueWidget({
                    mode: this.mode,
                    widgetPayload: widgetPayload,
                    initialLoad: true,
                    interactions: interactions,
                });
            });
        })
            .catch((err) => {
            widgets.map((widgetPayload) => {
                this.enqueueWidget({
                    mode: this.mode,
                    widgetPayload: widgetPayload,
                    initialLoad: true,
                });
            });
        });
        this.loadTimeline = (programId) => getPaginatedWidgets({ programId }).then((iterator) => {
            this.widgetPaginator = iterator;
            this.widgetPaginator
                .next()
                .then((res) => {
                waitForSyncStrat(this, () => {
                    let widgets = this.onInitialWidgetsLoaded({
                        widgets: res.value.widgets,
                    });
                    this.afterWidgetsLoaded({
                        widgets: widgets,
                        interactionUrl: res.value.widget_interactions_url_template,
                    });
                });
                this.loaded = true;
                !res.done &&
                    this.insertAdjacentHTML('beforeend', '<livelike-load-more-button slot="load-more-button"></livelike-load-more-button>');
            });
        });
        this.onMoreWidgetsLoaded = ({ widgets }) => {
            return widgets;
        };
        this.loadNextPage = () => this.widgetPaginator
            .next()
            .then((res) => {
            let widgets = this.onMoreWidgetsLoaded({
                widgets: res.value.widgets,
            });
            this.afterWidgetsLoaded({
                widgets: widgets,
                interactionUrl: res.value.widget_interactions_url_template,
            });
            return res.done;
        });
        this.updateEl = () => {
            const shouldShowWidget = () => this.mode === 'pop-up' && this.hasWidgetAttached ? false : true;
            updateWithQueue(this, shouldShowWidget, (args) => {
                if (args.payload &&
                    !this.querySelector(`[widgetid="${args.payload.id}"]`)) {
                    this.hasWidgetAttached = true;
                    this.showWidget({
                        mode: args.mode,
                        widgetPayload: args.payload,
                        initialLoad: args.initialLoad,
                        interactions: args.interactions,
                    });
                }
            });
        };
        /**
         * Registers widget mode
         */
        this.registerWidgetMode = (name, mode) => registerWidgetMode(name, mode);
        /**
         * Immediately begins widget lifecycle and state transitions.
         * @since 1.18.0
         */
        this.showWidget = (args) => this.start(args);
        /**
         * Adds widget to the widgetQueue for spoiler prevention
         * @since 1.18.0
         * @link spoilerPrevention
         */
        this.enqueueWidget = (args) => this.queue.enqueueItem({
            payload: args.widgetPayload,
            mode: args.mode,
            initialLoad: args.initialLoad,
            interactions: args.interactions,
        }, this.updateEl);
        /**
         * Creates and immediately displays widget.
         * @since 1.18.0
         * @example
         * ```js
         * const widgetEl = document.querySelector('livelike-widgets')\n
         * widgetEl.createWidgetElement({id: c1f6082c-f7b9-4d1e-b64e-9071a3860ffc, kind: 'text-poll'})
         * ```
         */
        this.createWidgetElement = (args) => getWidget({ id: args.id, kind: args.kind }).then((widgetPayload) => this.showWidget({
            widgetPayload,
            mode: args.mode,
            interactions: args.interactions,
        }));
        /**
         * Creates and queues widget from widget id and kind.
         *  Displays widget according to syncStrategy.
         * @see https://docs.livelike.com/docs/web-spoiler-free-sync
         * @since 1.18.0
         * @example
         * ```js
         * const widgetEl = document.querySelector('livelike-widgets')\n
         * widgetEl.createSyncWidget({id: c1f6082c-f7b9-4d1e-b64e-9071a3860ffc, kind: 'text-poll'})
         * ```
         */
        this.createSyncWidget = (args) => getWidget({ id: args.id, kind: args.kind }).then((widgetPayload) => this.enqueueWidget({
            widgetPayload,
            mode: args.mode,
            interactions: args.interactions,
        }));
        this.overRideTimer = ({ widget }) => {
            return null;
        };
        ///////////////////////////// lifecycle
        this.start = (args) => {
            eventDispatcher(this, 'beforewidgetattached', {
                widget: args.widgetPayload,
            });
            const mode = getMode(args.mode || this.mode);
            // Create widget, starts custom widget mode
            // console.log('start this.mode', this.mode);
            // console.log('args.mode', args.mode);
            return this.createWidget(args).then((widget) => widget &&
                mode({
                    target: this,
                    widget,
                    timer: this.overRideTimer({ widget }),
                }));
        };
        /**
         * @async
         * Attaches widget to DOM. Fires widgetattached events.
         * @fires widgetattached
         */
        this.attach = (widgetEl, position) => {
            const pos = position === 'append' ? 'beforeend' : 'afterbegin';
            this.insertAdjacentElement(pos, widgetEl);
            eventDispatcher(this, 'widgetattached', {
                element: widgetEl,
                widget: widgetEl.widgetPayload,
            });
            // TODO If it' livelike- widget it shoudl have the payload. Allows custom widgets
            // to be attached without errors. Should this be done a different way?
            if (widgetEl.widgetPayload) {
                // console.log('widgetEl.widgetPayload', widgetEl.widgetPayload)
                const trackObj = {
                    'Widget Type': kebabToTitle(widgetEl.widgetPayload.kind),
                    'Widget ID': widgetEl.widgetPayload.id,
                };
                if (widgetEl.widgetPayload.kind === WidgetKind.ALERT ||
                    widgetEl.widgetPayload.kind === WidgetKind.VIDEO_ALERT) {
                    // @ts-ignore
                    trackObj['Link URL'] = widgetEl.widgetPayload.link_url;
                }
                track('Widget Displayed', trackObj, this.programid);
            }
            return Promise.resolve();
        };
        /**
         * @async
         * Removes widget from DOM. Fires beforewidgetdetached and widgetdetached events. Removes listeners.
         * @fires beforewidgetdetached
         * @fires widgetdetached
         */
        this.detach = (widgetEl) => {
            // console.log('widgetEl', widgetEl)
            const obj = { element: widgetEl, widget: widgetEl.widgetPayload };
            if (widgetEl && this.contains(widgetEl)) {
                eventDispatcher(this, 'beforewidgetdetached', obj);
                widgetEl.remove();
                eventDispatcher(this, 'widgetdetached', obj);
                this.hasWidgetAttached = false;
            }
            return Promise.resolve();
        };
        this.createWidget = (args) => {
            const { widgetPayload, interactions } = args;
            const customWidget = this.customWidgetRenderer &&
                typeof this.customWidgetRenderer === 'function' &&
                this.customWidgetRenderer({ widgetPayload });
            let w = customWidget instanceof Element || customWidget instanceof Node
                ? customWidget
                : document.createElement('livelike-' + widgetPayload.kind);
            // TODO Maybe this isn't necessary - props should just be used through
            // this.widgetPayload so it's easier to know where everything is coming from.
            for (let prop in widgetPayload) {
                prop !== 'id' && prop !== 'attributes' && (w[prop] = widgetPayload[prop]);
            }
            w.widgetId = widgetPayload.id;
            w.setAttribute('widgetid', widgetPayload.id);
            w.widgetPayload = widgetPayload;
            w.interactions = interactions;
            interactions && (w.interaction = interactions[0]);
            w.reactions = this.reactions;
            w.dataId = this.dataId;
            w.programid = this.programid;
            w.authors = this.authors;
            w.timestamps = this.timestamps;
            w.timeformat = this.timeformat;
            w.lang = this.lang;
            if (this.customTemplateRenderer) {
                if (typeof this.customTemplateRenderer === 'function')
                    w.customTemplate = this.customTemplateRenderer;
                else
                    typeErr(this.localName, 'customTemplateRenderer', 'Function');
            }
            w.theme = _config.theme;
            w.hide_dismiss_button = this.hide_dismiss_button;
            if (args.initialLoad) {
                w.initialLoad = args.initialLoad; // TODO initialLoad isn't used anywhere anymore!
            }
            return Promise.resolve(w);
        };
        this.getUnclaimedRewards = () => {
            return getPaginatedUnclaimedRewards({ programId: this.programid }).then((iterator) => iteratorBase(['results'], iterator));
        };
    }
    firstUpdated() {
        selfChildCheck(this);
        getLang(this);
        registerWidgetMode('pop-up', popupMode);
        registerWidgetMode('timeline', timelineMode);
        registerWidgetMode('interactive-timeline', interactiveTimelineMode);
    }
    onWidgetReceived(widgetPayload) {
        return widgetPayload;
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.channel && sc.removeListener(this.channel, this.widgetListener);
    }
    updated(changedProps) {
        changedProps.forEach((prevProp, name) => {
            name === 'syncStrategy' &&
                this.syncStrategy &&
                (this.queue = queueBase(this.syncStrategy));
            const propEvt = (prop) => this[prop] !== undefined &&
                eventDispatcher(this, prop, { [prop]: true });
            (name === 'hasWidgetAttached' || name === 'loaded') && propEvt(name);
            if (name === 'channel' && this.channel) {
                if (prevProp) {
                    sc.removeListener(prevProp, this.widgetListener);
                    sc.unsubscribe(prevProp);
                }
                sc.addListener(this.channel, this.widgetListener);
            }
            const programId = name === 'programid' && this.programid && this.programid !== prevProp;
            const mode = name === 'mode' && prevProp !== undefined && this.mode !== prevProp;
            if (this.programid) {
                // this.channel && subscriptionCoordinator.unsubscribe(this.channel)
                if ((programId && prevProp !== undefined) || mode) {
                    while (this.firstChild) {
                        this.removeChild(this.firstChild);
                    }
                    !this.firstChild && (this.hasWidgetAttached = false);
                }
                if (!this.mode) {
                    this.mode = 'pop-up';
                }
                else if (programId || mode) {
                    this.connectWidget();
                }
            }
        });
    }
    render() {
        const widgetsContainer = this.loaded
            ? html `
          <slot></slot>
          <slot name="load-more-button"></slot>
        `
            : html ` <slot name="widgets-loading"></slot> `;
        return html `
      <style>
        :host {
          display: block;
        }
        livelike-widgets {
          display: block;
        }
      </style>
      ${widgetsContainer}
    `;
    }
};
__decorate([
    property({ type: Object })
], LiveLikeWidgets.prototype, "widgetPaginator", void 0);
__decorate([
    property({ type: String })
], LiveLikeWidgets.prototype, "channel", void 0);
__decorate([
    property({ type: String, reflect: true })
], LiveLikeWidgets.prototype, "programid", void 0);
__decorate([
    property({ type: String, reflect: true })
], LiveLikeWidgets.prototype, "lang", void 0);
__decorate([
    property({ type: String })
], LiveLikeWidgets.prototype, "mode", void 0);
__decorate([
    property({ type: Boolean })
], LiveLikeWidgets.prototype, "loaded", void 0);
__decorate([
    property({ type: Boolean })
], LiveLikeWidgets.prototype, "authors", void 0);
__decorate([
    property({ type: Boolean })
], LiveLikeWidgets.prototype, "timestamps", void 0);
__decorate([
    property({ type: Boolean })
], LiveLikeWidgets.prototype, "reactions", void 0);
__decorate([
    property({ type: Function })
], LiveLikeWidgets.prototype, "customWidgetRenderer", void 0);
__decorate([
    property({ type: Function })
], LiveLikeWidgets.prototype, "customTemplateRenderer", void 0);
__decorate([
    property({ type: Object })
], LiveLikeWidgets.prototype, "queue", void 0);
__decorate([
    property({ type: Object })
], LiveLikeWidgets.prototype, "syncStrategy", void 0);
__decorate([
    property({ type: Object })
], LiveLikeWidgets.prototype, "timeformat", void 0);
__decorate([
    property({ type: Boolean })
], LiveLikeWidgets.prototype, "hide_dismiss_button", void 0);
LiveLikeWidgets = __decorate([
    element('livelike-widgets')
], LiveLikeWidgets);
export { LiveLikeWidgets };

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html } from 'lit-element';
import { ThemedElement } from '../../mixins/ThemedElement';
import { element } from '../../utils/decorators';
import { styles } from './livelike-widget-root.style';
/**
 * @element livelike-widget-root
 * @slot header
 * @slot timer
 * @slot body
 * @slot Default Slot
 */
let LiveLikeWidgetRoot = class LiveLikeWidgetRoot extends ThemedElement {
    constructor() {
        super(...arguments);
        this.defaultStyles = styles;
    }
    // connectedCallback() {
    //   super.connectedCallback();
    // }
    defaultTemplate() {
        // console.log('widget root this.owner', this.owner);
        return html `
      <livelike-widget-bylines></livelike-widget-bylines>
      <slot name="header"></slot>
      <slot name="timer"></slot>
      <slot name="body"></slot>
      <slot></slot>
      <livelike-widget-reactions></livelike-widget-reactions>
    `;
    }
};
LiveLikeWidgetRoot = __decorate([
    element('livelike-widget-root')
], LiveLikeWidgetRoot);
export { LiveLikeWidgetRoot };

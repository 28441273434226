import { html } from 'lit-element';
export const modalStyles = html `
  <style>
    .livelike-modal {
      color: #000;
      padding: 1rem;
      background: #fff;
      border-radius: 5px;
      position: absolute;
      z-index: 15;
      width: 50%;
      top: 50%;
      left: 25%;
      opacity: 0;
    }
    .livelike-modal.showing {
      transition: all ease 0.3s;
      opacity: 1;
    }
  </style>
`;

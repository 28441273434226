let _sdkEventListenerMap = {};
/**
 * @description Add listener for a given SdkEvent
 * @since 2.24.0
 * @example
 * ```js
 * addSdkEventListener(SdkEvent.INITIALISED, function onSdkInitialisation(userProfile){
 *  console.log(userProfile);
 * })
 * ```
 */
export function addSdkEventListener(sdkEvent, listenerFn) {
    _sdkEventListenerMap[sdkEvent] = (_sdkEventListenerMap[sdkEvent] || []).concat(listenerFn);
}
/**
 * @description Remove registered listenerFn for the given SdkEvent,
 * when no listener passed, all registered event listener will be removed
 * @since 2.24.0
 * @example
 * ```js
 * removeSdkEventListener(SdkEvent.INITIALISED, onSdkInitialisation);
 * ```
 */
export function removeSdkEventListener(sdkEvent, listenerFn) {
    if (!_sdkEventListenerMap[sdkEvent]) {
        return;
    }
    if (!listenerFn) {
        _sdkEventListenerMap[sdkEvent] = [];
    }
    else {
        _sdkEventListenerMap[sdkEvent] = _sdkEventListenerMap[sdkEvent].filter((_listener) => _listener !== listenerFn);
    }
}
/**
 * @ignore
 */
export function getSdkEventListeners(sdkEvent) {
    return _sdkEventListenerMap[sdkEvent] || [];
}

import { errRefLink } from './index';
function getArgumentNumberLabel(argNumber) {
    switch (argNumber) {
        case 1:
            return '1st';
        case 2:
            return '2nd';
        case 3:
            return '3rd';
        default:
            return `${argNumber}th`;
    }
}
function createRejectResponse(errorMsges, path) {
    return errRefLink(errorMsges.join('\n'), path);
}
/**
 * validateArgs could be used to validate existence of mandatory API arguments or mandatory arg object properties
 * @param fnArgs - array of arguments
 * @param argsPropsToVerify - an array of string | string[], each array element sets the corresponding argument as mandatory
 * Incase argument is object with some mandatory properties, set array element as string[] where each subelement corresponds to mandatory object property.
 *
 * Example 1:
 * function abc(arg1, arg2) // arg2 is optional
 * validateArgs([arg1, arg2], ["arg1"]) // passing "arg1" as first element makes first argument mandatory
 *
 * Example 2:
 * function abc(arg1, arg2) // arg1 is object with "key1" as mandatory property
 * validateArgs([arg1, arg2], [["key1"]]) // passing ["key1"] as first element makes first argument mandatory and verify "key1" property exist in arg1
 *
 * @param scriptPath - path name which could be used for API docs reference link (for now, this is API function name, might change in future)
 * @param customValidator - optional validator function for having custom validation for eg: type checking
 * return reject string message in case validation fails or undefined if all proper
 */
export function validateArgs(fnArgs, argsPropsToVerify, scriptPath, customValidator) {
    const args = Array.from(fnArgs);
    let messages = [];
    for (const [index, argProp] of Object.entries(argsPropsToVerify)) {
        const isArray = Array.isArray(argProp);
        const argumentNumber = getArgumentNumberLabel(parseInt(index, 10) + 1);
        const argument = args[index];
        // validate argument
        if (argument === undefined) {
            messages.push(isArray
                ? `Missing object as ${argumentNumber} argument.`
                : `Missing "${argProp}" as ${argumentNumber} argument.`);
            continue;
        }
        if (isArray) {
            // check every property in the argument object
            const argObjValidation = validateObjectArgProperties(argument, argProp, argumentNumber);
            if (argObjValidation.length) {
                messages = messages.concat(argObjValidation);
            }
        }
    }
    const customValidatorResult = customValidator === null || customValidator === void 0 ? void 0 : customValidator();
    if (customValidatorResult) {
        messages = messages.concat(Array.isArray(customValidatorResult)
            ? customValidatorResult
            : [customValidatorResult]);
    }
    return messages.length
        ? Promise.reject(createRejectResponse(messages, scriptPath))
        : Promise.resolve(undefined);
}
function validateObjectArgProperties(argumentObj, argProps, argumentNumber) {
    let message = [];
    for (const prop of argProps) {
        // check for given property in argument object
        if (argumentObj[prop] === undefined) {
            message.push(`Missing object property "${prop}" in ${argumentNumber} argument object.`);
        }
    }
    return message;
}
/**
 * validateArgObj could be used to validate API with single argument object having certain mandatory properties
 * @param args required object argument whose properties to be validated
 * @param argsPropToVerify array of mandatory argument object key to be present
 * @param scriptPath - path name which could be used for API docs reference link (for now, this is API function name, might change in future)
 * @param customValidator - optional validator function for having custom validation for eg: type checking
 * return reject string message in case validation fails or undefined if all proper
 * @returns
 */
export function validateArgObj(args, argsPropToVerify, scriptPath, customValidator) {
    return validateArgs([args], [argsPropToVerify], scriptPath, customValidator);
}

import { Events, MessageEvents } from '../constants/ChatEvents';
import { _$$ } from '../scripts/internal/globals';
export const getBlockProfileIdsResource = () => _$$.blockedProfileIds;
export const setBlockProfileIdsResource = (ids) => {
    _$$.blockedProfileIds = ids;
};
const blockMessageChatEvents = [Events.RECEIVED, Events.UPDATED];
export const isBlockedProfileMessage = (message, event) => {
    return (blockMessageChatEvents.includes(MessageEvents[event]) &&
        getBlockProfileIdsResource().includes(message.sender_id));
};

import { _$$, userProfile } from './internal/globals';
import { getProgramResource, noArgPromiseRej, paginator, iteratorBase, listenerBase, request, buildUrl, getWidgetKindIdForInteraction, } from '../utils';
import { getMessages } from './internal/pubnubHandler';
import { register } from './internal/widgetMode';
export const getPaginatedWidgets = ({ programId, }) => !programId
    ? noArgPromiseRej('programId', 'getwidgets')
    : getProgramResource(programId).then((prog) => {
        const channel = prog.subscribe_channel;
        const paginatedMethod = (url) => {
            const widgetArr = [];
            let widgetInteractionString = '';
            return fetch(url)
                .then((r) => {
                // Due to caching of the widgets in the timeline resource,
                // the cache age must be checked to prevent very recently posted
                // widgets from not being returned from the timeline resource.
                // If response header age is > 0, there are widgets not yet in the timeline resource.
                const age = r.headers && Number(r.headers.get('age'));
                if (age && age > 0) {
                    // Convert age to seconds and subtract from current time.
                    // Add '0011' because pubnub timetoken is 17 digits.
                    const end = new Date().getTime() - age * 1000 + '0111';
                    getMessages(channel, { end }).then((r) => r.channels[channel] &&
                        r.channels[channel].map((m) => {
                            if (m.message && m.message.payload) {
                                widgetArr.push(m.message.payload);
                                let widgetInteractionDetails = getWidgetKindIdForInteraction(m.message.payload);
                                if (widgetInteractionDetails) {
                                    const { kind, id } = widgetInteractionDetails;
                                    widgetInteractionString =
                                        widgetInteractionString +
                                            '&' +
                                            kind.replace(/-/g, '_') +
                                            '_id=' +
                                            id;
                                }
                            }
                        }));
                }
                return r.json();
            })
                .then((r) => {
                r.results = [...widgetArr, ...r.results];
                if (r.widget_interactions_url_template.includes('?') &&
                    widgetInteractionString) {
                    r.widget_interactions_url_template =
                        r.widget_interactions_url_template + widgetInteractionString;
                }
                else if (widgetInteractionString &&
                    !r.widget_interactions_url_template.includes('?')) {
                    r.widget_interactions_url_template =
                        r.widget_interactions_url_template +
                            '?' +
                            widgetInteractionString;
                }
                return r;
            });
        };
        return paginator(prog.timeline_url, paginatedMethod, (r) => ({
            count: r.count,
            widgets: r.results,
            widget_interactions_url_template: r.widget_interactions_url_template,
        }));
    });
/**
 * Get list of widgets that have already been posted.
 * @async
 * @since 1.18.2
 */
export const getPostedWidgets = ({ programId, }) => getPaginatedWidgets({ programId }).then((iterator) => iteratorBase(['widgets', 'widget_interactions_url_template'], iterator));
/**
 * Gets widget object from passed kind and id
 * @async
 * @since 1.18.0
 */
export const getWidget = (args) => {
    const kindEnd = args.kind[args.kind.length - 1] === 'z' ? 'zes' : 's';
    return fetch(_$$.endpoint + args.kind + kindEnd + '/' + args.id + '/').then((r) => (r.ok ? r.json() : Promise.reject('Error getting widget')));
};
/**
 * Registers a custom widget state transition mode.
 * The name argument is used to pass to one of the widget instantiation methods to choose
 * @since 1.18.0
 */
export const registerWidgetMode = (name, mode) => register(name, mode);
/**
 * Add a widget listener
 * @since 2.4.0
 */
export const addWidgetListener = (arg, callback) => getProgramResource(arg.programId).then((prog) => listenerBase.add(prog.subscribe_channel, arg.programId, callback, {
    message: (msg) => msg.channel === prog.subscribe_channel &&
        callback({
            event: msg.message.event,
            widgetPayload: msg.message.payload,
            programId: arg.programId,
        }),
}));
/**
 * Remove widget listener
 * @since 2.4.0
 */
export const removeWidgetListener = (arg, callback) => listenerBase.remove(arg.programId, callback);
/**
 * Get list of widget interactions based on interaction url
 * or an array of widget id or an array of widget kinds
 * @async
 * @example
 * ```js
 * getWidgetInteractions({
 *    interactionUrl: "<url>"
 * }).then(interactions => console.log(interactions))
 *
 * // OR
 * getWidgetInteractions({
 *    programId: "<program-id>",
 *    widgets: [{
 *      kind: WidgetKind.TEXT_POLL,
 *      id: "<widget-id>"
 *   }]
 * }).then(interactions => console.log(interactions))
 *
 * // OR
 * getWidgetInteractions({
 *    programId: "<program-id>",
 *    widgetKinds: [ WidgetKind.TEXT_POLL, WidgetKind.TEXT_PREDICTION ]
 * }).then(interactions => console.log(interactions))
 * ```
 */
export const getWidgetInteractions = (args) => {
    if (!args.programId && !args.interactionUrl) {
        return noArgPromiseRej('programId or interactionUrl prop in', 'getWidgetInteractions');
    }
    else if (args.interactionUrl && !args.interactionUrl.includes('?')) {
        return Promise.reject('No Interaction Found');
    }
    else {
        let url = '';
        if (args.interactionUrl) {
            url = args.interactionUrl;
            return request
                .get({ url: buildUrl(userProfile.id, args.interactionUrl) })
                .then((res) => Promise.resolve(res.interactions))
                .catch((err) => Promise.reject(err));
        }
        else {
            return getProgramResource(args.programId).then((prog) => {
                var _a, _b;
                url = buildUrl(userProfile.id, prog.widget_interactions_url_template);
                let queryParams = [];
                if ((_a = args.widgets) === null || _a === void 0 ? void 0 : _a.length) {
                    queryParams = [
                        ...queryParams,
                        ...args.widgets.map((widget) => {
                            const kind = widget.kind.replace(/-/g, '_') + '_id';
                            return kind + '=' + widget.id;
                        }),
                    ];
                }
                if ((_b = args.widgetKinds) === null || _b === void 0 ? void 0 : _b.length) {
                    queryParams = [
                        ...queryParams,
                        ...args.widgetKinds.map((kind) => `widget_kind=${kind}`),
                    ];
                }
                const widgetInteractionUrl = `${url}?${queryParams.join('&')}`;
                return request
                    .get({ url: widgetInteractionUrl })
                    .then((res) => Promise.resolve(res.interactions))
                    .catch((err) => Promise.resolve({}));
            });
        }
    }
};
export const getPaginatedUnclaimedRewards = (args) => getProgramResource(args.programId)
    .then((r) => paginator(buildUrl(userProfile.id, r.unclaimed_widget_interactions_url_template), (url) => request.get({ url }), (r) => ({ count: r.count, results: r.results })))
    .catch((err) => Promise.reject(err));
/**
 * @description Fetch paginated List of Widgets filtered by widget status and kind
 * @since 2.13.0
 * @example
 * ```js
 * getWidgets({
 *   programId: "xxxx",
 *   status: "pending",
 *   widgetKinds: ["text-poll"],
 *   ordering: "recent",
 *   interactive: true,
 *   since: "2022-05-26T12:41:27.463Z"
 * }).then(res => console.log(res));
 * ```
 */
export const getWidgets = (args) => !args || !args.programId
    ? noArgPromiseRej('programId', 'getwidgets')
    : getProgramResource(args.programId)
        .then((prog) => {
        let url = prog.widgets_url + '?';
        if (args.status) {
            url = url + 'status=' + args.status;
        }
        args.widgetKinds &&
            args.widgetKinds.length > 0 &&
            args.widgetKinds.forEach((kind) => {
                url = url + '&kind=' + kind;
            });
        if (args.ordering) {
            url = url + '&ordering=' + args.ordering;
        }
        if (args.interactive !== undefined) {
            url = url + '&interactive=' + args.interactive;
        }
        if (args.since) {
            url = url + '&since=' + args.since;
        }
        return iteratorBase(['results'], paginator(url, (url) => request.get({ url }), (r) => ({ count: r.count, results: r.results })));
    })
        .catch((err) => Promise.reject(err));

import { ChatRoomEvent } from '../constants/subscriptionEvents';
import { ChatMessageEvent } from '../constants/ChatEvents';
export function isCustomMessageResponsePayload(message) {
    return message.message_event === ChatMessageEvent.CUSTOM_MESSAGE_CREATED;
}
export function isQuoteMessagePayload(message) {
    return message['quote_message_id'] !== undefined;
}
export var ChatRoomInvitationStatus;
(function (ChatRoomInvitationStatus) {
    ChatRoomInvitationStatus["ACCEPTED"] = "accepted";
    ChatRoomInvitationStatus["PENDING"] = "pending";
    ChatRoomInvitationStatus["REJECTED"] = "rejected";
})(ChatRoomInvitationStatus || (ChatRoomInvitationStatus = {}));
export var RewardItemTransferType;
(function (RewardItemTransferType) {
    RewardItemTransferType["SENT"] = "sent";
    RewardItemTransferType["RECEIVED"] = "received";
})(RewardItemTransferType || (RewardItemTransferType = {}));
export var WidgetStatus;
(function (WidgetStatus) {
    WidgetStatus["SCHEDULED"] = "scheduled";
    WidgetStatus["PENDING"] = "pending";
    WidgetStatus["PUBLISHED"] = "published";
})(WidgetStatus || (WidgetStatus = {}));
export var MessageContentFilter;
(function (MessageContentFilter) {
    MessageContentFilter["SHADOW_MUTED"] = "shadow-muted";
    MessageContentFilter["PROFANITY"] = "profanity";
})(MessageContentFilter || (MessageContentFilter = {}));

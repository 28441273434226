import { convertToCssString as cs } from '../../utils/themeConversions';
export const styles = (theme) => `
  @keyframes image0 {
    0% { transform: scale(1); }
    50% { transform: scale(1.2); }
    100% { transform: scale(1); }
  }
  @keyframes image1 {
    0% { transform: scale(1.2); }
    50% { transform: scale(1); }
    100% { transform: scale(1.2); }
  }

  .cheer-image-body {
    position: relative;
    display: flex;
  }

  .cheer-image-body livelike-vote-count {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    z-index: 100;
  }

  .cheer-item-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    position: relative;
    overflow: hidden;
    padding: 0 1.5rem;
    margin: auto;
  }

  .cheer-image-wrap {
    text-align: center;
    padding: 20px 0;
  }

  .cheer-image {
    position: relative;
    max-height: 80px;
    max-width: 85%;
    z-index: 5;
    top: 0;
    transition: all 0.2s;
    vertical-align: middle;
  }
  .cheer-image:hover {
    cursor: pointer;
  }
  .cheer-image.disabled:hover {
    cursor: default;
  }

  .button0.initial.enabled {
    animation: image0 2s linear 0s infinite normal;
  }
  .button1.initial.enabled {
    animation: image1 2s linear 0s infinite normal;
  }
  .cheer-image-wrap.enabled:after {
    content: '';
    position: absolute;
    display: block;
    z-index: 1;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    top: 10px;
    left: 50%;
    margin-left: -50px;
    opacity: 0;
    transition: all 0.2s;
  }
  .cheer-image-wrap.enabled:active:after {
    opacity: 1;
    width: 200px;
    height: 200px;
    top: -40px;
    margin-left: -100px;
  }
  .button0{
    ${cs(theme.sideAButton)}
  }
  .button1{
    ${cs(theme.sideBButton)}
  }
  .cheer-image.enabled:active {
    -webkit-transform: scale(0.9);
    -webkit-transform: rotate(10deg);
  }
  .button0.enabled:after {
    border: 2px solid rgba(80, 160, 250, 0.6);
    background: rgba(80, 160, 250, 0.4);
  }
  .button1.enabled:after {
    border: 2px solid rgba(250, 80, 100, 0.6);
    background: rgba(250, 80, 100, 0.4);
  }
`;
// .cheer-image-container.active.enabled .cheer-image-wrap.enabled:after {
//   content: '';
//   position: absolute;
//   display: block;
//   z-index: 1;
//   width: 50px;
//   height: 50px;
//   border-radius: 100%;
//   top: 35%;
//   left: 50%;
//   margin-left: -40px;
//   opacity: 0;
//   transition: all 0.2s;
// }
// .cheer-image-container.active.enabled .cheer-image-wrap.enabled:active:after {
//   opacity: 1;
//   width: 100px;
//   height: 100px;
//   top: 8px;
//   margin-left: -52px;
// }

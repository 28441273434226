var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, property } from 'lit-element';
import { element } from '../../utils/decorators';
import { styles } from './livelike-sticker-picker.style';
import { emojiIcon } from '../../icons';
import { LiveLikeChatElement } from '../../mixins/ChatElement';
import '../livelike-stickers';
let StickerPicker = class StickerPicker extends LiveLikeChatElement {
    constructor() {
        super(...arguments);
        this.selectedPack = '';
        this.changePack = (pack) => {
            this.selectedPack = pack && pack.id;
            this.selectedStickers = pack && pack.stickers;
        };
        this.stickerNav = () => this.owner.stickerPacks &&
            this.owner.stickerPacks.map((pack) => {
                const isActive = pack.id === this.selectedPack ? 'active' : '';
                return html `
        <button
          tabindex="0"
          type="button"
          aria-label="${this.owner.localize('chat.stickerPicker.stickerPackTab.ariaLabel', { packName: pack.name })}"
          class="sticker-nav-item ${isActive}"
          @click=${() => this.changePack(pack)}
        >
          <img src=${pack.file} alt="${pack.name}" />
        </button>
      `;
            });
        this.stickerNavFooter = () => html `
    <nav slot="sticker-drawer-footer" class="sticker-nav-container">
      <div class="sticker-nav-wrap">${this.stickerNav()}</div>
    </nav>
  `;
    }
    createRenderRoot() {
        return this;
    }
    ownerUpdated(e) {
        !this.selectedStickers &&
            e.name === 'stickerPacks' &&
            this.owner.stickerPacks &&
            this.owner.stickerPacks[0] &&
            this.changePack(this.owner.stickerPacks[0]);
    }
    render() {
        return html `
      ${styles}
      ${this.owner.stickerPacks && this.owner.stickerPacks.length
            ? html `
            <livelike-stickers
              .localizationKey=${'chat.stickerPicker'}
              .stickers=${this.selectedStickers}
              .buttonIcon=${emojiIcon}
              @drawer-opened=${() => { }}
              .navFooterTemplate=${this.stickerNavFooter}
            >
            </livelike-stickers>
          `
            : null}
    `;
    }
};
__decorate([
    property({ type: String })
], StickerPicker.prototype, "selectedPack", void 0);
__decorate([
    property({ type: Array })
], StickerPicker.prototype, "selectedStickers", void 0);
StickerPicker = __decorate([
    element('livelike-sticker-picker')
], StickerPicker);
export { StickerPicker };

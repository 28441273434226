var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, property } from 'lit-element';
import { defaultAvatar } from '../../icons';
import { LiveLikeChatElement } from '../../mixins/ChatElement';
import { element } from '../../utils/decorators';
let LivelikeChatMessageAvatar = class LivelikeChatMessageAvatar extends LiveLikeChatElement {
    constructor() {
        super(...arguments);
        this.senderImageUrl = null;
        this.avatarImageFailed = () => {
            this.senderImageUrl = null;
        };
    }
    createRenderRoot() {
        return this;
    }
    render() {
        const avatar = this.senderImageUrl
            ? html `
          <img
            src=${this.senderImageUrl}
            alt="Avatar"
            @error=${this.avatarImageFailed}
          />
        `
            : defaultAvatar;
        return html ` <div class="avatar">${avatar}</div> `;
    }
};
__decorate([
    property({ type: String })
], LivelikeChatMessageAvatar.prototype, "senderImageUrl", void 0);
LivelikeChatMessageAvatar = __decorate([
    element('livelike-chat-message-avatar')
], LivelikeChatMessageAvatar);
export { LivelikeChatMessageAvatar };

// @element //
const registeredTagNames = [];
export const isRegisteredTagName = (tagName) => registeredTagNames.includes(tagName);
export const registerElementSafely = (tagName, elementClass) => {
    if (!!customElements.get(tagName))
        console.warn(`${tagName} has already been registered`);
    else {
        customElements.define(tagName, elementClass);
        registeredTagNames.push(tagName);
    }
};
export const element = (tagName) => (elementClass) => registerElementSafely(tagName, elementClass);

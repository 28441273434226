import { html } from 'lit-element';
import { emojiIconInlineSvg } from '../../icons';
export const messageReactionStyles = html `
  <style>
    .reaction-button-container {
    }
    .reaction-button {
      border: none;
      border-radius: 0.5rem;
      background: rgba(255, 255, 255, 0.1);
      position: absolute;
      padding: 0.125rem 0.25rem;
      transform: translate(12px, -2px);
      right: 0;
      bottom: 0;
      top: auto;
      height: 21px;
      width: 21px;
      background-image: url('data:image/svg+xml;utf8,${emojiIconInlineSvg}');
      background-repeat: no-repeat;
      background-size: 13px 13px;
      background-position: center;
    }
    .reaction-button:hover {
      cursor: pointer;
    }
    .reaction-button > svg {
      fill: rgba(150, 150, 150, 0.5);
      height: 13px;
      width: 13px;
    }
    .reaction-item-container {
      position: absolute;
      display: flex;
      margin: 0;
      transition: opacity ease 0.3s;
      opacity: 0;
      z-index: 25;
      left: auto;
      right: auto;
      bottom: 0;
      top: auto;
    }
    .reaction-item-wrap {
      position: relative;
      display: inline-flex;
      padding: 0 1.4rem 0 0;
      height: 45px;
      border-radius: 12px;
      min-height: inherit;
      width: inherit;
      background: #282828;
    }
    .reaction-item {
      display: inline-block;
      position: relative;
      width: 2.5rem;
      height: 2.25rem;
      background: transparent;
      border-radius: 12px;
      border: none;
      margin: 4.5px 4px;
      cursor: pointer;
      padding: 0;
    }
    .reaction-item.selected,
    .reaction-item:hover,
    .reaction-item:focus {
      background: rgba(255, 255, 255, 0.2);
    }
    .reaction-image {
      width: 25px;
      height: 25px;
      position: relative;
      padding: 0;
    }
    .reaction-count {
      color: #fff;
      position: absolute;
      top: 0;
    }
    .mini-reactions {
      display: flex;
      position: absolute;
      right: 0;
    }
    .mini-reaction-image {
      height: 16px;
      width: 16px;
      margin: 0 0 0 -5px;
      vertical-align: middle;
    }
    .mini-reaction-count {
      color: #fff;
    }
  </style>
`;

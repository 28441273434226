var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, property } from 'lit-element';
import { ThemedWidget } from '../../mixins/WidgetTemplate';
import { getWidgetInteractions } from '../../scripts/widgets';
import { eventDispatcher } from '../../utils';
import { registerElementSafely } from '../../utils/decorators';
import { defaultHeader } from '../../utils/templates';
import { styles } from './votingWidget.styles';
import { numberStyles } from './numberVotingWidget.style';
import { PredictionFollowUp, predictionVotes } from './prediction';
import { WidgetKind } from '../../constants/Widgets';
export const numBase = (tag, superclass) => {
    registerElementSafely(tag, class extends superclass {
        render() {
            const textOptionTemplate = html `
          <div style="position:relative;width:100%;display:flex;">
            <livelike-description></livelike-description>
          </div>
        `;
            const imageOptionTemplate = html `
          <livelike-image height="64px" width="64px"></livelike-image>
          <div class="livelike-voting-image-container">
            <livelike-description></livelike-description>
          </div>
        `;
            const followUp = tag.indexOf('follow') !== -1;
            const widgetOption = (option, correct) => {
                const correctOption = !correct
                    ? html ` <input
                class="livelike-voting-number-input incorrect-number-input"
                type="number"
                placeholder="-"
                value="${option.number}"
                disabled
              />`
                    : null;
                return !followUp
                    ? html `
                <div class="livelike-voting-input-container">
                  <input
                    class="livelike-voting-number-input user-number-input"
                    type="number"
                    placeholder="-"
                    .value="${option.number}"
                    @input=${(e) => this.inputHandler(option, e)}
                    @keypress=${this.keypressHandler}
                    ?disabled="${this.disabled || this.voteDisable}"
                  />
                </div>
              `
                    : html `
                <div class="livelike-voting-input-container">
                  ${correctOption}
                  <input
                    class="livelike-voting-number-input correct-number-input"
                    type="number"
                    placeholder="-"
                    value="${option.correct_number}"
                    disabled
                  />
                </div>
              `;
            };
            const optionsRenderer = this.options.map((option, idx) => {
                const correct = option.number === option.correct_number;
                return html `
            <livelike-option index="${idx}">
              ${tag.indexOf('image') !== -1
                    ? imageOptionTemplate
                    : textOptionTemplate}
              ${widgetOption(option, correct)}
            </livelike-option>
          `;
            });
            const votedLabel = this.disabled && (this.vote || this.interaction)
                ? html `<span class="voted-text"
                >${this.owner.localize('widget.numberPrediction.votedText')}</span
              >`
                : null;
            const voteButton = !followUp
                ? html `<livelike-widget-footer>
              <button
                class="predict-button"
                @click=${() => this.lockInVote(this.options)}
                ?disabled="${this.disabled ||
                    this.voteDisable ||
                    this.voteButtonDisabled}"
              >
                ${this.owner.localize('widget.numberPrediction.voteButton.label')}
              </button>
              ${votedLabel}
            </livelike-widget-footer>`
                : null;
            return html `
          <template>
            <livelike-widget-root>
              ${styles} ${defaultHeader}
              <livelike-widget-body>
                ${optionsRenderer} ${voteButton}
              </livelike-widget-body>
            </livelike-widget-root>
          </template>
        `;
        }
    });
};
export class LiveLikeNumberPrediction extends ThemedWidget {
    constructor() {
        super(...arguments);
        this.defaultStyles = numberStyles;
        this.owner = this;
        this.voteButtonDisabled = true;
        this.isVoteValid = () => !this.options.some((option) => option.number == null);
        this.inputHandler = (option, e) => {
            const idx = e.target.value.indexOf('.');
            idx !== -1 && (e.target.value = e.target.value.replace(/[.][0-9]+$/, ''));
            const number = e.target.value ? +e.target.value : null;
            this.updateOption(option, number);
        };
        this.keypressHandler = (e) => e.which === 46 && (e.returnValue = false);
        this.updateOption = (option, number) => {
            if (!this.disabled) {
                if (Number.isInteger(number) || number == null) {
                    const idx = this.options.findIndex((c) => c.id === option.id);
                    this.options[idx].number = number;
                    this.voteButtonDisabled = !this.isVoteValid();
                    this.interactionEvent();
                }
                else {
                    console.error(`${number} is not an integer`);
                }
            }
        };
        this.lockInVote = (options) => {
            if (!this.voteDisable) {
                this.voteDisable = true;
                const data = {
                    votes: options &&
                        options instanceof Array &&
                        options.map((option) => {
                            return {
                                option_id: option.id,
                                number: option.number,
                            };
                        }),
                };
                this.createVote(this.vote_url, data).then((res) => {
                    this.disabled = true;
                    predictionVotes[res.widget_id] = {
                        votes: res.votes,
                        claim_token: res.claim_token,
                    };
                });
            }
        };
        this.resultEvent = () => {
            super.resultEvent();
            !this.interaction &&
                !this.vote &&
                this.options &&
                this.isVoteValid() &&
                this.lockInVote(this.options);
        };
    }
    firstUpdated() {
        super.firstUpdated();
        if (this.interaction) {
            this.interaction.votes.forEach((vote) => {
                const idx = this.options.findIndex((c) => c.id === vote.option_id);
                this.options[idx].number = vote.number;
            });
            this.requestUpdate();
        }
    }
}
__decorate([
    property({ type: Boolean })
], LiveLikeNumberPrediction.prototype, "voteButtonDisabled", void 0);
numBase('livelike-text-number-prediction', LiveLikeNumberPrediction);
numBase('livelike-image-number-prediction', LiveLikeNumberPrediction);
export class LiveLikeNumberFollowUp extends PredictionFollowUp {
    constructor() {
        super(...arguments);
        this.defaultStyles = numberStyles;
        this.owner = this;
        this.updateOptions = (interaction) => {
            interaction.votes.forEach((vote) => {
                const idx = this.options.findIndex((c) => c.id === vote.option_id);
                this.options[idx].number = vote.number;
            });
            this.requestUpdate();
        };
        this.resultEvent = () => {
            super.resultEvent();
            let result = 'unattempted';
            let isVoteCorrect = true;
            let isAttempted = true;
            this.options.forEach((option) => {
                option.number !== option.correct_number && (isVoteCorrect = false);
                option.number === undefined && (isAttempted = false);
            });
            if (this.options && isAttempted) {
                result = isVoteCorrect ? 'correct' : 'incorrect';
            }
            eventDispatcher(this, 'results', {
                result,
                element: this,
                widget: this.widgetPayload,
                votes: this.options,
            });
        };
    }
    firstUpdated() {
        super.firstUpdated();
        this.updateComplete.then(() => (this.disabled = true));
        const predId = this.widgetPayload.text_number_prediction_id ||
            this.widgetPayload.image_number_prediction_id;
        const predKind = this.widgetPayload.text_number_prediction_id
            ? WidgetKind.TEXT_NUMBER_PREDICTION
            : WidgetKind.IMAGE_NUMBER_PREDICTION;
        const savedPred = predictionVotes[predId] || this.interaction;
        if (savedPred) {
            this.updateOptions(savedPred);
            this.claimRewards(savedPred, predId);
        }
        else {
            getWidgetInteractions({
                programId: this.programid,
                widgets: [{ kind: predKind, id: predId }],
            }).then((res) => {
                if (res && res[predKind] && res[predKind][0]) {
                    this.updateOptions(res[predKind][0]);
                    this.claimRewards(res[predKind][0], predId);
                }
            });
        }
    }
}
numBase('livelike-image-number-prediction-follow-up', LiveLikeNumberFollowUp);
numBase('livelike-text-number-prediction-follow-up', LiveLikeNumberFollowUp);
